import {css} from '@emotion/react'

import {AnimatedContent, WideContent} from '../../components/content'
import GraphicContentGroup from '../../components/GraphicContentGroup'
import TranslationIcon from '../../assets/icons/translation.svg'
import AnnotationIcon from '../../assets/icons/annotation.svg'
import DisambiguationIcon from '../../assets/icons/disambiguation.svg'
import NLPIcon from '../../assets/icons/nlp.svg'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {
  backgroundLightGrayCss,
  baseSectionCss,
  centeredTextCss,
  sectionHeadingMarginCss,
} from '../../styles/common'
import {HeadingContent} from '../../components/headings'

const widthCss = css`
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    min-width: 444px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    min-width: unset;
  }
`

export default function UseCaseSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundLightGrayCss]}>
      <WideContent>
        <HeadingContent
          headerText="Kensho Extract Use Cases"
          css={[sectionHeadingMarginCss, centeredTextCss]}
        />
        <AnimatedContent animation="fade-in">
          <GraphicContentGroup
            css={widthCss}
            graphicContent={[
              {
                graphic: {data: TranslationIcon, isText: false},
                content: {
                  title: 'Translation',
                  description: (
                    <p css={widthCss}>
                      Extract text and tables while maintaining page structure for easy translation
                      to other languages.
                    </p>
                  ),
                },
              },
              {
                graphic: {data: AnnotationIcon, isText: false},
                content: {
                  title: 'Annotation',
                  description: (
                    <p css={widthCss}>
                      Augment your documents by pairing Kensho Extract with our NERD and LINK
                      services.
                    </p>
                  ),
                },
              },
              {
                graphic: {data: DisambiguationIcon, isText: false},
                content: {
                  title: 'Disambiguation',
                  description: (
                    <p css={widthCss}>
                      Find specific values in your documents to reduce your manual data operations
                      efforts.
                    </p>
                  ),
                },
              },
              {
                graphic: {data: NLPIcon, isText: false},
                content: {
                  title: 'Natural Language Processing (NLP)',
                  description: (
                    <p css={widthCss}>
                      Make it easy to run your own NLP models on documents without having to deal
                      with data extraction or structuring yourself.
                    </p>
                  ),
                },
              },
            ]}
            maxItemsPerRow={3}
          />
        </AnimatedContent>
      </WideContent>
    </section>
  )
}
