import {css} from '@emotion/react'

import bulletIcon from '../assets/icons/white-list-bullet.svg'
import {
  h3TitleCss,
  h3SubheadlineCss,
  h4Body1Css,
  h4Body2Css,
  labelCss,
  hBoldCss,
  sectionHeadingMarginCss,
  blackTextCss,
  centeredTextCss,
} from '../styles/common'
import {
  BREAKPOINT_XLARGE,
  BREAKPOINT_LARGE,
  BREAKPOINT_MEDIUM,
  BREAKPOINT_SMALL,
} from '../styles/breakpoints'

import {WideContent, AnimatedContent} from './content'

const shortTextContentCss = css`
  padding-top: 88px;
  padding-bottom: 68px;
  background: transparent;
  max-width: 900px;
  margin: auto;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    padding-top: 64px;
    padding-bottom: 24px;
    max-width: 100%;
  }
`

const textCss = css`
  opacity: 0.95;
`

interface ShortTextContentProps {
  text: string
  className?: string
}

export function ShortTextContent({text, className}: ShortTextContentProps): JSX.Element {
  return (
    <AnimatedContent css={shortTextContentCss} className={className}>
      <h3 css={[textCss, h3SubheadlineCss, blackTextCss, centeredTextCss]}>{text}</h3>
    </AnimatedContent>
  )
}

const textContentCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

const textContentWidthCss = css`
  max-width: 900px;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    max-width: 100%;
  }
`

const paragraphCss = css`
  :not(:last-child) {
    margin-bottom: 30px;
  }
`

export interface TextContentProps {
  title: string
  paragraphs: string[]
}

export function TextContent({title, paragraphs}: TextContentProps): JSX.Element {
  return (
    <WideContent css={textContentCss}>
      <AnimatedContent animation="fade-in" css={textContentWidthCss}>
        <h3 css={[centeredTextCss, sectionHeadingMarginCss, h3TitleCss, hBoldCss]}>{title}</h3>
        {paragraphs.map((text) => (
          <p css={[paragraphCss, h4Body2Css]} key={text}>
            {text}
          </p>
        ))}
      </AnimatedContent>
    </WideContent>
  )
}

const bulletedTextContentCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;

  @media (max-width: ${BREAKPOINT_XLARGE}px) {
    max-width: 1000px;
  }

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    max-width: 800px;
  }
`

const subtitleMarginCss = css`
  margin-bottom: 60px;
`

const listsContainerCss = css`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    gap: 20px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 60px;
  }
`

const listCss = css`
  list-style-image: url(${bulletIcon});
  padding-top: 30px;
  padding-left: 22px;
  margin: 0;
`

const itemPaddingCss = css`
  padding-left: 4px;
  padding-bottom: 8px;
`

const listItemCss = [h4Body1Css, itemPaddingCss]

const responsiveFlexCss = css`
  flex: 0 1 40%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    flex: 1 0;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex: unset;
    display: flex;
    justify-content: center;
  }
`

type BulletList = {
  title: string
  items: string[]
}

export interface BulletedTextContentProps {
  title: string
  subtitle: string
  leftList: BulletList
  rightList: BulletList
}

export function BulletedTextContent({
  title,
  subtitle,
  leftList,
  rightList,
}: BulletedTextContentProps): JSX.Element {
  return (
    <WideContent css={bulletedTextContentCss}>
      <h3 css={[sectionHeadingMarginCss, h3TitleCss, hBoldCss, centeredTextCss]}>{title}</h3>
      <p css={[subtitleMarginCss, h3SubheadlineCss, centeredTextCss]}>{subtitle}</p>
      <div css={listsContainerCss}>
        <div css={responsiveFlexCss}>
          <h4 css={[labelCss, hBoldCss]}>{leftList.title}</h4>
          <ul css={listCss}>
            {leftList.items.map((item) => (
              <li css={listItemCss} key={item}>
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div css={responsiveFlexCss}>
          <h4 css={[labelCss, hBoldCss]}>{rightList.title}</h4>
          <ul css={listCss}>
            {rightList.items.map((item) => (
              <li css={listItemCss} key={item}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </WideContent>
  )
}
