import {css} from '@emotion/react'

import ButtonLink from '../../components/Button/ButtonLink'
import TopicHeadline from '../../components/TopicHeadline'
import {AnimatedContent, WideContent} from '../../components/content'
import {
  h4Body1Css,
  h4Body2Css,
  centeredTextCss,
  backgroundLightGrayCss,
  blackTextCss,
  baseSectionCss,
  sectionHeadingMarginCss,
} from '../../styles/common'
import {HeadingContent} from '../../components/headings'

const contentCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const contentWidthCss = css`
  max-width: 908px;
`

const paragraphTextCss = css`
  color: rgb(0, 0, 0, 0.95);
  line-height: 35px;
`

const paragraphSpacingCss = css`
  margin-bottom: 30px;
`

const linkButtonCss = css`
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: 66px;
`

export default function StorySection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundLightGrayCss]}>
      <WideContent>
        <TopicHeadline css={blackTextCss} text="About Link" />
        <HeadingContent
          headerText="The Kensho Link Story"
          css={[sectionHeadingMarginCss, centeredTextCss]}
        />
        <AnimatedContent animation="fade-in" css={contentCss}>
          <p css={[contentWidthCss, paragraphSpacingCss, h4Body2Css, paragraphTextCss]}>
            Kensho Link began as an internal S&amp;P Global project, helping Market Intelligence and
            CapIQ integrate new datasets more quickly into their platform and Xpressfeed data
            offerings.
          </p>
          <p css={[contentWidthCss, paragraphSpacingCss, h4Body2Css, paragraphTextCss]}>
            As we learned more about the challenges of integrating successive large datasets, we
            began applying the lessons learned toward building a generic company linking model, one
            that would be robust to recognizing nuances and differences among datasets.
          </p>
          <p css={[contentWidthCss, paragraphSpacingCss, h4Body2Css, paragraphTextCss]}>
            In 2018, Kensho successfully linked Crunchbase’s private companies database to S&amp;P
            Global’s Market Intelligence data, enabling CapIQ and Market Intelligence platform users
            and data subscribers with access to privately held companies licensed from Crunchbase.
          </p>
          <p css={[contentWidthCss, h4Body2Css, paragraphTextCss]}>
            In 2020, the Kensho Link product launched on Marketplace to support S&amp;P Global’s
            data product offerings, giving customers direct access to Kensho Link’s capabilities for
            the first time.
          </p>
          <ButtonLink
            css={[contentWidthCss, h4Body1Css, linkButtonCss]}
            mode="light"
            variant="primary"
            to="https://link.kensho.com/"
          >
            Get Started
          </ButtonLink>
        </AnimatedContent>
      </WideContent>
    </section>
  )
}
