import {css} from '@emotion/react'

import {BREAKPOINT_LARGE} from '../../styles/breakpoints'
import {WideContent} from '../../components/content'
import {
  h3TitleCss,
  hBoldCss,
  blackTextCss,
  backgroundLightGrayCss,
  centeredTextCss,
  baseSectionCss,
} from '../../styles/common'
import Accordion from '../../components/Accordion'

const titleCss = css`
  margin-bottom: 80px;
  align-self: center;
`

const contentCss = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1000px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    max-width: 815px;
  }
`

const accordionItems = [
  {
    content: 'Do you support any type of document?',
    expandedContent:
      'We support any type of document that contains readable text, though poorly formatted documents are likely to result in lower extraction quality. Kensho Extract performs best with PDF files.',
  },
  {
    content: 'Do you support languages beyond English?',
    expandedContent:
      'Yes, we support extraction in any language, although performance will be better for left-to-right languages.',
  },
  {
    content: 'Do you support table extraction?',
    expandedContent:
      'Yes! You can extract tables and text from documents in their correct reading order.',
  },
  {
    content:
      'I only care about a specific topic in each document. Can you automate its extraction?',
    expandedContent:
      'Extract’s ability to automate extraction based on topic really depends on the use case. In some instances and with some training, Kensho Extract will be able to identify and send back just the table(s) or section(s) that interest you, leaving out everything else. Reach out to us for help on your specific needs!',
  },
]

export default function FAQSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundLightGrayCss]}>
      <h3 css={[titleCss, centeredTextCss, h3TitleCss, hBoldCss, blackTextCss]}>
        Frequently Asked Questions
      </h3>
      <WideContent css={contentCss}>
        <Accordion defaultExpanded accordionContent={accordionItems} />
      </WideContent>
    </section>
  )
}
