import DeveloperContent from '../../components/DeveloperContent/DeveloperContent'

export default function DeveloperSection(): JSX.Element {
  return (
    <section>
      <DeveloperContent
        description="See our developer documentation for information on how to transcribe your files, stream audio, and more with the Scribe APIs"
        product="Scribe"
        href="https://scribe.kensho.com/docs/developer-guide/"
      />
    </section>
  )
}
