import {Helmet} from 'react-helmet'
import {useLocation} from 'react-router-dom'

import {BASE_URL} from '../constants'

interface PageMetadataProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  title: string
  description: string
  image?: {source: string; height: number; width: number; type?: string}
}

export default function PageMetadata(props: PageMetadataProps): JSX.Element {
  const {title, description, image} = props
  const location = useLocation()
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Kensho Technologies" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={`${BASE_URL}${location.pathname}`} />
      </Helmet>
      {image && (
        <Helmet>
          <meta property="og:image" content={`${BASE_URL}${image.source}`} />
          <meta property="og:image:width" content={String(image.width)} />
          <meta property="og:image:height" content={String(image.height)} />
          <meta property="og:image:alt" content={title} />
          <meta property="og:image:type" content={image.type ?? 'image/jpg'} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image:alt" content={title} />
        </Helmet>
      )}
    </>
  )
}
