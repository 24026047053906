import {css} from '@emotion/react'

import ButtonLink from '../../Button/ButtonLink'
import type {ButtonVariant} from '../../Button/getButtonStyles'
import {labelCss} from '../../../styles/common'

import {NavLink} from './navigation'

interface NavLinkButtonProps {
  link: NavLink
  mode: 'dark' | 'light'
  variant: ButtonVariant
  className?: string
}

const navLinkCss = css`
  transition: all 300ms;
`

export default function NavLinkButton({
  className,
  link,
  mode,
  variant,
}: NavLinkButtonProps): JSX.Element {
  if (!link.to)
    return (
      <div css={[navLinkCss, labelCss]} className={className}>
        {link.label}
        {link.rightIcon && link.rightIcon}
      </div>
    )

  return (
    <ButtonLink
      to={link.to}
      mode={mode}
      variant={variant}
      css={[navLinkCss, labelCss]}
      className={className}
      {...(link.newTab
        ? {
            target: '_blank',
            rel: 'noopener noreferrer',
          }
        : {})}
    >
      {link.label}
      {link.rightIcon && link.rightIcon}
    </ButtonLink>
  )
}
