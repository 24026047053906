import {css} from '@emotion/react'

import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../styles/breakpoints'
import {h4Body2Css, labelCss, centeredTextCss, whiteTextCss} from '../styles/common'

const leftAlignCss = css`
  text-align: left;
`

const tableOverflowContainer = css`
  margin: 60px 0px 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  overflow: auto;
  white-space: nowrap;
`

const tableSizingContainer = css`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    justify-content: flex-start;
  }
`

const stickyColumnCss = css`
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  background-clip: padding-box;
  background-color: #007694;
`

const tableCss = css`
  table-layout: fixed;
  width: 910px;
  border-collapse: separate;
  border-spacing: 0px;
  margin-bottom: 10px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    width: 100%;
    min-width: 511px;
  }
`

const tableHeaderCss = css`
  padding: 15px 0px 15px 0px;
  border-bottom: 2px solid #ffffff;

  &:not(:first-of-type) {
    width: 225px;
  }

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    &:not(:first-of-type) {
      width: 200px;
    }

    padding-bottom: 25px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    &:not(:first-of-type) {
      width: 170px;
    }

    vertical-align: top;
    padding-bottom: 13px;
  }
`

const cellCss = css`
  border-bottom: 1px solid #ffffff;

  &:not(:first-of-type) {
    padding: 28px 5px 28px 0px;
  }
`

const noBorderCss = css`
  border: none;
`

const innerCellCss = [cellCss, labelCss, centeredTextCss, whiteTextCss]

const borderCss = css`
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    border-right: 1px solid #ffffff;
  }
`

const leftCellCss = [stickyColumnCss, borderCss, labelCss, cellCss, whiteTextCss]

export interface TableCell {
  key: string
  display: React.ReactNode
}

export interface TableRow {
  key: string
  cells: TableCell[]
}

interface ProductComparisonTableProps {
  className?: string
  headerCells: TableCell[]
  rows: TableRow[]
}

export default function ProductComparisonTable({
  className,
  headerCells,
  rows,
}: ProductComparisonTableProps): JSX.Element {
  return (
    <div css={tableOverflowContainer} className={className}>
      <div css={tableSizingContainer}>
        <table css={tableCss}>
          <thead>
            <tr>
              <th
                scope="col"
                css={[
                  stickyColumnCss,
                  borderCss,
                  tableHeaderCss,
                  leftAlignCss,
                  h4Body2Css,
                  whiteTextCss,
                ]}
              >
                {headerCells[0].display}
              </th>
              {headerCells.slice(1).map(({key, display}) => (
                <th scope="col" css={[tableHeaderCss, h4Body2Css, whiteTextCss]} key={key}>
                  {display}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map(({key: rowKey, cells}, rowIndex) => (
              <tr key={rowKey}>
                {cells.map(({key: cellKey, display}, index) => (
                  <td
                    css={[
                      index === 0 ? leftCellCss : innerCellCss,
                      rowIndex === rows.length - 1 ? noBorderCss : null,
                    ]}
                    key={cellKey}
                  >
                    {display}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
