import {LoggerProvider, transportSentry, transportConsole} from '@kensho/lumberjack'

const transports =
  process.env.NODE_ENV === 'production' ? [transportSentry()] : [transportConsole()]

interface AppLoggerProviderProps {
  children: React.ReactNode
}

export default function AppLoggerProvider(props: AppLoggerProviderProps): JSX.Element {
  const {children} = props
  return <LoggerProvider transports={transports}>{children}</LoggerProvider>
}
