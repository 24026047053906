import {css} from '@emotion/react'

import cleaningIcon from '../../assets/icons/cleaning.svg'
import coverageExpansionIcon from '../../assets/icons/coverage-expansion.svg'
import dataDuplicationIcon from '../../assets/icons/data-deduplication.svg'
import dataSynchronizationIcon from '../../assets/icons/data-sync.svg'
import dataEnrichmentIcon from '../../assets/icons/diamond.svg'
import productEnhancementIcon from '../../assets/icons/product-enhancement.svg'
import GraphicContentGroup from '../../components/GraphicContentGroup'
import TopicHeadline from '../../components/TopicHeadline'
import {AnimatedContent, WideContent} from '../../components/content'
import {
  h4Body1Css,
  blackTextCss,
  centeredTextCss,
  backgroundWhiteCss,
  baseSectionCss,
  sectionHeadingMarginCss,
} from '../../styles/common'
import SingleQuote from '../../components/SingleQuote'
import {HeadingContent} from '../../components/headings'

const contentCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const contentWidthCss = css`
  max-width: 908px;
`

const paragraphTextCss = css`
  color: rgb(0, 0, 0, 0.95);
  line-height: 35px;
`

const paragraphSpacingCss = css`
  margin-bottom: 40px;
`

export default function ImpactSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundWhiteCss]}>
      <WideContent>
        <TopicHeadline css={blackTextCss} text="Link's Impact" />
        <div css={contentCss}>
          <HeadingContent
            headerText="Save Time and Reduce Errors"
            css={[sectionHeadingMarginCss, centeredTextCss]}
          />
          <AnimatedContent>
            <p css={[contentWidthCss, paragraphSpacingCss, h4Body1Css, paragraphTextCss]}>
              Manual linking processes are impractical, and traditional automated solutions lack
              accuracy for all but the simplest linking jobs. Kensho Link accurately processes
              millions of company entities in hours.
            </p>
          </AnimatedContent>
          <AnimatedContent>
            <SingleQuote>
              Kensho Link’s strength is based on its algorithms and the unparalleled data of S&amp;P
              Global backing it.
            </SingleQuote>
          </AnimatedContent>
          <AnimatedContent>
            <p css={[contentWidthCss, paragraphSpacingCss, h4Body1Css, paragraphTextCss]}>
              Using proprietary training data from S&amp;P Global’s unique datasets, Kensho’s
              machine learning algorithms understand the patterns and data required to make highly
              accurate link predictions, even if your data is incomplete, uses aliases and
              abbreviations, or has incorrect information.
            </p>
          </AnimatedContent>
          <HeadingContent
            headerText="What are the benefits to using Kensho Link?"
            css={[sectionHeadingMarginCss, centeredTextCss]}
          />
          <AnimatedContent animation="fade-in">
            <GraphicContentGroup
              maxItemsPerRow={3}
              graphicContent={[
                {
                  graphic: {data: dataEnrichmentIcon, isText: false},
                  content: {
                    title: 'Data Enrichment',
                    description:
                      'Connect a list of company names to CapIQ IDs, enabling enrichment of your data with S&P Global’s vast databases.',
                  },
                },
                {
                  graphic: {data: productEnhancementIcon, isText: false},
                  content: {
                    title: 'Product Enhancement',
                    description:
                      'Enhance your database of companies and CRM with more valuable data, making them easier to effectively manage.',
                  },
                },
                {
                  graphic: {data: dataSynchronizationIcon, isText: false},
                  content: {
                    title: 'Data Synchronization',
                    description:
                      'Synchronize your data making it simple to add new data to your company databases.',
                  },
                },
                {
                  graphic: {data: cleaningIcon, isText: false},
                  content: {
                    title: 'Cleaning',
                    description:
                      'Uncover messy, erroneous or contradictory company data as inconsistencies are surfaced compared to S&P Global’s most up-to-date datasets.',
                  },
                },
                {
                  graphic: {data: dataDuplicationIcon, isText: false},
                  content: {
                    title: 'Data Deduplication',
                    description:
                      'Resolve and merge duplicates by assigning a unique ID to each company in your database.',
                  },
                },
                {
                  graphic: {data: coverageExpansionIcon, isText: false},
                  content: {
                    title: 'Coverage Expansion',
                    description:
                      'Quickly verify that CapIQ’s coverage fulfills your company’s data enrichment requirements.',
                  },
                },
              ]}
            />
          </AnimatedContent>
        </div>
      </WideContent>
    </section>
  )
}
