import {css} from '@emotion/react'

import familySupportIcon from '../../assets/icons/family-support.svg'
import healthWellnessIcon from '../../assets/icons/health-wellness.svg'
import investEmployeesIcon from '../../assets/icons/invest-employees.svg'
import workLifeBalanceIcon from '../../assets/icons/work-life.svg'
import BenefitsHeroLarge from '../../assets/photos/benefits-hero-large.jpg'
import BenefitsHeroMedium from '../../assets/photos/benefits-hero-medium.jpg'
import BenefitsHeroSmall from '../../assets/photos/benefits-hero-small.jpg'
import socialImg from '../../assets/social/benefits.jpg'
import {AnimatedContent, WideContent} from '../../components/content'
import GraphicContentGroup, {GraphicContent} from '../../components/GraphicContentGroup'
import HeroSection from '../../components/Hero/HeroSection'
import Link from '../../components/Link'
import Page from '../../components/Page'
import PageMetadata from '../../components/PageMetadata'
import TopicHeadline from '../../components/TopicHeadline'
import {TwoColumnHeadingContent} from '../../components/headings'
import {
  backgroundGradientCss,
  backgroundWhiteCss,
  baseSectionCss,
  bulletedListCss,
  bulletedListItemCss,
  noMarginCss,
  whiteTextCss,
} from '../../styles/common'

const heroTitleCss = css`
  margin: 100px 0 200px 0;
  color: white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media (max-width: 2100px) {
    margin: 50px 0 150px 0;
  }

  @media (max-width: 600px) {
    margin: 25px 0 125px 0;
  }
`

const innerContentCss = css`
  max-width: 420px;
  margin: 0 auto;
  text-align: left;

  p,
  li {
    text-align: left;
  }
`

const graphicContentData: GraphicContent[] = [
  {
    graphic: {data: healthWellnessIcon, isText: false},
    content: {
      title: 'Health and Wellness',
      description: (
        <div css={innerContentCss}>
          <p>
            We recognize life comes at you fast. Kensho provides best-in-class benefits and support
            where it matters most.
          </p>
          <ul css={bulletedListCss}>
            <li css={bulletedListItemCss}>
              100% Employer-Paid Health Benefits
              <sup>
                <Link to="https://transparency-in-coverage.bluecrossma.com/">1</Link>
              </sup>
            </li>
            <li css={bulletedListItemCss}>Comprehensive LOAs</li>
            <li css={bulletedListItemCss}>Annual Wellness Reimbursement</li>
            <li css={bulletedListItemCss}>Employee Wellbeing Support Resources</li>
          </ul>
        </div>
      ),
    },
  },
  {
    graphic: {data: workLifeBalanceIcon, isText: false},
    content: {
      title: 'Work-Life Balance',
      description: (
        <div css={innerContentCss}>
          <p>
            Kensho is a flexibility-first company. We encourage our employees to work and disconnect
            wherever, whenever and however they choose.
          </p>
          <ul css={bulletedListCss}>
            <li css={bulletedListItemCss}>Unlimited PTO</li>
            <li css={bulletedListItemCss}>Work-from-Anywhere Policy</li>
            <li css={bulletedListItemCss}>Flexible Working Hours</li>
            <li css={bulletedListItemCss}>Generous Holiday Schedule</li>
            <li css={bulletedListItemCss}>100% Paid 10-Week Sabbatical</li>
          </ul>
        </div>
      ),
    },
  },
  {
    graphic: {data: investEmployeesIcon, isText: false},
    content: {
      title: 'Investing in our Employees',
      description: (
        <div css={innerContentCss}>
          <p>
            At Kensho we encourage a growth mindset fueled by intellectual curiosity. We make it a
            priority to continually invest in our employees’ development and long-term success.
          </p>
          <ul css={bulletedListCss}>
            <li css={bulletedListItemCss}>401k &amp; Employer Match</li>
            <li css={bulletedListItemCss}>Monthly Knowledge Days</li>
            <li css={bulletedListItemCss}>Continual Learning Benefit</li>
            <li css={bulletedListItemCss}>$20k Tuition Reimbursement</li>
            <li css={bulletedListItemCss}>Frequent Learning &amp; Development Opportunities</li>
          </ul>
        </div>
      ),
    },
  },
  {
    graphic: {data: familySupportIcon, isText: false},
    content: {
      title: 'Family Support',
      description: (
        <div css={innerContentCss}>
          <p>
            We recognize that family looks different for everyone (and often includes your furry
            friends)! Kensho is here to support you through all of it.
          </p>

          <ul css={bulletedListCss}>
            <li css={bulletedListItemCss}>26-Week Paid Parental Leave</li>
            <li css={bulletedListItemCss}>Adoption Assistance</li>
            <li css={bulletedListItemCss}>Dog-Friendly Office in CAM</li>
            <li css={bulletedListItemCss}>PAW-rental Leave</li>
          </ul>
        </div>
      ),
    },
  },
]

const heroImageCss = css`
  background-image: url(${BenefitsHeroLarge});
  background-position-y: 0;
  filter: contrast(120%) brightness(50%) grayscale(30%);

  @media (max-width: 2200px) {
    background-image: url(${BenefitsHeroMedium});
  }

  @media (max-width: 600px) {
    background-image: url(${BenefitsHeroSmall});
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    opacity: 1;
  }
`

export default function BenefitsPage(): JSX.Element {
  return (
    <Page
      title={
        <HeroSection imageCss={heroImageCss}>
          <WideContent>
            <AnimatedContent>
              <h1 css={[heroTitleCss, whiteTextCss]}>Benefits</h1>
            </AnimatedContent>
          </WideContent>
        </HeroSection>
      }
    >
      <PageMetadata
        title="Benefits | Kensho"
        description="Kensho offers a best-in-class benefits package that supports your health and wellness, work-life balance, family and financial success."
        image={{source: socialImg, height: 1250, width: 2500}}
      />
      <section css={[baseSectionCss, whiteTextCss, backgroundGradientCss]}>
        <WideContent>
          <AnimatedContent>
            <TopicHeadline
              text="Benefits that Put Employee Wellbeing at the Center"
              css={noMarginCss}
            />
          </AnimatedContent>
        </WideContent>
        <TwoColumnHeadingContent
          headerText="We want you to feel comfortable bringing your whole self to work."
          additionalText="This starts with providing best-in-class and innovative benefits to empower you to
          be your best self at both work and at home. With an emphasis on stability,
          flexibility and support -- Kensho will be with you throughout all facets of your
          life."
          textColor="white"
        />
      </section>

      <section css={[baseSectionCss, backgroundWhiteCss]}>
        <WideContent>
          <AnimatedContent>
            <GraphicContentGroup graphicContent={graphicContentData} maxItemsPerRow={2} />
          </AnimatedContent>
        </WideContent>
      </section>
    </Page>
  )
}
