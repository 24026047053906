import {css} from '@emotion/react'

import SolutionCard from './SolutionCard'

const lineSpacingCss = css`
  margin-bottom: 20px;
`

export default function SolutionsIndices(): JSX.Element {
  return (
    <SolutionCard
      label="New Economies"
      description="The S&P Kensho New Economy Indices provide the most comprehensive framework with which to capture the industries and innovations of the Fourth Industrial Revolution."
      title="Indices"
      learnMoreUrl="https://www.spglobal.com/spdji/en/landing/investment-themes/new-economies/"
      mainContent={
        <>
          <p css={lineSpacingCss}>
            Rapid developments in artificial intelligence (AI) and robotics—coupled with ubiquitous
            connectivity and vast, easily accessible processing power—are the catalysts disrupting
            vast swathes of the global economy. Every facet of our lives will be impacted. This new
            industrial epoch is known as the Fourth Industrial Revolution.
          </p>
          <p>
            The S&P Kensho New Economies are the first and most comprehensive family of indices to
            capture the full breadth of companies driving the Fourth Industrial Revolution. They
            provide a unique lens with which to understand the disruption of existing industries and
            emergence of new ones.
          </p>
        </>
      }
    />
  )
}
