import {createContext} from 'react'

export type Size = 'small' | 'medium' | 'large'

export interface MenuContextValue {
  size?: Size
}

export const DEFAULT_MENU_CONTEXT: MenuContextValue = {}

export default createContext<MenuContextValue>(DEFAULT_MENU_CONTEXT)
