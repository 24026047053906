import {css} from '@emotion/react'

import {h4Body2Css, hBoldCss} from '../../styles/common'

interface ContactItemProps {
  children: React.ReactNode
  heading: string
  text: string
}

const contactItemCss = css`
  width: 300px;
  flex: 1 1 auto;
  margin: 40px 20px;

  @media (max-width: 600px) {
    margin: 20px 10px;
  }
`

const headingCss = css`
  font-size: 20px;
  text-align: left;
`

const textCss = css`
  margin: 12px 0;
`

export default function ContactItem({heading, text, children}: ContactItemProps): JSX.Element {
  return (
    <div css={contactItemCss}>
      <h2 css={[headingCss, hBoldCss]}>{heading}</h2>
      <p css={[textCss, h4Body2Css]}>{text}</p>
      {children}
    </div>
  )
}
