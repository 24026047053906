import {css} from '@emotion/react'

import extractDocumentImg from '../../assets/extract-animation/spgi-10q-p4.png'
import useHasScrolledInBounds from '../../hooks/useHasScrolledInBounds'
import {
  BREAKPOINT_XLARGE,
  BREAKPOINT_LARGE,
  BREAKPOINT_MEDIUM,
  BREAKPOINT_SMALL,
} from '../../styles/breakpoints'

import ExtractedDocumentContent from './ExtractedDocumentContent'

// Animation has seven stages over 11s:
//  - 6s "scanning" the page
//  - 1s "zoom out"
//  - 2s "scanning" more pages
//  - 0.5s fade white
//  - 0.5s hold white, "zoom in"
//  - 0.5s fade in
//  - 0.5s wait
// All animations are defined once per screen size, e.g. desktop (>${BREAKPOINT_LARGE}px), tablet (500-${BREAKPOINT_LARGE}px), and mobile (<${BREAKPOINT_SMALL}px)
// This ensures that animation components don't get out of sync with scannerLoop animation when screen size changes

const animationContainerCss = css`
  max-width: 100%;
  width: 800px;
  height: 1000px;
  position: relative;

  overflow: hidden;

  @media (max-width: ${BREAKPOINT_XLARGE}px) {
    height: 640px;
  }

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    height: 1000px;
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    height: 915px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 300px;
    height: 357px;
    padding: 0;
  }
`

const fadeAnimationCss = css`
  animation: sceneLoopWide 11s infinite;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    animation: sceneLoopMobile 11s infinite;
  }

  @keyframes sceneLoopWide {
    from,
    81.8%, /* 9s */
    95.3%, /* 10.5s */
    to {
      opacity: 1;
    }

    86.4%, /* 9.5s */
    90.9% /* 10s */ {
      opacity: 0;
    }
  }

  @keyframes sceneLoopMed {
    from,
    81.8%, /* 9s */
    95.6%, /* 10.5s */
    to {
      opacity: 1;
    }

    86.4%, /* 9.5s */
    90.9% /* 10s */ {
      opacity: 0;
    }
  }

  @keyframes sceneLoopMobile {
    from,
    81.8%, /* 9s */
    95.6%, /* 10.5s */
    to {
      opacity: 1;
    }

    86.4%, /* 9.5s */
    90.9% /* 10s */ {
      opacity: 0;
    }
  }
`

const zoomContainerCss = css`
  width: 200%;
`

const zoomAnimationCss = css`
  animation: zoomLoopWide 11s infinite;
  transform-origin: top left;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    animation: zoomLoopMobile 11s infinite;
  }

  @keyframes zoomLoopWide {
    from,
    42.9%, /* 6s */
    90.9%, /* 10s */
    to {
      transform: scale(1);
    }

    50%, /* 7s */
    86.4% /* 9.5s */ {
      transform: scale(0.5);
    }
  }

  @keyframes zoomLoopMed {
    from,
    42.9%, /* 6s */
    90.9%, /* 10s */
    to {
      transform: scale(1);
    }

    50%, /* 7s */
    86.4% /* 9.5s */ {
      transform: scale(0.5);
    }
  }

  @keyframes zoomLoopMobile {
    from,
    42.9%, /* 6s */
    90.9%, /* 10s */
    to {
      transform: scale(1);
    }

    50%, /* 7s */
    86.4% /* 9.5s */ {
      transform: scale(0.5);
    }
  }
`

const documentContainerCss = css`
  display: inline-block;
  position: relative;
  width: 48%;
  margin: 1%;
  padding: 0 12px;
`

const documentGraphicCss = css`
  width: 100%;
`

const overlayCss = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const scannedAreaCss = css`
  height: 100%;
  width: 100%;
  padding: 0 12px;
`

const documentGraphicOverlayCss = css`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0 12px;
  object-fit: cover;
  object-position: bottom;
  overflow: hidden;
  border-top: 4px solid #50afc6;
`

const animatedHeightCss = css`
  animation: scannerLoopWide 11s infinite linear;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    animation: scannerLoopMobile 11s infinite linear;
  }

  @keyframes scannerLoopWide {
    from,
    90.9%, /* 10s */
    to {
      max-height: 979px;
    }

    42.9%, /* 6s */
    86.4% /* 9.5s */ {
      max-height: 0;
    }
  }

  @keyframes scannerLoopMed {
    from,
    90.9%, /* 10s */
    to {
      max-height: 561px;
    }

    42.9%, /* 6s */
    86.4% /* 9.5s */ {
      max-height: 0;
    }
  }

  @keyframes scannerLoopMobile {
    from,
    90.9%, /* 10s */
    to {
      max-height: 369px;
    }

    42.9%, /* 6s */
    86.4% /* 9.5s */ {
      max-height: 0;
    }
  }
`

const delayedAnimatedHeightCss = css`
  animation: delayedScannerLoopWide 11s infinite linear;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    animation: delayedScannerLoopMobile 11s infinite linear;
  }

  @keyframes delayedScannerLoopWide {
    from,
    50%, /* 7s */
    90.9%, /* 10s */
    to {
      max-height: 979px;
    }

    90% /* 9.9s */ {
      max-height: 0;
    }
  }

  @keyframes delayedScannerLoopMed {
    from,
    50%, /* 7s */
    90.9%, /* 10s */
    to {
      max-height: 561px;
    }

    90% /* 9.9s */ {
      max-height: 0;
    }
  }

  @keyframes delayedScannerLoopMobile {
    from,
    50%, /* 7s */
    90.9%, /* 10s */
    to {
      max-height: 369px;
    }

    90% /* 9.9s */ {
      max-height: 0;
    }
  }
`

interface ExtractScanAnimationProps {
  delay?: boolean
  fuse: boolean
}

function ExtractScanAnimation({delay = false, fuse}: ExtractScanAnimationProps): JSX.Element {
  const animationCss = delay ? delayedAnimatedHeightCss : animatedHeightCss
  return (
    <div css={documentContainerCss}>
      <img
        src={extractDocumentImg}
        alt="Document being processed by Kensho Extract"
        css={documentGraphicCss}
      />
      <div css={overlayCss}>
        <div css={scannedAreaCss}>
          <ExtractedDocumentContent delay={delay} fuse={fuse} />
        </div>
        <img
          src={extractDocumentImg}
          alt="Document being processed by Kensho Extract"
          css={[documentGraphicOverlayCss, fuse && animationCss]}
        />
      </div>
    </div>
  )
}

export default function ExtractAnimatedDocument(): JSX.Element {
  // start animating as soon as the target comes into view and don't stop once it has started
  const {hasScrolledInBounds, setNode} = useHasScrolledInBounds<HTMLDivElement>()

  return (
    <div css={animationContainerCss} ref={setNode}>
      <div css={hasScrolledInBounds && fadeAnimationCss}>
        <div css={[zoomContainerCss, hasScrolledInBounds && zoomAnimationCss]}>
          <ExtractScanAnimation fuse={hasScrolledInBounds} />
          <ExtractScanAnimation fuse={hasScrolledInBounds} delay />
          <ExtractScanAnimation fuse={hasScrolledInBounds} delay />
          <ExtractScanAnimation fuse={hasScrolledInBounds} delay />
        </div>
      </div>
    </div>
  )
}
