import {css} from '@emotion/react'

import quoteIcon from '../../assets/icons/blue-gradient-quote.svg'
import {AnimatedContent, WideContent} from '../../components/content'
import TopicHeadline from '../../components/TopicHeadline'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {
  backgroundWhiteCss,
  baseSectionCss,
  blackTextCss,
  centeredTextCss,
  h4Body1Css,
  h4Body2Css,
  hBoldCss,
  sectionHeadingMarginCss,
} from '../../styles/common'
import {HeadingContent} from '../../components/headings'

const contentCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const contentWidthCss = css`
  max-width: 908px;
`

const subTitleCss = css`
  margin-bottom: 24px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-bottom: 33px;
  }
`

const blueTextCss = css`
  color: #0066ff;
`

const paragraphTextCss = css`
  color: rgb(0, 0, 0, 0.95);
  line-height: 35px;
`

const quoteContainerCss = css`
  display: flex;
  align-items: flex-start;
  margin: 40px 0px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin: 68px 0px;
    flex-direction: column;
  }
`

const quoteIconCss = css`
  width: 150px;
  height: 120px;
  margin-right: 52px;
  margin-left: 32px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin: 0px 20px 0px 0px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 70px;
    height: 56px;
    margin: 0px 0px 15px;
  }
`

const quoteContentCss = css`
  max-width: 1135px;
`

const paragraphSpacingCss = css`
  margin-bottom: 20px;
`

export default function StorySection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundWhiteCss]}>
      <WideContent>
        <TopicHeadline css={blackTextCss} text="About Scribe" />
        <HeadingContent
          headerText="The Kensho Scribe Story"
          css={[sectionHeadingMarginCss, centeredTextCss]}
        />
        <AnimatedContent animation="fade-in">
          <div css={contentCss}>
            <h3 css={[contentWidthCss, subTitleCss, h4Body1Css, hBoldCss, blackTextCss]}>
              S&amp;P Global is a leading provider of financial data, including credit ratings and
              analytics for global capital and commodity markets.
            </h3>
            <p css={[contentWidthCss, paragraphSpacingCss, h4Body2Css, paragraphTextCss]}>
              The company catalogs tens of thousands of corporate events and their corresponding
              metadata every day, including buybacks, guidance, defaults, board meetings and more
              than 150 other event types. In total, about 40,000 of these events are derived from
              audio files each year, essentially anything you could dial into or listen to –
              earnings calls, management presentations, acquisition calls and more.
            </p>
            <p css={[contentWidthCss, h4Body2Css, paragraphTextCss]}>
              S&amp;P Global employs hundreds of rigorously trained analysts who have created over
              100,000 hours of transcripts of these events over the past decade. The transcripts are
              99 percent accurate given their strict service level agreements. Their clients use
              these transcripts to quickly find the information they need to confidently make
              decisions. It’s much more efficient to simply search a transcript of an earnings call
              than it is to listen to the whole event.
            </p>
            <div css={quoteContainerCss}>
              <img src={quoteIcon} alt="Quote:" css={quoteIconCss} />
              <h3 css={[quoteContentCss, h4Body1Css, hBoldCss, blueTextCss]}>
                …transcripts are 99 percent accurate given our strict service level agreements. Our
                clients use these transcripts to quickly find the information they need
              </h3>
            </div>
            <p css={[contentWidthCss, paragraphSpacingCss, h4Body2Css, paragraphTextCss]}>
              The organization wanted to streamline the process to free analysts’ time for more
              value-added activities, but none of the existing commercial speech recognition
              solutions were up to the task. Too many of the audio clips included poor quality,
              financial or business jargon, accented speech or other challenges that resulted in
              errors in the transcriptions. This is how the idea for Kensho Scribe was born.
            </p>
            <p css={[contentWidthCss, paragraphSpacingCss, h4Body2Css, paragraphTextCss]}>
              With a motto of “Try crazy things and make them real,” the Kensho Applied R&D team
              embarked on the process of building a better machine-based transcription service.
            </p>
            <p css={[contentWidthCss, h4Body2Css, paragraphTextCss]}>
              The team used the trove of audio and corresponding near-perfect transcripts as a
              training corpus. More than 100,000 hours of high-quality, domain-specific, labeled
              data. A dream come true for any machine learning research scientist.
            </p>
            <div css={quoteContainerCss}>
              <img src={quoteIcon} alt="Quote:" css={quoteIconCss} />
              <h3 css={[quoteContentCss, h4Body1Css, hBoldCss, blueTextCss]}>
                Kensho Scribe saves 1.25 hours per call relative to the legacy process, with more
                than 50,000 person hours saved to date. The unlocked bandwidth has enabled us to
                increase transcription coverage by more than 1,500 companies…
              </h3>
            </div>
            <p css={[contentWidthCss, paragraphSpacingCss, h4Body2Css, paragraphTextCss]}>
              One year later, Kensho Scribe was being used to transcribe 99 percent of the earnings
              calls, management presentations and acquisition call transcripts that S&amp;P Global
              creates each year. Kensho Scribe saves 1.25 hours per call relative to the legacy
              process, with more than 50,000 person hours saved to date. The unlocked bandwidth has
              enabled S&P Global to increase transcription coverage by more than 1,500 companies and
              expand to other high-impact uses like transcribing voicemails, powering compliance
              efforts, creating meeting minutes and processing interviews.
            </p>
            <p css={[contentWidthCss, h4Body2Css, paragraphTextCss]}>
              In 2020, we opened the next chapter in the story by beginning to make Kensho Scribe
              available to the rest of the world. As we expand to new use cases for the product, we
              will continue to push the envelope of transcription quality, evolving the machine
              learning algorithms that make Kensho Scribe so powerful and accurate.
            </p>
          </div>
        </AnimatedContent>
      </WideContent>
    </section>
  )
}
