import {Level, Message, Transport, Context} from '../types'

/**
 * Creates a custom transport.
 *
 * @param levels Log levels by which to filter.
 * @param router Callback to invoke with each log.
 */
export default function transportCustom(
  levels: Level[] | undefined,
  router: (level: Level, message: Message, context?: Context) => void
): Transport {
  const levelsSet = new Set<Level>(levels)
  return function transport(level, message, context) {
    if (!levels || levelsSet.has(level)) router(level, message, context)
  }
}
