import {useState, useDebugValue, useLayoutEffect, useEffect} from 'react'

import {Size} from './types'
import assertBrowserEnv from './utils/assertBrowserEnv'
import areSizesEqual from './utils/areSizesEqual'

/** Gets the current size of the window. */
function getCurrentWindowSize(): Size {
  return typeof window === 'object'
    ? {height: window.innerHeight, width: window.innerWidth}
    : {height: 0, width: 0}
}

/**
 * The window size may change between now and the time that an instance of the hook mounts. The hook
 * implementation accounts for this by reading and setting the value on mount, but that must be done
 * in a layout effect, which will trigger a multi-pass render if the value differs from the initial
 * one. The likelihood of this can be reduced by making an educated guess about the initial value
 * such that the state update can bail in the common case where the value hasn't changed.
 */
const LIKELY_INITIAL_SIZE = getCurrentWindowSize()

/**
 * Tracks the current size of the window.
 *
 * @returns The window size.
 *
 * @see https://tacklebox.beta-p.kensho.com/useWindowSize
 */
export default function useWindowSize(): Size {
  assertBrowserEnv()

  const [windowSize, setWindowSize] = useState(LIKELY_INITIAL_SIZE)

  useDebugValue(windowSize)

  function handleResize(): void {
    const nextSize = getCurrentWindowSize()
    // bail if the next size is equal by value to the previous size
    setWindowSize((prevSize) => (areSizesEqual(prevSize, nextSize) ? prevSize : nextSize))
  }

  useLayoutEffect(() => {
    handleResize()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}
