import {css} from '@emotion/react'
import {IconChevronRight, IconChevronUp} from '@kensho/icons'
import {useState} from 'react'

import {h4Body2Css, hBoldCss, blackTextCss} from '../../../styles/common'
import COLORS from '../../../styles/colors'

interface AccordionItemProps {
  className?: string
  content: React.ReactNode
  expandedContent: React.ReactNode
  defaultExpanded?: boolean
}

const accordionItemCss = css`
  & + & {
    margin-top: 40px;
  }
`

const contentCss = css`
  padding-top: 24px;
  padding-left: 40px;
  flex: 1 0 auto;
  overflow: hidden;
  width: 100%;
`

const expandButtonCss = css`
  position: relative;
  display: flex;
  border: none;
  padding: 0;
  margin-left: 40px;

  &:hover {
    background: transparent;
    & p {
      color: ${COLORS.brand.light[500]};
    }
  }

  & p {
    text-align: left;
  }
`

// Value for left offset is calculated as (the negative of):
//   20px (icon width / 2) + 8.75px (svg path width / 2)
//     (^ this aligns the icon with the left edge of the text)
//     + 12px (the intended margin)
//     = 40.75px
// and rounded down to 40px.
// (This calculation is made using the "up" chevron which is wider than the "right" variant.)
const expandButtonIconCss = css`
  position: absolute;
  top: -6px;
  left: -40px;
  color: ${COLORS.brand.light[500]};
  flex: 0 0 auto;
`

export default function AccordionItem({
  className,
  content,
  expandedContent,
  defaultExpanded = false,
}: AccordionItemProps): JSX.Element {
  const [expanded, setExpanded] = useState(defaultExpanded)

  return (
    <div css={accordionItemCss} className={className}>
      <button css={expandButtonCss} type="button" onClick={() => setExpanded((prev) => !prev)}>
        {expanded ? (
          <IconChevronUp css={expandButtonIconCss} size={40} />
        ) : (
          <IconChevronRight css={expandButtonIconCss} size={40} />
        )}
        {typeof content === 'string' ? (
          <p css={[h4Body2Css, hBoldCss, blackTextCss]}>{content}</p>
        ) : (
          content
        )}
      </button>

      {expanded && (
        <div css={contentCss}>
          {typeof expandedContent === 'string' ? (
            <p css={[h4Body2Css, blackTextCss]}>{expandedContent}</p>
          ) : (
            expandedContent
          )}
        </div>
      )}
    </div>
  )
}
