import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

/** scroll page back to top upon client-side navigation */
export default function ScrollToTop(): null {
  const {pathname, hash} = useLocation()
  useEffect(() => {
    if (!hash) window.scrollTo(0, 0)
  }, [pathname, hash])

  return null
}
