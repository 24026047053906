import {css, SerializedStyles} from '@emotion/react'

import {BREAKPOINT_LARGE, BREAKPOINT_MEDIUM, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import COLORS from '../../styles/colors'
import {whiteTextCss} from '../../styles/common'
import {AnimatedContent, WideContent} from '../content'
import {HEADER_HEIGHT, SHORT_HEADER_HEIGHT} from '../Page/Header/constants'

export interface HeroSectionProps {
  children: React.ReactNode
  className?: string
  image?: string
  imageCss?: SerializedStyles
  short?: boolean
}

const heroCss = css`
  position: relative;
  height: 650px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.gray['100']};
  padding-top: ${HEADER_HEIGHT}px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    height: 550px;
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    padding-top: ${SHORT_HEADER_HEIGHT}px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 450px;
  }
`

const shortCss = css`
  height: 400px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    height: 350px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 350px;
  }
`

const vignetteCss = css`
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) calc(50% - 930px),
    rgba(0, 0, 0, 0) calc(50% + 930px),
    rgba(0, 0, 0, 1) 100%
  );
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  pointer-events: none;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    background: none;
  }
`

const baseImageCss = css`
  transition: all 700ms;
  filter: contrast(120%) brightness(30%) grayscale(30%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center 55%;
  background-size: cover;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE11 and IE10 only, shim for unsupported filter property */
    opacity: 0.15;
  }
`

const contentContainerCss = css`
  z-index: 1;
  position: relative;
`

const heroTitleCss = css`
  margin: 50px 0 170px 0;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin: 90px 0 130px 0;
  }
`

export default function HeroSection(props: HeroSectionProps): JSX.Element {
  const {children, className, image, imageCss, short = false} = props

  return (
    <section className={className} css={[heroCss, whiteTextCss, short && shortCss]}>
      {(image || imageCss) && (
        <div
          css={[baseImageCss, imageCss]}
          style={{backgroundImage: image ? `url(${image})` : undefined}}
        />
      )}
      <div css={vignetteCss} />
      <div css={contentContainerCss}>
        {typeof children === 'string' ? (
          <WideContent>
            <AnimatedContent>
              <h1 css={[heroTitleCss, whiteTextCss]}>{children}</h1>
            </AnimatedContent>
          </WideContent>
        ) : (
          <>{children}</>
        )}
      </div>
    </section>
  )
}
