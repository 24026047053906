import {ClassNames, css} from '@emotion/react'

import caseStudyImg from '../../assets/photos/wall-street-image.jpg'
import Carousel from '../../components/Carousel'
import CaseStudySlide, {CaseStudySlideProps} from '../../components/Carousel/slides/CaseStudySlide'
import type {CustomerTestimonialSlideProps} from '../../components/Carousel/slides/CustomerTestimonialSlide'
import CustomerTestimonialSlide from '../../components/Carousel/slides/CustomerTestimonialSlide'
import {WideContent} from '../../components/content'
import Link from '../../components/Link'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {h4Body2Css, hNormalCss, hSemiBoldCss, whiteTextCss} from '../../styles/common'

const citationCss = css`
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    font-size: 16px;
    line-height: 16px;
  }
`

const linkCss = css`
  text-decoration: underline;
`

const contentCss = css`
  padding-right: 0;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    max-width: unset;
  }
`

const fullHeightCss = css`
  height: 100%;
`

const marginCss = css`
  margin-bottom: 10px;
`

type Slide =
  | ({kind: 'testimonial'} & CustomerTestimonialSlideProps)
  | ({kind: 'case-study'} & CaseStudySlideProps)

const slides: Slide[] = [
  {
    kind: 'case-study',
    title: 'Kensho NERD for Financial Research',
    content: (
      <>
        <p css={[h4Body2Css, marginCss]}>
          Kensho NERD is powering academic research at the City University of Hong Kong and
          Wenzhou-Kean University, on the topic of Media Co-coverage and Overreaction in
          Cross-Industry Information Transfers.
        </p>
        <p css={[h4Body2Css, marginCss]}>
          The research team is using NERD to identify tens of thousands of company names mentioned
          in conference call transcripts and media articles, and analyze them, along with stock
          market and other data.
        </p>
        <p css={h4Body2Css}>
          Kensho NERD plays a vital role in identifying media co-coverage and establishing causality
          by spotting the co-covered firms quickly and accurately.
        </p>
      </>
    ),
    imageUri: caseStudyImg,
    imageAlt:
      'A crystalline structure representing how link connects nodes between various data sources.',
  },
  {
    kind: 'testimonial',
    quote:
      'If you’re reading an article, press release, or 10k, [NERD] enables you to use all the information and unstructured data in these documents',
    citation: (
      <p css={[h4Body2Css, hSemiBoldCss, citationCss]}>
        Akash Sinha, S&P Global.{' '}
        <Link
          to="https://blog.kensho.com/kensho-nerd-unlocking-the-potential-of-unstructured-data-f75e69efcb1e"
          css={[whiteTextCss, hNormalCss, linkCss]}
        >
          Read full blog
        </Link>
      </p>
    ),
  },
  {
    kind: 'testimonial',
    quote: 'Without Kensho NERD, our research study would not have been feasible or relevant',
    citation: 'Dr. Alex Zhang, Professor, City University of Hong Kong',
  },
]

export default function TestimonialSection(): JSX.Element {
  return (
    <section>
      <WideContent css={contentCss}>
        <ClassNames>
          {({css}) => (
            <Carousel
              slides={slides}
              slideRenderer={(slide) =>
                slide.kind === 'testimonial' ? (
                  <CustomerTestimonialSlide css={fullHeightCss} {...slide} />
                ) : (
                  <CaseStudySlide css={fullHeightCss} {...slide} />
                )
              }
              slideKeyGen={(slide) => (slide.kind === 'testimonial' ? slide.quote : slide.title)}
              slideTrayClassName={css`
                width: 90%;
              `}
              buttonTrayClassName={css`
                @media (max-width: ${BREAKPOINT_SMALL}px) {
                  display: none;
                }
              `}
              buttonColor="white"
            />
          )}
        </ClassNames>
      </WideContent>
    </section>
  )
}
