import {css} from '@emotion/react'

import TopicHeadline from '../../components/TopicHeadline'
import IntroPhotoLarge from '../../assets/photos/research-philosophy-large.png'
import IntroPhotoMedium from '../../assets/photos/research-philosophy-medium.png'
import IntroPhotoSmall from '../../assets/photos/research-philosophy-small.png'
import {
  backgroundLightGrayCss,
  baseSectionCss,
  blackTextCss,
  h3TitleCss,
  h4Body1Css,
  hBoldCss,
  sectionHeadingMarginCss,
} from '../../styles/common'
import {TwoColumnContent, AnimatedContent} from '../../components/content'
import {
  BREAKPOINT_XLARGE,
  BREAKPOINT_LARGE,
  BREAKPOINT_MEDIUM,
  BREAKPOINT_SMALL,
} from '../../styles/breakpoints'
import ButtonLink from '../../components/Button/ButtonLink'

const imageSizingCss = css`
  display: flex;
  justify-content: center;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    justify-content: center;
  }
`

const imageCss = css`
  height: 696px;
  width: 100%;
  object-fit: cover;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    height: 750px;
    margin-top: 64px;
  }
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 500px;
    margin-top: 60px;
  }
`

const buttonCss = css`
  display: none;
  height: 60px;
  width: 100%;
  margin-top: 48px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    display: flex;
  }
`

const removePaddingCss = css`
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 0;
  }
`

export default function IntroSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundLightGrayCss]}>
      <TwoColumnContent
        left={
          <>
            <TopicHeadline text="Our Approach" css={blackTextCss} />
            <AnimatedContent>
              <h3 css={[sectionHeadingMarginCss, h3TitleCss, hBoldCss, blackTextCss]}>
                At Kensho, we work on cutting-edge research and develop leading ML and NLP
                capabilities for real-world impact.
              </h3>
            </AnimatedContent>
            <AnimatedContent>
              <p css={[h4Body1Css, blackTextCss]}>
                We hire talented people and give them the freedom and resources needed to accomplish
                our shared goals. Our team conducts pure research with the goal of creating novel
                contributions and publishing our work in top-tier venues.
              </p>
            </AnimatedContent>
            <ButtonLink to="/team" variant="primary" css={buttonCss}>
              Meet the Team
            </ButtonLink>
          </>
        }
        rightContentCss={removePaddingCss}
        right={
          <AnimatedContent animation="fade-in" innerCss={imageSizingCss}>
            <picture>
              <source media={`(max-width: ${BREAKPOINT_SMALL}px)`} srcSet={IntroPhotoSmall} />
              <source media={`(max-width: ${BREAKPOINT_MEDIUM}px)`} srcSet={IntroPhotoMedium} />
              <source media={`(min-width: ${BREAKPOINT_XLARGE}px)`} srcSet={IntroPhotoLarge} />
              <img src={IntroPhotoLarge} alt="Research Intro" css={imageCss} loading="lazy" />
            </picture>
          </AnimatedContent>
        }
      />
    </section>
  )
}
