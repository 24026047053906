import {css} from '@emotion/react'

import {AnimatedContent, WideContent} from '../../components/content'
import GraphicContentGroup from '../../components/GraphicContentGroup'
import TextExtractionIcon from '../../assets/icons/text-extraction.svg'
import TableExtractionIcon from '../../assets/icons/table-extraction.svg'
import KeyValueIcon from '../../assets/icons/key-value-extraction.svg'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {
  backgroundWhiteCss,
  baseSectionCss,
  centeredTextCss,
  sectionHeadingMarginCss,
} from '../../styles/common'
import {HeadingContent} from '../../components/headings'

const widthCss = css`
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    min-width: 444px;
  }
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    min-width: unset;
  }
`

export default function FeatureSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundWhiteCss]}>
      <WideContent>
        <HeadingContent
          headerText="Kensho Extract Features"
          css={[sectionHeadingMarginCss, centeredTextCss]}
        />
        <AnimatedContent animation="fade-in">
          <GraphicContentGroup
            graphicContent={[
              {
                graphic: {data: TextExtractionIcon, isText: false},
                content: {
                  title: 'Text Extraction',
                  description: (
                    <p css={widthCss}>
                      Parse apart your documents and turn them into an easy-to-consume machine
                      readable format.
                    </p>
                  ),
                },
              },
              {
                graphic: {data: TableExtractionIcon, isText: false},
                content: {
                  title: 'Table Extraction',
                  description: (
                    <p css={widthCss}>
                      Find and extract the tables you care about for easy analysis or database
                      updates.
                    </p>
                  ),
                },
              },
              {
                graphic: {data: KeyValueIcon, isText: false},
                content: {
                  title: 'Key Value Extraction',
                  description: (
                    <p css={widthCss}>
                      Find specific values in your documents to reduce your manual data operations
                      efforts.
                    </p>
                  ),
                },
              },
            ]}
            maxItemsPerRow={3}
          />
        </AnimatedContent>
      </WideContent>
    </section>
  )
}
