import {css} from '@emotion/react'

import Page from '../../components/Page'
import PageMetadata from '../../components/PageMetadata'
import Link from '../../components/Link'
import KenshoLogo from '../../assets/logos/kensho-blue.svg'
import {
  h3SubheadlineCss,
  h4Body1Css,
  hBoldCss,
  blackTextCss,
  backgroundWhiteCss,
} from '../../styles/common'
import COLORS from '../../styles/colors'

const sectionCss = css`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
`

const contentCss = css`
  margin: 15vh 20px;
  max-width: 550px;
  height: min-content;
  padding: 50px 60px;
  border: 2px solid ${COLORS.gray[500]};
`

const logoCss = css`
  width: 200px;
  margin-bottom: 30px;
`

const headerCss = css`
  margin-bottom: 20px;
`

const paragraphCss = css`
  margin-bottom: 20px;
`

const linkCss = css`
  color: ${COLORS.brand.light[700]};
`

export default function ThanksPage(): JSX.Element {
  return (
    <Page minimal>
      <PageMetadata
        title="Thank You | Kensho"
        description="Thanks for contacting the team at Kensho."
      />
      <section css={[sectionCss, backgroundWhiteCss]}>
        <div css={contentCss}>
          <Link to="/">
            <img src={KenshoLogo} alt="Kensho Logo" css={logoCss} />
          </Link>
          <h3 css={[headerCss, h3SubheadlineCss, hBoldCss, blackTextCss]}>
            Thanks for your interest in Kensho!
          </h3>
          <p css={[paragraphCss, h4Body1Css, blackTextCss]}>
            We appreciate you contacting us and will get back to you shortly.
          </p>
          <p css={[h4Body1Css, blackTextCss]}>
            In the meantime, have a great day and consider signing up for{' '}
            <Link to="https://services.kensho.com/free-trial" css={[linkCss, hBoldCss]}>
              a free trial of Kensho’s AI Toolkit
            </Link>
          </p>
        </div>
      </section>
    </Page>
  )
}
