import {css} from '@emotion/react'
import {IconChevronRightLarge} from '@kensho/icons'
import {useLogger} from '@kensho/lumberjack'
import {useEffect, useState} from 'react'

import ensoImg from '../assets/logos/enso4.png'
import fetchWorkdayJobs, {WorkdayJob} from '../data/fetchWorkdayJobs'
import {BREAKPOINT_SMALL} from '../styles/breakpoints'
import {h2TitleCss, h3TitleCss, h4Body1Css, hBoldCss} from '../styles/common'

import ButtonLink from './Button/ButtonLink'
import TopicHeadline from './TopicHeadline'

const sectionCss = css`
  height: 100%;
  width: 100%;
  padding-top: 65px;
  padding-bottom: 55px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const marginCss = css`
  margin-bottom: 10px;
`

const jobsGridCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 90px 0;
`

const openPositionsCountCss = css`
  line-height: 100px;
`

const callToActionCss = css`
  display: flex;
  justify-content: center;
`

const buttonLinkCss = css`
  padding: 20px 60px;
`

export default function HiringPreviewSection(): JSX.Element {
  const log = useLogger()
  const [jobs, setJobs] = useState<WorkdayJob[] | null>(null)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    fetchWorkdayJobs()
      .then((fetchedJobs) => setJobs(fetchedJobs))
      .catch((error) => {
        setHasError(true)
        log.error(error)
      })
  }, [log])

  const isLoadingJobs = jobs === null && !hasError

  return (
    <section css={sectionCss}>
      <TopicHeadline text="Careers" />
      <h3 css={[h3TitleCss, hBoldCss, marginCss]}>Our team is growing!</h3>
      <p css={h4Body1Css}>Positions currently open:</p>
      <div css={jobsGridCss}>
        {isLoadingJobs && <img src={ensoImg} alt="Loading open positions..." />}
        {!hasError && !isLoadingJobs && jobs && jobs.length > 0 && (
          <h3 css={[h2TitleCss, openPositionsCountCss]}>{jobs.length}</h3>
        )}
      </div>
      <div css={callToActionCss}>
        <ButtonLink
          to="/careers#available-positions"
          variant="primary"
          mode="light"
          css={[buttonLinkCss, h4Body1Css]}
        >
          All opportunities
          <IconChevronRightLarge size={20} />
        </ButtonLink>
      </div>
    </section>
  )
}
