import {css} from '@emotion/react'

import {
  h4Body2Css,
  backgroundWhiteCss,
  baseSectionCss,
  largeButtonCss,
  sectionHeadingMarginCss,
} from '../../styles/common'
import {AnimatedContent, WideContent} from '../../components/content'
import {HeadingContent} from '../../components/headings'
import {ShortTextContent} from '../../components/textContent'
import ButtonLink from '../../components/Button/ButtonLink'
import {BREAKPOINT_MEDIUM, BREAKPOINT_SMALL} from '../../styles/breakpoints'

const containerCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const shortTextCss = css`
  margin-bottom: 64px;
  padding: 0;
  max-width: 1140px;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    padding: 0;
  }
`

const buttonCss = css`
  max-width: 580px;
  width: 100%;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 44px;
    max-width: 275px;
    ${h4Body2Css};
  }
`

export default function JoinUsSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundWhiteCss]}>
      <WideContent css={containerCss}>
        <HeadingContent headerText="Work with us!" css={sectionHeadingMarginCss} />
        <ShortTextContent
          text="We are looking for world-class researchers to join our growing team."
          css={shortTextCss}
        />
        <AnimatedContent animation="fade-in">
          <ButtonLink
            to="/careers#available-positions"
            variant="secondary"
            css={[largeButtonCss, buttonCss]}
          >
            See available positions
          </ButtonLink>
        </AnimatedContent>
      </WideContent>
    </section>
  )
}
