import {css} from '@emotion/react'

import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import TopicHeadline from '../../components/TopicHeadline'
import {TwoColumnContent, AnimatedContent} from '../../components/content'
import {
  h3TitleCss,
  hBoldCss,
  h3SubheadlineCss,
  whiteTextCss,
  backgroundGradientCss,
  backgroundMidGrayCss,
  sectionHeadingMarginCss,
} from '../../styles/common'

const RIGHT_VIDEO_MP4 = 'https://static.kensho.com/video/nerd-looping-video-1-AV1.mp4'
const RIGHT_VIDEO_WEBM_VP9 = 'https://static.kensho.com/video/nerd-looping-video-1-VP9.webm'
const RIGHT_VIDEO_IMAGE = 'https://static.kensho.com/video/nerd-looping-video-1.jpg'

const headlineCss = css`
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    display: none;
  }
`
const leftCss = css`
  padding: 75px 8px 90px 0px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding: 90px 0px 80px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 60px 0px 55px;
  }
`

const leftTitleCss = css`
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    text-align: center;
  }
`

const leftTextCss = css`
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    text-align: center;
  }
`

const videoContainerCss = css`
  padding: 0;
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding: 0;
    max-width: unset;
  }
`

const fullSizeCss = css`
  height: 100%;
  width: 100%;
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    height: 615px;
  }
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 415px;
  }
`

const videoCss = css`
  object-fit: cover;
  height: 100%;
  width: 100%;
`

export default function IntroSection(): JSX.Element {
  return (
    <section>
      <TwoColumnContent
        leftWrapperCss={[leftCss, backgroundGradientCss]}
        left={
          <>
            <TopicHeadline
              text="About NERD - Named Entity Recognition and Disambiguation"
              css={[headlineCss, whiteTextCss]}
            />
            <AnimatedContent>
              <h3 css={[sectionHeadingMarginCss, leftTitleCss, h3TitleCss, hBoldCss, whiteTextCss]}>
                Making sense of text requires understanding the who, what and where.
              </h3>
            </AnimatedContent>
            <AnimatedContent>
              <p css={[leftTextCss, h3SubheadlineCss, whiteTextCss]}>
                While humans do this naturally, machines struggle. Enter NERD. NERD identifies the
                entities in text — the companies, people, organizations, events, places etc. — and
                connects them to data and other information in S&P Capital IQ and Wikimedia,
                unlocking new understanding and insights.
              </p>
            </AnimatedContent>
          </>
        }
        rightWrapperCss={backgroundMidGrayCss}
        rightContentCss={videoContainerCss}
        right={
          <AnimatedContent animation="fade-in" css={fullSizeCss} innerCss={fullSizeCss}>
            <video css={videoCss} autoPlay loop muted poster={RIGHT_VIDEO_IMAGE}>
              <source src={RIGHT_VIDEO_WEBM_VP9} type='video/webm; codecs="vp9"' />
              <source src={RIGHT_VIDEO_MP4} type="video/mp4" />
              <img src={RIGHT_VIDEO_IMAGE} alt="Animated Grid" loading="lazy" />
            </video>
          </AnimatedContent>
        }
      />
    </section>
  )
}
