import {WideContent, AnimatedContent} from '../../components/content'
import {
  h3SubheadlineCss,
  hBoldCss,
  blackTextCss,
  backgroundWhiteCss,
  centeredTextCss,
  baseSectionCss,
} from '../../styles/common'

import LinkTicker from './EntitiesTicker'

export default function EntitiesSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundWhiteCss]}>
      <WideContent>
        <AnimatedContent animation="fade-in">
          <p css={[h3SubheadlineCss, blackTextCss, centeredTextCss]}>
            No other solution offers NERD’s combination of accuracy, general breadth and business
            and financial depth. See for yourself!
          </p>
          <LinkTicker />
          <p css={[h3SubheadlineCss, hBoldCss, blackTextCss, centeredTextCss]}>
            …entities recogizable by NERD!
          </p>
        </AnimatedContent>
      </WideContent>
    </section>
  )
}
