import {css} from '@emotion/react'

import {ButtonVariant} from '../../Button/getButtonStyles'

import {NavItem} from './navigation'
import {subLinkCss} from './styles'
import NavLinkButton from './NavLinkButton'

const sidebarNavItemCss = css`
  max-width: fit-content;
  padding-right: 0;
  padding-left: 10px;
  justify-content: flex-start;

  &:after {
    transform-origin: left;
  }
`

interface SidebarNavItemProps {
  item: NavItem
  variant?: ButtonVariant
}

export default function SidebarNavItem({
  item,
  variant = 'minimal',
}: SidebarNavItemProps): JSX.Element {
  return item.isGroup ? (
    <>
      <NavLinkButton link={item.link} variant={variant} mode="light" css={sidebarNavItemCss} />
      {item.sublinks.map((link) => (
        <NavLinkButton
          link={link}
          variant="minimal"
          mode="light"
          key={link.label}
          css={[sidebarNavItemCss, subLinkCss]}
        />
      ))}
    </>
  ) : (
    <NavLinkButton link={item} variant={variant} mode="light" css={sidebarNavItemCss} />
  )
}
