import {css} from '@emotion/react'

import ImpactHeroLarge from '../../assets/photos/impact-hero-large.jpg'
import ImpactHeroMedium from '../../assets/photos/impact-hero-medium.jpg'
import ImpactHeroSmall from '../../assets/photos/impact-hero-small.jpg'
import socialImg from '../../assets/social/impact.jpg'
import ButtonLink from '../../components/Button/ButtonLink'
import EmailLink from '../../components/EmailLink'
import HeroSection from '../../components/Hero/HeroSection'
import Link from '../../components/Link'
import Page from '../../components/Page'
import PageMetadata from '../../components/PageMetadata'
import TopicHeadline from '../../components/TopicHeadline'
import {AnimatedContent, TwoColumnContent, WideContent} from '../../components/content'
import TwoColumnGradient from '../../components/TwoColumnGradient'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {
  backgroundGradientCss,
  backgroundLightGrayCss,
  backgroundWhiteCss,
  baseSectionCss,
  baseSectionPaddingTopCss,
  bodyTextMarginCss,
  bulletedListCss,
  bulletedListItemCss,
  centerButtonCss,
  centeredTextCss,
  h3TitleCss,
  h4Body1Css,
  h4Body2Css,
  hBoldCss,
  largeButtonCss,
  noMarginCss,
  outlinedButtonOnDarkCss,
  whiteTextCss,
} from '../../styles/common'

const linkTextCss = css`
  color: #50afc6;
  line-height: 12px;
`

const heroTitleCss = css`
  margin: 50px 0 170px 0;
  color: white;
`

const projectListCss = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  list-style-type: none;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
    align-items: center;
    padding-left: 0;
  }
`

const projectListItemCss = css`
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    max-width: 100%;
  }
`

const cardCss = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 500px;
  width: 400px;
  padding: 40px;
  margin: 10px;
  overflow: hidden;
  background: #fff;
  border: 1px solid #e4e5e5;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    max-width: 390px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    max-width: 100%;
    padding: 20px 10px;
    margin: 10px 0;
  }
`

const cardMinimalLinkCss = css`
  align-self: flex-end;
  margin-top: 20px;
`

const subProjectListCss = css`
  overflow: auto;
  height: 300px;
  width: 100%;
`

const heroImageCss = css`
  background-image: url(${ImpactHeroLarge});
  background-position-y: 15%;
  filter: contrast(120%) brightness(50%) grayscale(30%);

  @media (max-width: 2200px) {
    background-image: url(${ImpactHeroMedium});
  }

  @media (max-width: 600px) {
    background-image: url(${ImpactHeroSmall});
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    opacity: 1;
  }
`

const footerPaddingCss = css`
  padding-bottom: 20px;
`

export default function ImpactPage(): JSX.Element {
  return (
    <Page
      title={
        <HeroSection imageCss={heroImageCss}>
          <WideContent>
            <AnimatedContent>
              <h1 css={[heroTitleCss, whiteTextCss]}>Impact</h1>
            </AnimatedContent>
          </WideContent>
        </HeroSection>
      }
    >
      <PageMetadata
        title="Impact | Kensho"
        description="Team Impact is Kensho’s program for giving back to the community through skills-based volunteerism, partnerships with non-profit organizations and special volunteer events."
        image={{source: socialImg, height: 1250, width: 2500}}
      />

      <section css={[baseSectionCss, backgroundWhiteCss]}>
        <WideContent>
          <TopicHeadline text="What We Do" css={noMarginCss} />
        </WideContent>
        <TwoColumnContent
          left={
            <div>
              <AnimatedContent>
                <h3 css={[h3TitleCss, hBoldCss]}>
                  Team Impact takes the lead on Kensho’s community involvement and skills-based
                  volunteer efforts.
                </h3>
              </AnimatedContent>
            </div>
          }
          right={
            <AnimatedContent>
              <p css={[h4Body1Css, bodyTextMarginCss]}>
                The cross-functional team currently has three working groups – Data Journalism,
                Environmental Impact and Educational Equity. These working groups have tackled
                projects large and small with a range of external partners and an eye towards
                initiatives that allow Kenshins to use their in-demand skills in data science,
                machine learning and software engineering and more to have maximum positive impact
                in their communities.
              </p>
            </AnimatedContent>
          }
        />
      </section>
      <TwoColumnGradient
        eyebrow="Our Work"
        subtitle="Team Impact is passionate about solving problems in arenas where there are
        opportunities to make meaningful contributions with our skills. The current working
        groups are Data Journalism, Climate Impact and Education Equity."
        buttonText="Read about Team Impact’s projects and events on our blog"
        href="https://blog.kensho.com/impact/home"
      />
      <section css={[baseSectionCss, backgroundLightGrayCss]}>
        <WideContent>
          <TopicHeadline text="Team Data Journalism" css={noMarginCss} />
          <AnimatedContent>
            <p css={[h4Body1Css, bodyTextMarginCss]}>
              The Data Journalism working group focuses on projects related to data and the media.
            </p>{' '}
            <p css={[h4Body1Css, bodyTextMarginCss]}>
              Their projects have included a Wikipedia suggestion engine that offers context to
              online readers, producing publicly available Wikimedia datasets for natural language
              processing, analyzing census and voter registration data to monitor for voter purges,
              and looking into how programmatic advertising allows companies to inadvertently fund
              online misinformation. Their partnerships include:
            </p>
          </AnimatedContent>
        </WideContent>

        <ul css={projectListCss}>
          <li css={projectListItemCss}>
            <AnimatedContent>
              <div css={cardCss}>
                <div>
                  <h4 css={h4Body2Css}>Credibility Coalition</h4>
                  <ul css={[bulletedListCss, h4Body2Css, subProjectListCss]}>
                    <li css={bulletedListItemCss}>
                      <Link
                        css={linkTextCss}
                        to="https://docs.google.com/presentation/d/1qoQAYPCRpp1p0RuYWsm-ThENUFNJrBCGRVVDnd4zmKA/edit#slide=id.gc3e76a0736_0_102"
                      >
                        Economics of Misinformation Working Group Update
                      </Link>{' '}
                      - MozFest March 2021
                    </li>
                    <li css={bulletedListItemCss}>
                      <span>
                        <Link
                          css={linkTextCss}
                          to="https://misinfocon.com/the-case-for-using-market-forces-to-combat-misinformation-and-disinformation-part-i-e0add528c635"
                        >
                          Part I
                        </Link>{' '}
                        &{' '}
                        <Link
                          css={linkTextCss}
                          to="https://misinfocon.com/the-case-for-using-market-forces-to-combat-misinformation-and-disinformation-part-ii-56f7556b73ff"
                        >
                          Part II: Using Market Forces to Combat… Disinformation
                        </Link>
                      </span>{' '}
                      - Misinfocon Blog
                    </li>
                    <li css={bulletedListItemCss}>
                      <Link
                        css={linkTextCss}
                        to="https://misinfocon.com/examining-opaque-programmatic-markets-with-the-credibility-coalition-adsellers-dataset-b9ff5d6781c4"
                      >
                        Examining Opaque Programmatic Markets
                      </Link>{' '}
                      - Credibility Coalition AdSellers Dataset
                    </li>
                  </ul>
                </div>
                <ButtonLink
                  to="https://credibilitycoalition.org/"
                  variant="minimal"
                  css={cardMinimalLinkCss}
                >
                  Credibility Coalition
                </ButtonLink>
              </div>
            </AnimatedContent>
          </li>

          <li css={projectListItemCss}>
            <AnimatedContent>
              <div css={cardCss}>
                <div>
                  <h4 css={h4Body2Css}>Ohio Voter Project</h4>
                  <ul css={[bulletedListCss, h4Body2Css, subProjectListCss]}>
                    <li css={bulletedListItemCss}>
                      <Link
                        css={linkTextCss}
                        to="https://www.kaggle.com/gabrielaltay/georgia-voter-list-202011"
                      >
                        Georgia Voter Lists
                      </Link>{' '}
                      - Kaggle Dataset
                    </li>
                    <li css={bulletedListItemCss}>
                      <Link
                        css={linkTextCss}
                        to="https://www.kaggle.com/kenshoimpactteam/kensho-ohio-voter-project"
                      >
                        Ohio Census Data
                      </Link>{' '}
                      - Kaggle Dataset
                    </li>
                  </ul>
                </div>
                <ButtonLink
                  to="https://ohiovoterproject.org/"
                  variant="minimal"
                  css={cardMinimalLinkCss}
                >
                  Ohio Voter Project
                </ButtonLink>
              </div>
            </AnimatedContent>
          </li>

          <li css={projectListItemCss}>
            <AnimatedContent>
              <div css={cardCss}>
                <div>
                  <h4 css={h4Body2Css}>Wiki Cred</h4>
                  <ul css={[bulletedListCss, h4Body2Css, subProjectListCss]}>
                    <li css={bulletedListItemCss}>
                      <Link
                        css={linkTextCss}
                        to="https://github.com/kensho-technologies/wikiwhatsthis"
                      >
                        WikiWhatsThis
                      </Link>{' '}
                      - Github
                    </li>
                    <li css={bulletedListItemCss}>
                      <Link css={linkTextCss} to="https://www.youtube.com/watch?v=C7ttHUokqes">
                        WikiWhatsThis Demo Hour by Minhvy Van
                      </Link>{' '}
                      - YouTube
                    </li>
                    <li css={bulletedListItemCss}>
                      <Link
                        css={linkTextCss}
                        to="https://misinfocon.com/wikiwhatsthis-initial-open-sourcing-and-next-steps-22159786120e"
                      >
                        WikiWhatsThis: Initial Open Sourcing and Next Steps
                      </Link>{' '}
                      - Misinfocon Blog
                    </li>
                    <li css={bulletedListItemCss}>
                      <Link
                        css={linkTextCss}
                        to="https://misinfocon.com/wikiwhatsthis-will-battle-misinformation-by-grounding-online-stories-with-wikipedia-content-f7db3489f4ea"
                      >
                        …Grounding Online Stories with Wikipedia
                      </Link>{' '}
                      - Misinfocon Blog
                    </li>
                  </ul>
                </div>
                <ButtonLink
                  to="https://www.wikicred.org/"
                  variant="minimal"
                  css={cardMinimalLinkCss}
                >
                  Wiki Cred
                </ButtonLink>
              </div>
            </AnimatedContent>
          </li>

          <li css={projectListItemCss}>
            <AnimatedContent>
              <div css={cardCss}>
                <div>
                  <h4 css={h4Body2Css}>Kensho Datasets</h4>
                  <ul css={[bulletedListCss, h4Body2Css, subProjectListCss]}>
                    <li css={bulletedListItemCss}>
                      <Link css={linkTextCss} to="https://datasets.kensho.com/datasets/wikimedia">
                        Wikimedia for NLP
                      </Link>{' '}
                      - Kensho Datasets
                    </li>
                    <li css={bulletedListItemCss}>
                      <Link
                        css={linkTextCss}
                        to="https://blog.kensho.com/announcing-the-kensho-derived-wikimedia-dataset-5d1197d72bcf"
                      >
                        Wikimedia for NLP
                      </Link>{' '}
                      - Kensho Blog
                    </li>
                  </ul>
                </div>
                <ButtonLink
                  to="https://datasets.kensho.com/"
                  variant="minimal"
                  css={cardMinimalLinkCss}
                >
                  Kensho Datasets
                </ButtonLink>
              </div>
            </AnimatedContent>
          </li>
        </ul>
      </section>

      <section css={[baseSectionCss, backgroundLightGrayCss]}>
        <WideContent>
          <TopicHeadline text="Team Climate Impact" css={noMarginCss} />
          <AnimatedContent>
            <p css={[h4Body1Css, bodyTextMarginCss]}>
              The Climate Impact working group focuses on projects related to climate change and
              protecting the environment.{' '}
            </p>
            <p css={[h4Body1Css, bodyTextMarginCss]}>
              The team’s efforts have included work to bring awareness to the climate impacts of
              behavior, research, analysis and education related to honeybee habitat data, analysis
              of the future tree canopy in Boston and building a tool to help people make
              sustainable choices in their diet. More on the team’s partnerships and projects:
            </p>
          </AnimatedContent>
        </WideContent>

        <ul css={projectListCss}>
          <li css={projectListItemCss}>
            <AnimatedContent>
              <div css={cardCss}>
                <div>
                  <h4 css={h4Body2Css}>Speak For The Trees</h4>
                  <ul css={[bulletedListCss, h4Body2Css, subProjectListCss]}>
                    <li css={bulletedListItemCss}>
                      <Link
                        css={linkTextCss}
                        to="https://blog.kensho.com/kenshins-using-data-science-to-visualize-the-future-of-bostons-urban-tree-canopy-37672ecadd25"
                      >
                        …visualize the future of Boston’s urban tree canopy
                      </Link>{' '}
                      - Kensho Blog
                    </li>
                    <li css={bulletedListItemCss}>
                      <Link
                        css={linkTextCss}
                        to="https://blog.kensho.com/announcing-the-kensho-derived-wikimedia-dataset-5d1197d72bcf"
                      >
                        Wikimedia for NLP
                      </Link>{' '}
                      - Kensho Blog
                    </li>
                  </ul>
                </div>
                <ButtonLink
                  to="https://www.sfttbos.org/"
                  variant="minimal"
                  css={cardMinimalLinkCss}
                >
                  Speak For The Trees
                </ButtonLink>
              </div>
            </AnimatedContent>
          </li>

          <li css={projectListItemCss}>
            <AnimatedContent>
              <div css={cardCss}>
                <div>
                  <h4 css={h4Body2Css}>The Bee Conservancy</h4>
                  <ul css={[bulletedListCss, h4Body2Css, subProjectListCss]}>
                    <li css={bulletedListItemCss}>
                      <Link
                        css={linkTextCss}
                        to="https://www.kaggle.com/kenshoimpactteam/nassbeecolonyandhoney"
                      >
                        Honey Bee Conservancy Data
                      </Link>{' '}
                      - Kaggle Dataset
                    </li>
                    <li css={bulletedListItemCss}>
                      <Link
                        css={linkTextCss}
                        to="https://blog.kensho.com/kenshins-put-their-talents-to-use-for-community-impact-projects-c91fff8e3e5"
                      >
                        Data Visualization
                      </Link>{' '}
                      - Kensho Blog
                    </li>
                  </ul>
                </div>
                <ButtonLink
                  to="https://thebeeconservancy.org/"
                  variant="minimal"
                  css={cardMinimalLinkCss}
                >
                  The Bee Conservancy
                </ButtonLink>
              </div>
            </AnimatedContent>
          </li>

          <li css={projectListItemCss}>
            <AnimatedContent>
              <div css={cardCss}>
                <div>
                  <h4 css={h4Body2Css}>Climate Neutral</h4>
                  <ul css={[bulletedListCss, h4Body2Css, subProjectListCss]}>
                    <li css={bulletedListItemCss}>
                      <Link
                        css={linkTextCss}
                        to="https://blog.kensho.com/kensho-commits-to-carbon-offset-program-focused-on-cloud-computing-footprint-916761432a89"
                      >
                        Kensho Commits to Carbon Offset Program
                      </Link>{' '}
                      - Kensho Blog
                    </li>
                  </ul>
                </div>
                <ButtonLink
                  to="https://www.climateneutral.org/"
                  variant="minimal"
                  css={cardMinimalLinkCss}
                >
                  Climate Neutral
                </ButtonLink>
              </div>
            </AnimatedContent>
          </li>

          <li css={projectListItemCss}>
            <AnimatedContent>
              <div css={cardCss}>
                <div>
                  <h4 css={h4Body2Css}>Sustainable Food Systems</h4>
                  <ul css={[bulletedListCss, h4Body2Css, subProjectListCss]}>
                    <li css={bulletedListItemCss}>
                      <Link
                        css={linkTextCss}
                        to="https://blog.kensho.com/what-is-the-environmental-footprint-of-my-recipe-fd7bf831bd33"
                      >
                        Food Impact
                      </Link>{' '}
                      - Kensho Blog
                    </li>
                  </ul>
                </div>
                <ButtonLink
                  to="https://blog.kensho.com/what-is-the-environmental-footprint-of-my-recipe-fd7bf831bd33"
                  variant="minimal"
                  css={cardMinimalLinkCss}
                >
                  Sustainable Food Systems
                </ButtonLink>
              </div>
            </AnimatedContent>
          </li>
        </ul>
      </section>

      <section css={[baseSectionCss, backgroundLightGrayCss]}>
        <WideContent>
          <TopicHeadline text="Team Education Equity" css={noMarginCss} />
          <AnimatedContent>
            <p css={[h4Body1Css, bodyTextMarginCss]}>
              The Educational Equity team focuses on projects to improve opportunities at schools
              for underserved communities.
            </p>
            <p css={[h4Body1Css, bodyTextMarginCss]}>
              Their projects have included mentoring and coaching, speaking to classes about career
              opportunities, and analyzing data about colleges and universities. Some of their
              partnerships include:
            </p>
          </AnimatedContent>
        </WideContent>

        <ul css={projectListCss}>
          <li css={projectListItemCss}>
            <AnimatedContent>
              <div css={cardCss}>
                <div>
                  <h4 css={h4Body2Css}>John D O‘Bryant School</h4>
                  <ul css={[bulletedListCss, h4Body2Css, subProjectListCss]}>
                    <li css={bulletedListItemCss}>FIRST Robotics Team</li>
                  </ul>
                </div>
              </div>
            </AnimatedContent>
          </li>

          <li css={projectListItemCss}>
            <AnimatedContent>
              <div css={cardCss}>
                <div>
                  <h4 css={h4Body2Css}>Yonkers Partners in Education (YPIE)</h4>
                  <ul css={[bulletedListCss, h4Body2Css, subProjectListCss]}>
                    <li css={bulletedListItemCss}>
                      <Link css={linkTextCss} to="https://www.ypie.org/">
                        Yonkers Partners in Education (YPIE)
                      </Link>{' '}
                      - STEM Education Mentorships with HS science students
                    </li>
                  </ul>
                </div>
                <ButtonLink to="https://www.ypie.org/" variant="minimal" css={cardMinimalLinkCss}>
                  Yonkers Partners in Education
                </ButtonLink>
              </div>
            </AnimatedContent>
          </li>

          <li css={projectListItemCss}>
            <AnimatedContent>
              <div css={cardCss}>
                <div>
                  <h4 css={h4Body2Css}>Data</h4>
                  <ul css={[bulletedListCss, h4Body2Css, subProjectListCss]}>
                    <li css={bulletedListItemCss}>
                      <Link
                        css={linkTextCss}
                        to="https://www.kaggle.com/kenshoimpactteam/kensho-college-equity-data"
                      >
                        Kensho College Equity Data
                      </Link>{' '}
                      - Kaggle Dataset
                    </li>
                  </ul>
                </div>
              </div>
            </AnimatedContent>
          </li>
        </ul>
      </section>

      <section css={[baseSectionCss, whiteTextCss, backgroundGradientCss]}>
        <WideContent>
          <TopicHeadline text="How to get involved?" />
          <AnimatedContent>
            <p css={[h4Body1Css, bodyTextMarginCss]}>
              Partner organizations: Are you a non-profit organization with a problem you think
              Kensho’s Team Impact could help solve? We are always interested to hear ideas or
              proposals from you, but please bear in mind this is a volunteer project. Reach out to
              the team at <EmailLink email="impact-contact@kensho.com" />.
            </p>

            <p css={[h4Body1Css, bodyTextMarginCss]}>
              Pitch in on one of our projects! Are you a developer, engineer, data scientist, or
              other professional looking to help make the world better in some of your free time?
              Some of our projects can use extra help. Contact the team at{' '}
              <EmailLink email="impact-contact@kensho.com" />.
            </p>

            <div css={centeredTextCss}>
              <ButtonLink
                to="https://blog.kensho.com/kenshins-put-their-talents-to-use-for-community-impact-projects-c91fff8e3e5"
                variant="secondary"
                css={[largeButtonCss, centerButtonCss, outlinedButtonOnDarkCss]}
              >
                Learn more about Kensho’s cutting-edge technology solutions
              </ButtonLink>
            </div>
          </AnimatedContent>
        </WideContent>
      </section>

      <section css={[baseSectionPaddingTopCss, backgroundWhiteCss, footerPaddingCss]}>
        <WideContent>
          <TopicHeadline text="History of Team Impact" />
          <AnimatedContent>
            <p css={[h4Body1Css, bodyTextMarginCss]}>
              Kensho’s Team Impact was founded in 2019 based on employee feedback in our annual
              company survey. The message was clear: Kenshins wanted to do more and have a bigger
              impact in their communities. Contributing to holiday gift drives or helping to build
              houses was great but didn’t really use our skills. Kensho has one of the best teams in
              the worlds of data, ML and software engineering, and it was clear that we could have a
              much larger impact by putting down the hammers and picking up our keyboards. Our
              executive team listened to what employees were saying and supported the efforts
              whole-heartedly.
            </p>
            <p css={[h4Body1Css, bodyTextMarginCss]}>
              Since its founding, Team Impact has worked on a wide range of projects, from targeted,
              one-day projects to longer-term, ongoing partnerships and initiatives. The team has
              hosted biannual Impactathon days, where employees from across the company contribute a
              day of their time to a community project.
            </p>
            <p css={[h4Body1Css, bodyTextMarginCss]}>
              The team takes a grassroots approach. All of our projects originate from employees
              seeing an opportunity in their communities and finding a way to help—with support from
              other Kenshins.
            </p>
            <p css={[h4Body1Css, bodyTextMarginCss]}>
              Today, the three working groups meet regularly to discuss plans and cowork on
              projects, and “help wanted” notices are posted in the company newsletter and Slack
              channels when projects need a volunteer.
            </p>
          </AnimatedContent>
        </WideContent>
      </section>
    </Page>
  )
}
