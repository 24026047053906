import {css} from '@emotion/react'

import ErrorBoundary from '../ErrorBoundary'
import useIsScrolledInBounds from '../../hooks/useIsScrolledInBounds'

import CookieConsentPopup from './Cookies/CookieConsentPopup'
import Footer from './Footer'
import Header from './Header'

interface PageProps {
  children: React.ReactNode
  image?: string
  imageClassName?: string
  title?: React.ReactNode
  headerClassName?: string
  minimal?: boolean
}

const imageCss = css`
  transition: all 700ms;
  filter: contrast(120%) brightness(30%) grayscale(30%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center 55%;
  background-size: cover;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE11 and IE10 only, shim for unsupported filter property */
    opacity: 0.15;
  }
`

const sectionCss = css`
  position: relative;
`

export default function Page(props: PageProps): JSX.Element {
  const {children, image, imageClassName, title, headerClassName, minimal} = props
  const {setNode, isScrolledInBounds} = useIsScrolledInBounds<HTMLDivElement>(true)

  return (
    <>
      {!minimal && (
        <Header mode={isScrolledInBounds ? 'dark' : 'light'} className={headerClassName} />
      )}
      <main>
        <ErrorBoundary>
          {title && (
            <section css={sectionCss} ref={setNode}>
              {image || imageClassName ? (
                <div
                  className={imageClassName}
                  css={imageCss}
                  style={{backgroundImage: image ? `url(${image})` : undefined}}
                />
              ) : null}
              {typeof title === 'string' ? <h1>{title}</h1> : title}
            </section>
          )}
          {children}
        </ErrorBoundary>
      </main>
      {!minimal && (
        <>
          <Footer />
          <CookieConsentPopup />
        </>
      )}
    </>
  )
}
