import {css} from '@emotion/react'

import kenshoColor from '../../assets/logos/kensho-color.svg'
import CareersHeroLarge from '../../assets/photos/careers-hero-large.jpg'
import CareersHeroMedium from '../../assets/photos/careers-hero-medium.jpg'
import CareersHeroSmall from '../../assets/photos/careers-hero-small.jpg'
import socialImg from '../../assets/social/careers.jpg'
import {AnimatedContent, TwoColumnContent, WideContent} from '../../components/content'
import ButtonLink from '../../components/Button/ButtonLink'
import EnsoVideo from '../../components/EnsoVideo'
import GraphicContentGroup from '../../components/GraphicContentGroup'
import HeroSection from '../../components/Hero/HeroSection'
import HiringPreviewSection from '../../components/HiringPreviewSection'
import ImageGrid from '../../components/ImageGrid'
import Page from '../../components/Page'
import PageMetadata from '../../components/PageMetadata'
import TestimonialsCarousel from '../../components/TestimonialsCarousel'
import TopicHeadline from '../../components/TopicHeadline'
import {SECTION_IDS} from '../../constants'
import testimonialData from '../../data/testimonials/testimonialData'
import {BREAKPOINT_LARGE, BREAKPOINT_MEDIUM, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {
  backgroundLightBlueCss,
  backgroundLightGrayCss,
  backgroundWhiteCss,
  baseSectionCss,
  bodyTextMarginCss,
  centerButtonCss,
  centeredTextCss,
  h4Body1Css,
  h4Body2Css,
  largeButtonCss,
  noMarginCss,
  whiteTextCss,
} from '../../styles/common'

import AvailablePositions from './AvailablePositions'
import compositeImagePaths from './compositeImagePaths'
import {benefitsContent, cultureContent} from './graphicContentData'

const avoidWrapCss = css`
  display: inline-block;
`

const imageContainerCss = css`
  position: relative;
  top: -95px;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    top: 0;
  }
`

const diversityImageCss = css`
  width: 400px;
  max-width: 90%;
  height: auto;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin: 0 0 30px;
  }
`

const heroImageCss = css`
  background-position-y: 15%;
  background-image: url(${CareersHeroLarge});
  filter: contrast(120%) brightness(50%) grayscale(30%);

  @media (max-width: 2200px) {
    background-image: url(${CareersHeroMedium});
  }

  @media (max-width: 600px) {
    background-image: url(${CareersHeroSmall});
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    opacity: 1;
  }
`

const heroTextCss = css`
  padding: 0 0 40px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 0;
    font-size: 36px;
  }
`

const fullSizeCss = css`
  height: 100%;
  width: 100%;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    height: 750px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 500px;
  }
`

const graphicContentGroupCss = css`
  margin-top: 20px;
`

const diversityLeftContentCss = css`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding-bottom: 60px;
  }
`

const carouselContainerCss = css`
  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    margin-top: 40px;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    max-width: unset;
  }
`

const paddingTopCss = css`
  padding-top: 65px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding-top: 65px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding-top: 50px;
  }
`

export default function CareersPage(): JSX.Element {
  return (
    <Page
      title={
        <HeroSection imageCss={heroImageCss}>
          <WideContent>
            <AnimatedContent>
              <h1 css={[heroTextCss, centeredTextCss, whiteTextCss]}>
                Work <span css={avoidWrapCss}>with the</span> brightest minds from academia and
                industry
              </h1>
            </AnimatedContent>
          </WideContent>
        </HeroSection>
      }
    >
      <PageMetadata
        title="Careers | Kensho"
        description="What’s it like to be part of the Kensho team? Learn about our benefits, open positions, culture, leadership and more."
        image={{source: socialImg, height: 1250, width: 2500}}
      />

      <section css={whiteTextCss}>
        <TwoColumnContent
          leftWrapperCss={[backgroundLightBlueCss, whiteTextCss]}
          left={<HiringPreviewSection />}
          rightWrapperCss={backgroundWhiteCss}
          right={
            <AnimatedContent css={fullSizeCss} innerCss={fullSizeCss}>
              <EnsoVideo />
            </AnimatedContent>
          }
        />
      </section>

      <section css={[baseSectionCss, backgroundWhiteCss]}>
        <WideContent>
          <AnimatedContent>
            <TopicHeadline text="Reasons to Join" css={noMarginCss} />
          </AnimatedContent>
          <AnimatedContent css={h4Body1Css}>
            <p css={bodyTextMarginCss}>
              At Kensho, you have the opportunity to benefit from the collaboration and innovation
              of a startup environment while also leveraging the resources of one of the world’s
              largest and most successful financial institutions, S&P Global.
            </p>

            <p css={bodyTextMarginCss}>
              Kensho acts as S&P’s innovation and transformation hub, enabling S&P and its clients
              to use information more intelligently and effectively. Here, you have the opportunity
              to create impactful, scalable contributions that have the potential to transform the
              way the world thinks about data.
            </p>
            <p css={bodyTextMarginCss}>
              You get to work with a diverse and inclusive group of curious, highly accomplished
              engineers and business professionals who value collaboration, curiosity and mentorship
              at all levels. At Kensho, swinging for the fences is considered a team sport, and
              every Kenshin’s unique perspective and experiences are valued. You will never be “just
              a number” at Kensho.
            </p>

            <div css={centeredTextCss}>
              <ButtonLink
                to={`#${SECTION_IDS.CAREERS.AVAILABLE_POSITIONS}`}
                variant="secondary"
                css={[largeButtonCss, centerButtonCss]}
              >
                If this sounds exciting to you, come join us!
              </ButtonLink>
            </div>
          </AnimatedContent>
        </WideContent>
      </section>

      <section css={[baseSectionCss, backgroundLightGrayCss]}>
        <WideContent>
          <AnimatedContent css={h4Body1Css}>
            <TopicHeadline
              id={SECTION_IDS.CAREERS.CULTURE}
              text="Culture at Kensho"
              css={noMarginCss}
            />
          </AnimatedContent>

          <AnimatedContent>
            <GraphicContentGroup css={graphicContentGroupCss} graphicContent={cultureContent} />
          </AnimatedContent>
        </WideContent>
      </section>

      <section css={[baseSectionCss, backgroundWhiteCss]}>
        <WideContent>
          <AnimatedContent>
            <TopicHeadline
              id={SECTION_IDS.CAREERS.DEI}
              text="Kensho Diversity, Equity & Inclusion"
              css={noMarginCss}
            />
          </AnimatedContent>

          <TwoColumnContent
            leftContentCss={diversityLeftContentCss}
            left={
              <AnimatedContent css={imageContainerCss}>
                <img
                  src={kenshoColor}
                  css={diversityImageCss}
                  alt="Kensho Diversity and Inclusion Logo"
                />
              </AnimatedContent>
            }
            right={
              <AnimatedContent css={h4Body2Css}>
                <p css={bodyTextMarginCss}>
                  Kenshins are open-minded and intellectual, and invite the diverse perspectives of
                  our team’s global background. Our culture empowers every employee to bring their
                  full, authentic selves to work and values every Kenshin’s unique perspective and
                  experiences are valued. We know that building a diverse team of people with
                  different backgrounds and experience spurs innovation and helps us make better
                  decisions and build better products.
                </p>

                <p css={bodyTextMarginCss}>
                  Kensho continues to recognize, promote and add to our diverse talent in a number
                  of ways. We value diversity at the senior engineering and management levels and
                  celebrate our people across all organizational levels. Kenshins participate in
                  industry events such as Grace Hopper, support other programs that highlight the
                  development of diverse talent in tech, and are committed to attracting candidates
                  from many backgrounds.
                </p>

                <div css={centeredTextCss}>
                  <ButtonLink to="/dei" variant="secondary" css={[largeButtonCss, centerButtonCss]}>
                    Learn More
                  </ButtonLink>
                </div>
              </AnimatedContent>
            }
          />
        </WideContent>
      </section>

      <AnimatedContent>
        <ImageGrid images={compositeImagePaths} />
      </AnimatedContent>

      <section css={[baseSectionCss, backgroundWhiteCss]}>
        <WideContent>
          <AnimatedContent>
            <TopicHeadline id={SECTION_IDS.CAREERS.DEI} text="Benefits & Perks" css={noMarginCss} />
          </AnimatedContent>
          <AnimatedContent>
            <GraphicContentGroup graphicContent={benefitsContent} />
            <div css={centeredTextCss}>
              <ButtonLink
                to="/benefits"
                variant="secondary"
                css={[largeButtonCss, centerButtonCss]}
              >
                See more on our Benefits page
              </ButtonLink>
            </div>
          </AnimatedContent>
        </WideContent>
      </section>

      <section css={[paddingTopCss, backgroundLightGrayCss]}>
        <WideContent css={carouselContainerCss}>
          <AnimatedContent>
            <TopicHeadline text="Employee Testimonials" css={noMarginCss} />
          </AnimatedContent>
          <AnimatedContent>
            <TestimonialsCarousel testimonials={testimonialData} />
          </AnimatedContent>
        </WideContent>
      </section>

      <section css={[baseSectionCss, backgroundWhiteCss]}>
        <WideContent>
          <AnimatedContent>
            <TopicHeadline
              id={SECTION_IDS.CAREERS.AVAILABLE_POSITIONS}
              text="Available Positions"
              css={noMarginCss}
            />
          </AnimatedContent>
          <AnimatedContent>
            <AvailablePositions />
          </AnimatedContent>
        </WideContent>
      </section>
    </Page>
  )
}
