import {css} from '@emotion/react'

import COLORS from '../../styles/colors'
import {
  hBoldCss,
  labelCss,
  h4Body2Css,
  blackTextCss,
  h3SubheadlineCss,
  darkBlueTextCss,
} from '../../styles/common'
import Avatar from '../../components/Avatar'
import {BREAKPOINT_SMALL} from '../../styles/breakpoints'

const cardCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 444px;
  flex-basis: 100%;

  padding: 40px;
  border: 1px solid ${COLORS.gray[500]};

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    max-width: 100%;
  }
`

const nameCss = css`
  margin: 15px 0 10px;
`

const bottomTextCss = css`
  height: 250px;
  margin-top: 30px;
`

export interface BioCardProps {
  headshot: string
  name: string
  title: string
  text: string
}

export default function BioCard({headshot, name, title, text}: BioCardProps): JSX.Element {
  return (
    <div css={cardCss}>
      <Avatar
        photo={headshot}
        name={name}
        nameCss={[nameCss, h3SubheadlineCss, hBoldCss]}
        title={title}
        titleCss={[labelCss, hBoldCss, darkBlueTextCss]}
        size={328}
      />
      <p css={[bottomTextCss, h4Body2Css, blackTextCss]}>{text}</p>
    </div>
  )
}
