import {css} from '@emotion/react'

import TopicHeadline from '../TopicHeadline'
import {TwoColumnContent, AnimatedContent} from '../content'
import ButtonLink from '../Button/ButtonLink'
import {BREAKPOINT_XLARGE, BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {
  h3TitleCss,
  h3SubheadlineCss,
  hBoldCss,
  whiteTextCss,
  sectionHeadingMarginCss,
  backgroundDarkBlueGradientCss,
  h4Body2Css,
} from '../../styles/common'

const sectionCss = css`
  padding: 64px 0;
`

const alignCss = css`
  align-items: center;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    align-items: stretch;
  }
`

const rightCss = css`
  padding-left: 108px;

  @media (max-width: ${BREAKPOINT_XLARGE}px) {
    padding-left: 92px;
  }

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding-left: 40px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding-left: 23px;
  }
`

const buttonCss = css`
  height: 65px;
  max-width: 550px;
  border: 1px solid #fff;
  text-align: center;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-top: 60px;
    max-width: 100%;
    width: 100%;
  }
`

interface TwoColumnGradientProps {
  eyebrow?: string
  title?: string
  subtitle?: string
  buttonText: string
  href: string
  className?: string
}

export default function TwoColumnGradient({
  eyebrow,
  title,
  subtitle,
  buttonText,
  href,
  className,
}: TwoColumnGradientProps): JSX.Element {
  return (
    <TwoColumnContent
      className={className}
      css={[alignCss, sectionCss, backgroundDarkBlueGradientCss]}
      left={
        <>
          {eyebrow && <TopicHeadline css={whiteTextCss} text={eyebrow} />}
          {title && (
            <AnimatedContent>
              <h3 css={[h3TitleCss, hBoldCss, whiteTextCss, sectionHeadingMarginCss]}>{title}</h3>
            </AnimatedContent>
          )}
          {subtitle && (
            <AnimatedContent>
              <p css={[h3SubheadlineCss, whiteTextCss]}>{subtitle}</p>
            </AnimatedContent>
          )}
        </>
      }
      rightContentCss={rightCss}
      right={
        <AnimatedContent animation="fade-in">
          <ButtonLink to={href} variant="secondary" css={[buttonCss, h4Body2Css, whiteTextCss]}>
            {buttonText}
          </ButtonLink>
        </AnimatedContent>
      }
    />
  )
}
