import {css} from '@emotion/react'

import logoDarkBlue from '../../../assets/logos/header/kensho-dark-blue.svg'
import logoLightBlue from '../../../assets/logos/header/kensho-light-blue.svg'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL, BREAKPOINT_XLARGE} from '../../../styles/breakpoints'
import COLORS from '../../../styles/colors'
import Link from '../../Link'

import HeaderNavItem from './HeaderNavItem'
import {contactUsNavLink, freeTrialNavLink, navigation, selfServiceNavLink} from './navigation'
import {bgDarkCss, bgLightCss, darkCss, headerCss, lightCss, logoCss} from './styles'

interface DesktopHeaderProps {
  className?: string
  mode: 'dark' | 'light'
}

const desktopHeaderCss = css`
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    display: none;
  }
`

const containerCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const contentCss = css`
  display: flex;
  padding: 0 20px;
  width: 1452px;
  max-width: 100%;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 0 20px;
  }
`

const navCss = css`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`

const leftNavCss = css`
  display: flex;
  gap: 16px;

  @media (max-width: ${BREAKPOINT_XLARGE}px) {
    gap: 8px;
  }
`

const rightNavCss = css`
  display: flex;
  gap: 16px;
`

const rightNavItemsCss = css`
  padding: 9px 20px;
`

const middleButtonLightCss = css`
  color: ${COLORS.brand.light[700]};
  border: 2px solid ${COLORS.brand.light[700]};
`

const middleButtonDarkCss = css`
  color: ${COLORS.brand.dark[700]};
  border: 2px solid ${COLORS.brand.dark[700]};
`

export default function DesktopHeader(props: DesktopHeaderProps): JSX.Element {
  const {mode, className} = props
  return (
    <header
      css={[
        headerCss,
        desktopHeaderCss,
        mode === 'dark' ? bgDarkCss : bgLightCss,
        mode === 'dark' ? darkCss : lightCss,
      ]}
      className={className}
    >
      <div css={containerCss}>
        <div css={contentCss}>
          <Link to="/">
            <img src={mode === 'dark' ? logoLightBlue : logoDarkBlue} alt="Kensho" css={logoCss} />
          </Link>
          <nav css={navCss}>
            <div css={leftNavCss}>
              {navigation.map((navItem) => (
                <HeaderNavItem
                  item={navItem}
                  mode={mode}
                  key={navItem.isGroup ? navItem.link.label : navItem.label}
                />
              ))}
            </div>
            <div css={rightNavCss}>
              <HeaderNavItem item={selfServiceNavLink} mode={mode} />
              <HeaderNavItem
                item={freeTrialNavLink}
                mode={mode}
                variant="secondary"
                css={[
                  rightNavItemsCss,
                  mode === 'dark' ? middleButtonDarkCss : middleButtonLightCss,
                ]}
              />
              <HeaderNavItem
                item={contactUsNavLink}
                mode={mode}
                variant="primary"
                css={rightNavItemsCss}
              />
            </div>
          </nav>
        </div>
      </div>
    </header>
  )
}
