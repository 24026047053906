import {useFuse} from '@kensho/tacklebox'

import useIsScrolledInBounds from './useIsScrolledInBounds'

interface UseHasScrolledInBoundsProps<T extends HTMLElement> {
  setNode: (node: T | null) => void
  hasScrolledInBounds: boolean
}

export default function useHasScrolledInBounds<T extends HTMLElement>(
  initialValue = false,
): UseHasScrolledInBoundsProps<T> {
  const {setNode, isScrolledInBounds} = useIsScrolledInBounds<T>(initialValue)
  const hasScrolledInBounds = useFuse(isScrolledInBounds)

  return {setNode, hasScrolledInBounds}
}
