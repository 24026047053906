import {css} from '@emotion/react'

import Page from '../../components/Page'
import PageMetadata from '../../components/PageMetadata'
import HeroSection from '../../components/Hero/HeroSection'
import {WideContent, AnimatedContent} from '../../components/content'
import {centeredTextCss, h1Css, hBoldCss, whiteTextCss} from '../../styles/common'
import ResearchHeroLarge from '../../assets/photos/research-hero-large.jpg'
import ResearchHeroMedium from '../../assets/photos/research-hero-medium.jpg'
import ResearchHeroSmall from '../../assets/photos/research-hero-small.jpg'
import {BREAKPOINT_SMALL} from '../../styles/breakpoints'

import ProjectSection from './ProjectSection'
import JoinUsSection from './JoinUsSection'
import PublicationSection from './PublicationSection'
import IntroSection from './IntroSection'
import TeamSection from './TeamSection'

const heroImageCss = css`
  background-image: url(${ResearchHeroLarge});
  filter: contrast(110%) brightness(60%);

  @media (max-width: 2200px) {
    background-image: url(${ResearchHeroMedium});
  }

  @media (max-width: 600px) {
    background-image: url(${ResearchHeroSmall});
  }

  @media all and(-ms-high-contrast: none), (-ms-high-contrast: active) {
    opacity: 1;
  }
`

const heroTitleCss = css`
  hyphen: manual;
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    font-size: 60px;
  }
`

export default function Research(): JSX.Element {
  return (
    <Page
      title={
        <HeroSection imageCss={heroImageCss}>
          <WideContent>
            <AnimatedContent>
              <h1 css={[h1Css, hBoldCss, whiteTextCss, heroTitleCss, centeredTextCss]}>
                Re&shy;search
              </h1>
            </AnimatedContent>
          </WideContent>
        </HeroSection>
      }
    >
      <PageMetadata
        title="Research | Kensho"
        description="R&D at Kensho"
        image={{source: ResearchHeroSmall, height: 1250, width: 2500}}
      />
      <IntroSection />
      <ProjectSection />
      <TeamSection />
      <JoinUsSection />
      <PublicationSection />
    </Page>
  )
}
