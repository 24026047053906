import {css} from '@emotion/react'
import {useState} from 'react'

import useCookie from '../../../hooks/useCookie'
import useIsMounted from '../../../hooks/useIsMounted'
import KenshoLogo from '../../../assets/logos/header/kensho-dark-blue.svg'
import Button from '../../Button'
import {h4Body2Css, whiteTextCss} from '../../../styles/common'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../../styles/breakpoints'
import Dialog from '../../ui/Dialog'
import {CONSENT_COOKIE_DEFAULTS, CONSENT_COOKIE_NAME} from '../../../constants'

import CookieConsentDialogContents, {CookieConsentOptions} from './CookieConsentDialogContents'

const bannerCss = css`
  z-index: 12;
  height: 400px;
  max-height: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 60px 30px;
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 20px 30px;
  }
`

const gdprConsentCss = css`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  max-width: 1300px;
  margin: 0 auto;
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const textCss = css`
  overflow-y: auto;
  max-width: 800px;
  margin-right: 40px;
  line-height: 36px;
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin: 0 0 30px 0;
  }
`

const buttonGroupCss = css`
  display: flex;
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    flex-direction: column;
    align-items: flex-end;
  }
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: row;
    align-self: flex-end;
    width: 100%;
  }
`

const buttonCss = css`
  padding: 30px 40px;
  white-space: nowrap;
  border: none;
  &:hover {
    opacity: 0.75;
  }
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex: 1;
    padding: 30px 20px;
    white-space: normal;
  }
`

const leftButtonCss = css`
  text-decoration: underline;

  &:after {
    border: none;
  }
`

const linkCss = css`
  text-decoration: underline;
`

const logoCss = css`
  width: 80px;
`

interface AnalyticsProvider {
  name: string
  enable: () => void
  disable: () => void
}

const analyticsProviders: AnalyticsProvider[] = [
  {
    name: 'Google Analytics',
    enable: (): void => {
      window['ga-disable-G-G5K9GB2NXM'] = false
      window.gtag('js', new Date())
      window.gtag('config', 'G-G5K9GB2NXM', {anonymize_ip: true})
    },
    disable: (): void => {
      window['ga-disable-G-G5K9GB2NXM'] = true
    },
  },
  {
    name: 'Google Tag Manager',
    enable: (): void => {
      window.gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted',
        functionality_storage: 'granted',
        personalization_storage: 'granted',
        security_storage: 'granted',
      })
    },
    disable: (): void => {
      window.gtag('consent', 'update', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        functionality_storage: 'denied',
        personalization_storage: 'denied',
        security_storage: 'denied',
      })
    },
  },
]

function submitConsent(consentUpdate: Partial<CookieConsentOptions>): void {
  analyticsProviders.forEach((provider) =>
    consentUpdate.targeting ? provider.enable() : provider.disable(),
  )
}

export default function GDPRPopup(): JSX.Element | null {
  const [isOpen, setIsOpen] = useState(false)
  const [consentCookie, setConsentCookie] = useCookie<CookieConsentOptions>(
    CONSENT_COOKIE_NAME,
    CONSENT_COOKIE_DEFAULTS,
  )
  const isMounted = useIsMounted()

  if (!isMounted || consentCookie.isSet) return null

  return (
    <>
      <div css={bannerCss}>
        <div css={gdprConsentCss}>
          <p css={[h4Body2Css, textCss, whiteTextCss]}>
            Kensho uses cookies to improve user experience and site performance and to inform site
            analytics. Where required by applicable law, we will obtain your consent before we place
            any cookies on your device that are not strictly necessary for the functioning of our
            websites. By clicking “Accept All Cookies”, you agree to our use of cookies. Learn about
            our cookies and how to modify your preferences in our Cookie Notice. Visit our updated{' '}
            <a
              css={[linkCss, whiteTextCss]}
              href="https://www.spglobal.com/en/cookie-notice"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              css={[linkCss, whiteTextCss]}
              href="https://www.spglobal.com/en/cookie-notice"
              target="_blank"
              rel="noreferrer"
            >
              Cookie Notice
            </a>
            , effective December 30, 2022.
          </p>
          <div css={buttonGroupCss}>
            <Button
              css={[buttonCss, leftButtonCss, h4Body2Css, whiteTextCss]}
              variant="minimal"
              mode="dark"
              onClick={() => {
                setIsOpen(true)
              }}
            >
              Cookie Settings
            </Button>
            <Button
              css={[buttonCss, h4Body2Css, whiteTextCss]}
              variant="primary"
              mode="dark"
              onClick={() => {
                const acceptAllCookie = {
                  functional: true,
                  targeting: true,
                  performance: true,
                  isSet: true,
                }
                setConsentCookie(acceptAllCookie)
                submitConsent(acceptAllCookie)
              }}
            >
              Accept all Cookies
            </Button>
          </div>
        </div>
      </div>
      <Dialog
        blurBackdrop
        // @ts-ignore: Pass image into title prop
        title={<img src={KenshoLogo} alt="Kensho Logo" css={logoCss} />}
        isOpen={isOpen}
        canOutsideClickClose
        canEscapeKeyClose
        onClose={() => setIsOpen(false)}
      >
        <CookieConsentDialogContents
          /* This submission depends on the current grouping of analytics (boolean gdprConsentCookie and the targeting cookies) - if the set of analytics gets updated, 
          we will need to update their data structure and how this submission works accordingly. */
          consentState={consentCookie}
          changeConsent={(cookieUpdate: Partial<CookieConsentOptions>) => {
            setConsentCookie({...consentCookie, ...cookieUpdate})
            submitConsent({...consentCookie, ...cookieUpdate})
          }}
        />
      </Dialog>
    </>
  )
}
