import {css} from '@emotion/react'

import ScribeGraphic from '../../assets/solutions/scribe.svg'
import Link from '../../components/Link'
import {URLS} from '../../constants'
import {bulletedListCss, bulletedListItemCss} from '../../styles/common'

import SolutionCard from './SolutionCard'

const blueLinkCss = css`
  color: #007694;
`

export default function SolutionsScribe(): JSX.Element {
  return (
    <SolutionCard
      label="Transcribe Speech to Text"
      graphic={{src: ScribeGraphic, alt: 'Scribe Icon'}}
      title="Scribe"
      description="Kensho Scribe is a transcription solution optimized for tricky, real-world audio."
      learnMoreUrl="/scribe"
      mainContent={
        <>
          <p>Scribe transcribes your audio with unparalleled accuracy, speed and security:</p>
          <ul css={bulletedListCss}>
            <li css={bulletedListItemCss}>
              25% accuracy improvement over other transcription services.
            </li>
            <li css={bulletedListItemCss}>Real-time transcription.</li>
            <li css={bulletedListItemCss}>99+% accuracy in 6 hours with professional review.</li>
            <li css={bulletedListItemCss}>
              Read about Scribe in our blog post,{' '}
              <Link
                css={blueLinkCss}
                to="https://blog.kensho.com/speech-recognition-for-finance-86983b2b97bd"
              >
                Speech Recognition for Finance
              </Link>
            </li>
          </ul>
        </>
      }
      linkList={[
        {href: 'https://scribe.kensho.com/ ', text: 'Developer Docs'},
        {href: 'mailto:sales@kensho.com?subject=Scribe', text: 'Contact Scribe'},
      ]}
      callToActionLink={{
        href: `${URLS.FREE_TRIAL}/scribe`,
        text: 'Free Trial',
      }}
    />
  )
}
