import ClassifyGraphic from '../../assets/solutions/classify.svg'
import {URLS} from '../../constants'
import {bulletedListCss, bulletedListItemCss} from '../../styles/common'

import SolutionCard from './SolutionCard'

export default function SolutionsClassify(): JSX.Element {
  return (
    <SolutionCard
      label="Understand Document Concepts"
      graphic={{src: ClassifyGraphic, alt: 'Classify Icon'}}
      title="Classify"
      description="Kensho Classify is a machine learning tool that enables you to tag and search text documents with completely customizable concepts."
      mainContent={
        <>
          <p>Use AI to understand what your documents are conceptually about.</p>
          <ul css={bulletedListCss}>
            <li css={bulletedListItemCss}>
              Develop custom concept models without machine learning expertise.
            </li>
            <li css={bulletedListItemCss}>See where specific concepts occur in text.</li>
            <li css={bulletedListItemCss}>
              Reveal insights, enrich your data structure, and connect disparate content
            </li>
          </ul>
        </>
      }
      learnMoreUrl="/classify"
      linkList={[
        {href: 'https://classify.kensho.com/docs/', text: 'Developer Docs'},
        {href: 'mailto:sales@kensho.com?subject=Classify', text: 'Contact Classify'},
      ]}
      callToActionLink={{
        href: `${URLS.FREE_TRIAL}/classify`,
        text: 'Free Trial',
      }}
    />
  )
}
