import {css} from '@emotion/react'

interface OfficeProps {
  city: string
  name: string
  address: string
}

const officeCss = css`
  &:not(:last-of-type) {
    margin-right: 40px;
  }

  @media (max-width: 1150px) {
    &:not(:last-of-type) {
      margin-right: 0;
    }
  }

  @media (max-width: 720px) {
    &:not(:last-of-type) {
      margin-bottom: 30px;
    }
  }
`

export default function Office(props: OfficeProps): JSX.Element {
  const {address, city, name} = props
  return (
    <div css={officeCss}>
      <h4>{name}</h4>
      <div>{address}</div>
      <div>{city}</div>
    </div>
  )
}
