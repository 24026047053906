import {css} from '@emotion/react'

import ScribeProductLogo from '../../../assets/logos/products/scribe.svg'
import ScribeHeroLarge from '../../../assets/photos/scribe-hero-large.jpg'
import ScribeHeroMedium from '../../../assets/photos/scribe-hero-medium.jpg'
import ScribeHeroSmall from '../../../assets/photos/scribe-hero-small.jpg'
import socialImg from '../../../assets/social/scribe.jpg'
import ButtonLink from '../../../components/Button/ButtonLink'
import {AnimatedContent, TwoColumnContent, WideContent} from '../../../components/content'
import ProductHeroSection from '../../../components/Hero/ProductHeroSection'
import Page from '../../../components/Page'
import PageMetadata from '../../../components/PageMetadata'
import {URLS} from '../../../constants'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL, BREAKPOINT_XLARGE} from '../../../styles/breakpoints'
import {
  avoidWrapCss,
  backgroundGradientCss,
  backgroundWhiteCss,
  blackTextCss,
  centeredTextCss,
  h3SubheadlineCss,
  h4Body1Css,
  h4Body2Css,
  hBoldCss,
  whiteTextCss,
} from '../../../styles/common'

import {WER_CALCULATION_SECTION} from './constants'
import WERCalculator from './WERCalculator'

const heroImageCss = css`
  background-image: url(${ScribeHeroLarge});

  @media (max-width: 2200px) {
    background-image: url(${ScribeHeroMedium});
  }

  @media (max-width: 600px) {
    background-image: url(${ScribeHeroSmall});
  }

  filter: none;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    opacity: 1;
  }
`

const sectionCss = css`
  padding: 95px 0px 60px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding: 40px 0px;
  }
`

const alignCss = css`
  align-items: center;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    align-items: stretch;
  }
`

const leftTextCss = css`
  margin: 45px 0px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-bottom: 65px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-top: 35px;
  }
`

const leftCss = css`
  padding-right: 120px;
`

const rightCss = css`
  padding-left: 120px;
`

const freeTrialButtonCss = css`
  height: 65px;
  width: 550px;
  border: 1px solid #fff;

  @media (max-width: ${BREAKPOINT_XLARGE}px) {
    width: 439px;
  }

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    width: 100%;
  }
`

const questionCss = css`
  font-size: 20px;
  margin-bottom: 14px;
`

const explanationCss = css`
  margin: 40px 0;

  &:first-of-type {
    margin-top: 0;
  }
`

export default function WERCalculatorPage(): JSX.Element {
  return (
    <Page
      title={
        <ProductHeroSection
          imageCss={heroImageCss}
          productLogo={ScribeProductLogo}
          productTitle="Scribe"
          productSubtitle="Word Error Rate Calculator"
        />
      }
    >
      <PageMetadata
        title="Kensho Word Error Rate Calculator | Scribe"
        description="Kensho Scribe's Word Error Rate Calculator is a free tool that can be used to evaluate transcription accuracy."
        image={{source: socialImg, height: 1250, width: 2500}}
      />
      <WERCalculator />
      <section css={[sectionCss, backgroundGradientCss]}>
        <TwoColumnContent
          css={alignCss}
          left={
            <>
              <AnimatedContent>
                <h3 css={[h3SubheadlineCss, hBoldCss, whiteTextCss, centeredTextCss]}>
                  Need accurate transcripts?
                </h3>
              </AnimatedContent>
              <AnimatedContent>
                <p css={[leftTextCss, h3SubheadlineCss, whiteTextCss, centeredTextCss]}>
                  Get started with Scribe immediately.
                </p>
              </AnimatedContent>
            </>
          }
          rightContentCss={rightCss}
          right={
            <AnimatedContent animation="fade-in">
              <ButtonLink
                to={`${URLS.FREE_TRIAL}?product=scribe`}
                variant="secondary"
                mode="dark"
                css={[freeTrialButtonCss, h4Body1Css, whiteTextCss]}
              >
                Free Trial
              </ButtonLink>
            </AnimatedContent>
          }
        />
      </section>

      <section css={[sectionCss, backgroundWhiteCss]} id={WER_CALCULATION_SECTION}>
        <WideContent>
          <AnimatedContent>
            <h3 css={[h3SubheadlineCss, hBoldCss, blackTextCss]}>Word Error Rate</h3>
          </AnimatedContent>

          <AnimatedContent css={explanationCss}>
            <h3 css={[questionCss, hBoldCss, blackTextCss]}>What is Word Error Rate?</h3>
            <p css={[h4Body2Css, blackTextCss]}>
              Although there are other metrics to measure how well an ASR system is performing, WER
              is a relatively simple but actionable metric that allows us to compare ASR systems to
              one another, and to evaluate an individual ASR system’s accuracy over time
            </p>
          </AnimatedContent>
          <AnimatedContent css={explanationCss}>
            <h3 css={[questionCss, hBoldCss, blackTextCss]}>How is Word Error Rate Calculated?</h3>
            <p css={[h4Body2Css, blackTextCss]}>
              Word Error Rate (WER) is a commonly used metric to judge the accuracy of Automatic
              Speech Recognition (ASR) systems. It is the ratio of errors in the ASR’s transcription
              to the total number of words spoken. WER is typically calculated ignoring differences
              in punctuation, whitespace, and capitalization.
            </p>
          </AnimatedContent>
        </WideContent>
      </section>

      <section css={[sectionCss, backgroundGradientCss]}>
        <TwoColumnContent
          css={alignCss}
          leftContentCss={leftCss}
          left={
            <AnimatedContent animation="fade-in">
              <ButtonLink
                to="/scribe"
                variant="secondary"
                mode="dark"
                css={[freeTrialButtonCss, h4Body1Css, whiteTextCss]}
              >
                Learn About Scribe
              </ButtonLink>
            </AnimatedContent>
          }
          right={
            <AnimatedContent>
              <h3 css={[h3SubheadlineCss, hBoldCss, whiteTextCss, centeredTextCss]}>
                Learn more about Scribe AI and <span css={avoidWrapCss}>human-in-the-loop</span>{' '}
                transcription services.
              </h3>
            </AnimatedContent>
          }
        />
      </section>
    </Page>
  )
}
