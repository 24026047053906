import {css} from '@emotion/react'

import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {AnimatedContent, TwoColumnContent, WideContent} from '../../components/content'
import ButtonLink from '../../components/Button/ButtonLink'
import TopicHeadline from '../../components/TopicHeadline'
import Skeleton from '../../components/Skeleton'
import {
  h3TitleCss,
  h3SubheadlineCss,
  h4Body2Css,
  hBoldCss,
  blackTextCss,
  backgroundLightGrayCss,
  hNormalCss,
  centeredTextCss,
  h4Body1Css,
  baseSectionCss,
} from '../../styles/common'
import useIsMounted from '../../hooks/useIsMounted'
import {URLS} from '../../constants'

import AudioSamples from './AudioSamples'
import TranscribedAudioTicker from './TranscribedAudioTicker'

const rightColumnCss = css`
  max-width: 740px;
`

const skeletonContainerCss = css`
  max-width: 1145px;
  margin: 50px 0px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    max-width: 753px;
    margin: 45px 0px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-bottom: 330px;
    margin: 35px 0px;
  }
`

const textSection = css`
  margin-bottom: 75px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-bottom: 60px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-bottom: 32px;
  }
`

const leftTextCss = css`
  margin-bottom: 0px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-bottom: 20px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-bottom: 40px;
  }
`

const rightHeaderCss = css`
  margin-bottom: 16px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-bottom: 30px;
  }
`

const centerCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const buttonCss = css`
  max-width: 1136px;
  width: 100%;
  height: 70px;
  border: 2px solid #007694;
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    max-width: 753px;
  }
`

export default function AudioSection(): JSX.Element {
  const isMounted = useIsMounted()

  return (
    <section css={[baseSectionCss, backgroundLightGrayCss]}>
      <WideContent>
        <TopicHeadline css={blackTextCss} text="See for Yourself" />
      </WideContent>
      <TwoColumnContent
        css={textSection}
        left={
          <AnimatedContent>
            <h3 css={[leftTextCss, h3TitleCss, hBoldCss, blackTextCss]}>
              Listen to the sample audio clips below.{' '}
            </h3>
          </AnimatedContent>
        }
        rightContentCss={rightColumnCss}
        right={
          <>
            <AnimatedContent>
              <h3 css={[rightHeaderCss, h3SubheadlineCss, hNormalCss, blackTextCss]}>
                See the difference between the transcriptions offered by Kensho Scribe vs the
                competition.
              </h3>
              <p css={[h4Body2Css, blackTextCss]}>
                Scribe is specifically optimized for the complexities of your messy real-world audio
                and the nuances of spoken language. It was trained on more than 100,000 hours of
                professionally-curated audio and corresponding transcripts.
              </p>
            </AnimatedContent>
          </>
        }
      />
      <AnimatedContent animation="fade-in">
        {isMounted ? (
          <AudioSamples />
        ) : (
          <WideContent css={skeletonContainerCss}>
            <Skeleton height={425} />
          </WideContent>
        )}
      </AnimatedContent>
      <WideContent css={centerCss}>
        <AnimatedContent animation="fade-in">
          <ButtonLink
            to={`${URLS.FREE_TRIAL}/scribe`}
            variant="secondary"
            css={[buttonCss, h4Body1Css]}
          >
            Get Started
          </ButtonLink>
        </AnimatedContent>
        <AnimatedContent animation="fade-in">
          <TranscribedAudioTicker />
          <h3 css={[h3SubheadlineCss, hBoldCss, blackTextCss, centeredTextCss]}>
            ...of high-quality audio transcribed and counting!
          </h3>
        </AnimatedContent>
      </WideContent>
    </section>
  )
}
