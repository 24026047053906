import {css} from '@emotion/react'

import {BREAKPOINT_SMALL} from '../../../styles/breakpoints'
import whitePlayIcon from '../../../assets/icons/white-play.svg'
import bluePlayIcon from '../../../assets/icons/blue-play.svg'
import durationToMinutesAndSeconds from '../../../utils/durationToMinutesAndSeconds'
import {labelCss, hBoldCss, blackTextCss, backgroundLightBlueCss} from '../../../styles/common'

interface AudioItemProps {
  className?: string
  name: string
  duration?: number
  selected?: boolean
  onClick?: (event: React.MouseEvent) => void
}

const listItemCss = css`
  margin-bottom: 25px;
  border-radius: 5px;

  &:hover {
    background: rgba(80, 175, 198, 0.2);
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-bottom: 20px;
  }
`

const audioItemCss = css`
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px;

  &:hover {
    background: transparent;
  }
`

const playIconCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #007694;
  border-radius: 100%;
  height: 44px;
  width: 44px;

  & > img {
    height: 16px;
    width: 16px;
    margin-left: 4px;
  }
`

const nameCss = css`
  margin-left: 13px;
  letter-spacing: 0.1em;
`

const blueTextCss = css`
  color: #007694;
`

const durationCss = css`
  margin: 0px 5px;
  letter-spacing: 0.1em;
`

const selectedCss = css`
  background: rgba(80, 175, 198, 0.15);
`

export default function AudioItem({
  className,
  name,
  duration,
  selected,
  onClick,
}: AudioItemProps): JSX.Element {
  return (
    <li css={[listItemCss, selected && selectedCss]} className={className}>
      <button css={audioItemCss} type="button" onClick={onClick}>
        <div css={[playIconCss, selected && backgroundLightBlueCss]}>
          {selected ? (
            <img src={whitePlayIcon} alt="Play" />
          ) : (
            <img src={bluePlayIcon} alt="Play" />
          )}
        </div>
        <span
          css={[labelCss, blueTextCss, nameCss, selected && blackTextCss, selected && hBoldCss]}
        >
          {name}
        </span>
        {!!duration && (
          <span
            css={[
              labelCss,
              blueTextCss,
              durationCss,
              selected && blackTextCss,
              selected && hBoldCss,
            ]}
          >{`(${durationToMinutesAndSeconds(duration)})`}</span>
        )}
      </button>
    </li>
  )
}
