import {css} from '@emotion/react'
import {IconChevronRight} from '@kensho/icons'

import linkCaseStudyImg from '../../assets/photos/link-case-study.jpg'
import {AnimatedContent, TwoColumnContent} from '../../components/content'
import ButtonLink from '../../components/Button/ButtonLink'
import TopicHeadline from '../../components/TopicHeadline'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL, BREAKPOINT_XLARGE} from '../../styles/breakpoints'
import {
  h3TitleCss,
  h4Body1Css,
  hBoldCss,
  whiteTextCss,
  backgroundGradientCss,
  backgroundMidGrayCss,
  sectionHeadingMarginCss,
} from '../../styles/common'

const leftHalfCss = css`
  padding: 100px 0;

  @media (max-width: ${BREAKPOINT_LARGE}) {
    padding: 80px 0;
  }

  @media (max-width: ${BREAKPOINT_SMALL}) {
    padding: 55px 0;
  }
`

const fullSizeCss = css`
  height: 100%;
  width: 100%;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    height: 850px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 500px;
  }
`

const bottomMarginCss = css`
  margin-bottom: 24px;
`

const linkButtonCss = css`
  margin-top: 40px;
  width: 50%;

  @media (max-width: ${BREAKPOINT_XLARGE}px) {
    width: 70%;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 100%;
  }
`

const imageContainerCss = css`
  padding: 0;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding: 0;
    max-width: unset;
  }
`

const imageCss = css`
  object-fit: cover;
`

export default function CaseStudySection(): JSX.Element {
  return (
    <section>
      <TwoColumnContent
        leftWrapperCss={backgroundGradientCss}
        left={
          <div css={[leftHalfCss, whiteTextCss]}>
            <TopicHeadline text="Case Study" />
            <AnimatedContent>
              <h3 css={[sectionHeadingMarginCss, h3TitleCss, hBoldCss]}>
                See how an investment management firm grows their data subscriptions and future
                dataset growth
              </h3>
            </AnimatedContent>
            <AnimatedContent css={bottomMarginCss}>
              <p css={h4Body1Css}>
                For one investment management company, the ability to map messy data in the form of
                company names, aliases, and domains to the CapIQ CompanyID enhances their data
                subscriptions and promotes future dataset growth.
              </p>
            </AnimatedContent>
            <AnimatedContent>
              <p css={h4Body1Css}>
                Not being able to quickly and efficiently pull data on companies in their database
                is painful. Kensho Link enables this client to do the mapping at scale with
                confidence. To learn more about how a global media &amp; advertising firm is using
                Kensho Link, click the button below. Once mapped to these unique IDs, a database
                becomes much more powerful. It is linked and cleaned, making data enrichment and
                deduplication seamless.
              </p>
            </AnimatedContent>
            <AnimatedContent animation="fade-in">
              <ButtonLink
                to="https://www.spglobal.com/_assets/documents/marketplace/solution-docs/transforming-sales.pdf"
                variant="secondary"
                mode="dark"
                css={linkButtonCss}
              >
                Read Full Case Study
                <IconChevronRight size={65} />
              </ButtonLink>
            </AnimatedContent>
          </div>
        }
        rightWrapperCss={backgroundMidGrayCss}
        rightContentCss={imageContainerCss}
        right={
          <AnimatedContent animation="fade-in" css={fullSizeCss} innerCss={fullSizeCss}>
            <img
              src={linkCaseStudyImg}
              alt="A well-dressed man works on his tablet outside a refined executive building."
              height="100%"
              width="100%"
              css={imageCss}
              loading="lazy"
            />
          </AnimatedContent>
        }
      />
    </section>
  )
}
