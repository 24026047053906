import {css} from '@emotion/react'

import {AnimatedContent, TwoColumnContent, WideContent} from '../../components/content'
import TopicHeadline from '../../components/TopicHeadline'
import ShopperLarge from '../../assets/photos/shopper.jpg'
import {
  backgroundLightBlueCss,
  h4Body2Css,
  h3SubheadlineCss,
  hBoldCss,
  centeredTextCss,
  whiteTextCss,
} from '../../styles/common'
import COLORS from '../../styles/colors'
import {BREAKPOINT_XLARGE, BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'

const leftPaddingCss = css`
  padding: 0;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding: 0;
  }
`

const leftContentCss = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const fullSizeCss = css`
  height: 100%;
  width: 100%;
`

const fullSizeFlexCss = css`
  height: 100%;
  width: 100%;
  display: flex;
`

const imageCss = css`
  object-fit: cover;
`

const bottomLeftCss = css`
  background-color: ${COLORS.brand.light[300]};
  padding-top: 65px;
  padding-bottom: 65px;
  height: 360px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    height: unset;
    padding-top: 50px;
    padding-bottom: 55px;
  }
`

const rightCss = css`
  padding: 65px 0 35px 70px;
`

const rightTextCss = css`
  font-size: 40px;
`

const numbersGroupCss = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 50px;

  margin-top: 65px;
  margin-bottom: 40px;

  &:first-of-type {
    margin-top: 0;
  }

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-top: 50px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
`

const numberCss = css`
  font-size: 130px;

  @media (max-width: ${BREAKPOINT_XLARGE}px) {
    font-size: 100px;
  }

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    font-size: 130px;
  }
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    font-size: 100px;
  }
`

const numberSublineCss = css`
  line-height: 20px;
  margin-top: -40px;
  max-width: 200px;
`

export default function NumbersSection(): JSX.Element {
  return (
    <section>
      <TwoColumnContent
        leftContentCss={leftPaddingCss}
        left={
          <div css={leftContentCss}>
            <AnimatedContent animation="fade-in" css={fullSizeCss} innerCss={fullSizeFlexCss}>
              <img src={ShopperLarge} alt="Shopper" css={[fullSizeCss, imageCss]} />
            </AnimatedContent>
            <WideContent css={bottomLeftCss}>
              <TopicHeadline text="Innovation from Kensho NLP" css={whiteTextCss} />
              <AnimatedContent animation="fade-in">
                <p css={[h3SubheadlineCss, whiteTextCss]}>
                  Kensho Classify provides a superior, tailored product for your toughest
                  classification use cases.
                </p>
              </AnimatedContent>
            </WideContent>
          </div>
        }
        rightWrapperCss={backgroundLightBlueCss}
        rightContentCss={rightCss}
        right={
          <>
            <TopicHeadline text="By The Numbers" css={whiteTextCss} />
            <AnimatedContent animation="fade-in">
              <h3 css={[rightTextCss, hBoldCss, whiteTextCss]}>Superior custom concept creation</h3>
            </AnimatedContent>
            <AnimatedContent animation="fade-in" delay={1}>
              <div css={numbersGroupCss}>
                <div css={centeredTextCss}>
                  <h3 css={[numberCss, hBoldCss, whiteTextCss]}>&ge;10%</h3>
                  <p css={[h4Body2Css, numberSublineCss, centeredTextCss, hBoldCss, whiteTextCss]}>
                    Better prediction quality (F1 score)
                  </p>
                </div>
                <div css={centeredTextCss}>
                  <h3 css={[numberCss, hBoldCss, whiteTextCss]}>8x</h3>
                  <p css={[h4Body2Css, numberSublineCss, centeredTextCss, hBoldCss, whiteTextCss]}>
                    Faster concept training time
                  </p>
                </div>
                <div css={centeredTextCss}>
                  <h3 css={[numberCss, hBoldCss, whiteTextCss]}>2x</h3>
                  <p css={[h4Body2Css, numberSublineCss, centeredTextCss, hBoldCss, whiteTextCss]}>
                    Faster concept annotation time
                  </p>
                </div>
                <div css={centeredTextCss}>
                  <h3 css={[numberCss, hBoldCss, whiteTextCss]}>1M</h3>
                  <p css={[h4Body2Css, numberSublineCss, centeredTextCss, hBoldCss, whiteTextCss]}>
                    Character API request capacity
                  </p>
                </div>
                <div css={centeredTextCss}>
                  <h3 css={[numberCss, hBoldCss, whiteTextCss]}>30</h3>
                  <p css={[h4Body2Css, numberSublineCss, centeredTextCss, hBoldCss, whiteTextCss]}>
                    Only 30 text excerpts (sentence or more) needed for a custom concept
                  </p>
                </div>
              </div>
            </AnimatedContent>
          </>
        }
      />
    </section>
  )
}
