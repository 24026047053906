import {css} from '@emotion/react'

import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {
  h4Body1Css,
  h4Body2Css,
  hBoldCss,
  blackTextCss,
  darkBlueTextCss,
  centeredTextCss,
} from '../../styles/common'
import getRowItemFlex from '../utils/getRowItemFlex'

const graphicGroupCss = css`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: -25px auto;
  width: 100%;
`

const graphicItemCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 1 auto;
  margin: 25px 0;
  padding: 0 25px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    flex: 0 1 50%;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex: 0 1 100%;
  }
`

const graphicContainerCss = css`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100px;
`

const graphicImgCss = css`
  height: 100%;
`

const titleCss = css`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const subtitleCss = css`
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const descriptionCss = css`
  margin-top: 20px;
  max-width: 444px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export interface GraphicContent {
  graphic: {
    data: string
    isText: boolean
  }
  content: {
    title?: React.ReactNode
    subtitle?: React.ReactNode
    description?: React.ReactNode
  }
}

interface GraphicContentGroupProps {
  graphicContent: GraphicContent[]
  className?: string
  maxItemsPerRow?: number
}

export default function GraphicContentGroup({
  graphicContent,
  className,
  maxItemsPerRow,
}: GraphicContentGroupProps): JSX.Element {
  return (
    <div className={className} css={graphicGroupCss}>
      {graphicContent.map(({graphic, content: {title, subtitle, description}}) => (
        <div
          key={graphic.data}
          css={[graphicItemCss, getRowItemFlex(graphicContent.length, maxItemsPerRow)]}
        >
          {graphic.isText ? (
            <div>{graphic.data}</div>
          ) : (
            <div css={graphicContainerCss}>
              <img css={graphicImgCss} src={graphic.data} alt={`${title} icon`} loading="lazy" />
            </div>
          )}
          {title && (
            <div css={[titleCss, centeredTextCss, h4Body1Css, hBoldCss, darkBlueTextCss]}>
              {title}
            </div>
          )}
          {subtitle && (
            <div css={[subtitleCss, centeredTextCss, h4Body2Css, hBoldCss]}>{subtitle}</div>
          )}
          {description && (
            <div css={[descriptionCss, centeredTextCss, h4Body2Css, blackTextCss]}>
              {description}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}
