import {css} from '@emotion/react'

import interviewIcon from '../../assets/icons/interview.svg'
import techStackIcon from '../../assets/icons/tech-stack.svg'
import {AnimatedContent, TwoColumnContent, WideContent} from '../../components/content'
import TestimonialsCarousel from '../../components/TestimonialsCarousel'
import testimonialData, {Team} from '../../data/testimonials/testimonialData'
import {BREAKPOINT_LARGE} from '../../styles/breakpoints'
import {
  backgroundWhiteCss,
  baseSectionPaddingTopCss,
  darkBlueTextCss,
  h3SubheadlineCss,
  h4Body1Css,
  h4Body2Css,
  hBoldCss,
} from '../../styles/common'

const teamInfoCss = css`
  margin: 0;
`

const headlineCss = css`
  letter-spacing: 1.2px;
  margin-bottom: 40px;
  text-transform: uppercase;
`

const headlineMarginCss = css`
  margin-bottom: 50px;
`

const aboutMarginCss = css`
  margin-bottom: 30px;
`

const infoItemCss = css`
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 640px) {
    margin: 30px 5%;
  }
`

const infoBlockCss = css`
  text-align: left;
  margin-left: 20px;
`

const iconCss = css`
  align-self: flex-start;
`

const listCss = css`
  padding-left: 28px;
  list-style-type: none;
  margin-top: 0;

  li {
    position: relative;
    counter-increment: list;
  }

  li:before {
    ${darkBlueTextCss};
    content: counter(list) '.';
    ${hBoldCss}
    left: -20px;
    position: absolute;
    text-align: right;
  }
`

const marginCss = css`
  margin-bottom: 45px;
`

const carouselContainerCss = css`
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-top: 40px;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    max-width: unset;
  }
`

interface TeamInfoSectionProps {
  className?: string
  body: string
  interviewTextNode?: React.ReactNode
  interviewRounds: string[]
  team: Team
  teamName: string
  techStackText?: string
}

export default function TeamInfoSection({
  className,
  body,
  interviewTextNode,
  interviewRounds,
  team,
  teamName,
  techStackText,
}: TeamInfoSectionProps): JSX.Element {
  const testimonials = testimonialData.filter((data) => data.team === team)

  return (
    <section className={className} css={[baseSectionPaddingTopCss, backgroundWhiteCss]}>
      <WideContent>
        <AnimatedContent>
          <h4 css={[h3SubheadlineCss, hBoldCss, headlineCss, headlineMarginCss]}>
            {teamName} Team
          </h4>
        </AnimatedContent>
        <AnimatedContent>
          <h4 css={[h4Body2Css, darkBlueTextCss, hBoldCss, headlineCss, aboutMarginCss]}>
            About the Team
          </h4>
        </AnimatedContent>
      </WideContent>
      <TwoColumnContent
        css={marginCss}
        left={
          <AnimatedContent>
            <p css={h4Body1Css}>{body}</p>
          </AnimatedContent>
        }
        right={
          <AnimatedContent>
            <div css={[teamInfoCss, h4Body2Css]}>
              {techStackText ? (
                <div css={infoItemCss}>
                  <img
                    src={techStackIcon}
                    alt="Technical Stack"
                    height={60}
                    width={60}
                    css={iconCss}
                  />
                  <div css={infoBlockCss}>
                    <h4 css={[h4Body2Css, darkBlueTextCss, hBoldCss]}>Tech stack</h4>
                    <p>{techStackText}</p>
                  </div>
                </div>
              ) : null}
              <div css={infoItemCss}>
                <img src={interviewIcon} alt="Interviews" height={60} width={60} css={iconCss} />
                <div css={infoBlockCss}>
                  <h4 css={[h4Body2Css, darkBlueTextCss, hBoldCss]}>Interview process</h4>
                  {interviewTextNode ? <p>{interviewTextNode}</p> : null}
                  <ol css={listCss}>
                    {interviewRounds.map((description) => (
                      <li key={description}>{description}</li>
                    ))}
                  </ol>
                </div>
              </div>
            </div>
          </AnimatedContent>
        }
      />
      <WideContent css={carouselContainerCss}>
        <AnimatedContent>
          <TestimonialsCarousel testimonials={testimonials} />
        </AnimatedContent>
      </WideContent>
    </section>
  )
}
