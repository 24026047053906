interface MenuIconProps {
  className?: string
}

export default function MenuIcon({className}: MenuIconProps): JSX.Element {
  return (
    <svg
      width="66"
      height="56"
      viewBox="0 0 66 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <line
        x1="21"
        y1="19"
        x2="45"
        y2="19"
        stroke="currentcolor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="21"
        y1="27"
        x2="40"
        y2="27"
        stroke="currentcolor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="21"
        y1="35"
        x2="32"
        y2="35"
        stroke="currentcolor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
