import {css} from '@emotion/react'

import {BREAKPOINT_LARGE, BREAKPOINT_SMALL, BREAKPOINT_XLARGE} from '../../styles/breakpoints'
import {AnimatedContent, TwoColumnContent} from '../../components/content'
import ButtonLink from '../../components/Button/ButtonLink'
import {
  h3SubheadlineCss,
  hBoldCss,
  whiteTextCss,
  backgroundGradientCss,
  centeredTextCss,
  baseSectionCss,
} from '../../styles/common'

const alignCss = css`
  align-items: center;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    align-items: stretch;
  }
`

const leftTextCss = css`
  margin: 45px 0px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-bottom: 65px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-top: 35px;
  }
`

const rightCss = css`
  padding-left: 120px;
`

const buttonCss = css`
  height: 65px;
  width: 550px;
  border: 1px solid #fff;
  color: white;
  font-size: 24px;

  @media (max-width: ${BREAKPOINT_XLARGE}px) {
    width: 439px;
  }

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    width: 100%;
  }
`

export default function ContactSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundGradientCss]}>
      <TwoColumnContent
        css={alignCss}
        left={
          <>
            <AnimatedContent>
              <h3 css={[h3SubheadlineCss, hBoldCss, whiteTextCss, centeredTextCss]}>
                Still not sure?
              </h3>
            </AnimatedContent>
            <AnimatedContent>
              <p css={[leftTextCss, h3SubheadlineCss, whiteTextCss, centeredTextCss]}>
                Or if you have questions, reach out to us. We&apos;re happy to help!
              </p>
            </AnimatedContent>
          </>
        }
        rightContentCss={rightCss}
        right={
          <AnimatedContent animation="fade-in">
            <ButtonLink
              to="mailto:sales@kensho.com?subject=Nerd"
              variant="secondary"
              mode="dark"
              css={buttonCss}
            >
              Contact Us
            </ButtonLink>
          </AnimatedContent>
        }
      />
    </section>
  )
}
