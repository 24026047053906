import actionCulture from '../../assets/icons/action.svg'
import collaborationCulture from '../../assets/icons/collaboration.svg'
import trustCulture from '../../assets/icons/trust.svg'
import eyeCulture from '../../assets/icons/eye.svg'
import learningCulture from '../../assets/icons/learning.svg'
import healthBenefits from '../../assets/icons/health.svg'
import wellnessBenefits from '../../assets/icons/wellness.svg'
import growthBenefits from '../../assets/icons/growth.svg'
import {GraphicContent} from '../../components/GraphicContentGroup'

export const cultureContent: GraphicContent[] = [
  {
    graphic: {data: actionCulture, isText: false},
    content: {
      title: 'Action Bias',
      subtitle: 'We like results',
      description:
        'We insist on a bias towards action and minimize unhelpful hierarchy and process.',
    },
  },
  {
    graphic: {data: collaborationCulture, isText: false},
    content: {
      title: 'Collaboration',
      subtitle: 'We solve problems, together',
      description:
        "We collaborate, using our teammates' diverse perspectives to solve hard problems.",
    },
  },
  {
    graphic: {data: trustCulture, isText: false},
    content: {
      title: 'Trust',
      subtitle: 'We believe in our people',
      description: 'We communicate openly, honestly and efficiently.',
    },
  },
  {
    graphic: {data: eyeCulture, isText: false},
    content: {
      title: 'Forward-looking approach',
      subtitle: 'We build for the future',
      description: 'We produce technology that is scalable, robust and useful.',
    },
  },
  {
    graphic: {data: learningCulture, isText: false},
    content: {
      title: 'Culture of continuous learning',
      subtitle: 'We value exploration',
      description:
        'We dedicate time and resources to explore new ideas in order to keep innovating.',
    },
  },
]

export const benefitsContent: GraphicContent[] = [
  {
    graphic: {data: healthBenefits, isText: false},
    content: {
      title: 'Health',
      subtitle: '',
      description: '100% employer-paid insurance that covers you and your family... and more',
    },
  },
  {
    graphic: {data: wellnessBenefits, isText: false},
    content: {
      title: 'Wellness',
      subtitle: '',
      description: '26 weeks paid parental leave, flexible work hours... and more',
    },
  },
  {
    graphic: {data: growthBenefits, isText: false},
    content: {
      title: 'Growth',
      subtitle: '',
      description: '6% 401K match, 20K tuition reimbursement, Knowledge Days... and more',
    },
  },
]
