import TwoColumnGradient from '../TwoColumnGradient'

interface DeveloperContentProps {
  description: string
  product: string
  href: string
}

export default function DeveloperContent({
  description,
  product,
  href,
}: DeveloperContentProps): JSX.Element {
  return (
    <TwoColumnGradient
      eyebrow="For Developers"
      title="API Guides &amp; Tutorials"
      subtitle={description}
      buttonText={`${product} Developer Docs`}
      href={href}
    />
  )
}
