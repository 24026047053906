import {css} from '@emotion/react'

import {centeredTextCss} from '../../styles/common'

interface ImageGridProps {
  images: string[]
  className?: string
}

const breakpoints = {
  small: 640,
  medium: 1080,
  large: 1500,
}

const imageGridCss = css`
  width: 100%;
  overflow: hidden;
  height: 0;
  margin-top: -1px; /* fixes resizing artifacts at the top of the grid */

  /* removes possibility of whitespace after inline-divs */
  font-size: 0;

  /* imageGrid height is a whole number multiple of imageContainer width,
   * which itself is a whole number fraction of imageGrid width.
   * Ergo imageGrid height is a function of imageGrid width.
   * padding-bottom % allows imageGrid to set its height
   * as a fraction of its container's width, which it spans across.
   * height is set to zero to make padding the only vertical dimension.
   * The formula for padding is imageContainer width x #rows
   * minus a fudge factor to account for resizing artifacts. */

  /* 7 rows x 3 columns for max width view */
  padding-bottom: calc(300% / 7 - 4px);

  /* 6 x 3 */
  @media screen and (max-width: ${breakpoints.large}px) {
    padding-bottom: 49.5%;
  }

  /* 5 x 3 */
  @media screen and (max-width: ${breakpoints.medium}px) {
    padding-bottom: 59.3%;
  }

  /* 3 x 4 */
  @media screen and (max-width: ${breakpoints.small}px) {
    padding-bottom: calc(400% / 3 - 5px);
  }
`

const imageContainerCss = css`
  display: inline-block;
  vertical-align: top;

  /* overlap images so that rounding error does not create space between */
  margin-bottom: -1px;

  /* 7 x 3 */
  width: calc(100% / 7);

  /* 6 x 3 */
  @media screen and (max-width: ${breakpoints.large}px) {
    /* using calc to make images fit in one line as best as possible */
    width: calc(100% / 6);
  }

  /* 5 x 3 */
  @media screen and (max-width: ${breakpoints.medium}px) {
    width: 20%;
  }

  /* 3 x 4 */
  @media screen and (max-width: ${breakpoints.small}px) {
    width: calc(100% / 3);
  }
`

const imageCss = css`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center center;
`

export default function ImageGrid(props: ImageGridProps): JSX.Element {
  const {images, className} = props
  return (
    <div className={className} css={[imageGridCss, centeredTextCss]}>
      {images.map((imagePath) => (
        <div css={imageContainerCss} key={imagePath}>
          <div css={imageCss} style={{backgroundImage: `url(${imagePath})`}} />
        </div>
      ))}
    </div>
  )
}
