import {css} from '@emotion/react'

import NerdProductLogo from '../../assets/logos/products/nerd.svg'
import NerdHeroLarge from '../../assets/photos/nerd-hero-large.jpg'
import NerdHeroMedium from '../../assets/photos/nerd-hero-medium.jpg'
import NerdHeroSmall from '../../assets/photos/nerd-hero-small.jpg'
import socialImg from '../../assets/social/nerd.jpg'
import ButtonLink from '../../components/Button/ButtonLink'
import ProductHeroSection from '../../components/Hero/ProductHeroSection'
import Page from '../../components/Page'
import PageMetadata from '../../components/PageMetadata'
import {URLS} from '../../constants'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import COLORS from '../../styles/colors'
import {h4Body1Css, whiteTextCss} from '../../styles/common'

import ApplicationSection from './ApplicationSection'
import SampleSection from './ComparisonSection'
import ContactSection from './ContactSection'
import DeveloperSection from './DeveloperSection'
import EntitiesSection from './EntitiesSection'
import FAQSection from './FAQSection'
import ImpactSection from './ImpactSection'
import IntroSection from './IntroSection'
import TestimonialSection from './TestimonialSection'
import VideoSection from './VideoSection'
import WhySection from './WhySection'

const heroImageCss = css`
  background-image: url(${NerdHeroLarge});

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    background-image: url(${NerdHeroMedium});
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    background-image: url(${NerdHeroSmall});
  }

  filter: brightness(75%);
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    opacity: 1;
  }
`

const callToActionCss = css`
  display: flex;
  justify-content: center;
`

const buttonCss = css`
  height: 60px;
  width: 213px;
  border: 1px solid ${COLORS.white};
  margin-top: 60px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-top: 30px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-top: 20px;
    max-width: 333px;
    width: 100%;
  }
`

export default function NERD(): JSX.Element {
  return (
    <Page
      title={
        <ProductHeroSection
          imageCss={heroImageCss}
          productLogo={NerdProductLogo}
          productTitle="NERD"
          productSubtitle="Understand all organizations, people, places and things in text"
          callToAction={
            <div css={callToActionCss}>
              <ButtonLink
                to={`${URLS.FREE_TRIAL}/nerd`}
                variant="secondary"
                mode="dark"
                css={[h4Body1Css, whiteTextCss, buttonCss]}
              >
                Get Started
              </ButtonLink>
            </div>
          }
        />
      }
    >
      <PageMetadata
        title="Nerd | Kensho"
        description="Kensho NERD is an ML-based service to understand the companies, people, places, products and events in text"
        image={{source: socialImg, height: 1250, width: 2500}}
      />
      <IntroSection />
      <EntitiesSection />
      <VideoSection />
      <SampleSection />
      <ContactSection />
      <ApplicationSection />
      <ImpactSection />
      <DeveloperSection />
      <WhySection />
      <TestimonialSection />
      <FAQSection />
    </Page>
  )
}
