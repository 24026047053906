import {css} from '@emotion/react'

import {BREAKPOINT_MEDIUM, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {blackTextCss, darkBlueTextCss, h3TitleCss, hBoldCss, h4Body2Css} from '../../styles/common'
import COLORS from '../../styles/colors'

const cardCss = css`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 555px;
  max-width: 444px;
  flex-basis: 100%;

  padding: 80px 40px 40px;
  border: 1px solid ${COLORS.gray[500]};

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    padding: 40px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    max-width: 100%;
  }
`

const titleCss = css`
  height: 160px;
`

export interface ProjectCardProps {
  title: string
  text: React.ReactNode | string
}

export default function ProjectCard({title, text}: ProjectCardProps): JSX.Element {
  return (
    <div css={cardCss}>
      <h3 css={[titleCss, h3TitleCss, hBoldCss, darkBlueTextCss]}>{title}</h3>
      {typeof text === 'string' ? <p css={[h4Body2Css, blackTextCss]}>{text}</p> : text}
    </div>
  )
}
