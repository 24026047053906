import {ClassNames} from '@emotion/react'

import {BREAKPOINT_SMALL} from '../styles/breakpoints'
import {Testimonial} from '../data/testimonials/testimonialData'

import Carousel from './Carousel'
import TestimonialItem from './TestimonialItem'

interface TestimonialsCarouselProps {
  testimonials: Testimonial[]
  className?: string
}

export default function TestimonialsCarousel({
  className,
  testimonials,
}: TestimonialsCarouselProps): JSX.Element {
  return (
    <ClassNames>
      {({css}) => (
        <Carousel
          className={className}
          slides={testimonials}
          slideTrayClassName={css`
            width: 90%;
          `}
          slideRenderer={(testimonial) => <TestimonialItem testimonial={testimonial} />}
          slideKeyGen={(testimonial) => testimonial.name}
          buttonTrayClassName={css`
            @media (max-width: ${BREAKPOINT_SMALL}px) {
              display: none;
            }
          `}
          buttonColor="white"
        />
      )}
    </ClassNames>
  )
}
