import {BrowserRouter} from 'react-router-dom'

import App from '../App'

export default function ClientApp(): JSX.Element {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  )
}
