import {css} from '@emotion/react'

import quoteIcon from '../../../assets/icons/quote.svg'
import {WideContent} from '../../content'
import {
  backgroundGradientCss,
  h3SubheadlineCss,
  h4Body2Css,
  hSemiBoldCss,
  labelCss,
  whiteTextCss,
} from '../../../styles/common'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../../styles/breakpoints'

const titleCss = css`
  margin-bottom: 50px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-bottom: 40px;
  }
`

const quoteIconCss = css`
  vertical-align: top;
  width: 150px;
  height: 120px;
  line-height: 0.75;
  margin-right: 36px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 80px;
    height: 64px;
    margin-right: 0;
  }
`

const contentContainerCss = css`
  height: 728px;
  padding-left: 40px;
  padding-right: 40px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding-left: 23px;
  }
`

const quoteContainerCss = css`
  display: flex;
  flex-direction: row;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
  }
`

const contentCss = css`
  height: 455px;
  z-index: 1;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`

const testimonialCss = css`
  padding: 50px 0;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 20px 0;
  }
`

const quoteCss = css`
  font-size: 32px;
  line-height: 38.98px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    font-size: 24px;
    line-height: 29.23px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    font-size: 22px;
    line-height: 27px;
  }
`

const citationCss = css`
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    font-size: 16px;
    line-height: 16px;
  }
`

export interface CustomerTestimonialSlideProps {
  className?: string
  quote: string
  citation: React.ReactNode
}

export default function CustomerTestimonialSlide({
  className,
  quote,
  citation,
}: CustomerTestimonialSlideProps): JSX.Element {
  return (
    <div css={[contentContainerCss, backgroundGradientCss]} className={className}>
      <WideContent css={[testimonialCss, whiteTextCss]}>
        <h4 css={[labelCss, titleCss]}>What people are saying</h4>
        <div css={quoteContainerCss}>
          <img src={quoteIcon} alt="Quote:" css={quoteIconCss} />
          <div>
            <div css={contentCss}>
              <p css={[h3SubheadlineCss, quoteCss]}>{quote}</p>
            </div>
            {typeof citation === 'string' ? (
              <p css={[h4Body2Css, hSemiBoldCss, citationCss]}>{citation}</p>
            ) : (
              citation
            )}
          </div>
        </div>
      </WideContent>
    </div>
  )
}
