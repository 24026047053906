import {css} from '@emotion/react'

import Counter from '../../components/Counter'
import useHasScrolledInBounds from '../../hooks/useHasScrolledInBounds'

// start date 5/26/2020
// rate: 49823 links a day --> 0.0345993 links per millisecond
const baseTime = new Date('2020-05-26').getTime()
const linkIncrementRate = 0.034

// total links created extrapolated to current date
const initialLinkCount = Math.floor((Date.now() - baseTime) * linkIncrementRate)

const counterContainerCss = css`
  margin-top: 20px;
  height: 125px;
  width: 100%;
  transition: 1.5s;
  opacity: 0;

  @media (max-width: 1100px) {
    height: 85px;
  }
`

const fullOpacityCss = css`
  opacity: 1;
`

export default function LinkTicker(): JSX.Element {
  const {setNode, hasScrolledInBounds} = useHasScrolledInBounds<HTMLDivElement>()

  return (
    <div ref={setNode} css={[counterContainerCss, hasScrolledInBounds && fullOpacityCss]}>
      {hasScrolledInBounds && (
        <Counter animate initialCount={initialLinkCount} rate={linkIncrementRate} interval={150} />
      )}
    </div>
  )
}
