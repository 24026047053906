import DesktopHeader from './DesktopHeader'
import CompactHeader from './CompactHeader'

interface HeaderProps {
  className?: string
  mode: 'dark' | 'light'
}

export default function Header({className, mode}: HeaderProps): JSX.Element {
  return (
    <>
      <DesktopHeader mode={mode} className={className} />
      <CompactHeader mode={mode} className={className} />
    </>
  )
}
