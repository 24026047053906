import {BulletedTextContent, BulletedTextContentProps} from '../../components/textContent'
import {backgroundGradientCss, baseSectionCss, whiteTextCss} from '../../styles/common'

const IntroContent: BulletedTextContentProps = {
  title: 'Transcriptions are only valuable when they’re right.',
  subtitle:
    'That’s why we optimized our transcription technology to handle the complexities of messy, real-world audio for:',
  leftList: {
    title: 'Names & Numbers',
    items: [
      'Specific Numbers',
      'Currencies',
      'Stock Tickers',
      'Product Names (e.g., pharmaceutical drugs and tech products)',
      'Company Names',
    ],
  },
  rightList: {
    title: 'Specific Speech',
    items: [
      'Heavily Accented Speech',
      'Multiple Speakers (speaker diarization)',
      'Nuances of Spoken Language (including mumbling, stuttering, filler words, hesitation and self-correction)',
      'Industry-Specific Jargon',
    ],
  },
}

export default function IntroSection(): JSX.Element {
  return (
    <>
      <section css={[baseSectionCss, backgroundGradientCss, whiteTextCss]}>
        <BulletedTextContent
          title={IntroContent.title}
          subtitle={IntroContent.subtitle}
          leftList={IntroContent.leftList}
          rightList={IntroContent.rightList}
        />
      </section>
    </>
  )
}
