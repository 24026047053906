import TopicHeadline from '../../components/TopicHeadline'
import {TwoColumnHeadingContent} from '../../components/headings'
import {WideContent} from '../../components/content'
import {
  blackTextCss,
  backgroundLightGrayCss,
  baseSectionCss,
  twoColumnSectionHeadingMarginCss,
} from '../../styles/common'

import ProductCardGrid from './ProductCardGrid'

export default function ProductsSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundLightGrayCss, blackTextCss]}>
      <WideContent>
        <TopicHeadline text="Our products" />
      </WideContent>
      <TwoColumnHeadingContent
        headerText="Scale &amp; advance your business with our AI solutions"
        additionalText="We provide solutions for audio transcription, structuring and enriching text
        documents, connecting data to external knowledge sources and more."
        textColor="black"
        css={twoColumnSectionHeadingMarginCss}
      />
      <WideContent>
        <ProductCardGrid />
      </WideContent>
    </section>
  )
}
