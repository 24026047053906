import {css, SerializedStyles} from '@emotion/react'

import {centeredTextCss, darkBlueTextCss, h4Body1Css} from '../../styles/common'

interface AvatarProps {
  className?: string
  photo: string
  name?: string
  title?: string
  nameCss?: SerializedStyles | SerializedStyles[]
  titleCss?: SerializedStyles | SerializedStyles[]
  size?: number
}

const avatarCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const photoCss = css`
  overflow: hidden;
  border-radius: 100%;
  background-position-x: 50%;
  background-size: cover;
`

const identityInfoCss = css`
  margin-top: 15px;
`

const textCss = css`
  margin: 0;
  line-height: 1.1em;
  font-size: 20px;
`

export default function Avatar({
  className,
  photo,
  name,
  nameCss,
  title,
  titleCss,
  size = 150,
}: AvatarProps): JSX.Element {
  return (
    <figure className={className} css={avatarCss}>
      <img
        css={photoCss}
        src={photo}
        style={{width: `${size}px`, height: `${size}px`}}
        alt={`${name}'s avatar`}
        loading="lazy"
      />
      <figcaption css={[identityInfoCss, centeredTextCss]}>
        {name && <div css={[h4Body1Css, darkBlueTextCss, nameCss]}>{name}</div>}
        {title && <div css={[textCss, titleCss]}>{title}</div>}
      </figcaption>
    </figure>
  )
}
