import {css} from '@emotion/react'

import ButtonLink from '../../components/Button/ButtonLink'
import TopicHeadline from '../../components/TopicHeadline'
import Skeleton from '../../components/Skeleton'
import {TwoColumnHeadingContent} from '../../components/headings'
import {WideContent, TwoColumnContent, AnimatedContent, WIDTH_LARGE} from '../../components/content'
import useIsMounted from '../../hooks/useIsMounted'
import {
  h3SubheadlineCss,
  h4Body1Css,
  h4Body2Css,
  hBoldCss,
  blackTextCss,
  backgroundLightGrayCss,
  bulletedListCss,
  bulletedListItemCss,
  baseSectionCss,
  twoColumnSectionHeadingMarginCss,
} from '../../styles/common'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'

import LinkAnimation from './LinkAnimation'

const productContentCss = css`
  margin-bottom: 100px;
`

const buttonLinkCss = css`
  width: 80%;
  padding-top: 16px;
  padding-bottom: 16px;
`

const animationWrapperCss = css`
  flex: 1 auto;
`

const LEFT_WIDTH = 616
const animationLeftCss = css`
  max-width: ${LEFT_WIDTH}px;
  width: ${LEFT_WIDTH}px;
  border-right: 1px solid #000;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    width: auto;
    border-right: none;
  }
`

const animationRightCss = css`
  max-width: ${WIDTH_LARGE - LEFT_WIDTH}px;
  width: ${WIDTH_LARGE - LEFT_WIDTH}px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    width: auto;
  }
`

const animationLabelCss = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding-left: 50px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding-left: 0;
  }
`

const listIndentCss = css`
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-left: 80px;
    column-count: 2;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-left: 20px;
    column-count: 1;
  }
`

const skeletonMarginCss = css`
  margin: 40px 0;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin: 20px 0;
  }
`

export default function SeeForYourselfSection(): JSX.Element {
  const isMounted = useIsMounted()

  return (
    <section css={[baseSectionCss, backgroundLightGrayCss, blackTextCss]}>
      <WideContent>
        <TopicHeadline text="See for yourself" />
      </WideContent>
      <TwoColumnHeadingContent
        headerText="To return a unique ID for each of your entities."
        additionalText="Kensho Link compares your submitted data to S&amp;P Global’s Capital IQ database, a
        leading company database with over 20 million (and growing) entities."
        textColor="black"
        css={twoColumnSectionHeadingMarginCss}
      />
      <TwoColumnContent
        css={productContentCss}
        leftWrapperCss={animationWrapperCss}
        rightWrapperCss={animationWrapperCss}
        leftContentCss={animationLeftCss}
        rightContentCss={animationRightCss}
        left={
          <AnimatedContent css={animationLabelCss} animation="fade-in">
            <h3 css={[h3SubheadlineCss, hBoldCss, {marginBottom: 30}]}>
              To find a match, Kensho Link works with what you have, even if it’s just a company
              name.
            </h3>
            <p css={[h4Body1Css, {marginBotom: 30}]}>
              If available, Link will also use the following to increase the quality of its
              predictions:
            </p>
            <ul css={[bulletedListCss, listIndentCss]}>
              <li css={[bulletedListItemCss, h4Body2Css]}>Aliases</li>
              <li css={[bulletedListItemCss, h4Body2Css]}>URLs</li>
              <li css={[bulletedListItemCss, h4Body2Css]}>Phone Numbers</li>
              <li css={[bulletedListItemCss, h4Body2Css]}>Street Addresses</li>
              <li css={[bulletedListItemCss, h4Body2Css]}>Cities</li>
              <li css={[bulletedListItemCss, h4Body2Css]}>States</li>
              <li css={[bulletedListItemCss, h4Body2Css]}>Years founded</li>
            </ul>
          </AnimatedContent>
        }
        right={
          isMounted ? (
            <AnimatedContent animation="fade-in">
              <LinkAnimation />
            </AnimatedContent>
          ) : (
            <Skeleton css={skeletonMarginCss} height={475} />
          )
        }
      />
      <WideContent>
        <div css={{display: 'flex', justifyContent: 'center'}}>
          <ButtonLink variant="secondary" mode="light" to="" css={[h4Body1Css, buttonLinkCss]}>
            Get Started
          </ButtonLink>
        </div>
      </WideContent>
    </section>
  )
}
