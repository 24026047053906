import DeveloperContent from '../../components/DeveloperContent/DeveloperContent'

export default function DeveloperSection(): JSX.Element {
  return (
    <section>
      <DeveloperContent
        description="Check out our developer documentation, for building tools with the Classify APIs and more"
        product="Classify"
        href="https://classify.kensho.com/docs/"
      />
    </section>
  )
}
