import {css} from '@emotion/react'

import photo01 from '../../assets/photos/aiLabGrid/01-zooming-to-the-theater.jpg'
import photo02 from '../../assets/photos/aiLabGrid/02-viewing-a-tech-talk.jpg'
import photo03 from '../../assets/photos/aiLabGrid/03-froth-at-an-angle.jpg'
import photo04 from '../../assets/photos/aiLabGrid/04-peering-to-the-window.jpg'

const images = [photo01, photo02, photo03, photo04]

const imageRowCss = css`
  display: grid;
  grid: ${100 / images.length}vw / repeat(${images.length}, ${100 / images.length}vw);
`

const imageCss = css`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
`

export default function AILabImageRow(): JSX.Element {
  return (
    <div css={imageRowCss}>
      {images.map((imagePath) => (
        <img key={imagePath} css={imageCss} src={imagePath} alt="Kensho's AI Lab" loading="lazy" />
      ))}
    </div>
  )
}
