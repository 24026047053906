import {css} from '@emotion/react'

import Link from '../../components/Link'
import {h4Body2Css, labelCss, hBoldCss} from '../../styles/common'
import {WorkdayJob} from '../../data/fetchWorkdayJobs'

const jobCss = css`
  margin: 20px 0;
`

const jobTextCss = css`
  text-transform: none;
  letter-spacing: initial;
`

const jobLinkCss = css`
  display: inline-block;
  position: relative;
  color: #50afc6;
  margin: 0 15px;

  &:not(:last-of-type):after {
    content: '|';
    position: absolute;
    right: -15px;
    color: #5d5d5d;
  }
`

export default function JobListing(job: WorkdayJob): JSX.Element {
  const {id, title, locations, url} = job
  return (
    <div css={jobCss} key={id}>
      <Link css={[jobLinkCss, h4Body2Css, hBoldCss]} to={url}>
        {title}
      </Link>
      {locations.map((location) => (
        <h4 css={[jobTextCss, labelCss]}>{location}</h4>
      ))}
    </div>
  )
}
