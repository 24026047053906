import {css} from '@emotion/react'

import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import TopicHeadline from '../../components/TopicHeadline'
import ButtonLink from '../../components/Button/ButtonLink'
import {TwoColumnContent, AnimatedContent} from '../../components/content'
import {
  hBoldCss,
  blackTextCss,
  backgroundWhiteCss,
  h3SubheadlineCss,
  h4Body2Css,
  baseSectionCss,
} from '../../styles/common'
import ExtractProject from '../../assets/photos/extract-project.jpg'

const columnAlignCss = css`
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    align-items: center;
  }
`

const rightContainerCss = css`
  padding: 0;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding: 0px 40px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 0;
  }
`

const imageContainerCss = css`
  height: 755px;
  width: 100%;
  display: flex;
  flex-direction: center;
  justify-content: center;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    height: 746px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 500px;
  }
`

const imageCss = css`
  height: 150%;
  width: 100%;
  object-fit: cover;
  object-position: 60% -110px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    object-position: 65% -140px;
  }
`

const leftSpacingCss = css`
  margin-bottom: 40px;
`

const leftCss = css`
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-bottom: 20px;
  }
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-bottom: 55px;
  }
`

const buttonCss = css`
  height: 56px;
  width: 100%;
  max-width: 757px;
`

export default function WhySection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundWhiteCss]}>
      <TwoColumnContent
        css={columnAlignCss}
        leftWrapperCss={leftCss}
        left={
          <>
            <TopicHeadline text="See how we can help" css={blackTextCss} />
            <AnimatedContent>
              <h3 css={[leftSpacingCss, h3SubheadlineCss, hBoldCss, blackTextCss]}>
                The fundamental block for all of these initiatives is having access to clean,
                structured data.
              </h3>
            </AnimatedContent>
            <AnimatedContent>
              <p css={[leftSpacingCss, h4Body2Css, blackTextCss]}>
                Unfortunately, the data most companies have is neither structured nor clean -
                whether hidden in slide decks, pdfs, or in a database that has mutated a dozen times
                since inception, data is frequently all but inaccessible without investing a lot of
                incredibly valuable expert time in trying to understand the information and then
                attempt to structure it via liberal use of excel spreadsheets.
              </p>
            </AnimatedContent>
            <AnimatedContent>
              <p css={[leftSpacingCss, h4Body2Css, blackTextCss]}>We feel your pain.</p>
            </AnimatedContent>
            <AnimatedContent>
              <p css={[leftSpacingCss, h4Body2Css, blackTextCss]}>
                S&P Global employs thousands of trained analysts who process more than 5 million
                pages of financial content on a yearly basis. Luckily, all that effort has created
                one of the largest data sets of machine learning training data for corporate
                financial documents, allowing us to speed up our internal operations anywhere from
                50% - 100% depending on the task at hand.
              </p>
            </AnimatedContent>
            <ButtonLink
              to="mailto:sales@kensho.com?subject=Extract"
              variant="primary"
              css={buttonCss}
            >
              Contact Us
            </ButtonLink>
          </>
        }
        rightContentCss={rightContainerCss}
        right={
          <AnimatedContent animation="fade-in" css={imageContainerCss} innerCss={imageContainerCss}>
            <img src={ExtractProject} alt="First Extract Project" css={imageCss} loading="lazy" />
          </AnimatedContent>
        }
      />
    </section>
  )
}
