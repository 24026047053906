import {css, keyframes, ClassNames, SerializedStyles} from '@emotion/react'
import {useEffect, useState, ReactNode} from 'react'
import {IconCircleCheck} from '@kensho/icons'
import {CSSTransition} from 'react-transition-group'

import capIQIcon from '../../../assets/link-animation/capIQ-data.svg'
import yourDataIcon from '../../../assets/link-animation/your-data.svg'
import yourDataIconSmall from '../../../assets/link-animation/your-data-small.svg'
import useHasScrolledInBounds from '../../../hooks/useHasScrolledInBounds'
import {labelCss, legalCss} from '../../../styles/common'

import Number from './Number'

// Animation Parameters
const numHighlightedRowsVisible = 4
const totalRowsVisible = 10

// Durations
const scrollUpTime = 1800
const counterDuration = 1500 // TOTAL TIME
const backgroundTransitionDuration = 400 // transition 1 duration & transition 2 start time
const numberAnimationFadeIn = 700 // transition 2 fade in
const numberAnimationDuration = 1200 // transition 2 duration & transition 3 start time
const linkedTextAnimationFadeIn = 400 // transition 3 fade in
const linkedTextAnimationDuration = 500 // transition 3 duration
const linkedTextAnimationFadeOut = 200 // transition 3 fade out

interface IssueProps {
  text: string
  color: string
}

interface IssueNode {
  index: number
  issue: ReactNode
  capID: number
}

const allIssues: IssueProps[] = [
  {
    text: 'Wrong Country',
    color: 'red',
  },
  {
    text: 'New Ownership Structure',
    color: 'red',
  },
  {
    text: 'Misspelled Company Name',
    color: 'orange',
  },
  {
    text: 'Old Phone Number',
    color: 'red',
  },
  {
    text: 'Poorly Formatted URL',
    color: 'orange',
  },
  {
    text: 'Duplicate Entry',
    color: 'orange',
  },
  {
    text: 'Outdated Address',
    color: 'red',
  },
  {
    text: 'Common Name',
    color: 'orange',
  },
]

const scrollUp = keyframes({
  '0%': {
    transform: `translateY(${290 - 45 * numHighlightedRowsVisible}px);`,
  },
  '100%': {
    transform: 'translateY(0px); ',
  },
})

const scrollCss = css`
  animation: ${scrollUp} ${scrollUpTime}ms ease-in-out;
`

const hideAnimationCss = css`
  max-height: 0;
  margin-bottom: 0;
`

const tableCss = css`
  position: relative;
  border-collapse: collapse;
  overflow-anchor: none;
  &,
  & p {
    text-align: center;
    font-weight: 800;
    vertical-align: middle;
    line-height: 16px;
    margin: 5px;
    letter-spacing: 0.1em;
    vertical-align: middle;
  }
  & span {
    vertical-align: middle;
  }
`

const tableRowCss = css`
  transition:
    max-height ${counterDuration}ms ease-in-out ${counterDuration / -2}ms,
    margin-bottom ${counterDuration / 2}ms linear,
    opacity ${counterDuration / 3}ms linear;
  max-height: 100px;
  max-width: 600px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  left: 0;
  right: 0;
`

const issueTextCss = css`
  font-size: 8px;
  color: darkgray;
  width: 250px;
  @media (max-width: 540px) {
    width: 160px;
  }
`

const linkedCss = css`
  color: #50afc6;
  display: flex;
  justify-content: center;
  align-items: center;
`

const blueCss = css`
  color: #50afc6;
`

const backgroundDataColorCss = css`
  background-image: linear-gradient(to left, transparent 50%, transparent 50%);
  background-position: right;
  background-size: 200%;
  transition: all ${backgroundTransitionDuration / 2}ms linear;
`
const highlightedCss = css`
  background-image: linear-gradient(to left, transparent 50%, #50afc6 50%);
  background-position: left;
  color: white;
  & p,
  & span {
    transition:
      color ${backgroundTransitionDuration / 2}ms,
      opacity ${numberAnimationFadeIn}ms;
    color: white;
  }
`

const scrollingNumberContainerCss = css`
  height: 350px;
  overflow-y: hidden;
  width: 100%;
`

const counterContainerCss = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  height: 100%;
  transition: 1.5s;
  opacity: 0;
`

const labelAndImgContainerCss = css`
  display: flex;
  & > div {
    flex: 1;
  }
  justify-content: space-between;

  width: 100%;
  max-width: 600px;
`

const labelAndImgColumnCss = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 5px;
`

const linkedFillerColumnCss = css`
  margin: 5px;
  opacity: 0;
`

const fullOpacityCss = css`
  opacity: 1;
`

const labelTextCss = css`
  font-size: 36px;
  font-weight: 500;
  color: darkgray;
  margin-top: 20px;
  line-height: 1;
`

const capIdCss = css`
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to left, transparent 50%, transparent 50%);
  background-position: right;
  background-size: 200%;
  transition: all ${backgroundTransitionDuration / 2}ms ease-out
    ${backgroundTransitionDuration / 2}ms;
`

const linkedTextCss = css`
  margin-left: 10px;
  @media (max-width: 480px) {
    display: none;
  }
`

const databaseImageCss = css`
  max-height: 148px;
`

const capIQImageCss = css`
  max-width: 92px;
  max-height: 148px;
`

const generateBlockText = (len = 40): string => {
  const arr = new Uint8Array((len || 40) / 2)
  return Array.from(arr, () =>
    Math.floor(Math.random() * 14)
      .toString(14)
      .replace(/[01]+/g, ' ')
      .replace(/\w+/g, '█'),
  ).join('')
}

const createIssue = (index: number): IssueNode => {
  const issue = allIssues[Math.floor(allIssues.length * Math.random())]
  const textLength = 65 - issue.text.length
  const randomNum = Math.floor(Math.random() * textLength)
  const firstText = generateBlockText(randomNum).trim()
  const randomHighlightedTextLength = Math.floor(Math.random() * (textLength - randomNum))
  const secondText = generateBlockText(randomHighlightedTextLength).trim()
  const thirdText = generateBlockText(textLength - randomNum - randomHighlightedTextLength).trim()

  return {
    index,
    capID: parseInt(`${Math.random() * 1000000000}`, 10),
    issue: (
      <p css={issueTextCss}>
        {firstText} <span css={blueCss}>{secondText}</span>{' '}
        <span
          css={[
            legalCss,
            css`
              color: ${issue.color};
            `,
          ]}
        >
          {issue.text}
        </span>{' '}
        {thirdText}
      </p>
    ),
  }
}

function getOpacityCss(currentItemIndex = 0, highlightedIndex = 0): SerializedStyles {
  if (currentItemIndex < 0)
    return css`
      opacity: 0;
    `
  return css`
    opacity: ${currentItemIndex <= highlightedIndex
      ? Math.max(0.1, 1 - 0.2 * (highlightedIndex - currentItemIndex))
      : 0.7};
  `
}

export default function LinkAnimation(): JSX.Element {
  const {setNode, hasScrolledInBounds} = useHasScrolledInBounds<HTMLDivElement>()
  const [visibleIndex, setVisibleIndex] = useState(-1)

  const [issues, setIssues] = useState(
    [...Array<number>(totalRowsVisible).keys()].map((element) =>
      createIssue(element - numHighlightedRowsVisible),
    ),
  )

  useEffect(() => {
    const interval = setInterval(
      () => {
        if (hasScrolledInBounds) {
          if (visibleIndex < 0) {
            setVisibleIndex((prevVisibleIndex) => prevVisibleIndex + 1)
          } else {
            const issueIndex = issues.length + visibleIndex - numHighlightedRowsVisible
            const newIssueList = issues.concat(createIssue(issueIndex))
            setVisibleIndex((prevVisibleIndex) => prevVisibleIndex + 1)
            setIssues(newIssueList.slice(1))
          }
        }
      },
      visibleIndex < 0 ? 100 : counterDuration,
    )
    return () => clearInterval(interval)
  }, [issues, visibleIndex, hasScrolledInBounds])

  return (
    <div ref={setNode} css={[counterContainerCss, hasScrolledInBounds && fullOpacityCss]}>
      <div css={scrollingNumberContainerCss}>
        <div css={tableCss}>
          {issues.map((issue) => (
            <div
              key={issue.capID}
              css={[
                getOpacityCss(issue.index, visibleIndex),
                visibleIndex + 1 === issue.index &&
                  issue.index >= numHighlightedRowsVisible &&
                  fullOpacityCss,
                tableRowCss,
                labelAndImgContainerCss,
                visibleIndex - numHighlightedRowsVisible === issue.index && hideAnimationCss,
                hasScrolledInBounds && issue.index <= numHighlightedRowsVisible && scrollCss,
              ]}
            >
              <div css={[backgroundDataColorCss, visibleIndex >= issue.index && highlightedCss]}>
                {issue.issue}
              </div>
              <div css={[capIdCss, visibleIndex >= issue.index && highlightedCss]}>
                <ClassNames>
                  {({css}) => (
                    <CSSTransition
                      in={visibleIndex >= issue.index}
                      classNames={{
                        enter: css`
                          opacity: 0;
                        `,
                        enterDone: css`
                          opacity: 1;
                        `,
                      }}
                      timeout={backgroundTransitionDuration}
                      mountOnEnter
                    >
                      <Number
                        animateDuration={numberAnimationDuration}
                        animate
                        initialCount={issue.capID}
                      />
                    </CSSTransition>
                  )}
                </ClassNames>
              </div>

              <ClassNames>
                {({css}) => (
                  <CSSTransition
                    in={visibleIndex === issue.index}
                    classNames={{
                      enter: css`
                        opacity: 0;
                      `,
                      enterDone: css`
                        opacity: 1;
                        transition: opacity ${linkedTextAnimationFadeIn}ms;
                      `,
                      exit: css`
                        opacity: 0;
                        transition: opacity ${linkedTextAnimationFadeOut}ms
                          ${linkedTextAnimationDuration}ms;
                      `,
                      exitDone: css`
                        opacity: 0;
                      `,
                    }}
                    timeout={numberAnimationDuration}
                    mountOnEnter
                  >
                    <div css={[linkedCss, labelCss]}>
                      <IconCircleCheck size={24} />
                      <span css={linkedTextCss}>Linked!</span>
                    </div>
                  </CSSTransition>
                )}
              </ClassNames>
            </div>
          ))}
        </div>
      </div>

      {/* ------------------ Labels and Images --------------------------- */}
      <div css={[tableCss, labelAndImgContainerCss]}>
        <div css={labelAndImgColumnCss}>
          <img
            css={[
              databaseImageCss,
              css`
                @media (max-width: 540px) {
                  display: none;
                }
              `,
            ]}
            src={yourDataIcon}
            alt="Your Data"
          />
          <img
            css={[
              databaseImageCss,
              css`
                @media (min-width: 540px) {
                  display: none;
                }
              `,
            ]}
            src={yourDataIconSmall}
            alt="Your Data Small"
          />
          <h2 css={labelTextCss}>Your Data</h2>
        </div>
        <div css={labelAndImgColumnCss}>
          <img css={capIQImageCss} src={capIQIcon} alt="CapIQ IDs" />

          <h2 css={[labelTextCss, blueCss]}>CapIQ IDs</h2>
        </div>
        <div css={[linkedCss, labelCss, linkedFillerColumnCss]}>
          <IconCircleCheck size={24} />
          <span css={linkedTextCss}>Linked!</span>
        </div>
      </div>
    </div>
  )
}
