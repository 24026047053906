import {css} from '@emotion/react'

import {AnimatedContent, TwoColumnContent} from '../../components/content'
import EnsoVideo from '../../components/EnsoVideo'
import TopicHeadline from '../../components/TopicHeadline'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {
  h3TitleCss,
  h4Body1Css,
  hBoldCss,
  whiteTextCss,
  backgroundGradientCss,
  backgroundWhiteCss,
  sectionHeadingMarginCss,
} from '../../styles/common'

const leftHalfCss = css`
  padding: 100px 0;

  @media (max-width: ${BREAKPOINT_LARGE}) {
    padding: 80px 0;
  }

  @media (max-width: ${BREAKPOINT_SMALL}) {
    padding: 55px 0;
  }
`

const fullSizeCss = css`
  height: 100%;
  width: 100%;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    height: 750px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 500px;
  }
`

export default function IntroSection(): JSX.Element {
  return (
    <section>
      <TwoColumnContent
        leftWrapperCss={backgroundGradientCss}
        left={
          <div css={[leftHalfCss, whiteTextCss]}>
            <TopicHeadline text="About us" />
            <AnimatedContent>
              <h3 css={[sectionHeadingMarginCss, h3TitleCss, hBoldCss]}>
                Our AI Solutions unlock insights, enabling you to make decisions with conviction.
              </h3>
            </AnimatedContent>
            <AnimatedContent>
              <p css={h4Body1Css}>
                In partnership with S&amp;P Global, we have access to vast amounts of data, which we
                use to train and develop our machine learning algorithms and build data-centric
                solutions to the business world’s most pressing challenges.
              </p>
            </AnimatedContent>
          </div>
        }
        rightWrapperCss={backgroundWhiteCss}
        right={
          <AnimatedContent css={fullSizeCss} innerCss={fullSizeCss}>
            <EnsoVideo />
          </AnimatedContent>
        }
      />
    </section>
  )
}
