import {css} from '@emotion/react'

import SolutionsHeroLarge from '../../assets/photos/solutions-hero-large.jpg'
import SolutionsHeroMedium from '../../assets/photos/solutions-hero-medium.jpg'
import SolutionsHeroSmall from '../../assets/photos/solutions-hero-small.jpg'
import socialImg from '../../assets/social/solutions.jpg'
import {AnimatedContent, WideContent} from '../../components/content'
import {TwoColumnHeadingContent} from '../../components/headings'
import HeroSection from '../../components/Hero/HeroSection'
import Page from '../../components/Page'
import PageMetadata from '../../components/PageMetadata'
import TopicHeadline from '../../components/TopicHeadline'
import {ShortTextContent, TextContent} from '../../components/textContent'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {
  backgroundLightGrayCss,
  backgroundWhiteCss,
  centeredTextCss,
  h1Css,
  h2SubheadlineCss,
  baseSectionCss,
  h4Body2Css,
  hBoldCss,
  whiteTextCss,
  backgroundGradientCss,
} from '../../styles/common'

import SolutionsClassify from './SolutionsClassify'
import SolutionsDatasets from './SolutionsDatasets'
import SolutionsExtract from './SolutionsExtract'
import SolutionsIndices from './SolutionsIndices'
import SolutionsLink from './SolutionsLink'
import SolutionsNERD from './SolutionsNERD'
import SolutionsScribe from './SolutionsScribe'

const heroTitleCss = css`
  word-wrap: break-word;
  hyphen: manual;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    font-size: 122px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    ${h2SubheadlineCss}
    width: 335px;
  }
`

const cardsContainerCss = css`
  padding-bottom: 40px;
`

const cardEdgeCss = css`
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`

const heroImageCss = css`
  background-image: url(${SolutionsHeroLarge});

  @media (max-width: 2200px) {
    background-image: url(${SolutionsHeroMedium});
  }

  @media (max-width: 600px) {
    background-image: url(${SolutionsHeroSmall});
  }

  filter: none;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    opacity: 1;
  }
`

export default function SolutionsPage(): JSX.Element {
  return (
    <Page
      title={
        <HeroSection imageCss={heroImageCss}>
          <WideContent>
            <AnimatedContent>
              <h1 css={[h1Css, hBoldCss, whiteTextCss, centeredTextCss, heroTitleCss]}>
                Solu&shy;tions
              </h1>
            </AnimatedContent>
          </WideContent>
        </HeroSection>
      }
    >
      <PageMetadata
        title="Solutions | Kensho"
        description="Learn about all Kensho solutions and services, including Scribe, Link, Extract, NERD, Classify and Indices."
        image={{source: socialImg, height: 1250, width: 2500}}
      />
      <section css={[baseSectionCss, backgroundWhiteCss]}>
        <WideContent>
          <TopicHeadline css={[hBoldCss, h4Body2Css]} text="Our products" />
        </WideContent>
        <TwoColumnHeadingContent
          headerText="Kensho’s AI and Machine Learning capabilities structure the world’s data."
          additionalText="Kensho’s world-class solutions discover, extract, link and enrich unstructured data,
          creating value for users at all levels and roles in an organization."
          textColor="black"
        />
      </section>
      <section css={[backgroundLightGrayCss, cardsContainerCss]}>
        <ShortTextContent
          text="Whether you’re looking to use Kensho’s solutions on your own data or to leverage the
          unparalleled breadth, depth and accuracy of S&P Global’s sources, Kensho unlocks
          insights in hard-to-get-to data, making it accessible, insightful, relevant and
          ultimately, transformative."
        />
        <WideContent css={cardEdgeCss}>
          <AnimatedContent animation="fade-in">
            <SolutionsScribe />
          </AnimatedContent>
          <AnimatedContent animation="fade-in">
            <SolutionsNERD />
          </AnimatedContent>
          <AnimatedContent animation="fade-in">
            <SolutionsClassify />
          </AnimatedContent>
          <AnimatedContent animation="fade-in">
            <SolutionsExtract />
          </AnimatedContent>
          <AnimatedContent animation="fade-in">
            <SolutionsLink />
          </AnimatedContent>
          <AnimatedContent animation="fade-in">
            <SolutionsDatasets />
          </AnimatedContent>
          <AnimatedContent animation="fade-in">
            <SolutionsIndices />
          </AnimatedContent>
        </WideContent>
      </section>
      <section css={[baseSectionCss, backgroundGradientCss, whiteTextCss]}>
        <TextContent
          title="About Kensho Solutions"
          paragraphs={[
            'Kensho pairs the latest techniques in machine learning with the unparalleled breadth and depth of data at S&P Global to give customers comprehensive, timely, actionable insights, empowering them to make decisions with conviction.',
            'The world currently has more than a billion knowledge workers spanning hundreds of industries, and those workers deal with a massive uninterrupted flow of unstructured data requiring highly time-intensive manual processing. Workers are overwhelmed and miss insights.',
            'Kensho solves this problem by building foundational tools that unlock the hard-to-get data and insights organizations and individuals need to make decisions with conviction. Users gain access to timely insights using our ML Toolkit—AI-driven software that extracts and enriches meaning from complex data—to make data more discoverable, collaborative, and useful.',
            'With Kensho Solutions, knowledge workers quickly access the enterprise data they need and analyze, transform and shape it with our suite of tools, unlocking insights in hard-to-get data.',
          ]}
        />
      </section>
    </Page>
  )
}
