import {css} from '@emotion/react'

import {BREAKPOINT_MEDIUM, BREAKPOINT_SMALL} from '../../../styles/breakpoints'

import {HEADER_HEIGHT, SHORT_HEADER_HEIGHT} from './constants'

export const headerCss = css`
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  transition: all 300ms;
  padding: 24px 0;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    height: ${SHORT_HEADER_HEIGHT}px;
    padding: 14px 0;
  }
`

export const bgDarkCss = css`
  background: rgba(0, 0, 0, 0.85);
`

export const bgLightCss = css`
  background: #fff;
`

export const darkCss = css`
  color: #00b9e8;
  border-color: #00b9e8;
`

export const lightCss = css`
  color: #007694;
  border-color: #007694;
`

export const logoCss = css`
  display: block;
  width: 148px;
  height: 48px;
  margin-right: 24px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 24px;
    width: 116px;
  }
`

export const subLinkCss = css`
  margin-left: 30px;
`
