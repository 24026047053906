import {useCallback, useEffect, useState, TouchEvent, useRef} from 'react'
import {flushSync} from 'react-dom'
import {css, SerializedStyles} from '@emotion/react'
import {IconChevronLeft, IconChevronRight} from '@kensho/icons'

import assertNever from '../../utils/assertNever'

const SLIDE_GAP = 20

const sliderContainerCss = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const slideAndArrowsCss = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const slideSectionCss = css`
  flex: 1 1 auto;
  display: flex;
  overflow-y: hidden;
  overflow-x: hidden;
`

const buttonTrayCss = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-right: ${SLIDE_GAP}px;
`

const arrowCss = css`
  user-select: none;
  border: 1px solid currentcolor;
  padding: 0;

  svg {
    display: block;
    margin: auto;
  }
`

const slideTrayCss = css`
  white-space: nowrap;
  width: 100%;
`

const slideCss = css`
  white-space: normal;
  display: inline-block;
  width: 100%;
  height: 100%;
  vertical-align: top;
  position: relative;
`

const slideGapCss = css`
  padding-right: ${SLIDE_GAP}px;
`

const slideTrayInnerCss = css`
  height: 100%;
`

const animatedTrayCss = css`
  transition: transform 1s;
`

const countLabelCss = css`
  margin-right: 12px;
`

const whiteBgArrowCss = css`
  color: #007694;

  &:hover {
    color: #fff;
    background-color: #007694;
    border-color: #007694;
  }

  &:active {
    color: #fff;
    background-color: #004d61;
    border-color: #004d61;
  }
`

const blackBgArrowCss = css`
  color: #fff;
  background-color: #000;

  &:hover {
    color: #000;
    background-color: #88e3fa;
    border-color: #88e3fa;
  }

  &:active {
    color: #000;
    background-color: #00b9e8;
    border-color: #00b9e8;
  }
`

const blueBgArrowCss = css`
  color: #fff;
  background-color: ##00425a;

  &:hover {
    color: #000;
    background-color: #88e3fa;
    border-color: #88e3fa;
  }

  &:active {
    color: #000;
    background-color: #00b9e8;
    border-color: #00b9e8;
  }
`

function getButtonColorCss(color: 'white' | 'black' | 'blue'): SerializedStyles {
  switch (color) {
    case 'white':
      return whiteBgArrowCss
    case 'black':
      return blackBgArrowCss
    case 'blue':
      return blueBgArrowCss
    default:
      return assertNever(color)
  }
}

const TOUCH_BUFFER = 40

export interface BaseCarouselProps<T> {
  className?: string
  slideClassName?: string
  slideTrayClassName?: string
  buttonTrayClassName?: string
  buttonColor: 'white' | 'black' | 'blue'
  initialIndex?: number
  slides: T[]
  slideRenderer: (slide: T) => JSX.Element
  slideKeyGen: (slide: T) => React.Key
  chevronSize?: number
  mobileChevronSize?: number
}

export default function BaseCarousel<T>(props: BaseCarouselProps<T>): JSX.Element {
  const {
    className,
    slideClassName,
    slideTrayClassName,
    buttonTrayClassName,
    buttonColor,
    initialIndex = 0,
    slides,
    slideRenderer,
    slideKeyGen,
    mobileChevronSize = 90,
  } = props

  const [activeIndex, setActiveIndex] = useState(initialIndex)
  const numSlides = slides.length

  // N.B. This is one half of feature support for "infinite scrolling" so that the animation moves smoothly in the same direction even when repeating the array.
  const [transitionEnabled, setTransitionEnabled] = useState(true)
  // When the animated container transition ends, animation is briefly disabled to "snap" back to the bounds of the array.
  const onTransitionEnd = useCallback((): void => {
    const updateIndex = (updater: (prev: number) => number): void => {
      flushSync(() => {
        setActiveIndex(updater)
        setTransitionEnabled(false)
      })
    }
    if (activeIndex < 0) {
      updateIndex((prevIndex) => ((numSlides - Math.abs(prevIndex)) % numSlides) + numSlides)
    }
    if (activeIndex >= numSlides) {
      updateIndex((prevIndex) => prevIndex % numSlides)
    }
  }, [activeIndex, numSlides])

  // After the onTransitionEnd state changes are applied, React can re-enable animation.
  useEffect(() => {
    if (!transitionEnabled) setTransitionEnabled(true)
  }, [transitionEnabled])

  const goLeft = useCallback((): void => {
    setActiveIndex((current) => current - 1)
  }, [])

  const goRight = useCallback((): void => {
    setActiveIndex((current) => current + 1)
  }, [])

  const touchAnchorXRef = useRef<number | null>(null)
  const onTouchStart = useCallback((event: TouchEvent<HTMLDivElement>): void => {
    touchAnchorXRef.current = event.touches[0].screenX
  }, [])

  const onTouchEnd = useCallback(
    (event: TouchEvent<HTMLDivElement>): void => {
      if (touchAnchorXRef.current !== null) {
        const releaseX = event.changedTouches[0].screenX
        const deltaX = releaseX - touchAnchorXRef.current
        touchAnchorXRef.current = null
        if (deltaX < -TOUCH_BUFFER) {
          goRight()
        }
        if (deltaX > TOUCH_BUFFER) {
          goLeft()
        }
      }
    },
    [goLeft, goRight],
  )

  const renderSlide = (slide: T, spilloverIndex?: number): JSX.Element => (
    <div
      key={`${slideKeyGen(slide)}-${spilloverIndex}`}
      css={[slideCss, slides.length > 1 && slideGapCss]}
      className={slideClassName}
      // N.B. Slides outside the typical bounds are rendered specially to maintain the animation when adding new nodes.
      style={
        spilloverIndex === undefined
          ? undefined
          : {position: 'absolute', transform: `translateX(${spilloverIndex * 100}%)`}
      }
    >
      {slideRenderer(slide)}
    </div>
  )

  const numRepeatsLeft = activeIndex >= 0 ? 1 : Math.floor(Math.abs(activeIndex) / numSlides) + 1
  const numRepeatsRight = activeIndex <= 0 ? 1 : Math.floor(activeIndex / numSlides) + 1
  const slidesOverflowLeft = Array(numRepeatsLeft).fill(slides).flat()
  const slidesOverflowRight = Array(numRepeatsRight).fill(slides).flat()
  const currentSlideNum =
    activeIndex < 0
      ? numSlides - ((Math.abs(activeIndex) - 1) % numSlides)
      : (activeIndex % numSlides) + 1
  const buttonColorCss = getButtonColorCss(buttonColor)
  return (
    <div className={className} css={sliderContainerCss}>
      <div css={slideAndArrowsCss}>
        <div css={slideSectionCss}>
          <div
            css={slideTrayCss}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            className={slideTrayClassName}
          >
            <div
              style={{transform: `translateX(${-100 * activeIndex}%)`}}
              css={[slideTrayInnerCss, transitionEnabled && animatedTrayCss]}
              onTransitionEnd={onTransitionEnd}
            >
              {/* When traversing to items before the beginning of the array, copy the slides from the end for smooth scrolling */}
              {slidesOverflowLeft.map((slide, index) =>
                renderSlide(slide, -1 * (numRepeatsLeft * numSlides - index)),
              )}
              {slides.map((slide) => renderSlide(slide))}
              {/* Vice versa for the end of the array */}
              {slidesOverflowRight.map((slide, index) => renderSlide(slide, index))}
            </div>
          </div>
        </div>
      </div>
      {slides.length > 1 && (
        <div css={buttonTrayCss} className={buttonTrayClassName}>
          <span css={countLabelCss}>
            {currentSlideNum > 9 ? currentSlideNum : `0${currentSlideNum}`} of{' '}
            {slides.length > 9 ? slides.length : `0${slides.length}`}
          </span>
          <button
            aria-label="Previous"
            css={[arrowCss, buttonColorCss]}
            onClick={goLeft}
            type="button"
          >
            <IconChevronLeft size={mobileChevronSize} />
          </button>
          <button
            aria-label="Next"
            css={[arrowCss, buttonColorCss]}
            onClick={goRight}
            type="button"
          >
            <IconChevronRight size={mobileChevronSize} />
          </button>
        </div>
      )}
    </div>
  )
}
