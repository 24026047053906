import {useDebugValue, useEffect, useRef} from 'react'

/**
 * Repeatedly invokes a callback with a time delay between each invocation.
 *
 * @param callback The callback to invoke.
 * @param delay The number of milliseconds that should pass between invocations. If `null`, the callback is never invoked.
 *
 * @see https://overreacted.io/making-setinterval-declarative-with-react-hooks
 * @see https://tacklebox.beta-p.kensho.com/useInterval
 */
export default function useInterval(callback: () => void, delay: number | null): void {
  useDebugValue(delay)

  const callbackRef = useRef(callback)

  useEffect(() => {
    callbackRef.current = callback
  })

  useEffect(() => {
    if (delay === null) return undefined
    const intervalId = setInterval(() => callbackRef.current(), delay)
    return () => clearInterval(intervalId)
  }, [delay])
}
