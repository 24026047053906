import {css} from '@emotion/react'
import {ReactNode} from 'react'

import ButtonLink from '../../components/Button/ButtonLink'
import {blackTextCss} from '../../styles/common'
import {BREAKPOINT_SMALL} from '../../styles/breakpoints'

const containerCss = css`
  display: flex;
  width: 100%;
  padding: 24px 0;
  align-items: start;
  justify-content: space-between;
  border-bottom: 1px solid #9da1a6;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
  }
`

const leftCss = css`
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 65%;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    max-width: none;
  }
`

const buttonCss = css`
  margin-bottom: 20px;
  padding: 0;
  text-decoration-line: underline;

  &:after {
    content: none;
  }
`

const rightTextCss = css`
  margin-top: 10px;
  font-size: 14px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-top: 20px;
  }
`

export interface PublicationProps {
  title: string
  url: string
  authors: ReactNode
  publisher: string
}

export default function Publication({
  title,
  url,
  authors,
  publisher,
}: PublicationProps): JSX.Element {
  return (
    <div css={containerCss}>
      <div css={leftCss}>
        <ButtonLink to={url} variant="minimal" css={buttonCss}>
          {title}
        </ButtonLink>
        {authors}
      </div>
      <p css={[rightTextCss, blackTextCss]}>{publisher}</p>
    </div>
  )
}
