import {css} from '@emotion/react'

import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../../styles/breakpoints'
import useHasScrolledInBounds from '../../../hooks/useHasScrolledInBounds'

import Duration from './Duration'

const baseTranscribedMs = 283824000000 // 9 years
const baseTime = new Date('2020-12-15').getTime()
const transcriptionRate = 10
// total transcribed audio extrapolated to current date
const initialTranscribedMs = Math.floor(
  baseTranscribedMs + (Date.now() - baseTime) * transcriptionRate,
)

const durationContainerCss = css`
  margin: 120px 0px 30px;
  height: 125px;
  width: 100%;
  transition: 1.5s;
  opacity: 0;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-top: 95px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 105px;
    margin-top: 115px;
    margin-bottom: 35px;
  }
`

const fullOpacityCss = css`
  opacity: 1;
`

export default function TranscribedAudioTicker(): JSX.Element {
  const {setNode, hasScrolledInBounds} = useHasScrolledInBounds<HTMLDivElement>()

  return (
    <div ref={setNode} css={[durationContainerCss, hasScrolledInBounds && fullOpacityCss]}>
      {hasScrolledInBounds && (
        <Duration
          animate
          initialDuration={initialTranscribedMs}
          rate={transcriptionRate}
          interval={150}
        />
      )}
    </div>
  )
}
