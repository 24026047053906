import {css} from '@emotion/react'
import {useLogger} from '@kensho/lumberjack'
import {useEffect, useMemo, useState} from 'react'

import ButtonLink from '../../components/Button/ButtonLink'
import fetchWorkdayJobs, {WorkdayJob} from '../../data/fetchWorkdayJobs'
import {centeredTextCss} from '../../styles/common'

import JobListing from './JobListing'

const jobListCss = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 40px;
`

const filtersCss = css`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 5px auto 10px auto;
`

const filterCss = css`
  width: 200px;
  height: 32px;
  margin: 10px;
`

const fallbackContainerCss = css`
  display: flex;
`
const buttonFallbackCss = css`
  display: inline-block;
  margin: 0 auto;
`

export default function AvailablePositions(): JSX.Element {
  const log = useLogger()
  const [selectedLocation, setSelectedLocation] = useState<string>('')
  const [jobs, setJobs] = useState<WorkdayJob[] | null>(null)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    fetchWorkdayJobs()
      .then((fetchedJobs) => setJobs(fetchedJobs))
      .catch((error) => {
        setHasError(true)
        log.error(error)
      })
  }, [log])

  const locations = useMemo(() => new Set(jobs?.flatMap((job) => job.locations) ?? []), [jobs])
  const filteredJobs = useMemo(
    () =>
      selectedLocation === ''
        ? jobs
        : (jobs?.filter((job) => job.locations.includes(selectedLocation)) ?? []),
    [jobs, selectedLocation],
  )

  if (hasError)
    return (
      <div css={fallbackContainerCss}>
        <ButtonLink
          to="https://careers.spglobal.com/jobs?view=search&tags9=KENSHO&page=1"
          variant="secondary"
          css={buttonFallbackCss}
        >
          View all of our available positions
        </ButtonLink>
      </div>
    )

  const isLoadingJobs = jobs === null && !hasError

  return (
    <>
      {jobs?.length && (
        <>
          <div css={filtersCss}>
            <select
              css={filterCss}
              value={selectedLocation}
              onChange={(event) => setSelectedLocation(event.target.value)}
            >
              <option value="">All Locations</option>
              {[...locations].sort().map((location) => (
                <option key={location} value={location}>
                  {location}
                </option>
              ))}
            </select>
          </div>
          <div css={[jobListCss, centeredTextCss]}>
            {filteredJobs?.map((job) => <JobListing {...job} key={job.id} />)}
          </div>
        </>
      )}
      {isLoadingJobs && <p>Loading available positions…</p>}
      {Array.isArray(jobs) && !jobs.length && (
        <p>There are no available positions at this time. Please check back later.</p>
      )}
    </>
  )
}
