import {css} from '@emotion/react'

import kenshoLogo from '../../assets/logos/kensho-black.svg'
import ButtonLink from '../../components/Button/ButtonLink'
import Link from '../../components/Link'
import {BREAKPOINT_LARGE, BREAKPOINT_XLARGE} from '../../styles/breakpoints'
import {
  h3TitleCss,
  h4Body2Css,
  labelCss,
  hBoldCss,
  blackTextCss,
  darkBlueTextCss,
} from '../../styles/common'

const cardCss = css`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background: #fff;
  padding: 45px;
  margin-bottom: 20px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #e4e5e5;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    flex-direction: column;
    align-items: stretch;
  }
`

const leftContentCss = css`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  align-items: flex-start;
  width: 335px;
  margin-right: 100px;

  @media (max-width: ${BREAKPOINT_XLARGE}px) {
    width: 295px;
    margin-right: 40px;
  }

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    width: 100%;
    margin: 0px 0px 20px;
  }
`

const solutionLabelCss = css`
  position: absolute;
  top: 0px;
  left: 0px;
`

const graphicSectionCss = css`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 50px 0px 0px;
  padding: 20px 0px;
`

const imageCss = css`
  margin-right: 20px;
`

const imageTitleCss = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const kenshoLogoCss = css`
  width: 67px;
  height: 11px;
`
const descriptionCss = css`
  display: block;
`
const linkCss = css`
  margin: 20px 0px 0px;
`

const linkListCss = css`
  list-style-type: none;
  padding: 0;
`

const blueLinkCss = css`
  color: #007694;
`

const mainContentCss = css`
  display: block;
  text-align: left;
  margin: 0px 15px 0px;
  flex-item: 1 0 auto;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin: 0px 0px 20px;
  }
`
const linkSectionCss = css`
  width: 275px;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  justify-content: space-between;
  margin-left: 100px;

  @media (max-width: ${BREAKPOINT_XLARGE}px) {
    width: 260px;
    margin-left: 40px;
  }

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin: 0px;
    width: 100%;
  }
`

const actionButtonCss = css`
  margin-top: 60px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-top: 40px;
  }
`

interface Image {
  src: string
  alt: string
}

interface CardLink {
  href: string
  text: string
}

interface SolutionCardProps {
  className?: string
  label?: string
  graphic?: Image
  title: string
  description: string
  learnMoreUrl?: string
  mainContent?: React.ReactNode
  linkList?: CardLink[]
  callToActionLink?: CardLink
}

export default function SolutionCard({
  className,
  label,
  graphic,
  title,
  description,
  learnMoreUrl,
  mainContent,
  callToActionLink,
  linkList,
}: SolutionCardProps): JSX.Element {
  return (
    <div className={className} css={cardCss}>
      <div css={leftContentCss}>
        {label && (
          <span css={[labelCss, hBoldCss, darkBlueTextCss, solutionLabelCss]}>{label}</span>
        )}

        <div css={graphicSectionCss}>
          {graphic && <img css={imageCss} src={graphic.src} alt={graphic.alt} />}
          <div css={imageTitleCss}>
            <img css={kenshoLogoCss} src={kenshoLogo} alt="Kensho Logo" />
            <h3 css={[h3TitleCss, hBoldCss, blackTextCss]}>{title}</h3>
          </div>
        </div>

        <p css={[labelCss, hBoldCss, descriptionCss, blackTextCss]}>{description}</p>
        {learnMoreUrl && (
          <Link css={[blueLinkCss, h4Body2Css, linkCss]} to={learnMoreUrl}>
            Learn More
          </Link>
        )}
      </div>

      {mainContent && <div css={[h4Body2Css, mainContentCss, blackTextCss]}>{mainContent}</div>}

      {(linkList || callToActionLink) && (
        <div css={linkSectionCss}>
          {linkList && (
            <div>
              <h3 css={[labelCss, hBoldCss, blackTextCss]}>Discover more</h3>
              <ul css={linkListCss}>
                {linkList.map((link) => (
                  <li css={linkCss} key={link.href}>
                    <Link css={[blueLinkCss, h4Body2Css]} to={link.href}>
                      {link.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {callToActionLink && (
            <ButtonLink to={callToActionLink.href} variant="primary" css={actionButtonCss}>
              {callToActionLink.text}
            </ButtonLink>
          )}
        </div>
      )}
    </div>
  )
}
