import {css} from '@emotion/react'

import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import bulletIcon from '../../assets/icons/list-bullet.svg'
import NERDProject from '../../assets/photos/nerd-project.png'
import Link from '../../components/Link'
import TopicHeadline from '../../components/TopicHeadline'
import {AnimatedContent, TwoColumnContent, WideContent} from '../../components/content'
import {
  h3SubheadlineCss,
  h4Body2Css,
  blackTextCss,
  backgroundWhiteCss,
  baseSectionCss,
} from '../../styles/common'
import COLORS from '../../styles/colors'

const imagePaddingCss = css`
  padding-right: 0;
`

const imageSizingCss = css`
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    justify-content: center;
  }
`

const imageCss = css`
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 415px;
  }
`

const rightCss = css`
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-top: 90px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-top: 50px;
  }
`

const headerCss = css`
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    max-width: 600px;
  }
`

const listCss = css`
  list-style-image: url(${bulletIcon});
  padding-top: 60px;
  padding-left: 22px;
  margin: 0;
`

const itemPaddingCss = css`
  padding-left: 4px;
  padding-bottom: 20px;
`

const lineHeightCss = css`
  line-height: 24px;
`

const linkCss = css`
  color: ${COLORS.brand.light[550]};
`

const listItemCss = [h4Body2Css, blackTextCss, lineHeightCss, itemPaddingCss]

export default function WhySection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundWhiteCss]}>
      <WideContent>
        <TopicHeadline text="Why Kensho NERD" css={blackTextCss} />
      </WideContent>
      <TwoColumnContent
        leftContentCss={imagePaddingCss}
        left={
          <AnimatedContent animation="fade-in" innerCss={imageSizingCss}>
            <img src={NERDProject} alt="First NERD Project" css={imageCss} loading="lazy" />
          </AnimatedContent>
        }
        rightWrapperCss={rightCss}
        right={
          <>
            <AnimatedContent>
              <h3 css={[headerCss, h3SubheadlineCss, blackTextCss]}>
                90% of data is unstructured, but 4 in 5 organizations can&apos;t effectively utilize
                it.
              </h3>
            </AnimatedContent>
            <AnimatedContent>
              <ul css={listCss}>
                <li css={listItemCss}>
                  Unstructured data presents organizations with an opportunity to turn cost into
                  intelligence and competitive differentiation.{' '}
                  <Link
                    to="https://blog.kensho.com/kensho-ml-engineer-showcases-nlp-tool-at-virtual-meetup-2bc3866286e6"
                    css={linkCss}
                  >
                    Read more here
                  </Link>
                </li>
                <li css={listItemCss}>
                  Text is ubiquitous in unstructured data, whether emails, articles, documents,
                  audio and/or video transcripts, reports, log files, presentations, social media
                  posts, or any one of many other formats.
                </li>
                <li css={listItemCss}>
                  Like many organizations, S&P Global is awash with unstructured text data, with
                  millions of documents, articles, transcripts, etc. generated each year.
                  Effectively processing such data was a strategic imperative to find operational
                  efficiencies, new revenue streams and business intelligence.
                </li>
                <li css={listItemCss}>
                  Kensho developed NERD to help S&P address this challenge. S&P Global has used NERD
                  to great success. NERD has enriched 55M+ docs and has made 600M+ new connections
                  to S&P Global’s company databases. NERD can do the same for your organization; we
                  are eager to help!{' '}
                  <Link
                    to="https://blog.kensho.com/kensho-launches-new-text-analysis-product-kensho-nerd-265aefb15be4"
                    css={linkCss}
                  >
                    Read more here
                  </Link>
                </li>
              </ul>
            </AnimatedContent>
          </>
        }
      />
    </section>
  )
}
