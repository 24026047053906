import {useState, useCallback} from 'react'

import useResizeObserver from './useResizeObserver'

/**
 * Tracks the measured width of an element.
 *
 * @returns The element's current width, and a callback ref to attach to the element.
 *
 * @see `useResizeObserver`, which this hook composes.
 * @see https://tacklebox.beta-p.kensho.com/useElementWidth
 */
export default function useElementWidth(): [number, (node: HTMLElement | null) => void] {
  const [width, setWidth] = useState(0)
  const ref = useResizeObserver(useCallback((size) => setWidth(size.width), []))
  return [width, ref]
}
