import {css} from '@emotion/react'

import {
  backgroundDarkBlueCss,
  h3SubheadlineCss,
  h4Body2Css,
  labelCss,
  whiteTextCss,
} from '../../../styles/common'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../../styles/breakpoints'

const headerCss = css`
  margin-bottom: 70px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-bottom: 40px;
  }
`

const caseStudyCss = css`
  display: flex;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    justify-content: center;
  }
`

const flexCss = css`
  flex: 1;
`

const contentCss = css`
  padding: 50px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    max-width: 828px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 20px 20px 40px;
  }
`

const titleCss = css`
  margin-bottom: 40px;
`

const imageContainerCss = css`
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    display: none;
  }
`

const imageCss = css`
  object-fit: cover;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    display: none;
  }
`

export interface CaseStudySlideProps {
  className?: string
  title: string
  content: React.ReactNode
  imageUri: string
  imageAlt: string
}

export default function CaseStudySlide({
  className,
  title,
  content,
  imageUri,
  imageAlt,
}: CaseStudySlideProps): JSX.Element {
  return (
    <div css={[caseStudyCss, whiteTextCss, backgroundDarkBlueCss]} className={className}>
      <div css={[contentCss, flexCss]}>
        <h4 css={[labelCss, headerCss]}>Case Study</h4>
        <h3 css={[h3SubheadlineCss, titleCss]}>{title}</h3>
        {typeof content === 'string' ? <p css={h4Body2Css}>{content}</p> : content}
      </div>
      <div css={[imageContainerCss, flexCss]}>
        <img
          src={imageUri}
          alt={imageAlt}
          height="100%"
          width="100%"
          css={imageCss}
          loading="lazy"
        />
      </div>
    </div>
  )
}
