import {css} from '@emotion/react'
import {useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'

import socialImg from '../../assets/social/contact.jpg'
import {WideContent} from '../../components/content'
import EmailLink from '../../components/EmailLink'
import HeroSection from '../../components/Hero/HeroSection'
import Link from '../../components/Link'
import Page from '../../components/Page'
import PageMetadata from '../../components/PageMetadata'
import {EMAILS} from '../../constants'
import {BREAKPOINT_LARGE} from '../../styles/breakpoints'
import {h4Body2Css, backgroundWhiteCss, baseSectionCss} from '../../styles/common'

import ContactItem from './ContactItem'

const linkCss = css`
  color: #50afc6;
`

const contactListCss = css`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
  max-width: 1200px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    justify-content: center;
  }
`

const formLinkCss = css`
  display: flex;
  align-items: center;
  color: #50afc6;
`

export default function ContactPage(): JSX.Element {
  const {search} = useLocation()
  const [emailQuery, setEmailQuery] = useState('')

  // Choosing to use a derived state pattern with an effect here in order to avoid the
  // alternative solution, which requires the server side to send and cache query strings,
  // possibly leading to excessive memory usage and performance loss
  useEffect(() => {
    const queryParams = new URLSearchParams(search)
    const emailParams = new URLSearchParams()

    const subject = queryParams.get('subject')
    if (subject !== null) emailParams.append('subject', subject)
    const body = queryParams.get('body')
    if (body !== null) emailParams.append('body', body)

    setEmailQuery(emailParams.toString())
  }, [search])

  return (
    <Page title={<HeroSection short>Contact</HeroSection>}>
      <PageMetadata
        title="Contact | Kensho"
        description="Contact the team at Kensho for more information on careers, products and services."
        image={{source: socialImg, height: 1250, width: 2500}}
      />
      <section css={[baseSectionCss, backgroundWhiteCss]}>
        <WideContent>
          <ul css={contactListCss}>
            <li>
              <ContactItem
                heading="Interested in our Solutions?"
                text="Tell us about your use case."
              >
                <Link
                  css={[formLinkCss, h4Body2Css]}
                  to={`mailto:sales@kensho.com${emailQuery ? `?${emailQuery}` : ''}`}
                >
                  sales@kensho.com
                </Link>
              </ContactItem>
            </li>
            <li>
              <ContactItem heading="Ran into an issue?" text="Reach out to our Support team.">
                <EmailLink css={[linkCss, h4Body2Css]} email={EMAILS.SUPPORT} />
              </ContactItem>
            </li>
            <li>
              <ContactItem heading="Have a question?" text="Submit a general inquiry.">
                <EmailLink css={[linkCss, h4Body2Css]} email={EMAILS.INFO} />
              </ContactItem>
            </li>
            <li>
              <ContactItem heading="Found a vulnerability?" text="Let us know.">
                <EmailLink css={[linkCss, h4Body2Css]} email={EMAILS.SECURITY} />
              </ContactItem>
            </li>
          </ul>
        </WideContent>
      </section>
    </Page>
  )
}
