import {css} from '@emotion/react'

import {COMPANY_NAME, CURRENT_YEAR, EMAILS, SOCIAL_URLS} from '../../constants'
import kenshoSpgLogo from '../../assets/logos/kensho-spg-white.svg'
import EmailLink from '../EmailLink'
import Link from '../Link'
import {centeredTextCss} from '../../styles/common'

import Office from './Office'

const footerCss = css`
  background-color: #141516;
  color: #888;
`

const containerCss = css`
  margin: 0 auto;
  padding: 64px 128px 84px 128px;
  max-width: 1440px;

  @media (max-width: 1280px) {
    padding: 64px 64px 84px 64px;
  }
`

const titleCss = css`
  margin-bottom: 20px;
  text-transform: uppercase;

  @media (max-width: 720px) {
    text-align: center;
  }
`

const contentCss = css`
  display: flex;
  line-height: 2;

  a {
    color: #888;

    &:not(:last-of-type) {
      margin-right: 10px;
    }

    &:hover {
      color: white;
    }
  }

  h4 {
    color: white;
    white-space: pre;
    line-height: 1.4;
    margin-bottom: 6px;
  }

  @media (max-width: 1150px) {
    flex-direction: column;
  }
`

const copyrightCss = css`
  margin-top: 25px;
`

const kenshoSpgFooterLogoCss = css`
  width: 150px;
`

const officesCss = css`
  display: flex;

  @media (max-width: 1150px) {
    justify-content: space-between;
  }

  @media (max-width: 720px) {
    flex-direction: column;
    text-align: center;
  }
`

const resourcesCss = css`
  text-align: right;
  flex: 1;

  @media (max-width: 1150px) {
    margin-top: 50px;
    text-align: center;

    a {
      display: inline-block;
      padding: 12px 6px;
      margin: 0 2px;
    }
  }
`

export default function Footer(): JSX.Element {
  return (
    <footer css={footerCss}>
      <div css={containerCss}>
        <div css={titleCss}>
          <img
            src={kenshoSpgLogo}
            css={kenshoSpgFooterLogoCss}
            alt="Kensho, a company of S&amp;P Global"
            loading="lazy"
          />
        </div>
        <div css={contentCss}>
          <div css={officesCss}>
            <Office
              name={'Harvard Square\n+ AI Lab'}
              address="44 Brattle St"
              city="Cambridge, MA 02138"
            />
            <Office name="New York City" address="55 Water Street" city="New York, NY 10041" />
            <Office
              name="Washington D.C."
              address="1200 G Street Northwest"
              city="Washington, DC 20005"
            />
          </div>
          <div css={resourcesCss}>
            <div>
              <Link to="/contact">Contact</Link>
              <Link to="/privacy">Privacy Policy</Link>
              <Link to="/terms-of-use">Web Terms</Link>
              <Link to="/terms-of-service">Service Terms</Link>
            </div>
            <div>
              <EmailLink email={EMAILS.INFO}>Email</EmailLink>
              <Link to={SOCIAL_URLS.LINKEDIN}>LinkedIn</Link>
              <Link to={SOCIAL_URLS.TWITTER}>Twitter</Link>
            </div>
          </div>
        </div>
        <div css={[copyrightCss, centeredTextCss]}>
          Copyright &copy; {CURRENT_YEAR} {COMPANY_NAME}. Kensho marks are the property of{' '}
          {COMPANY_NAME}. All rights reserved.
        </div>
      </div>
    </footer>
  )
}
