import {css} from '@emotion/react'

import {ButtonVariant} from '../../Button/getButtonStyles'

import {NavItem} from './navigation'
import DropdownMenu from './DropdownMenu'
import NavLinkButton from './NavLinkButton'

const headerNavItem = css`
  height: 44px;
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 16px 16px;
`

const headerNavLightCss = css`
  color: #007694;

  &:focus {
    border-color: #007694;
    border-width: 1px;
    border-style: solid;
    outline: none;
  }

  &:hover {
    color: #007694;
  }

  &:hover:after {
    border-color: #007694;
  }

  &:active {
    color: #004d61;
  }

  &:active:after {
    border-color: #004d61;
  }
`

const headerNavDarkCss = css`
  color: #00b9e8;

  &:hover {
    color: #00b9e8;
  }

  &:hover:after {
    border-color: #00b9e8;
  }

  &:focus {
    border-color: #00b9e8;
    border-width: 1px;
    border-style: solid;
    outline: none;
  }

  &:active {
    color: #e4e5e5;
  }

  &:active:after {
    border-color: #e4e5e5;
  }
`

const dropdownCss = css`
  padding: 10px 100px 10px 0;
  width: 100%;
`

const dropdownNavItem = css`
  padding: 10px 15px;
  margin: 5px 15px;
  justify-content: flex-start;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &:after {
    transform-origin: left;
  }
`

interface DesktopNavItemProps {
  className?: string
  item: NavItem
  mode: 'dark' | 'light'
  variant?: ButtonVariant
}

export default function HeaderNavItem({
  className,
  item,
  mode,
  variant = 'minimal',
}: DesktopNavItemProps): JSX.Element {
  return item.isGroup ? (
    <DropdownMenu
      topLink={
        <NavLinkButton
          link={item.link}
          variant="minimal"
          mode={mode}
          css={[headerNavItem, mode === 'dark' ? headerNavDarkCss : headerNavLightCss]}
        />
      }
      theme="light"
      className={className}
    >
      <div css={dropdownCss}>
        {item.sublinks.map((link) => (
          <NavLinkButton
            link={link}
            variant="minimal"
            mode="light"
            key={link.label}
            css={dropdownNavItem}
          />
        ))}
      </div>
    </DropdownMenu>
  ) : (
    <NavLinkButton
      link={item}
      variant={variant}
      mode={mode}
      css={[
        headerNavItem,
        variant === 'minimal' && (mode === 'dark' ? headerNavDarkCss : headerNavLightCss),
      ]}
      className={className}
    />
  )
}
