import {css} from '@emotion/react'

import ExtractProductLogo from '../../assets/logos/products/extract.svg'
import ExtractHeroLarge from '../../assets/photos/extract-hero-large.jpg'
import ExtractHeroMedium from '../../assets/photos/extract-hero-medium.jpg'
import ExtractHeroSmall from '../../assets/photos/extract-hero-small.jpg'
import socialImg from '../../assets/social/extract.jpg'
import ButtonLink from '../../components/Button/ButtonLink'
import ProductHeroSection from '../../components/Hero/ProductHeroSection'
import Page from '../../components/Page'
import PageMetadata from '../../components/PageMetadata'
import {URLS} from '../../constants'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import COLORS from '../../styles/colors'
import {h4Body1Css} from '../../styles/common'

import AccessSection from './AccessSection'
import DeveloperSection from './DeveloperSection'
import FAQSection from './FAQSection'
import FeatureSection from './FeatureSection'
import IntroSection from './IntroSection'
import SampleSection from './SampleSection'
import UseCaseSection from './UseCaseSection'
import WeHelpSection from './WeHelpSection'

const heroImageCss = css`
  background-image: url(${ExtractHeroLarge});
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    background-image: url(${ExtractHeroMedium});
  }
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    background-image: url(${ExtractHeroSmall});
  }
  filter: brightness(75%);
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    opacity: 1;
  }
`

const callToActionCss = css`
  display: flex;
  justify-content: center;
`

const buttonCss = css`
  height: 60px;
  width: 213px;
  border: 1px solid ${COLORS.white};
  margin-top: 40px;
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-top: 30px;
  }
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-top: 35px;
    max-width: 333px;
    width: 100%;
  }
`

export default function ExtractPage(): JSX.Element {
  return (
    <Page
      title={
        <ProductHeroSection
          imageCss={heroImageCss}
          productLogo={ExtractProductLogo}
          productTitle="Extract"
          productSubtitle="Bulk text, table and key-value extraction made easy."
          callToAction={
            <div css={callToActionCss}>
              <ButtonLink
                to={`${URLS.FREE_TRIAL}/extract`}
                variant="secondary"
                mode="dark"
                css={[h4Body1Css, buttonCss]}
              >
                Get Started
              </ButtonLink>
            </div>
          }
        />
      }
    >
      <PageMetadata
        title="Extract | Kensho"
        description="Kensho Extract is an ML-based service for bulk text, table and key-value extraction made easy."
        image={{source: socialImg, height: 1250, width: 2500}}
      />
      <IntroSection />
      <SampleSection />
      <FeatureSection />
      <UseCaseSection />
      <AccessSection />
      <DeveloperSection />
      <WeHelpSection />
      <FAQSection />
    </Page>
  )
}
