import {css} from '@emotion/react'

import LinkProductLogo from '../../assets/logos/products/link.svg'
import LinkHeroLarge from '../../assets/photos/link-hero-large.jpg'
import LinkHeroMedium from '../../assets/photos/link-hero-medium.jpg'
import LinkHeroSmall from '../../assets/photos/link-hero-small.jpg'
import socialImg from '../../assets/social/link.jpg'
import ProductHeroSection from '../../components/Hero/ProductHeroSection'
import ButtonLink from '../../components/Button/ButtonLink'
import PageMetadata from '../../components/PageMetadata'
import Page from '../../components/Page'
import {h4Body1Css} from '../../styles/common'
import {URLS} from '../../constants'

import AboutSection from './AboutSection'
import CaseStudySection from './CaseStudySection'
import DeveloperSection from './DeveloperSection'
import EDXSection from './EDXSection'
import FAQSection from './FAQSection'
import ImpactSection from './ImpactSection'
import ProcessSection from './ProcessSection'
import SeeForYourselfSection from './SeeForYourselfSection'
import StorySection from './StorySection'
import TickerSection from './TickerSection'
import BECRSSection from './BECRSSection'

const heroImageCss = css`
  background-image: url(${LinkHeroLarge});

  @media (max-width: 2200px) {
    background-image: url(${LinkHeroMedium});
  }

  @media (max-width: 600px) {
    background-image: url(${LinkHeroSmall});
  }

  filter: none;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    opacity: 1;
  }
`

const callToActionCss = css`
  display: flex;
  justify-content: center;
`

const buttonLinkCss = css`
  width: 327px;
  margin-top: 40px;
`

export default function Link(): JSX.Element {
  return (
    <Page
      title={
        <ProductHeroSection
          imageCss={heroImageCss}
          productLogo={LinkProductLogo}
          productTitle="Link"
          productSubtitle="Map tabular data to world class databases."
          callToAction={
            <div css={callToActionCss}>
              <ButtonLink
                to={`${URLS.FREE_TRIAL}/link`}
                variant="secondary"
                mode="dark"
                css={[h4Body1Css, buttonLinkCss]}
              >
                Get Started
              </ButtonLink>
            </div>
          }
        />
      }
    >
      <PageMetadata
        title="Link | Kensho"
        description="Kensho Link is an ML-based service that is connecting the world’s data"
        image={{source: socialImg, height: 1250, width: 2500}}
      />
      <BECRSSection />
      <TickerSection />
      <AboutSection />
      <EDXSection />
      <SeeForYourselfSection />
      <ImpactSection />
      <CaseStudySection />
      <ProcessSection />
      <DeveloperSection />
      <StorySection />
      <FAQSection />
    </Page>
  )
}
