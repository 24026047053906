import {css} from '@emotion/react'

import TeamsHeroLarge from '../../assets/photos/teams-hero-large.jpg'
import TeamsHeroMedium from '../../assets/photos/teams-hero-medium.jpg'
import TeamsHeroSmall from '../../assets/photos/teams-hero-small.jpg'
import socialImg from '../../assets/social/team.jpg'
import {AnimatedContent, TwoColumnContent, WideContent} from '../../components/content'
import HeroSection from '../../components/Hero/HeroSection'
import Page from '../../components/Page'
import PageMetadata from '../../components/PageMetadata'
import TopicHeadline from '../../components/TopicHeadline'
import {
  backgroundGradientCss,
  backgroundLightGrayCss,
  baseSectionCss,
  bodyTextMarginCss,
  h3TitleCss,
  h4Body1Css,
  hBoldCss,
  whiteTextCss,
} from '../../styles/common'

import TeamInfoSection from './TeamInfoSection'

const heroImageCss = css`
  background-image: url(${TeamsHeroLarge});
  background-position-y: 25%;

  @media (max-width: 2200px) {
    background-image: url(${TeamsHeroMedium});
  }

  @media (max-width: 600px) {
    background-image: url(${TeamsHeroSmall});
  }

  filter: none;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    opacity: 1;
  }
`

export default function TeamPage(): JSX.Element {
  return (
    <Page
      title={
        <HeroSection imageCss={heroImageCss}>
          <WideContent>
            <AnimatedContent>
              <h1 css={whiteTextCss}>Meet the Team</h1>
            </AnimatedContent>
          </WideContent>
        </HeroSection>
      }
    >
      <PageMetadata
        title="Team | Kensho"
        description="Kensho’s team is an energetic and diverse group of software engineers, data scientists, ML and AI professionals and more."
        image={{source: socialImg, height: 1250, width: 2500}}
      />

      <section css={[baseSectionCss, whiteTextCss, backgroundGradientCss]}>
        <WideContent>
          <TopicHeadline text="Living the “Go Team” Spirit" />
        </WideContent>
        <TwoColumnContent
          left={
            <AnimatedContent>
              <h3 css={[h3TitleCss, hBoldCss]}>Our teams work together to do great things.</h3>
            </AnimatedContent>
          }
          right={
            <AnimatedContent css={h4Body1Css}>
              <p css={bodyTextMarginCss}>
                Kensho’s team mentality runs deep. We believe that each person’s successes are the
                team’s successes, each person’s failures are the team’s failures, and that you
                always have a friend who has your back when something goes wrong. Our teams work
                together to do great things. Read on for more about our teams, a few of each team’s
                members, our interview processes and more!
              </p>
            </AnimatedContent>
          }
        />
      </section>

      <TeamInfoSection
        teamName="Software Engineering - Applications"
        body="The Applications Team has two missions: First, enable users to structure unstructured data by building a toolkit of thoughtfully designed, robust, scalable and maintainable services. Second, enable Kensho’s rapid iteration by investing in the highest leverage services and people. The team is composed of four sub teams focused on products and cross-team platform work."
        techStackText="Python, React, Kubernetes"
        interviewRounds={[
          'Talk to a recruiter',
          'Technical interview with the manager or a team member',
          'Virtual final round with 3 technical discussions and 1 cultural interview',
        ]}
        team="applications"
      />
      <TeamInfoSection
        css={backgroundLightGrayCss}
        teamName="Infrastructure"
        body="The Infrastructure team within Kensho has grown organically over the years. It is a high-leverage platform team comprising 4 sub teams: Core Infra, Site Reliability Engineering, ML Ops and Security. The team's mission is to build frameworks and solutions that make engineers independent and confident about the code, services and models that are deployed. The idea is to shift left in our engineering practices so we can achieve the zero-bugs dream and keep our uptime high."
        techStackText="Python, AWS, Kubernetes, Docker, Airflow, Terraform, JSonnet"
        interviewRounds={[
          'Talk to a recruiter',
          'Technical interview with the manager or a team member',
          'Virtual final round with 3 technical discussions and 1 cultural interview',
        ]}
        team="infrastructure"
      />
      <TeamInfoSection
        teamName="Machine Learning"
        body="The Machine Learning Team is charged with research for and implementation of Kensho’s core AI capabilities. With a strong focus on scientific rigor and engineering-led development, Team ML is the innovation engine of Kensho. The team comprises four pods, each focused on a core ML disciplinary area: Core NLP, Document Extraction, Database Linking and Speech to Text. We are strongly biased towards cross-pod collaboration and never miss an opportunity to share our learnings with the whole team. We employ Weights & Biases for experiment tracking, hyperparameter tuning, and other ML operations."
        techStackText="Python, AWS, Pytorch, Tensorflow, Scikit-Learn, LightGBM"
        interviewRounds={[
          'Talk to a recruiter',
          'Technical interview with the manager or a team member',
          'Virtual final round with 3 technical discussions and 1 cultural interview',
        ]}
        team="machine-learning"
      />
      <TeamInfoSection
        css={backgroundLightGrayCss}
        teamName="Product"
        body="Kensho is a product-led, engineering-driven company. The product team is responsible for defining the vision for the company and working with engineering teams to drive toward that vision. Product managers have lots of responsibility and autonomy to do whatever is necessary to ensure the success of their business unit."
        interviewRounds={[
          'Talk to a recruiter',
          'Talk to a team member about your product management skills',
          'Virtual final round with both product and engineering team members',
        ]}
        team="product"
      />
      <TeamInfoSection
        teamName="Product Design"
        body="The Product Design Team helps Kensho better understand our customers, create elegant, seamless and cohesive experiences across our AI toolkit, and iterate quickly to meet user needs. We do this through a robust UX design process that integrates user research, design thinking workshops, prototyping, testing and a shared design system."
        interviewRounds={['Talk to a recruiter', 'Portfolio review', 'Virtual final round']}
        interviewTextNode={
          <>
            For a detailed description of our interview process, see our blog post,{' '}
            <a href="https://blog.kensho.com/how-kensho-interviews-for-our-design-team-3622c922090a">
              How Kensho Interviews for Our Design Team
            </a>
            .
          </>
        }
        team="product-design"
      />
      <TeamInfoSection
        css={backgroundLightGrayCss}
        teamName="People"
        body="The People Team at Kensho strives to create a best-in-class employee experience from your first interview with us through onboarding, career development and more. Our goal is to create a diverse, inclusive and flexibility-first environment that enables all of our employees to do their best work and have a healthy work-life balance."
        interviewRounds={[
          'Talk to a recruiter',
          'Talk to the hiring manager',
          'Virtual final round',
        ]}
        team="people"
      />
    </Page>
  )
}
