import {LinkProps, Link as RouterLink} from 'react-router-dom'

export default function Link({children, to, ...passthru}: LinkProps): JSX.Element {
  // external
  if (typeof to === 'string' && to.match(/^https?:\/\//)) {
    return (
      <a href={to as string} target="_blank" rel="noopener noreferrer" {...passthru}>
        {children}
      </a>
    )
  }

  // includes hash (need to use regular anchor for scroll behavior)
  if ((typeof to === 'string' && to.includes('#')) || (typeof to === 'object' && to.hash)) {
    return (
      <a href={to as string} {...passthru}>
        {children}
      </a>
    )
  }

  // internal
  return (
    <RouterLink to={to} {...passthru}>
      {children}
    </RouterLink>
  )
}
