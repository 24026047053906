import {css} from '@emotion/react'

import {labelCss, whiteTextCss} from '../../styles/common'

const featureContainerCss = css`
  display: flex;
  max-width: 320px;
  white-space: normal;
  padding: 28px 5px 28px 0px;
`

const imageContainerCss = css`
  margin-right: 20px;
  width: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface FeatureLabelProps {
  className?: string
  alt: string
  src: string
  text: React.ReactNode
}

export default function FeatureLabel({className, alt, src, text}: FeatureLabelProps): JSX.Element {
  return (
    <div css={[featureContainerCss, labelCss, whiteTextCss]} className={className}>
      <div css={imageContainerCss}>
        <img src={src} alt={alt} />
      </div>
      {typeof text === 'string' ? <p>{text}</p> : text}
    </div>
  )
}
