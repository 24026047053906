import {css} from '@emotion/react'
import {IconChevronDown} from '@kensho/icons'

import {h4Body2Css} from '../../styles/common'
import COLORS from '../../styles/colors'

const selectWrapperCss = css`
  position: relative;
`

const selectCss = css`
  outline: none;
  border: 1px solid #007694;
  appearance: none;
  height: 64px;
  background: transparent;
  letter-spacing: 0.1em;
  padding: 0 50px 0 10px;
  width: 100%;
  color: ${COLORS.brand.light[700]};

  ::-ms-expand {
    display: none;
  }
`

const disabledCss = css`
  background: rgba(0, 0, 0, 0.06);
`

const caretCss = css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  height: 100%;
`

export default function Select({
  className,
  children,
  disabled,
  ...selectProps
}: React.HTMLProps<HTMLSelectElement>): JSX.Element {
  return (
    <div className={className} css={selectWrapperCss}>
      <select
        css={[selectCss, h4Body2Css, disabled && disabledCss]}
        disabled={disabled}
        {...selectProps}
      >
        {children}
      </select>
      <div css={caretCss}>
        <IconChevronDown size={40} fill={disabled ? '#999' : COLORS.brand.light[700]} />
      </div>
    </div>
  )
}
