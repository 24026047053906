import {css, SerializedStyles} from '@emotion/react'
import * as Diff from 'diff'
import {useMemo} from 'react'

import COLORS from '../../../styles/colors'

type DiffMode = 'removals' | 'additions' | 'both'

interface DiffTextProps {
  leftText: string
  rightText: string
  mode?: DiffMode
}

const wordCss = css`
  display: inline;
  margin: 0 1px;
  line-height: 24px;
  white-space: wrap;
  word-break: break-word;

  :first-of-type {
    margin-left: 0;
  }

  :last-of-type {
    margin-right: 0;
  }
`

const whiteSpaceCss = css`
  white-space: pre-wrap;
`

const highlightCss = css`
  color: ${COLORS.brand.light[400]};
  background: ${COLORS.brand.light[400]}44;
`

const strikethroughCss = css`
  text-decoration: line-through;
  color: ${COLORS.brand.light[400]};
`

function getWordCss(change: Diff.Change): SerializedStyles | undefined {
  if (change.removed) {
    return strikethroughCss
  }
  if (change.added) {
    return highlightCss
  }
  return undefined
}

function diffWords(rightText: string, leftText: string, mode: DiffMode = 'both'): Diff.Change[] {
  const diffs = Diff.diffWordsWithSpace(rightText, leftText)

  const reducedDiffs = diffs.reduce<Diff.Change[]>((acc, d) => {
    // if the diff is punctuation, ignore it
    if (d.value.match(/^[!?,.]+$/ || d.value.trim() === '')) {
      if (d.added) return acc
      if (d.removed) {
        const {removed, ...rest} = d
        acc.push(rest)
        return acc
      }
    } else if (d.value.includes('\n') && d.value.match(/[^\n]+/)) {
      // if the diff contains newlines and other characters, then
      // split newlines into their own item so that later we can render separately with a <pre/>
      const newLineSplit = d.value.split('\n')
      const diffsWithSeparateNewLines = newLineSplit.reduce<Diff.Change[]>((accDiffs, text, i) => {
        if (text) accDiffs.push({...d, value: text})
        if (!(i === newLineSplit.length - 1)) accDiffs.push({...d, value: '\n'})
        return accDiffs
      }, [])
      acc.push(...diffsWithSeparateNewLines)

      return acc
    }

    if (
      mode === 'both' ||
      (!d.added && !d.removed) ||
      (mode === 'additions' && d.added) ||
      (mode === 'removals' && d.removed)
    )
      acc.push(d)

    return acc
  }, [])

  return reducedDiffs
}

export default function DiffText(props: DiffTextProps): JSX.Element {
  const {leftText, rightText, mode = 'both'} = props
  // punctuation, casing, whitespace are ignored
  const diff = useMemo(() => diffWords(leftText, rightText, mode), [leftText, rightText, mode])

  return (
    <>
      {/* eslint-disable react/no-array-index-key */}
      {diff.map((change, i) =>
        change.value.startsWith('\n') ? (
          // render newlines as <pre/> so we preserve the original text's formatting
          <pre key={`${change.value}:${i}`} css={[wordCss, getWordCss(change), whiteSpaceCss]}>
            {change.value}
          </pre>
        ) : (
          // render everything else as a <span/> so we can text wrap
          <span key={`${change.value}:${i}`} css={[wordCss, getWordCss(change)]}>
            {change.value}
          </span>
        ),
      )}
      {/* eslint-enable react/no-array-index-key */}
    </>
  )
}
