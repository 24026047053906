import {css} from '@emotion/react'

import {WideContent} from '../../components/content'
import Accordion from '../../components/Accordion'
import Link from '../../components/Link'
import {
  h3TitleCss,
  h4Body2Css,
  hBoldCss,
  blackTextCss,
  backgroundWhiteCss,
  baseSectionCss,
  centeredTextCss,
} from '../../styles/common'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'

const titleCss = css`
  margin-bottom: 80px;
  align-self: center;
`

const contentCss = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1000px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    max-width: 815px;
  }
`

const listCss = css`
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding-left: 20px;
  }
`

const accordionItems = [
  {
    content: 'How good is Classify’s performance?',
    expandedContent: (
      <>
        <p css={[h4Body2Css, blackTextCss]}>
          Classify’s performance is best in class, accelerating time to value and driving superior
          outcomes in any application. It trains faster, annotates faster, and produces better
          annotations. It more than meets the needs of serious workloads.
        </p>
        <ul css={[listCss, h4Body2Css, blackTextCss]}>
          <li>
            Prediction Quality: Classify’s precision, recall, and F1 are substantially better than
            open source and other vendor text classification solutions. Our benchmarks indicate
            Classify F1 is ≥10% higher than common solutions.
          </li>
          <li>
            Scalability: Classify can handle million-character payloads and concurrent requests
            without any impact on performance or reliability.
          </li>
          <li>
            Custom Model Creation: Classify requires a trivial amount of labeled data and needs no
            machine learning expertise.
          </li>
          <li>
            Time to Value: Classify is fast! You can define, train, and use a performant model in
            minutes rather than the typical weeks or months.
          </li>
        </ul>
      </>
    ),
  },
  {
    content: 'How do I use Classify?',
    expandedContent: (
      <p css={[h4Body2Css, blackTextCss]}>
        Classify is accessed via a simple REST API. Easily define your concepts, submit your text,
        and receive a list of annotated concepts in response in the form of a JSON. For more
        detailed information, please visit the{' '}
        <Link to="https://classify.kensho.com/docs/">API reference</Link>. We have built and helped
        customers build other ways to consume Classify, such as extensions for applications such as
        Microsoft Office. If you are interested in such options, please contact us.
      </p>
    ),
  },
  {
    content: 'Can I change concepts I’ve created?',
    expandedContent:
      'Yes! You can continuously update your Classify model with new custom concepts or refinements to existing concepts. Even better, Classify maintains a history of these model updates, so you can revert back any model you created.',
  },
  {
    content: 'What programming languages does Classify support?',
    expandedContent:
      'Classify can support any language capable of making a REST API call, such as Python, R, Java, JavaScript, TypeScript, Ruby, Rust and more.',
  },
  {
    content: 'How is Classify priced?',
    expandedContent:
      'Classify is available as a pay-as-you-go solution priced per thousand characters processed or through an annual subscription. Contact us to discuss what’s right for you.',
  },
  {
    content: 'How does Classify work?',
    expandedContent:
      'Classify is a collection of advanced machine learning models that represent the conceptual content of documents to look for your specific topics. Kensho combines the latest advances in machine learning with S&P Global’s unparalleled data universe to train the models that underlie Classify.',
  },
  {
    content: 'How does Classify safeguard my data?',
    expandedContent:
      'Kensho takes your privacy and security seriously. Data submitted to Classify is encrypted in transit and at rest, inaccessible to anyone but you, the user. Contact us to learn more.',
  },
]

export default function FAQSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundWhiteCss]}>
      <h3 css={[titleCss, h3TitleCss, hBoldCss, centeredTextCss, blackTextCss]}>
        Frequently Asked Questions
      </h3>
      <WideContent css={contentCss}>
        <Accordion defaultExpanded accordionContent={accordionItems} />
      </WideContent>
    </section>
  )
}
