import {css} from '@emotion/react'

import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import TopicHeadline from '../../components/TopicHeadline'
import Skeleton from '../../components/Skeleton'
import {TwoColumnHeadingContent} from '../../components/headings'
import {WideContent} from '../../components/content'
import useIsMounted from '../../hooks/useIsMounted'
import {
  blackTextCss,
  backgroundWhiteCss,
  baseSectionCss,
  twoColumnSectionHeadingMarginCss,
} from '../../styles/common'

import Comparison from './Comparison'

const skeletonContainerCss = css`
  max-width: 1145px;
  margin-top: 95px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    max-width: 753px;
    margin-top: 85px;
  }
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-top: 40px;
  }
`

export default function SampleSection(): JSX.Element {
  const isMounted = useIsMounted()

  return (
    <section css={[baseSectionCss, backgroundWhiteCss]}>
      <WideContent>
        <TopicHeadline text="See for yourself" css={blackTextCss} />
      </WideContent>
      <TwoColumnHeadingContent
        headerText="See the difference between NERD and the competition in the examples below."
        additionalText="While other solutions struggles with ambiguous names, abbreviations, nicknames and the
        like, NERD is able to disambiguate entities and connect them to S&P Capital IQ data."
        textColor="black"
        css={twoColumnSectionHeadingMarginCss}
      />
      {isMounted ? (
        <Comparison />
      ) : (
        <WideContent css={skeletonContainerCss}>
          <Skeleton height={425} />
        </WideContent>
      )}
    </section>
  )
}
