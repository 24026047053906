import {createContext, useContext, useEffect, useState} from 'react'

const IsMountedContext = createContext(false)

export default function useIsMounted(): boolean {
  return useContext(IsMountedContext)
}

interface IsMountedProviderProps {
  children: React.ReactNode
}

export function IsMountedProvider(props: IsMountedProviderProps): JSX.Element {
  const {children} = props
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return <IsMountedContext.Provider value={isMounted}>{children}</IsMountedContext.Provider>
}
