import {css} from '@emotion/react'

import socialImg from '../../assets/social/kensho-home.jpg'
import HiringPreviewSection from '../../components/HiringPreviewSection'
import Page from '../../components/Page'
import PageMetadata from '../../components/PageMetadata'
import {TwoColumnContent} from '../../components/content'
import {BREAKPOINT_LARGE} from '../../styles/breakpoints'
import {
  backgroundDarkBlueCss,
  backgroundLightBlueCss,
  backgroundMidGrayCss,
  whiteTextCss,
} from '../../styles/common'

import BusinessSection from './BusinessSection'
import CaseStudyCarousel from './CaseStudyCarousel'
import ConnectWithUsSection from './ConnectWithUsSection'
import Hero from './Hero'
import IntroSection from './IntroSection'
import ProductsSection from './ProductsSection'

const caseStudyContentCss = css`
  padding-left: 0;
  padding-right: 0;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }
`

const caseStudySectionCss = css`
  height: 100%;
`

export default function Home(): JSX.Element {
  return (
    <Page title={<Hero />}>
      <PageMetadata
        title="Home | Kensho"
        description="Kensho develops cutting-edge products and technologies that transform businesses. We are the AI Innovation Hub for S&P Global."
        image={{source: socialImg, height: 1250, width: 2500}}
      />
      <IntroSection />
      <ProductsSection />
      <BusinessSection />
      <TwoColumnContent
        leftWrapperCss={[backgroundLightBlueCss, whiteTextCss]}
        left={<HiringPreviewSection />}
        rightWrapperCss={[backgroundMidGrayCss, whiteTextCss]}
        rightContentCss={caseStudyContentCss}
        right={
          <section css={[caseStudySectionCss, backgroundDarkBlueCss]}>
            <CaseStudyCarousel />
          </section>
        }
      />
      <ConnectWithUsSection />
    </Page>
  )
}
