import {css} from '@emotion/react'

import ClassifyHeroLarge from '../../assets/photos/classify-hero-large.jpg'
import ClassifyHeroMedium from '../../assets/photos/classify-hero-medium.jpg'
import ClassifyHeroSmall from '../../assets/photos/classify-hero-small.jpg'
import ClassifyHeroLogo from '../../assets/logos/products/classify.svg'
import socialImg from '../../assets/social/classify.jpg'
import ProductHeroSection from '../../components/Hero/ProductHeroSection'
import ButtonLink from '../../components/Button/ButtonLink'
import Page from '../../components/Page'
import PageMetadata from '../../components/PageMetadata'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {URLS} from '../../constants'
import {h4Body1Css} from '../../styles/common'
import COLORS from '../../styles/colors'

import IntroSection from './IntroSection'
import NumbersSection from './NumbersSection'
import SampleSection from './SampleSection'
import ProcessSection from './ProcessSection'
import CompareSection from './CompareSection'
import NotSureSection from './NotSureSection'
import ApplicationSection from './ApplicationSection'
import DeveloperSection from './DeveloperSection'
import FAQSection from './FAQSection'

const heroImageCss = css`
  background-image: url(${ClassifyHeroLarge});

  @media (max-width: 2200px) {
    background-image: url(${ClassifyHeroMedium});
  }

  @media (max-width: 600px) {
    background-image: url(${ClassifyHeroSmall});
  }

  filter: none;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    opacity: 1;
  }
`

const callToActionCss = css`
  display: flex;
  justify-content: center;
`

const buttonCss = css`
  height: 60px;
  width: 213px;
  border: 1px solid ${COLORS.white};
  margin-top: 60px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-top: 30px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-top: 20px;
    max-width: 333px;
    width: 100%;
  }
`

export default function Classify(): JSX.Element {
  return (
    <Page
      title={
        <ProductHeroSection
          imageCss={heroImageCss}
          productLogo={ClassifyHeroLogo}
          productTitle="Classify"
          productSubtitle="Let AI understand your documents."
          callToAction={
            <div css={callToActionCss}>
              <ButtonLink
                to={`${URLS.FREE_TRIAL}/classify`}
                variant="secondary"
                mode="dark"
                css={[h4Body1Css, buttonCss]}
              >
                Get Started
              </ButtonLink>
            </div>
          }
        />
      }
    >
      <PageMetadata
        title="Classify | Kensho"
        description="Use AI to understand your documents with Kensho Classify, a tool for tagging text documents with completely customizable concepts."
        image={{source: socialImg, height: 1250, width: 2500}}
      />
      <IntroSection />
      <SampleSection />
      <NumbersSection />
      <ProcessSection />
      <CompareSection />
      <NotSureSection />
      <ApplicationSection />
      <DeveloperSection />
      <FAQSection />
    </Page>
  )
}
