import {css} from '@emotion/react'

import CallCenterIcon from '../../assets/icons/call-center.svg'
import ClosedCaptionIcon from '../../assets/icons/closed-captions.svg'
import ComplianceIcon from '../../assets/icons/compliance.svg'
import DepositionsIcon from '../../assets/icons/deposition.svg'
import EarningsIcon from '../../assets/icons/earnings-calls.svg'
import InterviewsIcon from '../../assets/icons/interviews.svg'
import PodcastsIcon from '../../assets/icons/podcast.svg'
import VoicemailsIcon from '../../assets/icons/voicemails.svg'
import {AnimatedContent, WideContent} from '../../components/content'
import GraphicContentGroup from '../../components/GraphicContentGroup'
import TopicHeadline from '../../components/TopicHeadline'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {
  backgroundWhiteCss,
  baseSectionCss,
  blackTextCss,
  twoColumnSectionHeadingMarginCss,
} from '../../styles/common'
import {TwoColumnHeadingContent} from '../../components/headings'

const appSectionCss = css`
  margin-top: 100px;
  max-width: 910px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    max-width: 750px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-top: 60px;
    max-width: 335px;
  }
`

export default function ApplicationSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundWhiteCss]}>
      <WideContent>
        <TopicHeadline css={blackTextCss} text="Applications" />
      </WideContent>
      <TwoColumnHeadingContent
        headerText="Scribe makes your audio accessible and searchable in a snap."
        additionalText="Scribe is a valuable tool for any kind of organization or business that deals with
        high volumes of audio, from the corporate world to academia, from science and medicine
        to finance and law."
        textColor="black"
        css={twoColumnSectionHeadingMarginCss}
      />
      <AnimatedContent animation="fade-in">
        <GraphicContentGroup
          css={appSectionCss}
          graphicContent={[
            {
              graphic: {data: CallCenterIcon, isText: false},
              content: {title: 'Call Center'},
            },
            {
              graphic: {data: VoicemailsIcon, isText: false},
              content: {title: 'Voicemails'},
            },
            {
              graphic: {data: DepositionsIcon, isText: false},
              content: {title: 'Depositions'},
            },
            {
              graphic: {data: InterviewsIcon, isText: false},
              content: {title: 'Interviews'},
            },
            {
              graphic: {data: ClosedCaptionIcon, isText: false},
              content: {title: 'Closed Captioning'},
            },
            {
              graphic: {data: ComplianceIcon, isText: false},
              content: {title: 'Compliance'},
            },
            {
              graphic: {data: PodcastsIcon, isText: false},
              content: {title: 'Podcasts'},
            },
            {
              graphic: {data: EarningsIcon, isText: false},
              content: {title: 'Earnings Calls'},
            },
          ]}
          maxItemsPerRow={4}
        />
      </AnimatedContent>
    </section>
  )
}
