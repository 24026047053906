import {css} from '@emotion/react'

import quoteIcon from '../assets/icons/quote-light.svg'
import {Testimonial} from '../data/testimonials/testimonialData'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../styles/breakpoints'
import COLORS from '../styles/colors'
import {h3TitleCss, h4Body1Css, h4Body2Css, whiteTextCss} from '../styles/common'

interface TestimonialItemProps {
  testimonial: Testimonial
}

const containerCss = css`
  margin-right: 10px;
  height: 100%;
  padding: 100px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding: 50px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-right: 0;
    padding: 40px 20px 20px 20px;
  }
`

const identityCss = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 50px 0;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
    margin: 25px 0;
  }
`

const photoContainerCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 125px;
  width: 125px;
`

const photoCss = css`
  height: 125px;
  width: 125px;
  user-select: none;
  display: block;
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  object-fit: cover;
`

const identityInfoCss = css`
  align-self: flex-end;
  margin: 0 0 10px 20px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    align-self: center;
    text-align: center;
    margin: 0;
  }
`

const quoteIconCss = css`
  width: 140px;
  height: 110px;
`

const gradientCss = css`
  background: linear-gradient(
    to right bottom,
    ${COLORS.brand.light[400]},
    ${COLORS.brand.light[550]}
  );
`

export default function TestimonialItem(props: TestimonialItemProps): JSX.Element {
  const {testimonial} = props
  const {photo, name, title, quote} = testimonial

  return (
    <div css={[containerCss, gradientCss]}>
      <img src={quoteIcon} alt="Quote:" css={quoteIconCss} />
      <div css={identityCss}>
        <div css={photoContainerCss}>
          {photo && <img css={photoCss} src={photo} alt="Kensho employee" />}
        </div>
        <div css={[identityInfoCss, whiteTextCss]}>
          <div css={h3TitleCss}>{name}</div>
          <div css={h4Body2Css}>{title}</div>
        </div>
      </div>
      <div css={[h4Body1Css, whiteTextCss]}>{quote}</div>
    </div>
  )
}
