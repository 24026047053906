import {css} from '@emotion/react'

import {AnimatedContent, WideContent} from '../../components/content'
import GraphicContentGroup from '../../components/GraphicContentGroup'
import APIIcon from '../../assets/icons/api.svg'
import UIIcon from '../../assets/icons/ui.svg'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {
  backgroundWhiteCss,
  baseSectionCss,
  centeredTextCss,
  sectionHeadingMarginCss,
} from '../../styles/common'
import {HeadingContent} from '../../components/headings'

const squeezeContainerCss = css`
  max-width: 900px;
`

const widthCss = css`
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    min-width: 444px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    min-width: unset;
  }
`

export default function AccessSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundWhiteCss]}>
      <WideContent>
        <HeadingContent
          headerText="Kensho Extract can be accessed in two ways:"
          css={[sectionHeadingMarginCss, centeredTextCss]}
        />
        <AnimatedContent animation="fade-in">
          <GraphicContentGroup
            css={squeezeContainerCss}
            graphicContent={[
              {
                graphic: {data: APIIcon, isText: false},
                content: {
                  title: 'API',
                  description: (
                    <p css={widthCss}>
                      A simple, easy-to-use API for fast, programatic, high-throughput extraction
                    </p>
                  ),
                },
              },
              {
                graphic: {data: UIIcon, isText: false},
                content: {
                  title: 'UI',
                  description: (
                    <p css={widthCss}>
                      An intuitive UI for your team to review extraction results and make
                      corrections.
                    </p>
                  ),
                },
              },
            ]}
            maxItemsPerRow={2}
          />
        </AnimatedContent>
      </WideContent>
    </section>
  )
}
