import {useState, useCallback} from 'react'
import {css} from '@emotion/react'
import {IconX} from '@kensho/icons'

import logoDarkBlue from '../../../assets/logos/header/kensho-dark-blue.svg'
import logoLightBlue from '../../../assets/logos/header/kensho-light-blue.svg'
import Link from '../../Link'
import Portal from '../../Modal/Portal'
import {WideContent} from '../../content'
import {BREAKPOINT_LARGE, BREAKPOINT_MEDIUM, BREAKPOINT_SMALL} from '../../../styles/breakpoints'
import COLORS from '../../../styles/colors'

import {freeTrialNavLink, navigation, selfServiceNavLink, contactUsNavLink} from './navigation'
import HeaderNavItem from './HeaderNavItem'
import MenuIcon from './MenuIcon'
import NavLinkButton from './NavLinkButton'
import SidebarNavItem from './SidebarNavItem'
import {headerCss, logoCss, bgDarkCss, bgLightCss, darkCss, lightCss} from './styles'

interface MobileHeaderProps {
  className?: string
  mode: 'dark' | 'light'
}

const compactHeaderCss = css`
  display: none;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    display: block;
  }
`

const headerContainerCss = css`
  height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

const topNavCss = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`

const overlayCss = css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 20;
`

const menuCss = css`
  position: fixed;
  z-index: 20;
  right: 0;
  top: 0;
  bottom: 0;

  height: 100%;
  width: 400px;
  padding: 20px 25px 20px 20px;

  transition: all 300ms;
  background: #fff;

  display: none;
  overflow-y: auto;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    display: block;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    max-width: 100%;
    min-width: 100%;
  }
`

const closedMenuCss = css`
  width: 1px;
  opacity: 0;
  overflow-y: hidden;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    min-width: 1px;
  }
`

const menuLogoCss = css`
  visibility: hidden;
  width: 120px;
  padding: 5px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    visibility: initial;
  }
`

const rightNavCss = css`
  display: flex;
  align-items: center;
  gap: 16px;
`

const navItemCss = css`
  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    display: none;
  }
`

const buttonPaddingCss = css`
  padding: 9px 20px;
`

const middleButtonLightCss = css`
  color: ${COLORS.brand.light[700]};
  border: 2px solid ${COLORS.brand.light[700]};
`

const middleButtonDarkCss = css`
  color: ${COLORS.brand.dark[700]};
  border: 2px solid ${COLORS.brand.dark[700]};
`

const wideNavItemCss = css`
  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    width: 176px;
    margin-right: 8px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    display: none;
  }
`

const closeButtonCss = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`

const iconCss = css`
  cursor: pointer;
  background: transparent;
  border: none;
  vertical-align: middle;
`

const iconPaddingCss = css`
  padding: 0;
`

const iconDarkCss = css`
  color: #00b9e8;

  &:hover {
    color: #88e3fa;
  }

  &:active {
    color: #e4e5e5;
  }
`

const iconLightCss = css`
  color: #007694;

  &:hover {
    color: #007581;
  }

  &:active {
    color: #004d61;
  }
`

const largeButtonCss = css`
  width: 100%;
  border: none;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding: 14px 20px;
  }
`

const dropdownFreeTrialButtonCss = css`
  margin-bottom: 20px;
  display: none;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    display: flex;
  }
`

const margin40Css = css`
  margin-bottom: 30px;
`

export default function CompactHeader(props: MobileHeaderProps): JSX.Element {
  const {className, mode} = props

  const [isMenuOpen, setMenuOpen] = useState(false)
  const toggleMenu = useCallback(() => setMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen), [])

  return (
    <header
      css={[
        headerCss,
        compactHeaderCss,
        mode === 'dark' ? bgDarkCss : bgLightCss,
        mode === 'dark' ? darkCss : lightCss,
      ]}
      className={className}
    >
      <div css={headerContainerCss}>
        <WideContent>
          <div css={topNavCss}>
            <Link to="/">
              <img
                src={mode === 'dark' ? logoLightBlue : logoDarkBlue}
                alt="Kensho"
                css={logoCss}
              />
            </Link>
            <div css={rightNavCss}>
              <HeaderNavItem item={selfServiceNavLink} mode={mode} css={navItemCss} />
              <HeaderNavItem
                item={freeTrialNavLink}
                mode={mode}
                variant="secondary"
                css={[
                  navItemCss,
                  buttonPaddingCss,
                  mode === 'dark' ? middleButtonDarkCss : middleButtonLightCss,
                ]}
              />
              <HeaderNavItem
                item={contactUsNavLink}
                mode={mode}
                variant="primary"
                css={[wideNavItemCss, buttonPaddingCss]}
              />
              <div aria-label="Toggle menu" role="button" onClick={toggleMenu} tabIndex={0}>
                <MenuIcon css={[iconCss, mode === 'dark' ? iconDarkCss : iconLightCss]} />
              </div>
            </div>
          </div>
          <Portal>
            {isMenuOpen && <div css={overlayCss} onClick={toggleMenu} />}
            <div css={[menuCss, !isMenuOpen && closedMenuCss]}>
              <div css={closeButtonCss}>
                <img src={logoDarkBlue} alt="Kensho" css={menuLogoCss} />
                <div
                  aria-label="Close"
                  role="button"
                  tabIndex={0}
                  css={[iconCss, iconLightCss]}
                  onClick={toggleMenu}
                >
                  <IconX size={40} css={iconPaddingCss} />
                </div>
              </div>
              <NavLinkButton
                link={freeTrialNavLink}
                mode="light"
                variant="primary"
                css={[largeButtonCss, dropdownFreeTrialButtonCss]}
              />
              <NavLinkButton
                link={selfServiceNavLink}
                mode="light"
                variant="secondary"
                css={[largeButtonCss, margin40Css]}
              />
              {navigation.map((item) => (
                <SidebarNavItem key={item.isGroup ? item.link.label : item.label} item={item} />
              ))}
            </div>
          </Portal>
        </WideContent>
      </div>
    </header>
  )
}
