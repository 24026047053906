import {css} from '@emotion/react'

import {BREAKPOINT_LARGE, BREAKPOINT_MEDIUM} from '../../styles/breakpoints'
import {WideContent} from '../../components/content'
import {
  backgroundWhiteCss,
  h3TitleCss,
  h4Body2Css,
  hBoldCss,
  blackTextCss,
  centeredTextCss,
  baseSectionCss,
} from '../../styles/common'
import Link from '../../components/Link'
import Accordion from '../../components/Accordion'

const titleCss = css`
  margin-bottom: 80px;
  align-self: center;
`

const contentCss = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1000px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    max-width: 815px;
  }
  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    max-width: none;
  }
`

const linkCss = css`
  text-decoration: underline;
`

const accordionItems = [
  {
    content: 'How good is NERD’s performance?',
    expandedContent:
      'NERD’s entity predictions are market-leading for business and finance use cases as measured by F1, precision, and recall. In terms of its API, NERD is capable of handling more than 1 million characters in a single request and can respond synchronously or asynchronously.',
  },
  {
    content: 'What programming languages does NERD support?',
    expandedContent:
      'NERD can support any language capable of making a REST API call, such as Python, R, Java, JavaScript, TypeScript, Ruby, Rust, and more.',
  },
  {
    content: 'Can NERD output other types of entity IDs?',
    expandedContent: (
      <p css={[h4Body2Css, blackTextCss]}>
        If you would like to consume NERD’s output as other entity types (e.g., Global Tax IDs,
        Moody’s, Fitch, D&B Duns, LEI, etc.), we recommend using{' '}
        <Link
          to="https://www.marketplace.spglobal.com/en/datasets/business-entity-cross-reference-service-becrs-(4)"
          css={[blackTextCss, linkCss]}
        >
          S&P’s BECRS dataset
        </Link>
        . To learn more,{' '}
        <Link to="mailto:sales@kensho.com?subject=Nerd" css={[blackTextCss, linkCss]}>
          contact us.
        </Link>
      </p>
    ),
  },
  {
    content: 'How is NERD priced?',
    expandedContent:
      'NERD is available as a pay as you go solution priced per thousand characters processed or through an annual subscription. Contact us to discuss what’s right for you.',
  },
  {
    content: 'Can I use NERD to disambiguate to other knowledge bases?',
    expandedContent:
      'We are always open to exploring other knowledge bases. Contact us to discuss your needs.',
  },
  {
    content: 'How does NERD work?',
    expandedContent:
      'Kensho combines the latest advances in machine learning with S&P Global’s unparalleled data universe to train the models that make up NERD.  ',
  },
  {
    content: 'How does NERD handle subsidiary and parent companies?',
    expandedContent:
      'NERD is designed to recognize companies at the appropriate levels of their corporate hierarchies. Parent companies and their subsidiaries are both distinguished and represented according to the relationships contained in Capital IQ. NERD reports the most up-to-date information for these relationships -- even if the corporate hierarchies or names have changed.',
  },
  {
    content: 'How do I use NERD?',
    expandedContent:
      'NERD is accessed via REST API. Simply input your text, specify to which knowledge base(s) you would like to link, and get your results. NERD’s results are returned as a list of JSON annotations, each corresponding to a mention of an entity in your document. Each annotation will include: Location of the entity in the text, Entity Name, Entity ID in either Capital IQ or Wikidata, Entity Type and other relevant metadata. We have built and helped customers build other ways to consume NERD, such as a Microsoft Excel plug-in. If you are interested in such options, please contact us.',
  },
  {
    content: 'How does NERD safeguard my data?',
    expandedContent:
      'Kensho takes your privacy and security seriously. Data submitted to NERD is only temporarily stored in order to perform the service and is secured from being accessed by others. Contact us to learn more.',
  },
]

export default function FAQSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundWhiteCss]}>
      <h3 css={[titleCss, centeredTextCss, h3TitleCss, hBoldCss, blackTextCss]}>
        Frequently Asked Questions
      </h3>
      <WideContent css={contentCss}>
        <Accordion defaultExpanded accordionContent={accordionItems} />
      </WideContent>
    </section>
  )
}
