import {css} from '@emotion/react'

import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import TopicHeadline from '../../components/TopicHeadline'
import {AnimatedContent, WideContent} from '../../components/content'
import IntelligenceIcon from '../../assets/icons/market-intelligence.svg'
import EnrichmentIcon from '../../assets/icons/text-enrichment.svg'
import FilteringIcon from '../../assets/icons/document-filtering.svg'
import LabellingIcon from '../../assets/icons/labelling-analysis.svg'
import RedactionsIcon from '../../assets/icons/rapid-redactions.svg'
import BeakerIcon from '../../assets/icons/beaker.svg'
import GraphicContentGroup from '../../components/GraphicContentGroup'
import {
  backgroundWhiteCss,
  baseSectionCss,
  blackTextCss,
  twoColumnSectionHeadingMarginCss,
} from '../../styles/common'
import {TwoColumnHeadingContent} from '../../components/headings'

const appContainerCss = css`
  margin-top: 90px;
`

// Quick hack to ensure GraphicContentGroup renders single item per row for medium screen width while preserving the width of the individual items
const appWidthCss = css`
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    min-width: 444px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    min-width: 100%;
  }
`

export default function ApplicationSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundWhiteCss]}>
      <WideContent>
        <TopicHeadline text="Kensho NERD Use Cases" css={blackTextCss} />
      </WideContent>
      <TwoColumnHeadingContent
        headerText="NERD is a valuable tool for anyone dealing with high volumes of text, documents, or
        unstructured data."
        additionalText="For large enterprises, academics, financial or professional service providers,
        government bodies and more, NERD makes your text intelligible and searchable in a
        snap."
        textColor="black"
        css={twoColumnSectionHeadingMarginCss}
      />
      <WideContent>
        <AnimatedContent animation="fade-in">
          <GraphicContentGroup
            css={appContainerCss}
            graphicContent={[
              {
                graphic: {data: IntelligenceIcon, isText: false},
                content: {
                  title: <p css={appWidthCss}>Market Intelligence</p>,
                  description: (
                    <p css={appWidthCss}>
                      Rapidly identify emerging market trends, players and events for competitive
                      intelligence.
                    </p>
                  ),
                },
              },
              {
                graphic: {data: EnrichmentIcon, isText: false},
                content: {
                  title: <p css={appWidthCss}>Text Enrichment</p>,
                  description: (
                    <p css={appWidthCss}>
                      Transform documents into troves of embedded information, supercharging
                      research & analysis.
                    </p>
                  ),
                },
              },
              {
                graphic: {data: FilteringIcon, isText: false},
                content: {
                  title: <p css={appWidthCss}>Document Filtering</p>,
                  description: (
                    <p css={appWidthCss}>
                      Use NERD annotations to make search, content recommendation and document
                      routing smarter.
                    </p>
                  ),
                },
              },
              {
                graphic: {data: LabellingIcon, isText: false},
                content: {
                  title: <p css={appWidthCss}>Labelling & Analysis</p>,
                  description: (
                    <p css={appWidthCss}>
                      Analyze NERD output with linked S&P Global and Wikimedia data. Use NERD to
                      develop and evaluate AI/ML models.
                    </p>
                  ),
                },
              },
              {
                graphic: {data: RedactionsIcon, isText: false},
                content: {
                  title: <p css={appWidthCss}>Rapid Redactions</p>,
                  description: (
                    <p css={appWidthCss}>
                      Quickly identify potentially sensitive and secret information for redaction.
                      Data is only temporarily stored in order to perform the service.
                    </p>
                  ),
                },
              },
              {
                graphic: {data: BeakerIcon, isText: false},
                content: {
                  title: <p css={appWidthCss}>...And more</p>,
                  description: (
                    <p css={appWidthCss}>
                      New use cases for NERD appear all the time. Anywhere people, places and things
                      need to be identified and managed. How can NERD help you?
                    </p>
                  ),
                },
              },
            ]}
            maxItemsPerRow={3}
          />
        </AnimatedContent>
      </WideContent>
    </section>
  )
}
