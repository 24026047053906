import {css} from '@emotion/react'

import certificateIcon from '../../assets/icons/certificate.svg'
import chatIcon from '../../assets/icons/chat.svg'
import coinsIcon from '../../assets/icons/coins.svg'
import familyIcon from '../../assets/icons/family.svg'
import hammerIcon from '../../assets/icons/hammer.svg'
import lightningIcon from '../../assets/icons/lightning.svg'
import lotusIcon from '../../assets/icons/lotus.svg'
import pramIcon from '../../assets/icons/pram.svg'
import trainIcon from '../../assets/icons/train.svg'
import vennIcon from '../../assets/icons/venn.svg'
import whistleIcon from '../../assets/icons/whistle.svg'
import ensoDEILeft from '../../assets/logos/enso-dei-left.png'
import ensoDEIRight from '../../assets/logos/enso-dei-right.png'
import DEIHeroLarge from '../../assets/photos/DEI-hero-large.jpg'
import DEIHeroMedium from '../../assets/photos/DEI-hero-medium.jpg'
import DEIHeroSmall from '../../assets/photos/DEI-hero-small.jpg'
import socialImg from '../../assets/social/DEI.jpg'
import {AnimatedContent, TwoColumnContent, WideContent} from '../../components/content'
import GraphicContentGroup, {GraphicContent} from '../../components/GraphicContentGroup'
import HeroSection from '../../components/Hero/HeroSection'
import Page from '../../components/Page'
import PageMetadata from '../../components/PageMetadata'
import TestimonialsCarousel from '../../components/TestimonialsCarousel'
import TopicHeadline from '../../components/TopicHeadline'
import {HeadingContent} from '../../components/headings'
import {BREAKPOINT_LARGE, BREAKPOINT_MEDIUM, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import COLORS from '../../styles/colors'
import {
  backgroundGradientCss,
  backgroundLightGrayCss,
  backgroundWhiteCss,
  baseSectionCss,
  bodyTextMarginCss,
  centeredTextCss,
  h4Body1Css,
  h4Body2Css,
  hBoldCss,
  whiteTextCss,
} from '../../styles/common'

import deiLeadershipData from './deiLeadershipData'

const descriptionCss = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  text-align: left;
`

const programsGraphicContent: GraphicContent[] = [
  {
    graphic: {
      data: hammerIcon,
      isText: false,
    },
    content: {
      title: 'DEI Workshops & Trainings',
      description: (
        <div css={[h4Body2Css, descriptionCss]}>
          <p css={bodyTextMarginCss}>
            Kensho has hosted a number of workshops and training sessions for its employees on
            topics including:
          </p>
          <p css={bodyTextMarginCss}>
            Adaptivity and Resilience, Influential Communications, Effective Meetings, Apology
            Training, ReWork with the Adaway Group (Whiteness at Work series)
          </p>
        </div>
      ),
    },
  },
  {
    graphic: {
      data: trainIcon,
      isText: false,
    },
    content: {
      title: 'Onboarding Allies',
      description: (
        <div css={[h4Body2Css, descriptionCss]}>
          <p css={bodyTextMarginCss}>
            In 2020, we introduced an Onboarding Allies program, which pairs a veteran Kenshin with
            a new employee to help introduce them to Kensho culture and social events, in addition
            to the individual team onboarding new Kenshins receive.
          </p>
          <p css={bodyTextMarginCss}>
            Allies make sure every new Kenshin feels welcome and meets people from other offices,
            teams and projects, and introduces them to fun Slack channels and social events, like
            our weekly drink cart.
          </p>
        </div>
      ),
    },
  },
  {
    graphic: {
      data: lightningIcon,
      isText: false,
    },
    content: {
      title: 'Lightning Talks',
      description: (
        <div css={[h4Body2Css, descriptionCss]}>
          <p css={bodyTextMarginCss}>
            One of our most popular and longest-running programs at Kensho is our Lighting Talk
            series. Employees gather once a month to present for 5 minutes on any topic—serious,
            lighthearted or somewhere in between—as long as it’s not related to work. Recent
            Lightning Talk topics have included Venezulean heritage, working in a mine, Purim
            celebrations, why you should have a pair of chopsticks, and sign language for video
            chats, just to name a few!
          </p>
        </div>
      ),
    },
  },
  {
    graphic: {
      data: chatIcon,
      isText: false,
    },
    content: {
      title: 'Courageous Conversations',
      description: (
        <div css={[h4Body2Css, descriptionCss]}>
          <p css={bodyTextMarginCss}>
            Also in 2020, our parent company, S&P Global, started a monthly Courageous Conversations
            series, which is open to all Kenshins. As part of the series, guest speakers have
            included well-known and respected authors and scholars, including Isabel Wilkerson,
            Professor Ibram X. Kendi, Dr. Robin DiAngelo and Dr. Michael Eric Dyson among many
            others. The sessions are used for discussing, understanding and confronting systemic
            issues that impact our society and our colleagues from underrepresented groups.
          </p>
        </div>
      ),
    },
  },
]

const benefitsGraphicContent: GraphicContent[] = [
  {
    graphic: {
      data: pramIcon,
      isText: false,
    },
    content: {
      title: (
        <>
          Generous
          <br /> Parental Leave
        </>
      ),
    },
  },
  {
    graphic: {
      data: lotusIcon,
      isText: false,
    },
    content: {
      title: (
        <>
          Wellbeing
          <br /> Benefit
        </>
      ),
    },
  },
  {
    graphic: {
      data: coinsIcon,
      isText: false,
    },
    content: {
      title: (
        <>
          Donation
          <br />
          Matching
        </>
      ),
    },
  },
  {
    graphic: {
      data: certificateIcon,
      isText: false,
    },
    content: {
      title: (
        <>
          Education
          <br /> Reimbursement
        </>
      ),
    },
  },
  {
    graphic: {
      data: familyIcon,
      isText: false,
    },
    content: {
      title: (
        <>
          Family Support
          <br /> (Child & Adult)
        </>
      ),
    },
  },
  {
    graphic: {
      data: whistleIcon,
      isText: false,
    },
    content: {
      title: (
        <>
          College
          <br /> Coaching
        </>
      ),
    },
  },
  {
    graphic: {
      data: vennIcon,
      isText: false,
    },
    content: {
      title: (
        <>
          ERGs at Kensho
          <br /> & S&P Global
        </>
      ),
    },
  },
]

const DEIContainerCss = css`
  margin: auto;
  position: relative;
  height: 100px;
`

const DEIContentCss = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  right: 0;
  top: -60px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    top: -20px;
  }
`

const splashImgCss = css`
  width: 130px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    width: 100px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 75px;
  }
`

const graphicContentGroupCss = css`
  margin-top: 20px;
  line-height: 1.2em;
`

const heroTitleCss = css`
  margin: 50px 0 170px 0;
`

const goalLeftPaddingCss = css`
  padding-left: 40px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding-bottom: 30px;
  }
`

const goalTwoColumnContentCss = css`
  margin-top: 60px;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    margin-top: 30px;
  }
`

const heroImageCss = css`
  background-position-y: 15%;
  background-image: url(${DEIHeroLarge});
  filter: contrast(120%) brightness(50%) grayscale(30%);

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    background-image: url(${DEIHeroMedium});
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    background-image: url(${DEIHeroSmall});
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    opacity: 1;
  }
`

const headerCss = css`
  color: ${COLORS.brand.light[300]};
`

const carouselContainerCss = css`
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    max-width: unset;
  }
`

export default function DEIPage(): JSX.Element {
  return (
    <Page
      title={
        <HeroSection imageCss={heroImageCss}>
          <WideContent>
            <AnimatedContent>
              <h1 css={[heroTitleCss, whiteTextCss]}>DEI</h1>
            </AnimatedContent>
          </WideContent>
        </HeroSection>
      }
    >
      <PageMetadata
        title="Diversity, Equity & Inclusion | Kensho"
        description="Creating a diverse environment, sense of belonging and a place where employees can bring their whole selves to work"
        image={{source: socialImg, height: 1250, width: 2500}}
      />

      <section css={[baseSectionCss, backgroundWhiteCss]}>
        <WideContent>
          <HeadingContent
            headerText="Creating a diverse environment, sense of belonging and a place where employees can 
            bring their whole selves to work"
            textStyle={[headerCss, centeredTextCss]}
          />
        </WideContent>
      </section>

      <section css={[baseSectionCss, whiteTextCss, backgroundGradientCss]}>
        <WideContent>
          <TopicHeadline text="DEI Goals & How We Measure Them" />
          <AnimatedContent css={h4Body1Css}>
            <p>
              Kensho has two primary goals for its DEI efforts with clear strategies for how to
              achieve and measure each.
            </p>
          </AnimatedContent>
        </WideContent>
        <TwoColumnContent
          css={goalTwoColumnContentCss}
          left={
            <div css={goalLeftPaddingCss}>
              <AnimatedContent>
                <h3 css={[h4Body1Css, hBoldCss]}>Goal 1</h3>
                <h3 css={[h4Body1Css, hBoldCss]}>
                  We work to ensure that every Kenshin feels comfortable and confident to bring
                  their full authentic self to work.
                </h3>
              </AnimatedContent>
            </div>
          }
          right={
            <AnimatedContent css={h4Body1Css}>
              <p css={[h4Body2Css, bodyTextMarginCss]}>
                We tackle this goal through our policies, benefits, flexible work schedules, special
                programs and culture activities within Kensho, several of which are described in
                more detail on this page.
              </p>

              <p css={[h4Body2Css, bodyTextMarginCss]}>
                We measure progress on this goal through retention rates as well as bi-annual survey
                results, in particular, responses to how strongly different Kenshins agree with
                statements such as “I can be myself at work,” “My team has a climate in which
                different perspectives are valued,” and “I work in an environment where people with
                diverse backgrounds can succeed.”
              </p>
            </AnimatedContent>
          }
        />
        <TwoColumnContent
          css={goalTwoColumnContentCss}
          left={
            <div css={goalLeftPaddingCss}>
              <AnimatedContent>
                <h3 css={[h4Body1Css, hBoldCss]}>Goal 2</h3>
                <h3 css={[h4Body1Css, hBoldCss]}>
                  We work to broaden Kensho’s diversity by recruiting candidates from groups that
                  are underserved in tech, specifically women and people of color.
                </h3>
              </AnimatedContent>
            </div>
          }
          right={
            <AnimatedContent css={h4Body1Css}>
              <p css={[h4Body2Css, bodyTextMarginCss]}>
                Our approach to achieving this goal includes campus recruiting at historically Black
                colleges and universities, diversity-focused referral bonuses, working with
                recruiting platforms that focus on candidate diversity, creating an interview
                process that seeks to minimize unconscious bias, and efforts to improve our offer
                acceptance rate for candidates from these groups. To measure progress, we track all
                stages of our recruiting pipeline—from application through the interview process,
                offer stage and onboarding.
              </p>
            </AnimatedContent>
          }
        />
      </section>

      <div css={[backgroundWhiteCss, DEIContainerCss]}>
        <div css={DEIContentCss}>
          <img css={splashImgCss} src={ensoDEILeft} alt="Enso left half" loading="lazy" />
          <img css={splashImgCss} src={ensoDEIRight} alt="Enso right half" loading="lazy" />
        </div>
      </div>

      <section css={[baseSectionCss, backgroundWhiteCss]}>
        <WideContent>
          <TopicHeadline text="DEI Programs & Initiatives" />
          <AnimatedContent css={h4Body1Css}>
            <p>
              DEI at Kensho is dynamic and evolving. Below are just a sample of the programs we
              sponsor.
            </p>
          </AnimatedContent>
          <AnimatedContent animation="fade-in">
            <GraphicContentGroup
              css={graphicContentGroupCss}
              graphicContent={programsGraphicContent}
              maxItemsPerRow={2}
            />
          </AnimatedContent>
        </WideContent>
      </section>

      <section css={[baseSectionCss, backgroundLightGrayCss]}>
        <WideContent>
          <TopicHeadline text="DEI Policies & Benefits" />
          <AnimatedContent css={h4Body1Css}>
            <p>
              In additon to the benefits above we also have policies to support the employment
              environment we want. They are Equal Employment Opportunity, Code of Business Ethics
              Training and Annual Unconscious Bias Training.
            </p>
          </AnimatedContent>
          <AnimatedContent animation="fade-in">
            <GraphicContentGroup
              css={graphicContentGroupCss}
              graphicContent={benefitsGraphicContent}
            />
          </AnimatedContent>
        </WideContent>
      </section>

      <section css={[carouselContainerCss, backgroundWhiteCss]}>
        <WideContent>
          <AnimatedContent>
            <TestimonialsCarousel testimonials={deiLeadershipData} />
          </AnimatedContent>
        </WideContent>
      </section>
    </Page>
  )
}
