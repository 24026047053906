import {css, keyframes} from '@emotion/react'

import TopicHeadline from '../../../components/TopicHeadline'
import {WideContent} from '../../../components/content'
import {backgroundWhiteCss, baseSectionCss, blackTextCss} from '../../../styles/common'

import classifySamples from './Samples'

const contentContainerCss = css`
  width: 100%;
  overflow: hidden;
`

const sampleContainerCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 100%;
  width: 100%;
`

const translateSamples = keyframes`
  from,
  ${(11.175 * 100) / 37.275}% {
    transform: translateX(0%);
  }

  ${(11.675 * 100) / 37.275}%,
  ${(18.095 * 100) / 37.275}% {
    transform: translateX(-100%);
  }

  ${(18.595 * 100) / 37.275}%,
  ${(25.8975 * 100) / 37.275}% {
    transform: translateX(-200%);
  }

  ${(26.3975 * 100) / 37.275}%,
  to {
    transform: translateX(-300%);
  }
`

const contentCss = css`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;

  animation: ${translateSamples} 37.275s infinite linear;
`

export default function SampleSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundWhiteCss]}>
      <WideContent>
        <TopicHeadline text="See for yourself" css={blackTextCss} />
        <div css={contentContainerCss}>
          <div css={contentCss}>
            {classifySamples.map((sample) => (
              <div css={sampleContainerCss} key={sample.title}>
                {sample.content}
              </div>
            ))}
          </div>
        </div>
      </WideContent>
    </section>
  )
}
