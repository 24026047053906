import {css} from '@emotion/react'

import ButtonLink from '../../components/Button/ButtonLink'
import Link from '../../components/Link'
import TopicHeadline from '../../components/TopicHeadline'
import {AnimatedContent, TwoColumnContent} from '../../components/content'
import {BREAKPOINT_LARGE, BREAKPOINT_MEDIUM, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {
  backgroundGradientCss,
  centeredTextCss,
  baseSectionCss,
  h3SubheadlineCss,
  h3TitleCss,
  h4Body1Css,
  hBoldCss,
  sectionHeadingMarginCss,
  whiteTextCss,
} from '../../styles/common'

const alignCss = css`
  align-items: center;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    align-items: stretch;
  }
`

const leftTextCss = css`
  margin: 30px 0px;
`

const rightCss = css`
  padding-left: 120px;
`

const rightContentCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    gap: 12px;
  }
`

const buttonCss = css`
  height: 65px;
  width: 440px;
  border: 1px solid #fff;
  color: white;
  font-size: 24px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-top: 40px;
    max-width: 710px;
    width: 100%;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-top: 30px;
  }
`

const underlineCss = css`
  text-decoration: underline;
`

export default function BECRSSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundGradientCss]}>
      <TwoColumnContent
        css={alignCss}
        left={
          <>
            <TopicHeadline css={whiteTextCss} text="Product Update" />
            <AnimatedContent>
              <h3 css={[sectionHeadingMarginCss, h3TitleCss, hBoldCss, whiteTextCss]}>
                Discover Kensho Link’s most recent enhancement
              </h3>
            </AnimatedContent>
            <AnimatedContent>
              <p css={[leftTextCss, h4Body1Css, whiteTextCss]}>
                Kensho Link recently integrated with S&P Global Market Intelligence’s dataset{' '}
                <Link
                  css={[whiteTextCss, underlineCss]}
                  to="https://www.marketplace.spglobal.com/en/datasets/business-entity-cross-reference-service-becrs-(4)"
                >
                  Business Entity Cross Reference Service
                </Link>{' '}
                (BECRS). Customers will use Kensho Link to map company entities to the Capital IQ
                database and leverage BECRS expansive coverage of third-party identifiers and
                corporate hierarchy navigation capabilities. The integration enables a one-step
                process, simplifying user workflows and enhancing efficiency.
              </p>
            </AnimatedContent>
          </>
        }
        rightContentCss={rightCss}
        right={
          <AnimatedContent animation="fade-in">
            <div css={[rightContentCss, centeredTextCss]}>
              <p css={[h3SubheadlineCss, whiteTextCss, hBoldCss]}>Connect with us to learn more!</p>
              <p css={[h3SubheadlineCss, whiteTextCss]}>For sales inquiries or review a demo:</p>
              <ButtonLink
                to="mailto:sales@kensho.com?subject=Link&body=Link%20x%20BECRS%3A%20Product%20Information%20or%20a%20free%20trial%2Fdemo"
                variant="secondary"
                css={buttonCss}
              >
                Request a demo
              </ButtonLink>
              <p css={[h3SubheadlineCss, whiteTextCss]}>Read More:</p>

              <Link
                to="https://static.kensho.com/user-content/Kensho-Link-BECRS.pdf"
                css={[h4Body1Css, whiteTextCss, underlineCss]}
              >
                Download PDF
              </Link>
            </div>
          </AnimatedContent>
        }
      />
    </section>
  )
}
