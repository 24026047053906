import {css} from '@emotion/react'

import kenshoLogo from '../../assets/logos/kensho-black.svg'
import ButtonLink from '../../components/Button/ButtonLink'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {
  hBoldCss,
  h4Body2Css,
  labelCss,
  h3TitleCss,
  bulletedListCss,
  bulletedListItemCss,
  darkBlueTextCss,
} from '../../styles/common'

const cardCss = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  background: #fff;
  border: 1px solid #e4e5e5;

  max-width: 444px;
  padding: 40px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    max-width: 388px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    max-width: 100%;
    padding: 40px 20px;
  }
`

const descriptionCss = css`
  margin: 0;
  height: 3em;
  margin-bottom: 30px;
`

const titleLogoCss = css`
  width: 70px;
`

const bodyCss = css`
  padding-top: 40px;
  padding-bottom: 40px;
`

const buttonsContainerCss = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    flex-direction: column;
    justify-content: flex-start;
    max-width: 100%;
  }
`

const freeTrialCss = css`
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    width: 100%;
  }
`

const learnMoreCss = css`
  margin-left: 30px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-left: 0;
    margin-top: 10px;
  }
`

interface ProductCardProps {
  description: string
  productName: string
  bodyText: string
  features: string[]
  learnMoreLink: string
  freeTrialLink?: string
}

export default function ProductCard({
  description,
  productName,
  bodyText,
  features,
  learnMoreLink,
  freeTrialLink,
}: ProductCardProps): JSX.Element {
  return (
    <div css={cardCss}>
      <div>
        <h5 css={[labelCss, hBoldCss, darkBlueTextCss, descriptionCss]}>{description}</h5>
        <img src={kenshoLogo} alt="Kensho" css={titleLogoCss} />
        <h3 css={[h3TitleCss, hBoldCss]}>{productName}</h3>
        <div css={bodyCss}>
          <p css={h4Body2Css}>{bodyText}</p>
          <ul css={bulletedListCss}>
            {features.map((featureText) => (
              <li key={featureText} css={[h4Body2Css, bulletedListItemCss]}>
                {featureText}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div css={buttonsContainerCss}>
        {freeTrialLink !== undefined ? (
          <ButtonLink to={freeTrialLink} variant="secondary" mode="light" css={freeTrialCss}>
            Free Trial
          </ButtonLink>
        ) : null}
        <ButtonLink
          to={learnMoreLink}
          variant="minimal"
          mode="light"
          css={freeTrialLink !== undefined ? learnMoreCss : null}
        >
          Learn more
        </ButtonLink>
      </div>
    </div>
  )
}
