import {captureMessage, captureException, withScope} from '@sentry/core'
import {severityLevelFromString} from '@sentry/utils'

import {Level, Transport} from '../types'

import transportCustom from './transportCustom'

interface TransportSentryOptions {
  /** Log levels by which to filter. */
  levels?: Level[]
}

/**
 * Creates a transport which sends logs to a Sentry server. Uses a globally-initialized Sentry SDK instance.
 *
 * @see [Sentry documentation](https://docs.sentry.io)
 */
export default function transportSentry(options: TransportSentryOptions = {}): Transport {
  const {levels} = options
  return transportCustom(levels, (level, message, context) => {
    withScope((scope) => {
      scope.setLevel(severityLevelFromString(level))
      if (context) scope.setExtras(context)
      if (typeof message === 'string') captureMessage(message)
      else captureException(message)
    })
  })
}
