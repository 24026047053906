import {useEffect, useState} from 'react'

import BaseCarousel, {BaseCarouselProps} from './BaseCarousel'

/**
 * Returns a (stable) random number within [0, max).
 * Returns null on the first render.
 * Then calculates a random integer in [0, max) and returns that random number.
 * Calculates a new random number any time `max` changes.
 *
 * @param max Limit value for the random number.
 */
function useRandomValue(max: number): number | null {
  const [value, setValue] = useState<number | null>(null)

  useEffect(() => {
    setValue(Math.floor(Math.random() * max))
  }, [max])

  return value
}

type CarouselProps<T> = Omit<BaseCarouselProps<T>, 'initialIndex'>

export default function Carousel<T>(props: CarouselProps<T>): JSX.Element | null {
  const {slides, ...rest} = props
  const initialIndex = useRandomValue(slides.length)

  return initialIndex === null ? null : (
    <BaseCarousel initialIndex={initialIndex} slides={slides} {...rest} />
  )
}
