import {matchRoutes, Navigate, renderMatches, useLocation} from 'react-router-dom'

import routes, {EnhancedRouteMatch} from './routes'

export default function AppRouter(): JSX.Element | null {
  const location = useLocation()
  const matches = matchRoutes(routes, location) as EnhancedRouteMatch[] | null
  const redirect = matches?.find((match) => match.route.redirectTo)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return redirect ? <Navigate to={redirect.route.redirectTo!} replace /> : renderMatches(matches)
}
