import {css} from '@emotion/react'

import compareIcon from '../../assets/icons/compare.svg'
import computeIcon from '../../assets/icons/compute-stars.svg'
import lookUpIcon from '../../assets/icons/magnifying-glass.svg'
import rankIcon from '../../assets/icons/rank.svg'
import LinkChartSmall from '../../assets/solutions/link-chart-small.svg'
import LinkChart from '../../assets/solutions/link-chart.svg'
import GraphicContentGroup from '../../components/GraphicContentGroup'
import TopicHeadline from '../../components/TopicHeadline'
import {
  AnimatedContent,
  TwoColumnContent,
  WideContent,
  WIDTH_LARGE,
  WIDTH_XLARGE,
} from '../../components/content'
import {
  BREAKPOINT_LARGE,
  BREAKPOINT_MEDIUM,
  BREAKPOINT_SMALL,
  BREAKPOINT_XLARGE,
} from '../../styles/breakpoints'
import {
  h3TitleCss,
  hBoldCss,
  blackTextCss,
  backgroundWhiteCss,
  h3SubheadlineCss,
  darkBlueTextCss,
  h4Body2Css,
  centeredTextCss,
  baseSectionCss,
  sectionHeadingMarginCss,
} from '../../styles/common'
import {HeadingContent} from '../../components/headings'

const contentCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 64px;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    padding-bottom: 40px;
  }
`

const contentWidthCss = css`
  max-width: 908px;
`

const paragraphTextCss = css`
  color: rgb(0, 0, 0, 0.95);
`

const infoRowCss = css`
  padding: 24px 0px;
  align-items: center;
`

const infoTopRowCss = css`
  margin-bottom: 32px;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    margin-bottom: 16px;
  }
`

const infoBottomRowCss = css`
  margin-top: 32px;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    margin-top: 16px;
  }
`

const infoWrapperCss = css`
  flex: 1 auto;
`

const LEFT_WIDTH = 390
const infoLeftCss = css`
  max-width: ${LEFT_WIDTH}px;
  width: ${LEFT_WIDTH}px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    width: auto;
  }
`

const infoRightCss = css`
  padding-left: 80px;
  max-width: ${WIDTH_XLARGE - LEFT_WIDTH}px;
  width: ${WIDTH_XLARGE - LEFT_WIDTH}px;

  @media (max-width: ${BREAKPOINT_XLARGE}px) {
    max-width: ${WIDTH_LARGE - LEFT_WIDTH}px;
    width: ${WIDTH_LARGE - LEFT_WIDTH}px;
  }

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-top: 50px;
    width: auto;
  }
`

const headingCss = css`
  margin-bottom: 8px;
`

const paragraphSpacingCss = css`
  margin-bottom: 26px;
`

export default function ProcessSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundWhiteCss]}>
      <WideContent>
        <TopicHeadline css={blackTextCss} text="Process" />
        <HeadingContent
          headerText="How does Kensho Link work?"
          css={[sectionHeadingMarginCss, centeredTextCss]}
        />
        <AnimatedContent>
          <div css={contentCss}>
            <p css={[contentWidthCss, h3SubheadlineCss, paragraphTextCss]}>
              When a user submits a database record, Kensho Link identifies which records in S&amp;P
              Global’s databases match with your entity. Our service will:
            </p>
          </div>
        </AnimatedContent>
      </WideContent>
      <TwoColumnContent
        css={[infoRowCss, infoTopRowCss]}
        leftWrapperCss={infoWrapperCss}
        rightWrapperCss={infoWrapperCss}
        leftContentCss={infoLeftCss}
        rightContentCss={infoRightCss}
        left={
          <AnimatedContent>
            <h3 css={[h3TitleCss, hBoldCss, darkBlueTextCss, headingCss]}>01</h3>
            <p css={[blackTextCss, h4Body2Css, paragraphSpacingCss]}>
              First, we’ve designed our model to enable smart comparisons between your data and
              S&amp;P Global’s dataset.
            </p>
            <p css={[blackTextCss, h4Body2Css, paragraphSpacingCss]}>
              We use best practices from natural language processing (NLP) to compute the similarity
              of names and addresses much more effectively than traditional keyword matching or
              fuzzy logic.
            </p>
            <p css={[blackTextCss, h4Body2Css, paragraphSpacingCss]}>
              We use state-of-the-art tree-based models to learn the optimal ways to weight the
              similarities of different fields against one another.
            </p>
            <p css={[blackTextCss, h4Body2Css]}>
              This allows us to create an advanced approach that is less susceptible to edge cases
              than a rules-based model.
            </p>
          </AnimatedContent>
        }
        right={
          <AnimatedContent animation="fade-in">
            <GraphicContentGroup
              maxItemsPerRow={2}
              graphicContent={[
                {
                  graphic: {data: lookUpIcon, isText: false},
                  content: {
                    title: '1. Look Up',
                    description:
                      'Look at every available attribute of your data (name, address, country, etc)',
                  },
                },
                {
                  graphic: {data: compareIcon, isText: false},
                  content: {
                    title: '2. Compare',
                    description: 'Compare it to all candidates in CapIQ',
                  },
                },
                {
                  graphic: {data: computeIcon, isText: false},
                  content: {
                    title: '3. Compute',
                    description:
                      'Compute a Link Score from all of the information provided and the associated mappings',
                  },
                },
                {
                  graphic: {data: rankIcon, isText: false},
                  content: {
                    title: '4. Rank',
                    description: 'Provide up to five highest quality candidate links',
                  },
                },
              ]}
            />
          </AnimatedContent>
        }
      />
      <TwoColumnContent
        css={[infoRowCss, infoBottomRowCss]}
        leftWrapperCss={infoWrapperCss}
        rightWrapperCss={infoWrapperCss}
        leftContentCss={infoLeftCss}
        rightContentCss={infoRightCss}
        left={
          <AnimatedContent>
            <h3 css={[h3TitleCss, hBoldCss, darkBlueTextCss, headingCss]}>02</h3>
            <p css={[blackTextCss, h4Body2Css, paragraphSpacingCss]}>
              Second, our machine learning is backed by S&amp;P Global’s world-class data and
              experience linking datasets, something other AI data companies typically cannot
              leverage.
            </p>
            <p css={[blackTextCss, h4Body2Css, paragraphSpacingCss]}>
              S&amp;P Global has decades of experience linking external data sources to their
              databases, and through machine learning we can stand on the lessons from every past
              content set ingestion project when comparing your dataset to S&amp;P Global’s.
            </p>
            <p css={[blackTextCss, h4Body2Css]}>
              A linking service can only be as good as the data it is linking to and with over 20
              million companies and growing, S&amp;P Global’s Capital IQ dataset represents a gold
              standard dataset that will power the links you need to gain the most value from your
              data.
            </p>
          </AnimatedContent>
        }
        right={
          <AnimatedContent animation="fade-in">
            <img
              css={css`
                @media (max-width: ${BREAKPOINT_SMALL}px) {
                  display: none;
                }
              `}
              width="100%"
              src={LinkChart}
              alt="Link chart"
              loading="lazy"
            />
            <img
              css={css`
                @media (min-width: ${BREAKPOINT_SMALL}px) {
                  display: none;
                }
              `}
              width="100%"
              src={LinkChartSmall}
              alt="Mobile Link Chart"
              loading="lazy"
            />
          </AnimatedContent>
        }
      />
    </section>
  )
}
