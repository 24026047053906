import {css} from '@emotion/react'
import {IconChevronRight} from '@kensho/icons'

import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import bulletIcon from '../../assets/icons/list-bullet.svg'
import ButtonLink from '../../components/Button/ButtonLink'
import TopicHeadline from '../../components/TopicHeadline'
import {WideContent, TwoColumnContent, AnimatedContent} from '../../components/content'
import {
  h3TitleCss,
  h3SubheadlineCss,
  h4Body1Css,
  h4Body2Css,
  hBoldCss,
  blackTextCss,
  backgroundWhiteCss,
  baseSectionCss,
} from '../../styles/common'
import {URLS} from '../../constants'

const LEFT_VIDEO_IMAGE = 'https://static.kensho.com/video/ScribeAI.jpg'
const LEFT_VIDEO_MP4 = 'https://static.kensho.com/video/ScribeAI-H265-400k.mp4'
const LEFT_VIDEO_WEBM_AV1 = 'https://static.kensho.com/video/ScribeAI-AV1-400k.webm'
const LEFT_VIDEO_WEBM_VP9 = 'https://static.kensho.com/video/ScribeAI-VP9-400k.webm'

const RIGHT_VIDEO_IMAGE = 'https://static.kensho.com/video/HumanIntheloop.jpg'
const RIGHT_VIDEO_MP4 = 'https://static.kensho.com/video/HumanIntheloop-H265-400k.mp4'
const RIGHT_VIDEO_WEBM_AV1 = 'https://static.kensho.com/video/HumanIntheloop-AV1-400k.webm'
const RIGHT_VIDEO_WEBM_VP9 = 'https://static.kensho.com/video/HumanIntheloop-VP9-400k.webm'

const contentSpacingCss = css`
  margin-bottom: 90px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-bottom: 60px;
  }
`

const titleCss = css`
  margin-bottom: 45px;
`

const listCss = css`
  list-style: url(${bulletIcon});
  padding: 25px 0px 30px 22px;
  margin: 0;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding-bottom: 20px;
  }
`

const itemPaddingCss = css`
  padding-left: 4px;
  padding-bottom: 20px;
`

const listItemCss = [itemPaddingCss, h4Body2Css, blackTextCss]

const videoContainerCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 631px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-top: 60px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-top: 55px;
    height: 414px;
  }
`

const videoCss = css`
  height: 631px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 414px;
  }
`

const orderFlipCss = css`
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    order: 1;
  }
`

const videoPaddingCss = css`
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 0px;
  }
`

const buttonCss = css`
  height: 70px;
  width: 355px;
  border: 2px solid #007694;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    width: 100%;
  }
`

const buttonTitleCss = css`
  display: flex;
  align-items: center;
  padding-left: 30px;
`

const buttonTextCss = css`
  margin-right: 15px;
`

export default function ProductSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundWhiteCss]}>
      <WideContent>
        <TopicHeadline text="Our Scribe Products" css={blackTextCss} />
      </WideContent>
      <TwoColumnContent
        css={contentSpacingCss}
        left={
          <>
            <AnimatedContent>
              <h3 css={[titleCss, h3TitleCss, hBoldCss, blackTextCss]}>Scribe HITL</h3>
              <p css={[h3SubheadlineCss, blackTextCss]}>
                Kensho Scribe’s human-in-the-loop (HITL) solution with professional review is a
                best-in-class professional transcription service tailored to business and finance.
              </p>
              <ul css={listCss}>
                <li css={listItemCss}>
                  Process extensive audio volume, with professional review, in record time.
                </li>
                <li css={listItemCss}>
                  Leverage our team of in-house transcriptionists and editors, each with extensive
                  training and specialized in industry- and country-specific vocabulary.
                </li>
                <li css={listItemCss}>
                  Be assured of the safety of your information – our employees are required to
                  attend security training and have signed a confidentiality agreement.
                </li>
                <li css={listItemCss}>
                  Transcribe with a trusted team. We have 10+ years of experience working on highly
                  sensitive documents with consistent best-in-class quality.
                </li>
              </ul>
            </AnimatedContent>
            <AnimatedContent animation="fade-in">
              <ButtonLink
                to="mailto:sales@kensho.com?subject=Scribe"
                variant="secondary"
                css={[buttonCss, h4Body1Css]}
              >
                <div css={buttonTitleCss}>
                  <p css={buttonTextCss}>Talk to us</p>
                  <IconChevronRight size={65} />
                </div>
              </ButtonLink>
            </AnimatedContent>
          </>
        }
        rightContentCss={videoPaddingCss}
        right={
          <div css={videoContainerCss}>
            <AnimatedContent animation="fade-in">
              <video css={videoCss} autoPlay loop muted poster={RIGHT_VIDEO_IMAGE}>
                <source src={RIGHT_VIDEO_WEBM_AV1} type='video/webm; codecs="av01.0.05M.08"' />
                <source src={RIGHT_VIDEO_WEBM_VP9} type='video/webm; codecs="vp9"' />
                <source src={RIGHT_VIDEO_MP4} type="video/mp4" />
                <img src={RIGHT_VIDEO_IMAGE} alt="Greek Statue Animation" loading="lazy" />
              </video>
            </AnimatedContent>
          </div>
        }
      />
      <TwoColumnContent
        leftWrapperCss={orderFlipCss}
        leftContentCss={videoPaddingCss}
        left={
          <div css={videoContainerCss}>
            <AnimatedContent animation="fade-in">
              <video css={videoCss} autoPlay loop muted poster={LEFT_VIDEO_IMAGE}>
                <source src={LEFT_VIDEO_WEBM_AV1} type='video/webm; codecs="av01.0.05M.08"' />
                <source src={LEFT_VIDEO_WEBM_VP9} type='video/webm; codecs="vp9"' />
                <source src={LEFT_VIDEO_MP4} type="video/mp4" />
                <img src={LEFT_VIDEO_IMAGE} alt="Animated Grid" loading="lazy" />
              </video>
            </AnimatedContent>
          </div>
        }
        right={
          <>
            <AnimatedContent>
              <h3 css={[titleCss, h3TitleCss, hBoldCss, blackTextCss]}>Scribe AI</h3>
              <p css={[h3SubheadlineCss, blackTextCss]}>
                Kensho Scribe AI is a top-tier automated transcription tool for all audio, with
                specialization in business and finance.
              </p>
              <ul css={listCss}>
                <li css={listItemCss}>
                  Transcribe audio files into human- and machine-readable text with sophisticated
                  deep learning models.
                </li>
                <li css={listItemCss}>
                  Handle the complexities of business audio, including industry jargon, accents,
                  numbers, currencies and product names, as well as the nuances of spoken language,
                  including mumbling, stuttering and self correction.
                </li>
                <li css={listItemCss}>
                  Leverage S&amp;P Global’s history of high-quality transcripts, including more than
                  100,000 hours of domain-specific audio and associated text.
                </li>
              </ul>
            </AnimatedContent>
            <AnimatedContent animation="fade-in">
              <ButtonLink
                to={`${URLS.FREE_TRIAL}/scribe`}
                variant="secondary"
                css={[buttonCss, h4Body1Css]}
              >
                <div css={buttonTitleCss}>
                  <p css={buttonTextCss}>Free Trial</p>
                  <IconChevronRight size={65} />
                </div>
              </ButtonLink>
            </AnimatedContent>
          </>
        }
      />
    </section>
  )
}
