export interface WorkdayJob {
  id: string
  title: string
  url: string
  locations: string[]
}

export default function fetchWorkdayJobs(): Promise<WorkdayJob[]> {
  return fetch('/api/jobs').then((response) => response.json())
}
