import {css} from '@emotion/react'

const VIDEO_IMAGE = 'https://static.kensho.com/home/video/gradient-gel-enso.jpg'
const VIDEO_MP4 = 'https://static.kensho.com/home/video/gradient-gel-enso.mp4'
const VIDEO_WEBM_AV1 = 'https://static.kensho.com/home/video/gradient-gel-enso-av1.webm'
const VIDEO_WEBM_VP9 = 'https://static.kensho.com/home/video/gradient-gel-enso-vp9.webm'

const containerCss = css`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
`

const videoCss = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200%;
  height: 200%;
`

export default function EnsoVideo(): JSX.Element {
  return (
    <div css={containerCss}>
      <video css={videoCss} autoPlay loop muted poster={VIDEO_IMAGE}>
        <source src={VIDEO_WEBM_AV1} type='video/webm; codecs="av01.0.05M.08"' />
        <source src={VIDEO_WEBM_VP9} type='video/webm; codecs="vp9"' />
        <source src={VIDEO_MP4} type="video/mp4" />
        <img src={VIDEO_IMAGE} alt="Mobile Fallback" />
      </video>
    </div>
  )
}
