import {css} from '@emotion/react'
import {useState, useRef} from 'react'

import playIcon from '../../assets/icons/play-video.svg'
import {WideContent} from '../../components/content'
import {HEADER_HEIGHT, SHORT_HEADER_HEIGHT} from '../../components/Page/Header/constants'
import {BREAKPOINT_LARGE, BREAKPOINT_MEDIUM, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {centeredTextCss, h3TitleCss, hBoldCss, whiteTextCss} from '../../styles/common'

const VIDEO_IMAGE = 'https://static.kensho.com/home/video/nerd-pitch.jpg'
const VIDEO_MP4 = 'https://static.kensho.com/home/video/nerd-pitch.mp4'
const VIDEO_WEBM_AV1 = 'https://static.kensho.com/home/video/nerd-pitch-av1.webm'
const VIDEO_WEBM_VP9 = 'https://static.kensho.com/home/video/nerd-pitch-vp9.webm'

const linkTargetContainerCss = css`
  position: relative;
`

const linkTargetCss = css`
  position: absolute;
  top: -${HEADER_HEIGHT + 50}px;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    top: -${SHORT_HEADER_HEIGHT + 50}px;
  }
`

const containerCss = css`
  display: flex;
  position: relative;
  padding: 0;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding: 0;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 0;
  }
`

const overlayCss = css`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0, 0, 0, 0.8);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const titleCss = css`
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 300px;
    font-size: 36px;
  }
`

const buttonCss = css`
  padding: 0px;
  border: none;

  &:hover {
    background: none;
  }
`

const imageCss = css`
  margin-top: 40px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-top: 20px;
  }
`

const videoCss = css`
  width: 100%;
  height: 100%;
`

export default function VideoSection(): JSX.Element {
  const [isPaused, setIsPaused] = useState(true)
  const videoRef = useRef<HTMLVideoElement>(null)
  const playVideo = (): void => {
    videoRef.current?.play()
    setIsPaused(false)
  }
  const pauseVideo = (): void => {
    videoRef.current?.pause()
    setIsPaused(true)
  }

  return (
    <section>
      <div css={linkTargetContainerCss}>
        <div css={linkTargetCss} id="kensho-nerd-demo-video" />
      </div>
      <WideContent css={containerCss}>
        {isPaused && (
          <div css={overlayCss}>
            <h3 css={[titleCss, h3TitleCss, hBoldCss, centeredTextCss, whiteTextCss]}>
              NERD Overview
            </h3>
            <button type="button" onClick={playVideo} css={buttonCss}>
              <img src={playIcon} alt="Play Video" css={imageCss} />
            </button>
          </div>
        )}
        <video controls onPause={pauseVideo} ref={videoRef} css={videoCss} poster={VIDEO_IMAGE}>
          <source src={VIDEO_WEBM_AV1} type='video/webm; codecs="av01.0.05M.08"' />
          <source src={VIDEO_WEBM_VP9} type='video/webm; codecs="vp9"' />
          <source src={VIDEO_MP4} type="video/mp4" />
          <img src={VIDEO_IMAGE} alt="NERD Pitch" loading="lazy" />
        </video>
      </WideContent>
    </section>
  )
}
