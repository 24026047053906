import {useRef, useDebugValue} from 'react'

/**
 * Initializes a value which persists for the lifetime of the component.
 * It can be used for inter-op with imperative code, such as to create an instance of a class.
 * It can also be used to compose other stable values.
 *
 * **This hook breaks out of the React data flow. Please be very careful when using it.**
 *
 * Semantically equivalent to, but less expensive than:
 * ```ts
 * const [value] = useState(initializeValue)
 * ```
 *
 * @param initializeValue An initializer for the value.
 * @returns The initialized value.
 *
 * @see https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
 * @see https://tacklebox.beta-p.kensho.com/useStableValue
 */
export default function useStableValue<T>(initializeValue: () => T): T {
  const ref = useRef<{value: T} | null>(null)
  if (ref.current === null) ref.current = {value: initializeValue()}
  useDebugValue(ref.current.value)
  return ref.current.value
}
