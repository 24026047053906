import {css} from '@emotion/react'

import Accordion from '../../components/Accordion'
import Link from '../../components/Link'
import {WideContent} from '../../components/content'
import {SPG_MARKETPLACE_UTM_QUERY_PARAMS, URLS} from '../../constants'
import {BREAKPOINT_LARGE} from '../../styles/breakpoints'
import {
  h3TitleCss,
  hBoldCss,
  backgroundWhiteCss,
  blackTextCss,
  h4Body2Css,
  centeredTextCss,
  baseSectionCss,
} from '../../styles/common'

const avoidWrapCss = css`
  display: inline-block;
`

const marginCss = css`
  margin-bottom: 24px;
`

const titleCss = css`
  margin-bottom: 80px;
  align-self: center;
`

const contentCss = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1000px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    max-width: 815px;
  }
`

const verticalCss = css`
  display: flex;
  flex-direction: column;
`

const accordionItems = [
  {
    content: 'What is AI linking?',
    expandedContent:
      'AI Linking is the process of joining two datasets together using machine learning models when they do not share a common unique identifier.',
  },
  {
    content: 'Why should I use AI linking?',
    expandedContent:
      'Kensho Link makes it simple to identify which records in S&P Global’s CapIQ database match up with your database. It looks at the attributes of your data (company name, address, country, etc), compares them to candidates from the CapIQ database, and computes a score based on the information you’ve provided and the hundreds of thousands of hand-labeled annotations we’ve trained our models. This Link Score quantifies how good a match each of the candidates is. ',
  },
  {
    content: 'What makes Kensho Link the best AI linking service?',
    expandedContent: (
      <div css={verticalCss}>
        <p css={[h4Body2Css, blackTextCss, marginCss]}>
          Kensho Link’s strength comes from two places: its model design and the data backing it.
        </p>
        <p css={[h4Body2Css, blackTextCss, marginCss]}>
          Link’s design: We’ve designed our Link model to enable smart comparisons between your data
          and S&P Global’s. We use best practices from NLP to compute the similarity of names and
          addresses in ways more sophisticated than just keyword matching, (e.g.,{' '}
          <span css={avoidWrapCss}>TF-IDF</span> based methods), and we use{' '}
          <span css={avoidWrapCss}>state-of-the-art</span> tree-based models to learn the optimal
          ways to weight the similarities of different fields against one another. This allows us to
          create a robust approach that is much more accurate with difficult examples than a
          rules-based model.
        </p>
        <p css={[h4Body2Css, blackTextCss]}>
          Link’s data: Our industry-leading machine learning is backed by world-class data. S&P
          Global has decades of experience linking external data sources to their databases, and
          through machine learning, we leverage the lessons from every past content set ingestion
          project when comparing your dataset to S&P’s. Because S&P Global’s data universe is so
          broad — over 20 million companies and growing — Link is able to reliably provide the links
          you need to make your data actionable.
        </p>
      </div>
    ),
  },
  {
    content: 'What does the Kensho Link Score mean?',
    expandedContent:
      'The Link Score represents the quality of a particular pairwise match between your input and a matched CapIQ CompanyID. Higher scores indicate better matches.',
  },
  {
    content: 'How do I use the Kensho Link score?',
    expandedContent: (
      <div css={verticalCss}>
        <p css={[h4Body2Css, blackTextCss, marginCss]}>
          Kensho Link gives you a score associated with each link prediction. This score reflects
          the strength of the model’s prediction for a given matched pair. You can request up to the
          top five results for each link. By giving up to five predictions, Kensho Link demonstrates
          what machines do best — sorting through millions of records to surface the top few — and
          empowers you to do what people do best — make nuanced decisions about specific data
          points.
        </p>
        <p css={[h4Body2Css, blackTextCss]}>
          With Kensho Link, we make it simple for you to sort out which companies we have coverage
          for, which ones we can help you enrich, and which ones you might need to clean up. The
          link scores provide your team with the transparency and insight to develop trust in the
          model and its predictions, as well as cater the results you receive toward your own use
          cases.
        </p>
      </div>
    ),
  },
  {
    content: 'How does Kensho Link weigh various inputs?',
    expandedContent:
      'Kensho Link is a machine learning service that uses state-of-the-art technology combined with S&P Global’s rich data ecosystem to make predictions for links you might have. The predictions made by Link are determined by a vast set of training data and the implicit patterns therein, not by explicit rules or set weights. We’ve leveraged hundreds of thousands of hand-annotated samples for our training data to provide the highest quality links for your database. ',
  },
  {
    content: 'What model do you use?',
    expandedContent: `We use state-of-the-art machine learning methods to make our predicted links. Our linking has two key pieces: how we compute similarity between two records, and how score links based upon those similarities. We use Natural Language Processing to compute similarity in ways smarter than simple keyword matching, and in order to weigh the different similarities against one another, we use a gradient-boosted decision tree, LightGBM, which is both fast and accurate. For specifics, we use both word- and character-based similarity metrics to handle our text-based input fields, which allows us to detect misspellings and weight terms by their relative rarity (i.e., the term "Apple" is more important than the term "Incorporated").`,
  },
  {
    content: 'Can I see a sample of the Linking results?',
    expandedContent: (
      <p css={[h4Body2Css, blackTextCss]}>
        Yes, <Link to={`${URLS.FREE_TRIAL}/link`}>start your free trial</Link> or talk to your S&P
        Global relationship manager to discuss running a sample.
      </p>
    ),
  },
  {
    content: 'Where can I interact with a demo of the Link service?',
    expandedContent: (
      <p css={[h4Body2Css, blackTextCss]}>
        See the{' '}
        <Link
          to={`https://www.marketplace.spglobal.com/en/solutions/kensho-link-(2d7177f8-ada7-4c1c-b360-b33951f57248)?${SPG_MARKETPLACE_UTM_QUERY_PARAMS}`}
        >
          Kensho Link Solution page on Marketplace
        </Link>
        .
      </p>
    ),
  },
]

export default function FAQSection(): JSX.Element | null {
  return (
    <section css={[baseSectionCss, backgroundWhiteCss]}>
      <h3 css={[titleCss, centeredTextCss, h3TitleCss, hBoldCss, blackTextCss]}>
        Frequently Asked Questions
      </h3>
      <WideContent css={contentCss}>
        <Accordion defaultExpanded accordionContent={accordionItems} />
      </WideContent>
    </section>
  )
}
