import {css} from '@emotion/react'

import {TwoColumnContent, AnimatedContent} from '../../components/content'
import TopicHeadline from '../../components/TopicHeadline'
import {
  h3TitleCss,
  h4Body1Css,
  hBoldCss,
  whiteTextCss,
  backgroundGradientCss,
  backgroundMidGrayCss,
  sectionHeadingMarginCss,
} from '../../styles/common'
import ClassifyImageLarge from '../../assets/photos/classify-intro-large.jpg'
import ClassifyImageSmall from '../../assets/photos/classify-intro-small.jpg'
import {BREAKPOINT_XLARGE, BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'

const leftCss = css`
  padding: 50px 0;
`

const leftTextCss = css`
  line-height: 42px;
`

const imageContainerCss = css`
  padding: 0;
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding: 0;
    max-width: unset;
  }
`

const fullSizeCss = css`
  height: 100%;
  width: 100%;
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    height: 850px;
  }
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 500px;
  }
`

const imageCss = css`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

export default function IntroSection(): JSX.Element {
  return (
    <section>
      <TwoColumnContent
        leftWrapperCss={[leftCss, backgroundGradientCss]}
        left={
          <>
            <TopicHeadline text="About Classify" css={whiteTextCss} />
            <AnimatedContent>
              <h3 css={[sectionHeadingMarginCss, h3TitleCss, hBoldCss, whiteTextCss]}>
                Trying to make sense of hundreds, thousands or even millions of documents?
              </h3>
            </AnimatedContent>
            <AnimatedContent>
              <p css={[h4Body1Css, whiteTextCss, leftTextCss]}>
                Kensho Classify lets you find whether and where specific concepts occur in text,
                giving enriched structure, revealing new insights, and interconnecting disparate
                content. Classify is specifically tuned for the needs of business and finance, with
                pinpoint accuracy for novel concepts that matter to you.
              </p>
            </AnimatedContent>
          </>
        }
        rightWrapperCss={backgroundMidGrayCss}
        rightContentCss={imageContainerCss}
        right={
          <AnimatedContent animation="fade-in" css={fullSizeCss} innerCss={fullSizeCss}>
            <picture>
              <source media={`(max-width: ${BREAKPOINT_SMALL}px)`} srcSet={ClassifyImageSmall} />
              <source media={`(min-width: ${BREAKPOINT_XLARGE}px)`} srcSet={ClassifyImageLarge} />
              <img src={ClassifyImageLarge} alt="Hero" css={imageCss} />
            </picture>
          </AnimatedContent>
        }
      />
    </section>
  )
}
