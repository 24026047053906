import {css} from '@emotion/react'

import {WideContent} from '../../components/content'
import EmailLink from '../../components/EmailLink'
import HeroSection from '../../components/Hero/HeroSection'
import Link from '../../components/Link'
import Page from '../../components/Page'
import PageMetadata from '../../components/PageMetadata'
import {COMPANY_NAME, CURRENT_YEAR, EMAILS} from '../../constants'
import {backgroundWhiteCss, baseSectionCss} from '../../styles/common'

const termsCss = css`
  width: 1200px;
  font-size: 16px;

  p {
    text-align: left;
    font-size: inherit;
    max-width: 100%;
    margin: 16px 0;
  }

  h2 {
    font-size: 22px;
    text-align: left;
    margin: 0;

    ::after {
      display: none;
    }
  }

  b,
  strong {
    font-weight: bold;
  }

  address {
    font-style: normal;
    font-size: inherit;
    font-weight: 300;
  }

  li {
    font-size: inherit;
    font-weight: 300;

    a {
      color: #50afc6;
    }
  }

  > ol {
    padding-left: 20px;
  }
`

const underlineCss = css`
  text-decoration: underline;
`

const smallBoldCss = css`
  font-size: 0.8em;
  font-weight: bold;
`

const slightMarginCss = css`
  margin-left: 25px;
`

const extraMarginCss = css`
  margin-left: 50px;
`

export default function TermsOfUsePage(): JSX.Element {
  return (
    <Page title={<HeroSection short>Kensho Website Terms of Use</HeroSection>}>
      <PageMetadata
        title="Terms | Kensho"
        description="These Terms of Use describe the terms and conditions that apply to visitors to our websites."
      />
      <section css={[baseSectionCss, backgroundWhiteCss]}>
        <WideContent css={termsCss}>
          <p>Effective Date: January 22, 2021</p>
          <p>
            Thank you for your interest in {COMPANY_NAME} (“
            <strong>Kensho</strong>” or we).
          </p>
          <p>
            The following Terms of Use (“<strong>Site Terms</strong>”) describe the terms and
            conditions that apply to visitors to{' '}
            <Link to="https://www.kensho.com">www.kensho.com</Link>, our “<strong>Site</strong>”.
          </p>
          <p>
            Before accessing and using the Site, please read these Site Terms carefully because they
            constitute a legal agreement between you and Kensho. BY USING THE SITE, YOU AFFIRM THAT:
          </p>
          <ul>
            <li>YOU HAVE READ AND UNDERSTAND THESE SITE TERMS;</li>
            <li>YOU WILL COMPLY WITH THESE SITE TERMS; AND</li>
            <li>
              YOU ARE AT LEAST THE AGE OF LEGAL MAJORITY IN YOUR PLACE OF RESIDENCE AND OTHERWISE
              LEGALLY COMPETENT TO ENTER INTO LEGALLY BINDING CONTRACTS.
            </li>
          </ul>
          <h2>1. WHEN THESE SITE TERMS APPLY</h2>
          <p>
            These Site Terms apply to your use of the Site or any other website on which Kensho
            posts them.
          </p>
          <p>
            Use of any of Kensho’s machine learning, data science and statistical analysis
            software-as-a-service (SaaS) solutions (“<strong>Services</strong>”) is governed by the
            separate Kensho Terms of Service (“<strong>Service Terms</strong>”) or the Enterprise
            Agreement (as defined in the Service Terms) pursuant to which you obtained the Services.
            If you access and use Kensho’s Services as part of a subscription with Kensho’s
            affiliate, S&P Global, Inc. or its affiliates (collectively, “
            <strong>S&P Global</strong>”), then the terms of the applicable subscription agreement
            apply.
          </p>
          <p>
            If you have not agreed to the Service Terms and do not have another written agreement
            with Kensho or a subscription agreement with S&P Global, then these Site Terms govern
            your use of the limited content that you can use on the Site but these Site Terms do not
            authorize your access to the Services. If you are uncertain about the legal terms that
            apply to you, please contact us at <EmailLink email={EMAILS.LEGAL} />.
          </p>
          <h2>2. CHANGES TO SITE TERMS</h2>
          <p>
            The Effective Date of these Site Terms is set forth at the top of this webpage. As we
            add new features to the Site, we may need to modify these Site Terms. When we modify
            these Site Terms, we will update the Effective Date, providing you with notice of our
            changes, so we encourage you to review these Site Terms from time to time to review such
            updates. We will not make changes that have a material retroactive effect on your legal
            rights unless we are legally required to do so or to protect the rights of other Site
            users. Your continued use of the Site after the Effective Date constitutes your
            acceptance of the amended Site Terms, but any change to these Site Terms after your last
            use of the Site will not apply retroactively. The amended Site Terms supersede all
            previous versions of our agreements, notices or statements of or about the Site Terms.
          </p>
          <h2>3. ADDITIONAL TERMS</h2>
          <p>
            Additional terms and conditions may apply to certain features of the Site, such as terms
            that apply when you choose to apply for a position on our “
            <Link to="/careers">Careers</Link>” pages. When presented to you, you must agree to the
            additional terms before using the features to which they apply. These Site Terms and the
            additional terms will apply equally unless an additional term is inconsistent with these
            Site Terms, in which case the additional term will prevail but solely to the extent of
            the inconsistency.
          </p>
          <h2>4. PRIVACY</h2>
          <p>
            Please review the Privacy Policy at{' '}
            <Link to="https://kensho.com/privacy">https://kensho.com/privacy</Link> to learn about
            the information that Kensho collects from or about you when you use the Site and how
            Kensho and its authorized agents process it, and how to exercise privacy rights with
            respect to that personal information.
          </p>
          <h2>5. Site Content</h2>
          <p>
            The Site, including any opinion, photograph, graphic, artwork, text, video clip, audio
            clip, trademark, logo, product information and other content displayed or otherwise
            provided on the Site, and all intellectual property rights relating thereto,
            (collectively, “<strong>Site Content</strong>”) is owned by Kensho or its licensors and
            protected under both United States and foreign copyright, trademark and other laws.
          </p>
          <p>
            Kensho grants to you a nonexclusive, nontransferable and limited license (without the
            right to sublicense) to access and use the Site and download, print and/or copy
            publicly-available Site Content solely for your own personal use and subject to these
            Site Terms. You acknowledge that the trade secrets and intellectual property embodied in
            the Site are not and will not be licensed or otherwise disclosed to you. All rights not
            expressly granted herein are reserved by Kensho.
          </p>
          <p>
            These Site Terms and your use of the Site grant you only the limited rights described in
            these Site Terms. Nothing contained in these Site Terms grants by implication, estoppel
            or otherwise, any license or right in or to Kensho’s trademarks, logos or service marks
            (“
            <strong>Kensho Marks</strong>”) or patents, trade secrets or other intellectual property
            (together with the Kensho Marks, “<strong>Kensho IP</strong>”) embodied in the Site.
            Unauthorized use of any of the Kensho IP may violate applicable law.
          </p>
          <h2>6. USING THE SITE</h2>
          <ol type="a">
            <li>
              <span css={underlineCss}>Your Rights and Responsibilities</span>. You may use the Site
              for lawful, non-commercial purposes only. Unless expressly permitted in these Site
              Terms, you agree that you shall not (and you agree not to encourage or allow any third
              party to):
              <ol type="i">
                <li>
                  reverse engineer, decode or otherwise attempt to derive or gain access to any
                  portion of the Site or Site Content;
                </li>
                <li>
                  remove, delete, alter or obscure any copyright, trademark or other proprietary
                  rights notice contained in the Site or Site Content;
                </li>
                <li>
                  use any robot, spider, search/retrieval application or other automated device,
                  process or means to access, retrieve, scrape or index any of the Site;
                </li>
                <li>
                  rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer or
                  otherwise make available the Site or any feature or functionality of the Site to
                  any third party for any reason;
                </li>
                <li>
                  copy, modify, adapt, translate, reformat or frame, mirror or create derivative
                  works or improvements of any portion of the web pages that are part of the Site;
                </li>
                <li>
                  use the Site in any manner that could damage, disable, overburden or impair our
                  servers or networks or interfere with any other party&#39;s use and enjoyment of
                  the Site;
                </li>
                <li>
                  attempt to gain unauthorized access to the Site or Kensho’s systems or networks
                  through hacking, password mining or any other means;
                </li>
                <li>
                  access or use the Site for purposes of competitive analysis of the Site, the
                  development, provision or use of a competing product or service or any other
                  purpose that is to Kensho’s detriment or commercial disadvantage;
                </li>
                <li>
                  collect or store personal information about any other user without that user’s
                  express prior written consent;
                </li>
                <li>
                  use the Site to send any unauthorized advertisement, junk mail or other
                  unsolicited or unauthorized commercial or promotional content; or
                </li>
                <li>
                  otherwise access or use the Site beyond the scope of the authorization expressly
                  granted under these Site Terms.
                </li>
              </ol>
              <p>
                Kensho has the sole discretion to terminate your access to the Site without notice
                for any violation of the above rules.
              </p>
              <p>
                You are solely responsible for any and all charges, fees and other costs related to
                use of the Site. If you access and use the Site on your smartphone, tablet or other
                mobile device, you agree that you are solely responsible for all charges that you
                incur from your mobile service provider.
              </p>
            </li>
            <li>
              <p>
                <span css={underlineCss}>Feedback</span>. Kensho may from time to time offer areas
                in the Site where you and other users can post or otherwise submit (including via
                email) suggestions, ideas, notes, concepts or similar materials about Kensho to or
                through the Site (collectively, “<strong>Feedback</strong>”). By posting or
                transmitting Feedback to Kensho (and/or our designees) through the Site, you grant
                Kensho and our designees a worldwide, non-exclusive, sub-licensable (through
                multiple tiers), transferable, royalty-free, perpetual and irrevocable right to use,
                reproduce, sublicense (through multiple tiers), distribute, create derivative works
                of, perform and import your Feedback in any media now known or hereafter developed,
                for any purpose whatsoever, commercial or otherwise, without compensation to you. In
                other words, Kensho has the automatic right to use your Feedback -- including
                reproducing, disclosing, publishing or broadcasting your Feedback -- in any medium
                and for any purpose. Under no circumstances are you entitled to payment if Kensho
                uses your Feedback. All Feedback is deemed non-confidential and non-proprietary.{' '}
              </p>
              <p>By posting Feedback, you represent and warrant that:</p>
              <ul>
                <li>
                  you own or otherwise control all of the rights to your Feedback, including
                  copyrights;
                </li>
                <li>your Feedback is true and accurate;</li>
                <li>
                  your Feedback does not violate the rights of any other person or entity, such as
                  rights of privacy and publicity; and
                </li>
                <li>your Feedback complies with applicable laws, rules and regulations.</li>
              </ul>
              <p>
                You acknowledge and agree that Kensho has the right (but not the obligation) to
                alter, remove, or refuse to post or allow to be posted any Feedback. Kensho takes no
                responsibility and assumes no liability for any Feedback posted by you or any third
                party. Kensho is not responsible for information that you choose to communicate via
                Feedback.
              </p>
            </li>
          </ol>
          <h2>7. LINKS TO OTHER WEBSITES</h2>
          <p>
            The Site may contain links to third-party websites, including social media
            (collectively, “<strong>Linked Sites</strong>”). Linked Sites are not under the control
            of Kensho and Kensho is not responsible for Linked Sites or for any information or
            materials on, or any form of transmission received from, any Linked Site. You access
            Linked Sites at your own risk. Unless expressly noted, a link does not imply endorsement
            by Kensho of the Linked Site or any association with the operator of the Linked Site.
            Kensho does not investigate, verify or monitor Linked Sites. Kensho provides links to
            Linked Sites for your convenience only. Please check the URL address provided in your
            browser to see if you are on the Site’s domain, i.e.,{' '}
            <Link to="https://kensho.com">kensho.com</Link>.
          </p>
          <h2>8. DISCLAIMER OF WARRANTIES & LIMITATIONS OF LIABILITY</h2>
          <p>
            Kensho warrants that Kensho has validly entered into these Site Terms and has the legal
            power to do so. You warrant that you have validly entered into these Site Terms and have
            the legal power to do so.
          </p>
          <p>
            <strong>
              EXCEPT AS EXPRESSLY PROVIDED ABOVE, THE SITE IS PROVIDED “AS IS” AND “AS AVAILABLE”
              WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED.
            </strong>{' '}
            Kensho specifically disclaims all warranties and conditions of any kind, including all
            implied warranties and conditions of merchantability, fitness for a particular purpose,
            title, non-infringement, freedom from defects, uninterrupted use and all warranties
            implied from any course of dealing or usage of trade. Kensho does not warrant that (a)
            the Site will meet your requirements, (b) operation of the Site will be uninterrupted or
            virus- or error-free or (c) errors will be corrected. Any oral or written advice
            provided by Kensho or its authorized agents does not and will not create any warranty.
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES WHICH MEANS THAT
            SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
          </p>
          <p>
            <strong css={smallBoldCss}>YOUR USE OF THE SITE IS AT YOUR OWN RISK.</strong> YOU AGREE
            THAT NONE OF KENSHO AND ITS AFFILIATES (“<strong>KENSHO PARTIES</strong>”) AND
            THIRD-PARTY SUPPLIERS ARE LIABLE FOR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR
            PUNITIVE DAMAGES (INCLUDING PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, LOSS OF USE,
            DATA OR PROFITS OR BUSINESS INTERRUPTION), HOWEVER CAUSED AND UNDER ANY THEORY OF
            LIABILITY, THAT ARISE IN ANY WAY FROM OR ARE RELATED TO USE OF THE SITE OR THESE TERMS,
            WHETHER IN CONTRACT, STRICT LIABILITY OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE) EVEN
            IF ANY OF THE KENSHO PARTIES OR THEIR SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGE.
          </p>
          <p>
            THE MAXIMUM LIABILITY OF THE KENSHO PARTIES FOR ANY TYPE OF DAMAGES ARISING FROM YOUR
            USE OF THE SITE IS LIMITED TO THE GREATER OF ONE HUNDRED DOLLARS ($100) OR ACTUAL
            DAMAGES.
          </p>
          <p css={smallBoldCss}>
            YOU ACKNOWLEDGE AND AGREE THAT THE ABOVE LIMITATIONS OF LIABILITY TOGETHER WITH THE
            OTHER PROVISIONS IN THESE SITE TERMS THAT LIMIT LIABILITY ARE ESSENTIAL TERMS AND THAT
            KENSHO WOULD NOT BE WILLING TO GRANT YOU THE RIGHTS SET FORTH IN THESE SITE TERMS BUT
            FOR YOUR AGREEMENT TO THE ABOVE LIMITATIONS OF LIABILITY.
          </p>
          <p>
            <strong css={smallBoldCss}>
              THE DISCLAIMERS AND LIMITATIONS IN THIS SECTION 8 DO NOT PURPORT TO LIMIT LIABILITY OR
              ALTER ANY RIGHT THAT CANNOT BE EXCLUDED UNDER APPLICABLE LAW.
            </strong>{' '}
            If these Site Terms and the term of a statute applicable to you conflict, then the term
            of the statute shall prevail but solely with respect to the conflict.
          </p>
          <p>
            IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE YOUR RIGHTS WITH RESPECT TO CALIFORNIA CIVIL
            CODE SECTION 1542, WHICH SAYS “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE
            CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME
            OF EXECUTING THE RELEASE AND THAT IF KNOWN BY HIM WOULD HAVE MATERIALLY AFFECTED HIS
            SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY ”.
          </p>
          <h2>9. INDEMNIFICATION</h2>
          <p>
            You agree to indemnify and defend Kensho Parties and their respective directors,
            officers, members, managers, employees and agents from and against all losses,
            liabilities, actual or pending claims, actions, damages, expenses, costs of defense and
            reasonable attorneys’ fees brought against any of the Kensho Parties by any third party
            arising from your Feedback or use of the Site in violation of these Site Terms, the
            rights of a third party or applicable law. Kensho reserves the right, at its own
            expense, to assume the exclusive defense and control of any matter subject to
            indemnification hereunder. No settlement that affects the rights or obligations of
            Kensho may be made without Kensho’s prior written approval.
          </p>
          <h2>10. GOVERNING LAW; DISPUTE RESOLUTION</h2>
          <p>
            All matters related to the Site are governed by the internal laws of the State of New
            York, United States, as such laws apply to agreements made and performed therein
            (without giving effect to the principles of conflicts of laws). Any legal action or
            proceeding relating to your access to, or use of, the Site shall be instituted in a
            state or federal court in Borough of Manhattan, New York, New York. You and Kensho agree
            to submit to the jurisdiction of, and agree that venue is proper in, these courts in any
            such legal action or proceeding.
          </p>
          <p>
            You and Kensho agree that the United Nations Convention on Contracts for the
            International Sale of Goods does not apply to the interpretation or construction of
            these Site Terms.
          </p>
          <p css={smallBoldCss}>
            YOU AND KENSHO AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL
            BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
            ACTION OR PROCEEDING IN ANY FORUM.
          </p>
          <h2>11. ELECTRONIC COMMUNICATIONS AND CONTRACTING</h2>
          <p>
            The communications between you and Kensho may use electronic means. Except as prohibited
            by applicable law, you agree to receive communications from Kensho in an electronic form
            and agree that all terms and conditions, agreements, notices, disclosures, and other
            communications that Kensho electronically provides to you satisfy any legal requirement
            that such communications would satisfy if it were to be in writing. Your affirmative act
            of using the Site constitutes your electronic signature to these Site Terms and your
            consent to enter into agreements with us electronically.
          </p>
          <h2>12. GEOGRAPHIC RESTRICTIONS</h2>
          <p>
            Our servers and operations are located in the United States and our policies and
            procedures are based on United States law. The Site is not intended for distribution to,
            or use by, any person or entity in any jurisdiction or country where such distribution
            or use would be contrary to law or regulation, or that would subject Kensho or its
            affiliates to any registration requirement within such jurisdiction or country.
          </p>
          <h2>13. TERMINATION</h2>
          <p>
            These Site Terms automatically terminate when you fail to comply with any term or
            condition of them. Kensho reserves the right to terminate or modify the Site or your
            access to the Site. For example, Kensho may prohibit access to the Site if Kensho
            detects or suspects fraudulent use.
          </p>
          <p>
            Termination of these Site Terms will not limit any of Kensho’s other rights or remedies.
            Sections 5, 6.b., 8, 9, 10 and 13 through 15, as well as any other provision that must
            survive in order to give proper effect to the intent and purpose of these Site Terms,
            shall survive termination.
          </p>
          <h2>14. CLAIMS OF COPYRIGHT INFRINGEMENT</h2>
          <p>
            We respond to notices of alleged infringement as required by the U.S. Digital Millennium
            Copyright Act (“<strong>DMCA</strong>”), including by removing or disabling access to
            material claimed to be the subject of infringing activity. If you have a good faith
            belief that your work has been copied in a way that constitutes copyright infringement
            or that your intellectual property rights otherwise have been violated in or through the
            Site, please send your claim or notice of infringement to{' '}
            <EmailLink email={EMAILS.LEGAL} /> with the subject line “DMCA Agent”.{' '}
          </p>
          <p>
            Our DMCA Agent will only respond to notices and inquiries that comply with the
            requirements of the DMCA. Please see{' '}
            <Link to="https://www.copyright.gov">www.copyright.gov</Link> for more information.
          </p>
          <p>
            Upon receipt of notification complying with the DMCA, Kensho will take steps to remove
            or disable access to any infringing material and remove or disable access to any link to
            infringing material. Notifications must include ALL of the following:
          </p>
          <ul css={extraMarginCss}>
            <li>
              a physical or electronic signature of the persons authorized to act on behalf of the
              owner of an exclusive copyright that is allegedly infringed;
            </li>
            <li>a description of the copyrighted work that you claim has been infringed;</li>
            <li>
              a description of where in the Site the material that you claim is infringing is
              located;
            </li>
            <li>contact information reasonably sufficient to permit Kensho to contact you;</li>
            <li>
              a statement by you that you have a good faith belief that the disputed use is not
              authorized by the copyright owner, its agent or the law; and
            </li>
            <li>
              a statement by you, made under penalty of perjury, that the information in your
              notification to Kensho is accurate and that you are the copyright owner or authorized
              to act on behalf of the owner of an exclusive right that is allegedly infringed.
            </li>
          </ul>
          <h2>15. MISCELLANEOUS</h2>
          <ol css={slightMarginCss} type="a">
            <li>
              These Site Terms (including our Privacy Policy available at{' '}
              <Link to="https://www.spglobal.com/en/privacy/privacy-policy-english">
                https://www.spglobal.com/en/privacy/privacy-policy-english
              </Link>
              ) contain the entire understanding by and between Kensho and you with respect to the
              Site and matters contained herein.
            </li>
            <li>
              These Site Terms inure to the benefit of and are binding on Kensho’s and your
              successors and assigns, respectively.
            </li>
            <li>
              Kensho may assign these Site Terms to a successor in interest (in whole or in part)
              but you may not assign the Site Terms without the prior express written consent of
              Kensho.{' '}
            </li>
            <li>
              If any provision of these Site Terms is or becomes unenforceable or invalid, the
              remaining provisions will continue with the same effect as if such unenforceable or
              invalid provision was not used.
            </li>
            <li>
              If Kensho fails or you fail to perform any obligation under these Site Terms and the
              other party does not enforce such obligation, the failure to enforce on any occasion
              will not constitute a waiver of any obligation and will not prevent enforcement on any
              other occasion.
            </li>
            <li>
              Nothing contained in these Site Terms creates a relationship or partnership, joint
              venture, or agency between Kensho and you.
            </li>
            <li>There are no third-party beneficiaries to these Site Terms.</li>
            <li>
              If Kensho is or you are prevented from performing or unable to perform any obligation
              under these Site Terms due to any cause beyond the reasonable control of the party
              invoking this provision, the affected party’s performance will be extended for the
              period of delay or inability to perform due to such cause.
            </li>
            <li>Headings and captions are for convenience only. </li>
          </ol>
          <h2>QUESTIONS?</h2>
          <p>If you have questions about the Site or these Site Terms, please contact us at:</p>
          <address>
            {COMPANY_NAME}
            <br /> Attn: General Counsel
            <br /> 55 Water Street
            <br /> New York, NY 10041
            <br /> Email: <EmailLink email={EMAILS.LEGAL} />
          </address>
          <p>
            For Customer/Technical Support: <EmailLink email={EMAILS.SUPPORT} />
          </p>
          <p>
            For Privacy Issues: <EmailLink email={EMAILS.PRIVACY} />
          </p>
          <p>
            Copyright &copy; {CURRENT_YEAR} {COMPANY_NAME}. All rights reserved.
          </p>
        </WideContent>
      </section>
    </Page>
  )
}
