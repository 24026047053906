import {css} from '@emotion/react'
import {IconCheck} from '@kensho/icons'

import {URLS} from '../../constants'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import BadgeIcon from '../../assets/icons/most-accurate-badge.svg'
import HumanIcon from '../../assets/icons/user.svg'
import AccuracyIcon from '../../assets/icons/accuracy.svg'
import AudioIcon from '../../assets/icons/train-audio.svg'
import EnterpriseIcon from '../../assets/icons/enterprise-pricing.svg'
import FreeTrialIcon from '../../assets/icons/free-trial.svg'
import APIIcon from '../../assets/icons/api-access.svg'
import UIIcon from '../../assets/icons/ui-access.svg'
import TranscriptionIcon from '../../assets/icons/real-time-transcription.svg'
import ButtonLink from '../../components/Button/ButtonLink'
import Link from '../../components/Link'
import {AnimatedContent, WideContent} from '../../components/content'
import {
  backgroundLightBlueCss,
  h3TitleCss,
  h3SubheadlineCss,
  h4Body1Css,
  hBoldCss,
  whiteTextCss,
  labelCss,
  centeredTextCss,
  baseSectionCss,
} from '../../styles/common'
import ProductComparisonTable from '../../components/ProductComparisonTable'

import FeatureLabel from './FeatureLabel'

const sectionCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const contentCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1120px;
`

const widthCss = css`
  width: 100%;
`

const titleMarginCss = css`
  margin-bottom: 20px;
`

const headerContainerCss = css`
  display: flex;
  justify-content: center;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
    align-items: center;
  }
`

const headerImageCss = css`
  margin-left: 7px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-left: 0px;
    margin-top: 7px;
  }
`

const endButtonCss = css`
  height: 44px;
  width: 185px;
  margin-top: 30px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    width: 174px;
    margin-top: 50px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 156px;
    margin-top: 23px;
  }
`

const questionPromptCss = css`
  margin-bottom: 15px;
`

const shortenTextCss = css`
  width: 83%;
`

const linkCss = css`
  margin: 46px auto 0px;
  text-decoration: underline;
`

export default function BusinessSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, sectionCss, backgroundLightBlueCss]}>
      <WideContent css={contentCss}>
        <AnimatedContent>
          <h3 css={[h3TitleCss, hBoldCss, whiteTextCss, centeredTextCss, titleMarginCss]}>
            Ready to get started?
          </h3>
          <p css={[h3SubheadlineCss, centeredTextCss, whiteTextCss]}>
            We have flexible options to suit all needs, including pay-as-you-go plans and discounts
            for large volumes.
          </p>
        </AnimatedContent>
        <AnimatedContent animation="fade-in" css={widthCss} innerCss={widthCss}>
          <ProductComparisonTable
            headerCells={[
              {key: 'feature', display: 'Included'},
              {
                key: 'scribe-hitl',
                display: (
                  <div css={headerContainerCss}>
                    <p>Scribe HITL</p>
                    <img src={BadgeIcon} alt="Most Accurate" css={headerImageCss} />
                  </div>
                ),
              },
              {key: 'scribe-ai', display: 'Scribe AI'},
            ]}
            rows={[
              {
                key: 'human-review',
                cells: [
                  {
                    key: 'label',
                    display: (
                      <FeatureLabel
                        src={HumanIcon}
                        alt="Human Review"
                        text="Includes human review"
                      />
                    ),
                  },
                  {key: 'scribe-hitl', display: <IconCheck size={35} />},
                  {key: 'scribe-ai', display: 'N/A'},
                ],
              },
              {
                key: 'accuracy',
                cells: [
                  {
                    key: 'label',
                    display: (
                      <FeatureLabel src={AccuracyIcon} alt="Lightning Bolt" text="Accuracy" />
                    ),
                  },
                  {key: 'scribe-hitl', display: '99+%'},
                  {key: 'scribe-ai', display: '95%'},
                ],
              },
              {
                key: 'training',
                cells: [
                  {
                    key: 'label',
                    display: (
                      <FeatureLabel
                        src={AudioIcon}
                        alt="Audio Wave"
                        text={
                          <p css={[labelCss, whiteTextCss, shortenTextCss]}>
                            Trained on 100K hours of high-quality business audio
                          </p>
                        }
                      />
                    ),
                  },
                  {key: 'scribe-hitl', display: <IconCheck size={35} />},
                  {key: 'scribe-ai', display: <IconCheck size={35} />},
                ],
              },
              {
                key: 'enterprise',
                cells: [
                  {
                    key: 'label',
                    display: (
                      <FeatureLabel
                        src={EnterpriseIcon}
                        alt="Money Icon"
                        text="Enterprise pricing available"
                      />
                    ),
                  },
                  {key: 'scribe-hitl', display: <IconCheck size={35} />},
                  {key: 'scribe-ai', display: <IconCheck size={35} />},
                ],
              },
              {
                key: 'free-trial',
                cells: [
                  {
                    key: 'label',
                    display: (
                      <FeatureLabel src={FreeTrialIcon} alt="Free Trial Icon" text="Free Trial" />
                    ),
                  },
                  {key: 'scribe-hitl', display: 'N/A'},
                  {key: 'scribe-ai', display: <IconCheck size={35} />},
                ],
              },
              {
                key: 'api-access',
                cells: [
                  {
                    key: 'label',
                    display: <FeatureLabel src={APIIcon} alt="API Icon" text="Access via API" />,
                  },
                  {key: 'scribe-hitl', display: <IconCheck size={35} />},
                  {key: 'scribe-ai', display: <IconCheck size={35} />},
                ],
              },
              {
                key: 'ui-access',
                cells: [
                  {
                    key: 'label',
                    display: (
                      <FeatureLabel src={UIIcon} alt="Computer Icon" text="Access with UI" />
                    ),
                  },
                  {key: 'scribe-hitl', display: 'N/A'},
                  {key: 'scribe-ai', display: <IconCheck size={35} />},
                ],
              },
              {
                key: 'real-time-transcription',
                cells: [
                  {
                    key: 'label',
                    display: (
                      <FeatureLabel
                        src={TranscriptionIcon}
                        alt="Clock Icon"
                        text="Real-time transcription"
                      />
                    ),
                  },
                  {key: 'scribe-hitl', display: 'N/A'},
                  {key: 'scribe-ai', display: <IconCheck size={35} />},
                ],
              },
              {
                key: 'buttons',
                cells: [
                  {key: 'label', display: null},
                  {
                    key: 'scribe-hitl',
                    display: (
                      <ButtonLink
                        to="mailto:sales@kensho.com?subject=Scribe"
                        variant="primary"
                        css={endButtonCss}
                      >
                        Talk to Us
                      </ButtonLink>
                    ),
                  },
                  {
                    key: 'scribe-ai',
                    display: (
                      <ButtonLink
                        to={`${URLS.FREE_TRIAL}/scribe`}
                        variant="primary"
                        css={endButtonCss}
                      >
                        Free Trial
                      </ButtonLink>
                    ),
                  },
                ],
              },
            ]}
          />
        </AnimatedContent>
        <AnimatedContent>
          <p css={[h4Body1Css, hBoldCss, whiteTextCss, centeredTextCss, questionPromptCss]}>
            Still not sure?
          </p>
          <p css={[h3SubheadlineCss, whiteTextCss, centeredTextCss]}>
            Or if you have questions, reach out to us. We’re happy to help!
          </p>
        </AnimatedContent>
        <AnimatedContent animation="fade-in">
          {' '}
          <Link
            to="mailto:sales@kensho.com?subject=Scribe"
            css={[h4Body1Css, whiteTextCss, linkCss]}
          >
            Contact Us
          </Link>{' '}
        </AnimatedContent>
      </WideContent>
    </section>
  )
}
