import {css} from '@emotion/react'

import DocumentManagement from '../../assets/icons/document-management.svg'
import QuantitativeInvestment from '../../assets/icons/quantitative-investment.svg'
import SmartSearch from '../../assets/icons/classify-smart-search.svg'
import MarketIntelligence from '../../assets/icons/classify-market-intelligence.svg'
import LabellingAnalytics from '../../assets/icons/labelling-analytics.svg'
import TextEnrichment from '../../assets/icons/classify-text-enrichment.svg'
import GraphicContentGroup from '../../components/GraphicContentGroup'
import TopicHeadline from '../../components/TopicHeadline'
import {blackTextCss, backgroundWhiteCss, baseSectionCss} from '../../styles/common'
import {AnimatedContent, WideContent} from '../../components/content'
import {BREAKPOINT_SMALL} from '../../styles/breakpoints'

const headlineCss = css`
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-bottom: 30px;
  }
`

export default function ApplicationSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundWhiteCss]}>
      <WideContent>
        <TopicHeadline css={[headlineCss, blackTextCss]} text="Kensho Classify Use Cases" />
        <AnimatedContent animation="fade-in">
          <GraphicContentGroup
            graphicContent={[
              {
                graphic: {data: DocumentManagement, isText: false},
                content: {
                  title: 'Document Management',
                  description:
                    'Automate document organization & review processes and collaborate in real time.',
                },
              },
              {
                graphic: {data: QuantitativeInvestment, isText: false},
                content: {
                  title: 'Quantitative Investment',
                  description:
                    'Easily incorporate Classify’s insights into financial models to make strategic investment decisions.',
                },
              },
              {
                graphic: {data: SmartSearch, isText: false},
                content: {
                  title: 'Smart Search',
                  description:
                    'Quickly find relevant concepts and themes in documents and text. Classify continuously improves as new concepts are added.',
                },
              },
              {
                graphic: {data: MarketIntelligence, isText: false},
                content: {
                  title: 'Market Intelligence',
                  description:
                    'Gain insight into the global market landscape, identify and track trends, and make data-driven decisions.',
                },
              },
              {
                graphic: {data: LabellingAnalytics, isText: false},
                content: {
                  title: 'Labelling & Analytics',
                  description:
                    'Use Classify to label text for other machine learning applications or analyze Classify’s output to reveal new market insights.',
                },
              },
              {
                graphic: {data: TextEnrichment, isText: false},
                content: {
                  title: 'Text Enrichments',
                  description:
                    'Enhance documents with embedded metadata and excerpt-level annotation. Use Classify’s tags to search, share and recommend content.',
                },
              },
            ]}
            maxItemsPerRow={3}
          />
        </AnimatedContent>
      </WideContent>
    </section>
  )
}
