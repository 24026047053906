import {css} from '@emotion/react'

import ExtractGraphic from '../../assets/solutions/extract.svg'
import Link from '../../components/Link'
import {URLS} from '../../constants'
import {bulletedListCss, bulletedListItemCss} from '../../styles/common'

import SolutionCard from './SolutionCard'

const blueLinkCss = css`
  color: #007694;
`

export default function SolutionsExtract(): JSX.Element {
  return (
    <SolutionCard
      label="Extract Data from PDF Documents"
      graphic={{src: ExtractGraphic, alt: 'Extract Icon'}}
      title="Extract"
      description="Kensho Extract enables customers to automate extraction of critical insights from tables and text in complex and inconsistent PDF documents."
      learnMoreUrl="/extract"
      mainContent={
        <>
          <p>
            Extract information exactly as represented in the original document or standardize table
            contents such as financial metrics, time periods, currencies and other information.
          </p>
          <ul css={bulletedListCss}>
            <li css={bulletedListItemCss}>
              Save manual processing time by structuring data in documents that can be used in
              databases and other downstream systems.
            </li>
            <li css={bulletedListItemCss}>
              Automatically identify tables, headers and text from documents.
            </li>
            <li css={bulletedListItemCss}>Flatten and standardize information in tables.</li>
            <li css={bulletedListItemCss}>
              Read about Extract in our blog post,{' '}
              <Link
                css={blueLinkCss}
                to="https://blog.kensho.com/kensho-extract-and-the-value-of-document-segmentation-c23f33f7a9c2"
              >
                Kensho Extract and the Value of Document Segmentation
              </Link>
            </li>
          </ul>
        </>
      }
      linkList={[
        {href: 'https://extract.kensho.com/docs/', text: 'Developer Docs'},
        {href: 'mailto:sales@kensho.com?subject=Extract', text: 'Contact Extract'},
      ]}
      callToActionLink={{
        href: `${URLS.FREE_TRIAL}/extract`,
        text: 'Free Trial',
      }}
    />
  )
}
