import {css} from '@emotion/react'

import {BREAKPOINT_SMALL, BREAKPOINT_LARGE} from '../../styles/breakpoints'
import {h4Body2Css, hBoldCss} from '../../styles/common'
import {AnimatedContent} from '../content'

const topicHeadlineCss = css`
  margin: 0;
  margin-bottom: 90px;
  text-transform: uppercase;
  letter-spacing: 1px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-bottom: 60px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-bottom: 30px;
  }
`

interface TopicHeadlineProps {
  id?: string
  className?: string
  text: string
}

export default function TopicHeadline({id, className, text}: TopicHeadlineProps): JSX.Element {
  return (
    <AnimatedContent className={className}>
      <h4 id={id} css={[h4Body2Css, hBoldCss, topicHeadlineCss]}>
        {text}
      </h4>
    </AnimatedContent>
  )
}
