import {HTMLProps, ReactNode} from 'react'
import {css, SerializedStyles} from '@emotion/react'

import {
  BREAKPOINT_SMALL,
  BREAKPOINT_MEDIUM,
  BREAKPOINT_LARGE,
  BREAKPOINT_XLARGE,
} from '../styles/breakpoints'
import useHasScrolledInBounds from '../hooks/useHasScrolledInBounds'

export const WIDTH_XLARGE = 1452
export const WIDTH_LARGE = 1198
export const WIDTH_MEDIUM = 990
export const WIDTH_SMALL = 828

const wideContentContainerCss = css`
  width: 100%;
  display: flex;
  justify-content: center;
`

const wideContentCss = css`
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  max-width: ${WIDTH_XLARGE}px;

  @media (max-width: ${BREAKPOINT_XLARGE}px) {
    max-width: ${WIDTH_LARGE}px;
  }

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    max-width: ${WIDTH_MEDIUM}px;
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    max-width: ${WIDTH_SMALL}px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    max-width: 100%;
    padding-left: 23px;
    padding-right: 23px;
  }
`

const halfContentCss = css`
  width: 100%;
  height: 100%;
  max-width: ${WIDTH_XLARGE / 2}px;

  @media (max-width: ${BREAKPOINT_XLARGE}px) {
    max-width: ${WIDTH_LARGE / 2}px;
  }

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    max-width: ${WIDTH_MEDIUM}px;
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    max-width: ${WIDTH_SMALL}px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    max-width: 100%;
  }
`

const twoColumnContainerCss = css`
  display: flex;
  flex-direction: row;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    flex-direction: column;
  }
`

const halfContentWrapperCss = css`
  flex: 1 0;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    flex: unset;
    display: flex;
    justify-content: center;
  }
`

const leftHalfCss = css`
  float: right;
  padding-left: 40px;
  padding-right: 28px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    float: none;
    padding-right: 40px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding-left: 23px;
    padding-right: 23px;
  }
`

const rightHalfCss = css`
  float: left;
  padding-left: 28px;
  padding-right: 40px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    float: none;
    padding-left: 40px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding-left: 23px;
    padding-right: 23px;
  }
`

export function WideContent({
  children,
  className,
  ...rest
}: HTMLProps<HTMLDivElement>): JSX.Element {
  return (
    <div css={wideContentContainerCss}>
      <div css={wideContentCss} className={className} {...rest}>
        {children}
      </div>
    </div>
  )
}

function HalfWideContent({children, className, ...rest}: HTMLProps<HTMLDivElement>): JSX.Element {
  return (
    <div css={halfContentCss} className={className} {...rest}>
      {children}
    </div>
  )
}

interface TwoColumnContentProps {
  className?: string
  leftContentCss?: SerializedStyles | SerializedStyles[]
  leftWrapperCss?: SerializedStyles | SerializedStyles[]
  left: ReactNode
  rightContentCss?: SerializedStyles | SerializedStyles[]
  rightWrapperCss?: SerializedStyles | SerializedStyles[]
  right: ReactNode
}

export function TwoColumnContent({
  className,
  leftContentCss,
  leftWrapperCss,
  left,
  rightContentCss,
  rightWrapperCss,
  right,
}: TwoColumnContentProps): JSX.Element {
  return (
    <div css={twoColumnContainerCss} className={className}>
      <div css={[halfContentWrapperCss, leftWrapperCss]}>
        <HalfWideContent css={[leftHalfCss, leftContentCss]}>{left}</HalfWideContent>
      </div>
      <div css={[halfContentWrapperCss, rightWrapperCss]}>
        <HalfWideContent css={[rightHalfCss, rightContentCss]}>{right}</HalfWideContent>
      </div>
    </div>
  )
}

const animationContainerCss = css`
  overflow: hidden;
`

const slideInBeforeCss = css`
  transition-property: transform, opacity;
  transition-duration: 500ms;
  transition-timing-function: ease;
  opacity: 0;
  transform: translateY(25px);
`

const slideInAfterCss = css`
  transform: translateY(0);
  opacity: 1;
`

const fadeBeforeCss = css`
  transition-property: opacity;
  transition-duration: 500ms;
  transition-timing-function: ease;
  opacity: 0;
`

const fadeAfterCss = css`
  opacity: 1;
`

interface AnimatedContentProps {
  children: React.ReactNode
  className?: string
  innerCss?: SerializedStyles
  /* seconds */
  delay?: number
  animation?: 'fade-in' | 'slide-in'
}

export function AnimatedContent({
  children,
  className,
  innerCss,
  delay = 0.3,
  animation = 'slide-in',
}: AnimatedContentProps): JSX.Element {
  const {setNode, hasScrolledInBounds} = useHasScrolledInBounds<HTMLDivElement>()
  const beforeCss = animation === 'slide-in' ? slideInBeforeCss : fadeBeforeCss
  const afterCss = animation === 'slide-in' ? slideInAfterCss : fadeAfterCss
  return (
    <div css={animationContainerCss} className={className} ref={setNode}>
      <div
        css={[beforeCss, hasScrolledInBounds && afterCss, {transitionDelay: `${delay}s`}, innerCss]}
      >
        {children}
      </div>
    </div>
  )
}
