import {css, Global} from '@emotion/react'

const fonts = css`
  /* light */
  @font-face {
    font-family: 'proxima-nova';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
      url('/fonts/proxima-nova-light.woff2') format('woff2'),
      url('/fonts/proxima-nova-light.woff') format('woff');
    /* latin */
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* regular */
  @font-face {
    font-family: 'proxima-nova';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
      url('/fonts/proxima-nova-regular.woff2') format('woff2'),
      url('/fonts/proxima-nova-regular.woff') format('woff');
    /* latin */
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* medium */
  @font-face {
    font-family: 'proxima-nova';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src:
      url('/fonts/proxima-nova-medium.woff2') format('woff2'),
      url('/fonts/proxima-nova-medium.woff') format('woff');
    /* latin */
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* semibold */
  @font-face {
    font-family: 'proxima-nova';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src:
      url('/fonts/proxima-nova-semibold.woff2') format('woff2'),
      url('/fonts/proxima-nova-semibold.woff') format('woff');
    /* latin */
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* bold */
  @font-face {
    font-family: 'proxima-nova';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
      url('/fonts/proxima-nova-bold.woff2') format('woff2'),
      url('/fonts/proxima-nova-bold.woff') format('woff');
    /* latin */
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* extrabold */
  @font-face {
    font-family: 'proxima-nova';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src:
      url('/fonts/proxima-nova-extrabold.woff2') format('woff2'),
      url('/fonts/proxima-nova-extrabold.woff') format('woff');
    /* latin */
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
`

export default <Global styles={fonts} />
