import {css} from '@emotion/react'

import {TwoColumnContent, AnimatedContent} from '../../components/content'
import ButtonLink from '../../components/Button/ButtonLink'
import {
  baseSectionCss,
  centeredTextCss,
  h3SubheadlineCss,
  h4Body1Css,
  hBoldCss,
  whiteTextCss,
} from '../../styles/common'
import {BREAKPOINT_XLARGE, BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import COLORS from '../../styles/colors'

const sectionCss = css`
  background-color: ${COLORS.brand.light[300]};
`

const alignCss = css`
  align-items: center;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    align-items: stretch;
  }
`

const headerCss = css`
  margin-bottom: 40px;
`

const rightCss = css`
  padding-left: 120px;
`

const buttonCss = css`
  height: 65px;
  width: 550px;
  border: 1px solid #fff;

  @media (max-width: ${BREAKPOINT_XLARGE}px) {
    width: 439px;
  }

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-top: 40px;
    width: 100%;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-top: 30px;
  }
`

export default function NotSureSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, sectionCss]}>
      <TwoColumnContent
        css={alignCss}
        left={
          <>
            <AnimatedContent>
              <p css={[headerCss, centeredTextCss, h3SubheadlineCss, hBoldCss, whiteTextCss]}>
                Still not sure?
              </p>
            </AnimatedContent>
            <AnimatedContent>
              <p css={[centeredTextCss, h3SubheadlineCss, whiteTextCss]}>
                Or if you have questions, reach out to us. We’re happy to help!
              </p>
            </AnimatedContent>
          </>
        }
        rightContentCss={rightCss}
        right={
          <AnimatedContent animation="fade-in">
            <ButtonLink
              to="mailto:sales@kensho.com?subject=Classify"
              variant="secondary"
              css={[h4Body1Css, whiteTextCss, buttonCss]}
            >
              Contact Us
            </ButtonLink>
          </AnimatedContent>
        }
      />
    </section>
  )
}
