import {css} from '@emotion/react'

import Counter from '../../components/Counter'
import useHasScrolledInBounds from '../../hooks/useHasScrolledInBounds'
import {BREAKPOINT_SMALL} from '../../styles/breakpoints'

// start date 5/23/2022
// rate: 0.6 entities per second --> 0.0006 entities per millisecond
const base = 86700405
const baseTime = new Date('2022-05-23').getTime()
const rate = 0.0006

// total links created extrapolated to current date
const initialCount = Math.floor(base + (Date.now() - baseTime) * rate)

const counterContainerCss = css`
  margin-top: 50px;
  margin-bottom: 30px;
  width: 100%;
  transition: 1.5s;
  opacity: 0;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 73px;
    margin-bottom: 50px;
  }
`

const fullOpacityCss = css`
  opacity: 1;
`

export default function LinkTicker(): JSX.Element {
  const {setNode, hasScrolledInBounds} = useHasScrolledInBounds<HTMLDivElement>()

  return (
    <div ref={setNode} css={[counterContainerCss, hasScrolledInBounds && fullOpacityCss]}>
      {hasScrolledInBounds && (
        <Counter animate initialCount={initialCount} rate={rate} interval={150} />
      )}
    </div>
  )
}
