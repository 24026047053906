import {css} from '@emotion/react'

interface Props {
  className?: string
  label: string
}

const badgeCss = css`
  color: #1d4ed8;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid #bfdbfe;
  background: #eff6ff;
  border-radius: 8px;
  padding: 2px 8px;
`

export default function Badge(props: Props): JSX.Element {
  const {className, label} = props

  return (
    <div className={className} css={badgeCss}>
      {label}
    </div>
  )
}
