import {css} from '@emotion/react'

import Link from '../../components/Link'
import TopicHeadline from '../../components/TopicHeadline'
import {WideContent, AnimatedContent} from '../../components/content'
import {TwoColumnHeadingContent} from '../../components/headings'
import {baseSectionCss, backgroundWhiteCss, blackTextCss, h4Body2Css} from '../../styles/common'
import {BREAKPOINT_SMALL} from '../../styles/breakpoints'

import ProjectCard, {ProjectCardProps} from './ProjectCard'

const cardContainer = css`
  margin: 70px 0;
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0;
  }
`

const underlineCss = css`
  text-decoration: underline;
`

const projectData: ProjectCardProps[] = [
  {
    title: 'Tokenization',
    text: 'What is the role of tokenization in training LLMs, particularly for financial and quantitative use cases? In our research, we dissect and improve the tokenization process, quantifying impact on pre-training and downstream performance.',
  },
  {
    title: 'LLMs',
    text: 'LLMs show strong performance on many challenging tasks, but they still struggle to solve many real-world problems in business and finance. We’re developing better models for the business world, while focusing on the entire pipeline, including tokenization, constructing high-quality datasets, alignment, and evaluation.',
  },
  {
    title: 'Numeric Understanding',
    text: 'LLMs often struggle with numeric understanding. While we can alleviate some of this difficulty with code generation, we still want our models to be able to understand and process numerical data. Our team is investigating how well language models use numbers, and identifying the mechanisms language models learn to use numbers.',
  },
  {
    title: 'Benchmarks',
    text: (
      <p css={[h4Body2Css, blackTextCss]}>
        LLMs require rigorous evaluation benchmarks, and targeting the reasoning skills needed in
        business and finance presents unique challenges. We are developing benchmarks for evaluating
        a model’s ability to reason about realistic financial problems,{' '}
        <Link to="https://benchmarks.kensho.com/" css={underlineCss}>
          S&P AI Benchmarks.
        </Link>
      </p>
    ),
  },
  {
    title: 'Multi Document QA',
    text: 'Current state-of-the-art foundational models do not always correctly answer complex questions that require grounding knowledge from multiple sources. We are developing intelligent reading comprehension agents that can process and reason over a range of document collections.',
  },
  {
    title: 'Factuality',
    text: 'As GenAI applications become more prevalent in our daily lives, it’s increasingly important that they produce factually correct and accurate outputs. We develop methods to monitor factuality with the ultimate goal of providing a tool to manage model outputs of GenAI products.',
  },
]

export default function ProjectSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundWhiteCss]}>
      <WideContent>
        <AnimatedContent>
          <TopicHeadline css={blackTextCss} text="Our Projects" />
        </AnimatedContent>
      </WideContent>
      <TwoColumnHeadingContent
        headerText="Learn more about what we’re researching."
        additionalText="We focus our efforts on problems in the finance and business worlds, designed to push the envelope and drive impact."
        textColor="black"
      />
      <WideContent css={cardContainer}>
        {projectData.map(({title, text}) => (
          <AnimatedContent key={title}>
            <ProjectCard title={title} text={text} />
          </AnimatedContent>
        ))}
      </WideContent>
    </section>
  )
}
