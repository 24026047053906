import {AnimatedContent, WideContent} from '../../components/content'
import {
  backgroundWhiteCss,
  blackTextCss,
  centeredTextCss,
  baseSectionCss,
  h3SubheadlineCss,
  hBoldCss,
} from '../../styles/common'

import LinkTicker from './LinkTicker'

export default function TickerSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundWhiteCss]}>
      <WideContent>
        <AnimatedContent animation="fade-in">
          <LinkTicker />
          <h3 css={[h3SubheadlineCss, centeredTextCss, blackTextCss, hBoldCss]}>
            ...links made to-date and counting fast!
          </h3>
        </AnimatedContent>
      </WideContent>
    </section>
  )
}
