interface EmailLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children?: React.ReactNode
  email: string
}

export default function EmailLink(props: EmailLinkProps): JSX.Element {
  const {children, email, ...rest} = props
  return (
    <a href={`mailto:${email}`} {...rest}>
      {children || email}
    </a>
  )
}
