import {css} from '@emotion/react'
import {useState} from 'react'

import {labelCss, legalCss, hBoldCss} from '../../../styles/common'
import COLORS from '../../../styles/colors'
import Plus from '../../../assets/icons/plus.svg'
import Minus from '../../../assets/icons/minus.svg'

const cookieOptionCss = css`
  border: 1px solid #bbb;
  border-bottom: none;
`

const expandButtonCss = css`
  position: relative;
  display: flex;
  width: 100%;
  padding: 12px 15px;
  align-items: center;
  justify-content: flex-start;

  &:hover {
    background: transparent;
  }
`

const expandIconCss = css`
  width: 12px;
  height: 12px;
  margin-right: 15px;
`

const buttonTitleCss = css`
  color: #444;
  text-align: left;
`

const switchCss = css`
  margin-left: auto;
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
`

const inputCss = css`
  width: 0;
  height: 0;
  opacity: 0;
`

const sliderCss = css`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2f1f1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid #ddd;
  border-radius: 25px;

  &:before {
    position: absolute;
    content: '';
    height: 21px;
    width: 21px;
    left: 2px;
    top: 1px;
    background-color: #7d7d7d;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`

const activeSliderCss = css`
  background-color: #cddcf2;
  border: 1px solid #3860be;

  &:before {
    background-color: #3860be;
    transform: translateX(18px);
  }
`

const activeTextCss = css`
  color: ${COLORS.brand.light[700]};
  margin-left: auto;
`

const expandedContentCss = css`
  padding: 15px 20px;
  background-color: #f8f8f8;
`

interface CookieOptionProps {
  className?: string
  title: string
  expandedContent: React.ReactNode
  checked?: boolean
  onChange?: (setCookie: boolean) => void
}

export default function CookieOption({
  className,
  title,
  expandedContent,
  checked,
  onChange,
}: CookieOptionProps): JSX.Element {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className={className} css={cookieOptionCss}>
      <button css={expandButtonCss} type="button" onClick={() => setExpanded((prev) => !prev)}>
        {expanded ? (
          <img src={Minus} alt="Minus" css={expandIconCss} />
        ) : (
          <img src={Plus} alt="Plus" css={expandIconCss} />
        )}
        <p css={[buttonTitleCss, labelCss, hBoldCss]}>{title}</p>
        {onChange ? (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label css={switchCss}>
            <input
              type="checkbox"
              css={inputCss}
              checked={checked}
              onChange={(event) => onChange(event.target.checked)}
            />
            <span css={[sliderCss, checked && activeSliderCss]} />
          </label>
        ) : (
          <p css={[activeTextCss, legalCss, hBoldCss]}>Always Active</p>
        )}
      </button>
      {expanded && <div css={expandedContentCss}>{expandedContent}</div>}
    </div>
  )
}
