import {useState} from 'react'

/**
 * Reflects whether a condition has ever been true throughout the lifecycle of the component.
 *
 * @param condition The condition, reduced to a boolean value.
 *
 * @returns Whether the condition is, or has ever been, true.
 *
 * @see https://en.wikipedia.org/wiki/Fuse_(electrical)
 * @see https://tacklebox.beta-p.kensho.com/useFuse
 */
export default function useFuse(condition: boolean): boolean {
  const [hasEverBeenTrue, setHasEverBeenTrue] = useState(condition)
  if (condition && !hasEverBeenTrue) setHasEverBeenTrue(true)
  return condition || hasEverBeenTrue
}
