import {css} from '@emotion/react'

import {BREAKPOINT_XLARGE, BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import ExtractIntroLarge from '../../assets/photos/extract-intro-large.jpg'
import ExtractIntroSmall from '../../assets/photos/extract-intro-small.jpg'
import TopicHeadline from '../../components/TopicHeadline'
import {AnimatedContent, TwoColumnContent} from '../../components/content'
import {
  h3TitleCss,
  hBoldCss,
  h3SubheadlineCss,
  whiteTextCss,
  backgroundGradientCss,
  backgroundMidGrayCss,
  sectionHeadingMarginCss,
} from '../../styles/common'

const headlineCss = css`
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    display: none;
  }
`
const leftCss = css`
  padding: 65px 8px 95px 0px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 60px 0px;
  }
`

const paragraphSpacingCss = css`
  margin-bottom: 45px;
`

const fullSizeCss = css`
  height: 100%;
  width: 100%;
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    height: 850px;
  }
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 500px;
  }
`

const imageContainerCss = css`
  padding: 0;
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding: 0;
    max-width: unset;
  }
`

const imageCss = css`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

export default function IntroSection(): JSX.Element {
  return (
    <section>
      <TwoColumnContent
        leftWrapperCss={[leftCss, backgroundGradientCss]}
        left={
          <>
            <TopicHeadline text="About Extract" css={[headlineCss, whiteTextCss]} />
            <AnimatedContent>
              <h3 css={[sectionHeadingMarginCss, h3TitleCss, hBoldCss, whiteTextCss]}>
                Save time on document processing through automated text and table extraction{' '}
              </h3>
            </AnimatedContent>
            <AnimatedContent>
              <p css={[paragraphSpacingCss, h3SubheadlineCss, whiteTextCss]}>
                Simple optical character recognition (OCR) tools are outdated.
              </p>
            </AnimatedContent>
            <AnimatedContent>
              <p css={[h3SubheadlineCss, whiteTextCss]}>
                Whether you’re looking to make your financial documents machine readable, trying to
                join table data to your proprietary database, or looking for specific data points
                across multiple documents, Kensho Extract can help.
              </p>
            </AnimatedContent>
          </>
        }
        rightWrapperCss={backgroundMidGrayCss}
        rightContentCss={imageContainerCss}
        right={
          <AnimatedContent animation="fade-in" css={fullSizeCss} innerCss={fullSizeCss}>
            <picture>
              <source media={`(max-width: ${BREAKPOINT_SMALL}px)`} srcSet={ExtractIntroSmall} />
              <source media={`(min-width: ${BREAKPOINT_XLARGE}px)`} srcSet={ExtractIntroLarge} />
              <img src={ExtractIntroLarge} alt="Hero" css={imageCss} />
            </picture>
          </AnimatedContent>
        }
      />
    </section>
  )
}
