import {css} from '@emotion/react'

import {centeredTextCss, h3SubheadlineCss} from '../../styles/common'
import {WideContent, AnimatedContent} from '../content'
import {BREAKPOINT_SMALL} from '../../styles/breakpoints'

import HeroSection, {HeroSectionProps} from './HeroSection'

const alignCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const heroHeightCss = css`
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 550px;
  }
`

const productTitleContainerCss = css`
  display: flex;
  margin-bottom: 35px;
`

const productLogoCss = css`
  width: 400px;
  margin-right: 30px;
`

const extraTitleCss = css`
  display: none;
`

interface ProductHeroSectionProps extends Omit<HeroSectionProps, 'children'> {
  productLogo: string
  productTitle: React.ReactNode
  productSubtitle?: React.ReactNode
  callToAction?: React.ReactNode
}

export default function ProductHeroSection(props: ProductHeroSectionProps): JSX.Element {
  const {productLogo, productTitle, productSubtitle, callToAction, ...rest} = props
  const title = `Kensho ${productTitle}`
  return (
    <HeroSection css={callToAction && heroHeightCss} {...rest}>
      <WideContent>
        <AnimatedContent innerCss={alignCss}>
          <div css={productTitleContainerCss}>
            <img src={productLogo} alt={title} css={productLogoCss} />
            <h1 css={extraTitleCss}>{title}</h1>
          </div>
          <h3 css={[h3SubheadlineCss, centeredTextCss]}>{productSubtitle}</h3>
          {callToAction}
        </AnimatedContent>
      </WideContent>
    </HeroSection>
  )
}
