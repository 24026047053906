import {useStableValue} from '@kensho/tacklebox'

import {Transport} from './types'
import Logger from './Logger'
import LoggerContext from './LoggerContext'

interface LoggerProviderProps {
  children: React.ReactNode
  transports: Transport[]
}

export default function LoggerProvider(props: LoggerProviderProps): JSX.Element {
  const {children, transports} = props
  const logger = useStableValue(() => new Logger(transports))
  return <LoggerContext.Provider value={logger}>{children}</LoggerContext.Provider>
}
