import {css, SerializedStyles} from '@emotion/react'
import {useState, useCallback} from 'react'

interface DropdownMenuProps {
  topLink: React.ReactNode
  className?: string
  innerContainerCss?: SerializedStyles
  children: React.ReactNode
  theme: 'dark' | 'light'
}

const dropdownMenuCss = css`
  position: relative;
  display: inline-block;
`

const dropdownCss = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: white;
  position: absolute;
  margin-top: 0px;
  margin-left: 20px;
  min-width: 140px;
  white-space: nowrap;
  color: #666;

  max-height: 0px;
  transition:
    max-height 300ms,
    padding 300ms;
  overflow: hidden;
`

const openCss = css`
  padding: 10px 0;
  max-height: 1000px;
`

const darkDropdownCss = css`
  background-color: #222;
  color: white;
`

export default function DropdownMenu(props: DropdownMenuProps): JSX.Element {
  const {children, className, innerContainerCss, topLink, theme = 'light'} = props

  const [isOpen, setOpen] = useState(false)
  const open = useCallback(() => setOpen(true), [])
  const close = useCallback(() => setOpen(false), [])

  return (
    <div
      className={className}
      css={dropdownMenuCss}
      onFocus={open}
      onBlur={close}
      onMouseEnter={open}
      onMouseLeave={close}
    >
      {topLink}
      <div
        css={[
          dropdownCss,
          theme === 'dark' && darkDropdownCss,
          innerContainerCss,
          isOpen && openCss,
        ]}
        onClick={close}
      >
        {children}
      </div>
    </div>
  )
}
