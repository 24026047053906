import {css} from '@emotion/react'

import {BREAKPOINT_SMALL, BREAKPOINT_LARGE} from '../styles/breakpoints'
import {darkBlueTextCss, h3SubheadlineCss, h4Body1Css, hBoldCss} from '../styles/common'
import quoteIcon from '../assets/icons/blue-gradient-quote.svg'

const quoteContainerCss = css`
  display: flex;
  align-items: flex-start;
  margin: 40px 0px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin: 68px 0px;
    flex-direction: column;
  }
`

const quoteIconCss = css`
  width: 150px;
  height: 120px;
  margin-right: 52px;
  margin-left: 32px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin: 0px 20px 0px 0px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 70px;
    height: 56px;
    margin: 0px 0px 15px;
  }
`

const smallQuoteCss = css`
  width: 60px;
  height: 48px;
  margin: 0 10px 0 0;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 60px;
    height: 48px;
    margin: 0 0 15px;
  }
`

const quoteContentCss = css`
  max-width: 1135px;
`

interface SingleQuoteProps {
  size?: 'small' | 'large'
  children: React.ReactNode
}

export default function SingleQuote(props: SingleQuoteProps): JSX.Element {
  const {size = 'large', children} = props

  const textSizeCss = size === 'small' ? h4Body1Css : h3SubheadlineCss
  return (
    <div css={quoteContainerCss}>
      <img src={quoteIcon} alt="Quote:" css={[quoteIconCss, size === 'small' && smallQuoteCss]} />
      <p css={[quoteContentCss, textSizeCss, hBoldCss, darkBlueTextCss]}>{children}</p>
    </div>
  )
}
