import {css} from '@emotion/react'

import {
  blackTextCss,
  backgroundWhiteCss,
  twoColumnSectionHeadingMarginCss,
} from '../../styles/common'
import TopicHeadline from '../../components/TopicHeadline'
import {WideContent} from '../../components/content'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {TwoColumnHeadingContent} from '../../components/headings'

import CustomerTestimonialCarousel from './CustomerTestimonialCarousel'

const sectionCss = css`
  padding-top: 65px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding-top: 50px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding-top: 55px;
  }
`

const businessContentCss = css`
  padding-right: 0;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    max-width: unset;
  }
`

export default function BusinessSection(): JSX.Element {
  return (
    <section css={[sectionCss, backgroundWhiteCss, blackTextCss]}>
      <WideContent>
        <TopicHeadline text="Browse our latest" />
      </WideContent>
      <TwoColumnHeadingContent
        headerText="Start making sense of your messy data."
        additionalText="Whatever your business or industry, we’re with you 100% every step of the way."
        textColor="black"
        css={twoColumnSectionHeadingMarginCss}
      />
      <WideContent css={businessContentCss}>
        <CustomerTestimonialCarousel />
      </WideContent>
    </section>
  )
}
