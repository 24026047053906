import {css} from '@emotion/react'

import photoBhavesh from '../../assets/people/bhavesh.jpg'
import photoMeaghan from '../../assets/people/meaghan.png'
import {Testimonial} from '../../data/testimonials/testimonialData'
import {BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {h4Body1Css, labelCss} from '../../styles/common'

const quoteCss = css`
  margin: 30px 50px;
  font-style: italic;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin: 40px 10px;
  }
`

const quoteContainerCss = css`
  ${h4Body1Css}

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    ${labelCss}
  }
`

const deiLeadershipData: Testimonial[] = [
  {
    photo: photoBhavesh,
    name: 'Bhavesh Dayalji',
    title: 'CEO',
    quote: (
      <div css={quoteContainerCss}>
        <p>
          We have high aspirations at Kensho. We build AI/ML solutions that structure unstructured
          data, making important insights discoverable and empowering our customers to make
          decisions with conviction. Achieving this vision requires us to attract, hire and develop
          a team that reflects the diverse pool of people, perspectives and talents we see in our
          communities.
        </p>
        <p css={quoteCss}>
          …this vision requires us to attract, hire and develop a team that reflects the diverse
          pool of people, perspectives and talents we see in our communities.
        </p>
        <p>
          My leadership team and I are fully committed to achieving our DEI goals, which are
          outlined here on our website. I am proud of the progress we’ve made so far but know
          there’s more to do as we work to make Kensho an even more diverse and inclusive place
          where all employees feel confident bringing their full authentic selves to work.
        </p>
      </div>
    ),
  },
  {
    photo: photoMeaghan,
    name: 'Meaghan Cassidy',
    title: 'DEI Chair',
    quote: (
      <div css={quoteContainerCss}>
        <p>
          Kenshins wear many hats in our fast-paced workplace, and the ability to think from
          different perspectives enables us to critically analyze the work that we do, and
          ultimately build better AI and ML services. Different perspectives impact our daily
          work—from how we build ML models to which technologies we experiment with and how we
          choose to collaborate and communicate—and we welcome and encourage constructive criticism.
        </p>
        <p css={quoteCss}>
          There’s work to be done, and we are striving to make progress together through
          transparency, trust and commitment.
        </p>
        <p>
          As Chief of Staff, one of the hats that I wear is that of our DEI Chair, and I am
          ultimately accountable to our DEI Goals. This accountability emphasizes our leadership’s
          commitment to DEI efforts, lets everyone know who’s responsible for decisions, and
          provides clarity on where to go with suggestions and ideas. There’s work to be done, and
          we are striving to make progress together through transparency, trust and commitment.
        </p>
      </div>
    ),
  },
]

export default deiLeadershipData
