import {css} from '@emotion/react'

import bookIcon from '../../assets/icons/book.svg'
import brainIcon from '../../assets/icons/brain.svg'
import fireIcon from '../../assets/icons/fire.svg'
import heartIcon from '../../assets/icons/heart.svg'
import journalIcon from '../../assets/icons/journal.svg'
import labIcon from '../../assets/icons/lab.svg'
import lightningIcon from '../../assets/icons/lightning.svg'
import mortarboardIcon from '../../assets/icons/mortarboard.svg'
import mountainIcon from '../../assets/icons/mountain.svg'
import placeSettingIcon from '../../assets/icons/placesetting.svg'
import presentationIcon from '../../assets/icons/presentation.svg'
import speechBubbleIcon from '../../assets/icons/speech-bubble.svg'
import thumbsupIcon from '../../assets/icons/thumbs-up.svg'
import Bhavesh from '../../assets/people/bhavesh.jpg'
import Melissa from '../../assets/people/melissa.jpg'
import AboutHeroLarge from '../../assets/photos/about-hero-large.jpg'
import AboutHeroMedium from '../../assets/photos/about-hero-medium.jpg'
import AboutHeroSmall from '../../assets/photos/about-hero-small.jpg'
import BhaveshMeaghanImg from '../../assets/photos/bhavesh-meaghan-chat.jpg'
import CamOfficExteriorImg from '../../assets/photos/cam-office-exterior.jpg'
import EnsoGridDEIImg from '../../assets/photos/enso-grid-dei.svg'
import EnsoWithNetworkImg from '../../assets/photos/enso-with-network.svg'
import SwirlingDataImg from '../../assets/photos/swirling-data.jpg'
import socialImg from '../../assets/social/about.jpg'
import Avatar from '../../components/Avatar'
import ButtonLink from '../../components/Button/ButtonLink'
import GraphicContentGroup, {GraphicContent} from '../../components/GraphicContentGroup'
import HeroSection from '../../components/Hero/HeroSection'
import Link from '../../components/Link'
import Page from '../../components/Page'
import PageMetadata from '../../components/PageMetadata'
import SingleQuote from '../../components/SingleQuote'
import TopicHeadline from '../../components/TopicHeadline'
import {HeadingContent, TwoColumnHeadingContent} from '../../components/headings'
import {AnimatedContent, TwoColumnContent, WideContent} from '../../components/content'
import {SECTION_IDS} from '../../constants'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {
  backgroundBlackCss,
  backgroundGradientCss,
  backgroundLightGrayCss,
  backgroundWhiteCss,
  baseSectionCss,
  bodyTextMarginCss,
  centerButtonCss,
  h1Css,
  h3SubheadlineCss,
  h3TitleCss,
  h4Body1Css,
  h4Body2Css,
  hBoldCss,
  largeButtonCss,
  noMarginCss,
  outlinedButtonOnDarkCss,
  sectionHeadingMarginCss,
  twoColumnSectionHeadingMarginCss,
  whiteTextCss,
} from '../../styles/common'

import AILabImageRow from './AILabImageRow'

const valuesGraphicContent: GraphicContent[] = [
  {
    graphic: {
      data: thumbsupIcon,
      isText: false,
    },
    content: {
      title: 'Go Team',
      description: 'We put the team before ourselves. We aren’t selfish. We disagree but commit.',
    },
  },
  {
    graphic: {
      data: brainIcon,
      isText: false,
    },
    content: {
      title: 'Empowerment',
      description:
        'We prefer a flat hierarchy and don’t pay much attention to titles. We encourage intelligent risk-taking; we like action, and we don’t waste time.',
    },
  },
  {
    graphic: {
      data: heartIcon,
      isText: false,
    },
    content: {
      title: 'Inclusion',
      description:
        'We encourage differences. We respect and seek out backgrounds and opinions that are different from our own.',
    },
  },
  {
    graphic: {
      data: speechBubbleIcon,
      isText: false,
    },
    content: {
      title: 'Open Communication',
      description:
        'We default to openness and happily acknowledge and embrace failures so we can learn from them.',
    },
  },
  {
    graphic: {
      data: mountainIcon,
      isText: false,
    },
    content: {
      title: 'Pursuit of Excellence',
      description:
        'We never sacrifice our high bar and optimize under the assumption that quality work hours from the best people are the scarcest resource.',
    },
  },
]

const learningGraphicContent: GraphicContent[] = [
  {
    graphic: {
      data: mortarboardIcon,
      isText: false,
    },
    content: {
      title: 'Continuous Education',
      description:
        'A benefit available to full-time employees that includes training courses and tuition reimbursement.',
    },
  },
  {
    graphic: {
      data: bookIcon,
      isText: false,
    },
    content: {
      title: 'Knowledge Days',
      description:
        'A monthly day set aside for engineers to break from their normal projects and focus on learning new skills.',
    },
  },
  {
    graphic: {
      data: labIcon,
      isText: false,
    },
    content: {
      title: 'Kensho AI Lab',
      description:
        'The start-of-the-art AI Lab in Kensho’s Cambridge office hosts events for AI and ML enthusiasts.',
    },
  },
  {
    graphic: {
      data: presentationIcon,
      isText: false,
    },
    content: {
      title: 'Tech Talks',
      description:
        'Where engineering team members share technical knowledge to help broaden the entire team’s skillbase.',
    },
  },
  {
    graphic: {
      data: fireIcon,
      isText: false,
    },
    content: {
      title: 'Fireside Chats',
      description:
        'Informal conversations on a variety of topics, from DEI issues to business strategy to technology.',
    },
  },
  {
    graphic: {
      data: journalIcon,
      isText: false,
    },
    content: {
      title: 'ML Journal Club',
      description:
        'A group of Kenshins who read Machine Learning journal articles, then meet to discuss interesting or applicable ideas.',
    },
  },
  {
    graphic: {
      data: placeSettingIcon,
      isText: false,
    },
    content: {
      title: 'Lunch & Learn',
      description: 'Sessions where we share insights and ideas over a brown bag lunch.',
    },
  },
  {
    graphic: {
      data: lightningIcon,
      isText: false,
    },
    content: {
      title: 'Lightning Talks',
      description:
        'Fun 5-minute talks on any topic with one rule - it can’t be related to the person’s job!',
    },
  },
]

const centerCss = css`
  display: flex;
  justify-content: center;
`

const bioCss = css`
  max-width: 640px;
  flex: 1 1 50%;
  padding: 0 30px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    max-width: 100%;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 0;
  }
`

const graphicContentGroupCss = css`
  margin-top: 20px;
`

const heroTitleCss = css`
  margin: 50px 0 170px 0;

  @media (max-width: 600px) {
    margin: 90px 0 130px 0;
  }
`

const aiLabSectionCss = css`
  padding: 65px 0 0 0;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding: 65px 0 0 0;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 50px 0 0 0;
  }
`

const twoColumnImgCss = css`
  width: 100%;
  height: 100%;
  display: flex;
`

const objectCoverCss = css`
  object-fit: cover;
`

const ensoGridImgCss = css`
  padding: 0 75px;
  max-width: 700px;
`

const imageContainerCss = css`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding-top: 45px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding-top: 20px;
  }
`

const imagePositionCss = css`
  max-height: 525px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    position: unset;
    max-height: 571px;
    width: auto;
  }
`

const noPaddingCss = css`
  padding: 0;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding: 0;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 0;
  }
`

const fullWidthCss = css`
  width: 99%;
`

const heroImageCss = css`
  background-image: url(${AboutHeroLarge});
  filter: contrast(140%) brightness(60%) grayscale(30%);

  @media (max-width: 2200px) {
    background-image: url(${AboutHeroMedium});
  }

  @media (max-width: 600px) {
    background-image: url(${AboutHeroSmall});
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    opacity: 1;
  }
`

const verticalMarginCss = css`
  margin-bottom: 45px;
`

const halfContentPaddingCss = css`
  padding: 65px 0;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding: 45px 0;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 20px 0;
  }
`

const ensoWrapperPaddingCss = css`
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding-bottom: 50px;
  }
`

export default function AboutPage(): JSX.Element {
  return (
    <Page
      title={
        <HeroSection imageCss={heroImageCss}>
          <WideContent>
            <AnimatedContent>
              <h1 css={[h1Css, hBoldCss, whiteTextCss, heroTitleCss]}>About</h1>
            </AnimatedContent>
          </WideContent>
        </HeroSection>
      }
    >
      <PageMetadata
        title="About | Kensho"
        description="Kensho pairs latest advances in machine learning with an unparalleled breadth and depth of data. Our people enable end users to make decisions with conviction."
        image={{source: socialImg, height: 1250, width: 2500}}
      />

      <section>
        <TwoColumnContent
          leftWrapperCss={[backgroundGradientCss, whiteTextCss]}
          left={
            <div css={halfContentPaddingCss}>
              <TopicHeadline text="Who We Are" css={noMarginCss} />
              <AnimatedContent>
                <h3 css={[h3TitleCss, hBoldCss, sectionHeadingMarginCss]}>
                  Driving Essential Intelligence Through AI & Machine Learning
                </h3>

                <p css={[h4Body1Css, bodyTextMarginCss]}>
                  Kensho is the AI and Innovation Hub for S&P Global. We develop cutting-edge
                  technologies that transform businesses.
                </p>

                <p css={[h4Body2Css, bodyTextMarginCss]}>
                  By pairing the latest advances in machine learning with the unparalleled breadth
                  and depth of data at S&P Global, Kensho gives customers comprehensive, timely,
                  actionable insights for making decisions with conviction.
                </p>
              </AnimatedContent>
            </div>
          }
          rightWrapperCss={backgroundBlackCss}
          rightContentCss={noPaddingCss}
          right={<img css={twoColumnImgCss} src={SwirlingDataImg} alt="Swirling data" />}
        />
      </section>

      <section css={[halfContentPaddingCss, backgroundWhiteCss]}>
        <TwoColumnContent
          left={
            <>
              <TopicHeadline text="What We Do" />
              <AnimatedContent>
                <h3 css={[h3SubheadlineCss, hBoldCss, sectionHeadingMarginCss]}>
                  Kensho leverages S&P’s world-class data to build machine learning applications
                  internally for S&P Global and externally for our clients.
                </h3>

                <p css={[h4Body2Css, bodyTextMarginCss]}>
                  We work primarily with natural language data, including complex documents and
                  speech, and build machine learning models that add layers of structure to
                  unstructured and semi-structured data.
                </p>

                <p css={[h4Body2Css, bodyTextMarginCss]}>
                  These foundational AI services improve the breadth and speed of data collection,
                  the depth of data enrichment, and the ease and relevance of data discovery,
                  solving some of the most difficult challenges facing businesses today.
                </p>

                <p css={[h4Body2Css, bodyTextMarginCss]}>
                  Kensho’s solutions transform unstructured data into meaningful and actionable
                  business insights.
                </p>
                <p css={[h4Body2Css, bodyTextMarginCss, hBoldCss]}>
                  Learn more about Kensho’s cutting-edge technology solutions
                </p>
              </AnimatedContent>
              <AnimatedContent animation="fade-in">
                <div css={centerCss}>
                  <ButtonLink
                    to="/solutions"
                    variant="primary"
                    css={[largeButtonCss, fullWidthCss]}
                  >
                    About Our Solutions
                  </ButtonLink>
                </div>
              </AnimatedContent>
            </>
          }
          rightContentCss={[noPaddingCss, imageContainerCss]}
          right={
            <img
              css={[twoColumnImgCss, imagePositionCss]}
              src={EnsoWithNetworkImg}
              alt="Outlined Kensho logo with network fill"
              loading="lazy"
            />
          }
        />
      </section>

      <section>
        <TwoColumnContent
          leftContentCss={noPaddingCss}
          left={
            <img
              css={twoColumnImgCss}
              src={CamOfficExteriorImg}
              alt="Exterior of Kensho's Cambridge office"
              loading="lazy"
            />
          }
          rightWrapperCss={backgroundWhiteCss}
          right={
            <div css={halfContentPaddingCss}>
              <AnimatedContent>
                <TopicHeadline text="Where We Are" css={noMarginCss} />

                <h3 css={[h3TitleCss, hBoldCss, sectionHeadingMarginCss]}>
                  Kensho is headquartered in Harvard Square in Cambridge, MA.
                </h3>

                <p css={[h4Body1Css, bodyTextMarginCss]}>
                  We also have offices in New York City and Washington, DC. Take a{' '}
                  <Link to="https://vimeo.com/760051398">virtual tour</Link> of our beautiful
                  Cambridge office
                </p>

                <p css={[h4Body2Css, bodyTextMarginCss]}>
                  Kensho was acquired by S&P Global in 2018, and continues to operate independently,
                  retaining its distinct brand, culture and technology stack.
                </p>
              </AnimatedContent>
            </div>
          }
        />
      </section>

      <section css={[baseSectionCss, backgroundWhiteCss]}>
        <WideContent>
          <AnimatedContent css={h4Body1Css}>
            <TopicHeadline id={SECTION_IDS.ABOUT.VALUES} text="Our Values" />
          </AnimatedContent>
        </WideContent>
        <TwoColumnHeadingContent
          headerText="We insist on action bias and minimizing unhelpful hierarchy and unnecessary process."
          additionalText="We collaborate to understand our teammates’ diverse perspectives to solve hard
          problems together. We dedicate work time and resources to explore new ideas and to
          learn new things. We produce technology that is scalable, robust, and useful. We
          communicate openly, honestly, efficiently, and with kindness for one another."
          textColor="black"
          css={twoColumnSectionHeadingMarginCss}
        />
        <WideContent>
          <HeadingContent headerText="Our five core values are:" css={sectionHeadingMarginCss} />
          <AnimatedContent>
            <GraphicContentGroup
              css={graphicContentGroupCss}
              graphicContent={valuesGraphicContent}
            />
            <div css={centerCss}>
              <ButtonLink
                css={[largeButtonCss, centerButtonCss]}
                to={`/careers#${SECTION_IDS.CAREERS.CULTURE}`}
                variant="primary"
              >
                Learn More
              </ButtonLink>
            </div>
          </AnimatedContent>
        </WideContent>
      </section>

      <section css={[baseSectionCss, backgroundWhiteCss]}>
        <WideContent>
          <AnimatedContent css={h4Body1Css}>
            <TopicHeadline text="Leadership Team" css={noMarginCss} />
          </AnimatedContent>
        </WideContent>
        <TwoColumnContent
          left={
            <AnimatedContent css={h4Body1Css}>
              <article css={bioCss}>
                <Avatar
                  size={200}
                  photo={Bhavesh}
                  name="Bhavesh Dayalji, CEO"
                  nameCss={h3SubheadlineCss}
                />
                <p css={[h4Body2Css, bodyTextMarginCss]}>
                  Bhavesh is the Chief Executive Officer of Kensho. He has worked to drive Kensho’s
                  growth through the use of its products at the world’s largest financial
                  institutions and the U.S. Intelligence Community. Bhavesh was one of the company’s
                  early hires, and has served in a variety of leadership roles focused on developing
                  and scaling Kensho’s products, engineering and client-facing teams before the
                  acquisition to S&P Global in 2018. Since the acquisition, Kensho has focused on
                  leveraging AI to increase product innovation and transform businesses.
                </p>
                <SingleQuote size="small">
                  Kensho’s strength comes from the curiosity of our people and the way we’re
                  motivated by the most complex problems.
                </SingleQuote>
                <p css={[h4Body2Css, bodyTextMarginCss]}>
                  Prior to Kensho, Bhavesh was a senior leader in Deloitte’s strategy and M&A
                  practice. He was the engagement leader for many high-profile C-suite clients,
                  advising them on M&A strategy, leading due diligence efforts and developing growth
                  strategies in the financial services industry.
                </p>
                <p css={[h4Body2Css, bodyTextMarginCss]}>
                  Bhavesh started his career working on the software engineering team at CERN, the
                  place the World Wide Web was invented. Bhavesh graduated with a BS in Software
                  Engineering and Physics from De Montfort University in Leicester, United Kingdom.
                </p>
              </article>
            </AnimatedContent>
          }
          right={
            <AnimatedContent css={h4Body1Css}>
              <article css={bioCss}>
                <Avatar
                  size={200}
                  photo={Melissa}
                  name="Melissa Whitehead, CTO"
                  nameCss={h3SubheadlineCss}
                />
                <p css={[h4Body2Css, bodyTextMarginCss]}>
                  Melissa joined Kensho as a software engineer in August of 2016, and has worked her
                  way through several roles with increasing impact, including running the Internal
                  Tools team and serving as Head of Data Engineering and Query Infrastructure.
                </p>
                <SingleQuote size="small">
                  For me, success looks like an engaged team who sees the technical path forward and
                  can confidently execute because they understand where we are going as a company.
                  There’s a lot of opportunity in front of us, and I’m excited to help shape what
                  lies ahead!
                </SingleQuote>
                <p css={[h4Body2Css, bodyTextMarginCss]}>
                  Melissa loves working at Kensho because of the way the team takes the time to get
                  to know each other as people, not just coworkers, through programs like My Story
                  and Kensho Trivia. She also appreciates how we use our skills to leave an imprint
                  on our community through Team Impact work, and how we take time to learn from both
                  our successes and our failures. “And we have amazing pun wars and fierce debates
                  over what constitutes a sandwich.”
                </p>
                <p css={[h4Body2Css, bodyTextMarginCss]}>
                  In her spare time, Melissa enjoys playing strategy board games with her kids and
                  amateur bird photography.
                </p>
              </article>
            </AnimatedContent>
          }
        />
      </section>

      <section css={[baseSectionCss, backgroundLightGrayCss]}>
        <WideContent>
          <TopicHeadline text="Diversity, Equity and Inclusion" />
        </WideContent>
        <TwoColumnContent
          leftContentCss={[noPaddingCss, ensoWrapperPaddingCss]}
          left={
            <AnimatedContent animation="fade-in">
              <div css={centerCss}>
                <img
                  css={[twoColumnImgCss, ensoGridImgCss]}
                  src={EnsoGridDEIImg}
                  alt="Grid of different colored Kensho logos"
                  loading="lazy"
                />
              </div>
            </AnimatedContent>
          }
          right={
            <AnimatedContent>
              <p css={[bodyTextMarginCss, h4Body2Css]}>
                Kensho is committed to creating a kind, diverse and equitable workplace, fostering a
                sense of belonging, and ensuring that the company is a place where employees can
                bring their whole selves to work. Our DEI efforts are run by our Chief of Staff
                extraordinaire, Meaghan Cassidy, who partners on a range of initiatives with
                individuals across Kensho and S&P Global, and all Kenshins are expected to actively
                participate in moving DEI efforts forward.
              </p>
              <p css={[bodyTextMarginCss, h4Body2Css]}>
                Some recent DEI initiatives have included a Tech Investment Day, which was used to
                eliminate non-inclusive language from our technology and coding terms, training
                courses and awareness workshops, a monthly newsletter of suggested DEI-related
                reading / listening / watching, mental health awareness, support for caregivers and
                parents, and more.
              </p>
              <div css={centerCss}>
                <ButtonLink
                  to={`/careers#${SECTION_IDS.CAREERS.DEI}`}
                  variant="primary"
                  css={[largeButtonCss, centerButtonCss]}
                >
                  See how DEI is central to our culture
                </ButtonLink>
              </div>
            </AnimatedContent>
          }
        />
      </section>

      <section css={[baseSectionCss, backgroundWhiteCss]}>
        <WideContent>
          <AnimatedContent>
            <TopicHeadline
              text="Intellectual Curiosity and Continuous Learning"
              css={noMarginCss}
            />
          </AnimatedContent>
        </WideContent>
        <TwoColumnHeadingContent
          headerText="Curiosity is part of Kensho’s DNA"
          additionalText="Curiosity is part of Kensho’s DNA. From grassroots meetups to company-sponsored
          programs, Kenshins have a huge range of options for keeping their knowledge and
          skills up to date and finding inspiration for new ideas."
          textColor="black"
          css={twoColumnSectionHeadingMarginCss}
        />
        <WideContent>
          <AnimatedContent>
            <GraphicContentGroup
              css={graphicContentGroupCss}
              graphicContent={learningGraphicContent}
              maxItemsPerRow={3}
            />
          </AnimatedContent>
        </WideContent>
      </section>

      <section css={[baseSectionCss, aiLabSectionCss, backgroundLightGrayCss]}>
        <WideContent>
          <HeadingContent headerText="Kensho AI Lab" css={sectionHeadingMarginCss} />
          <AnimatedContent>
            <p css={[h4Body2Css, verticalMarginCss]}>
              Kensho’s AI Lab, located in our Harvard Square headquarters, is a state-of-the-art
              facility for hosting meetups, tech talks and hackathons for AI and machine learning
              enthusiasts, software developers, and the local tech community.
            </p>
          </AnimatedContent>
        </WideContent>
        <AnimatedContent>
          <AILabImageRow />
        </AnimatedContent>
      </section>

      <section css={[baseSectionCss, backgroundWhiteCss]}>
        <WideContent>
          <AnimatedContent>
            <TopicHeadline text="Team Impact" css={noMarginCss} />
          </AnimatedContent>
        </WideContent>
        <TwoColumnContent
          left={
            <AnimatedContent>
              <h3 css={[h3SubheadlineCss, hBoldCss, sectionHeadingMarginCss]}>
                Team Impact takes the lead on Kensho‘s community involvement and skills-based
                volunteer efforts.
              </h3>

              <p css={[h4Body1Css, bodyTextMarginCss]}>
                The cross-functional team currently has three working groups – Data Journalism,
                Environmental Impact and Educational Equity.
              </p>
            </AnimatedContent>
          }
          right={
            <AnimatedContent css={h4Body1Css}>
              <p css={bodyTextMarginCss}>
                These working groups have tackled projects large and small with a range of external
                partners and an eye towards initiatives that allow Kenshins to use their in-demand
                skills in data science, machine learning and software engineering and more to have
                maximum positive impact in their communities.
              </p>
              <div css={centerCss}>
                <ButtonLink
                  to="/impact"
                  variant="secondary"
                  css={[largeButtonCss, centerButtonCss, fullWidthCss]}
                >
                  Read our Team Impact page
                </ButtonLink>
              </div>
            </AnimatedContent>
          }
        />
      </section>

      <section>
        <TwoColumnContent
          leftWrapperCss={[backgroundGradientCss, whiteTextCss]}
          left={
            <div css={halfContentPaddingCss}>
              <AnimatedContent>
                <TopicHeadline text="History" css={noMarginCss} />

                <h3 css={[h4Body1Css, hBoldCss, sectionHeadingMarginCss]}>
                  Founded out of Harvard and MIT in 2013, Kensho received backing from Google
                  Ventures, leading financial institutions such as Goldman Sachs, Bank of America
                  Merrill Lynch, JPMorgan Chase and S&P Global, and the venture capital arm of the
                  CIA, In-Q-Tel.
                </h3>

                <p css={[h4Body2Css, bodyTextMarginCss]}>
                  Over five years, the team at Kensho built analytical products used by some of the
                  world’s biggest financial institutions and the U.S. Intelligence Community.
                </p>

                <p css={[h4Body2Css, bodyTextMarginCss]}>
                  In April of 2018, Kensho was acquired by S&P Global, and the two companies have
                  partnered closely, with Kensho’s suite of technologies helping cement S&P Global’s
                  reputation as one of the world’s best data companies.
                </p>
                <div css={centerCss}>
                  <ButtonLink
                    to="/solutions"
                    variant="secondary"
                    css={[largeButtonCss, centerButtonCss, outlinedButtonOnDarkCss, fullWidthCss]}
                  >
                    Check out our latest AI & ML
                  </ButtonLink>
                </div>
              </AnimatedContent>
            </div>
          }
          rightWrapperCss={backgroundBlackCss}
          rightContentCss={noPaddingCss}
          right={
            <img
              css={[twoColumnImgCss, objectCoverCss]}
              src={BhaveshMeaghanImg}
              alt="Two Kensho employees having a coffee chat."
              loading="lazy"
            />
          }
        />
      </section>
    </Page>
  )
}
