import DeveloperContent from '../../components/DeveloperContent/DeveloperContent'

export default function DeveloperSection(): JSX.Element {
  return (
    <section>
      <DeveloperContent
        description="Find our developer documentation, for building tools with the NERD’s APIs and more"
        product="NERD"
        href="https://nerd.kensho.com/"
      />
    </section>
  )
}
