import {Helmet} from 'react-helmet'

import {CONSENT_COOKIE_NAME} from './constants'

export default function AppHead(): JSX.Element {
  return (
    <Helmet>
      <html lang="en" />

      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <link rel="preconnect" href="https://static.kensho.com" />

      <link rel="manifest" href="/site.webmanifest" />
      <meta name="theme-color" content="#eaf8fc" />
      <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
      <link rel="icon" type="image/svg+xml" href="/icons/favicon.svg" />
      <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#50afc6" />
      <meta name="msapplication-TileImage" content="/icons/mstile-144x144.png" />

      {/* fonts */}
      {/* intentionally not pre-loading light since its only usage is on the legal pages */}
      <link
        rel="prefetch"
        type="font/woff2"
        as="font"
        href="/fonts/proxima-nova-light.woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        type="font/woff2"
        as="font"
        href="/fonts/proxima-nova-regular.woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        type="font/woff2"
        as="font"
        href="/fonts/proxima-nova-medium.woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        type="font/woff2"
        as="font"
        href="/fonts/proxima-nova-semibold.woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        type="font/woff2"
        as="font"
        href="/fonts/proxima-nova-bold.woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        type="font/woff2"
        as="font"
        href="/fonts/proxima-nova-extrabold.woff2"
        crossOrigin="anonymous"
      />

      {/* Plausible analytics */}
      <script async defer data-domain="kensho.com" src="https://plausible.io/js/script.js" />

      {/* Global site tag (gtag.js) - Google Analytics */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-G5K9GB2NXM" />
      <script id="script-2">
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}

        var cookieConsent = Boolean(document.cookie.match(/${CONSENT_COOKIE_NAME}={[^;]*%22targeting%22:true%2C[^;]*}/));
        if (!cookieConsent) {
          window['ga-disable-G-G5K9GB2NXM'] = true
        }

        gtag('js', new Date());
        gtag('config', 'G-G5K9GB2NXM');
        `}
      </script>

      {/* Google Tag Manager */}
      <script id="script-3">
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}

        var cookieConsent = Boolean(document.cookie.match(/${CONSENT_COOKIE_NAME}={[^;]*%22targeting%22:true%2C[^;]*}/));
        if (cookieConsent) {
          gtag('consent', 'default', {
            ad_storage: 'granted',
            analytics_storage: 'granted',
            functionality_storage: 'granted',
            personalization_storage: 'granted',
            security_storage: 'granted',
          })
        } else {
          gtag('consent', 'default', {
            'ad_storage': 'denied',
            'analytics_storage': 'denied',
            'functionality_storage': 'denied',
            'personalization_storage': 'denied',
            'security_storage': 'denied'
          });
        }
        `}
      </script>
      <script id="script-4">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-WZD45KP');
        `}
      </script>
    </Helmet>
  )
}
