import {ClassNames, css} from '@emotion/react'
import {IconChevronRightLarge} from '@kensho/icons'

import teamCaseStudyPhoto from '../../assets/photos/team-case-study.jpg'
import cultureCaseStudyPhoto from '../../assets/photos/culture-case-study.jpg'
import Carousel from '../../components/Carousel/BaseCarousel'
import Link from '../../components/Link'
import {h3SubheadlineCss, h4Body2Css, labelCss, whiteTextCss} from '../../styles/common'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'

const containerCss = css`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const cardCss = css`
  flex: 1;
  padding: 30px 30px 175px 30px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding-bottom: 140px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding-left: 23px;
    padding-right: 23px;
    padding-bottom: 110px;
  }
`

const linkCss = css`
  text-decoration: underline;
`

const imageCss = css`
  width: 100%;
`

const noImageCss = css`
  padding-bottom: 0;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding-bottom: 0;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding-bottom: 0;
  }
`

const categoryCss = css`
  margin-bottom: 40px;
`

const titleCss = css`
  margin-bottom: 10px;
`

const noImageTitleCss = css`
  margin-top: 90px;
  margin-bottom: 130px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-top: 90px;
    margin-bottom: 130px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-top: 0;
    margin-bottom: 0;
  }
`

const cardLinkCss = css`
  display: inline-flex;
  align-items: center;
`

interface CaseStudyCardProps {
  category: string
  title: string
  imageSrc?: string
  imageAlt?: string
  content: React.ReactNode
}

function CaseStudyCard({
  category,
  title,
  imageSrc,
  imageAlt,
  content,
}: CaseStudyCardProps): JSX.Element {
  return (
    <div css={containerCss}>
      {imageSrc ? <img src={imageSrc} alt={imageAlt} css={imageCss} loading="lazy" /> : null}
      <div css={[cardCss, !imageSrc && noImageCss]}>
        <h4 css={[labelCss, categoryCss]}>{category}</h4>
        <h3 css={[h3SubheadlineCss, titleCss, !imageSrc && noImageTitleCss]}>{title}</h3>
        {content}
      </div>
    </div>
  )
}

const marginCss = css`
  margin-top: 40px;
  margin-bottom: 40px;
`

const slides: number[] = [0, 1, 2, 3]

const SLIDES_MAP = [
  <CaseStudyCard
    imageSrc={teamCaseStudyPhoto}
    imageAlt="Learn more about our teams and people"
    category="Team"
    title="See yourself here."
    content={
      <p css={h4Body2Css}>
        Learn more about{' '}
        <Link to="/team" css={[linkCss, whiteTextCss]}>
          our teams
        </Link>{' '}
        and{' '}
        <Link to="/careers" css={[linkCss, whiteTextCss]}>
          people
        </Link>
        .
      </p>
    }
  />,
  <CaseStudyCard
    category="Culture"
    title="Bring your whole self to work."
    imageSrc={cultureCaseStudyPhoto}
    imageAlt="Learn more about Kensho's unique culture"
    content={
      <p css={h4Body2Css}>
        Learn more about Kensho’s{' '}
        <Link to="/careers#culture" css={[linkCss, whiteTextCss]}>
          unique culture
        </Link>
        .
      </p>
    }
  />,
  <CaseStudyCard
    category="Benefits"
    title="World-class benefits for our world-class employees."
    content={
      <>
        <p css={[h4Body2Css, marginCss]}>
          We believe the best way for our employees to do great work is for us to support them.
          Whether you need some vacation time, time to support a new baby, or even time to take care
          of a new pup, we have benefits that support you outside of work.
        </p>
        <p css={[h4Body2Css, marginCss]}>
          We know that learning outside of our daily work helps us solve our toughest problems. We
          provide educational stipends for you to go to conferences, take courses, or pursue a
          higher degree.
        </p>
        <p css={[h4Body2Css, marginCss]}>
          <Link to="/benefits" css={[linkCss, whiteTextCss, cardLinkCss]}>
            More on Kensho’s Benefits
            <IconChevronRightLarge size={20} />
          </Link>
        </p>
      </>
    }
  />,
  <CaseStudyCard
    category="Diversity, Equity & Inclusion"
    title="DEI as a Cornerstone of Culture"
    content={
      <>
        <p css={[h4Body2Css, marginCss]}>
          At Kensho we know that people do their best work when they can be themselves. We strive to
          create a culture where Kenshins can share ideas freely and constructively challenge each
          other to achieve better outcomes. This includes cultivating trust with each other and with
          leadership, knowing that Kenshins are supportive of new ideas, risk taking,
          experimentation, and learning—even if learning comes from failure. Our culture of
          innovation wouldn’t be possible without intentional efforts to increase diversity, equity,
          and inclusion.
        </p>
        <p css={[h4Body2Css, marginCss]}>
          <Link to="/dei" css={[linkCss, whiteTextCss, cardLinkCss]}>
            Learn more about DEI at Kensho
            <IconChevronRightLarge size={20} />
          </Link>
        </p>
      </>
    }
  />,
]

const slideCss = css`
  white-space: normal;
  display: inline-block;
  width: 100%;
  height: 100%;
  vertical-align: top;

  position: relative;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: auto;
  }
`

function renderSlide(slide: number, spilloverIndex?: number): JSX.Element {
  return (
    <div
      key={slide}
      css={slideCss}
      // N.B. Slides outside the typical bounds are rendered specially to maintain the animation when adding new nodes.
      style={
        spilloverIndex === undefined
          ? undefined
          : {position: 'absolute', transform: `translateX(${spilloverIndex * 100}%)`}
      }
    >
      {SLIDES_MAP[slide]}
    </div>
  )
}

export default function CaseStudyCarousel(): JSX.Element {
  return (
    <ClassNames>
      {({css}) => (
        <Carousel
          slides={slides}
          slideClassName={css`
            padding-right: 0;
          `}
          slideRenderer={renderSlide}
          slideKeyGen={(slide) => slide}
          buttonColor="blue"
        />
      )}
    </ClassNames>
  )
}
