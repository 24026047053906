import {css} from '@emotion/react'

import ChrisPhoto from '../../assets/people/chris.png'
import MichaelPhoto from '../../assets/people/michael.png'
import CharliePhoto from '../../assets/people/charlie.png'
import VarshiniPhoto from '../../assets/people/varshini.png'
import {backgroundLightGrayCss, baseSectionCss} from '../../styles/common'
import {AnimatedContent, WideContent} from '../../components/content'
import {HeadingContent} from '../../components/headings'
import {BREAKPOINT_SMALL} from '../../styles/breakpoints'

import BioCard, {BioCardProps} from './BioCard'

const cardContainerCss = css`
  margin: 70px 0;
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0;
  }
`

const bioData: BioCardProps[] = [
  {
    headshot: ChrisPhoto,
    name: 'Chris',
    title: 'Head of R&D',
    text: 'Chris created and leads Kensho’s R&D lab, and he holds a joint faculty appointment at MIT, where he teaches ML and NLP. Previously, he taught and advised graduate students at Harvard. Since 2004, he has conducted ML research within industry, government, and academia, including at MIT Lincoln Laboratory, Spotify, Google, IBM Watson. He received his PhD from Brown University.',
  },
  {
    headshot: MichaelPhoto,
    name: 'Michael',
    title: 'Research Scientist',
    text: 'Michael leads language model efforts at Kensho, which includes training, alignment and evaluation. Previously, Michael was part of a federally funded R&D center, leading research teams focusing on AI-enhanced decision making and ML security. He holds an M.S. in Computational Science and Engineering from Harvard.',
  },
  {
    headshot: VarshiniPhoto,
    name: 'Varshini',
    title: 'Research Engineer',
    text: 'Varshini’s research focuses on developing and evaluating language models for financial applications, with specific focus on both tokenization and retrieval-based approaches (RAG). Prior to joining Kensho’s R&D team as a Research Engineer, she obtained her Master’s in Data Science from Harvard.',
  },
  {
    headshot: CharliePhoto,
    name: 'Charlie',
    title: 'Research Scientist',
    text: 'Charlie is interested in both language model alignment for conversational and code-based use cases, and understanding what and how language models learn. Before joining Kensho, he completed his PhD at Brown University as part of the Language Understanding and Representation Lab.',
  },
]

export default function TeamSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundLightGrayCss]}>
      <WideContent>
        <HeadingContent headerText="Meet the R&D Team" />
      </WideContent>
      <WideContent css={cardContainerCss}>
        {bioData.map(({headshot, name, title, text}) => (
          <AnimatedContent animation="fade-in" key={name}>
            <BioCard headshot={headshot} name={name} title={title} text={text} />
          </AnimatedContent>
        ))}
      </WideContent>
    </section>
  )
}
