import {css} from '@emotion/react'
import {useElementWidth, useInterval} from '@kensho/tacklebox'
import {useCallback, useEffect, useMemo, useState} from 'react'

import {BREAKPOINT_SMALL} from '../../../styles/breakpoints'
import {labelCss, hBoldCss} from '../../../styles/common'

interface Duration {
  years: number
  weeks: number
  days: number
  hours: number
  minutes: number
  seconds: number
  milliseconds: number
}

interface DurationProps {
  initialDuration?: number
  rate?: number // accepts negatives
  interval?: number
  className?: string
  animate?: boolean
  animateDuration?: number
}

function easeOutSine(x: number): number {
  return Math.sin((x * Math.PI) / 2)
}

function convertMsToTime(ms: number): Duration {
  let remainder = ms

  const milliseconds = ms % 1000
  remainder = (remainder - milliseconds) / 1000
  const seconds = remainder % 60
  remainder = (remainder - seconds) / 60
  const minutes = remainder % 60
  remainder = (remainder - minutes) / 60
  const hours = remainder % 24
  remainder = (remainder - hours) / 24
  const days = remainder % 7
  remainder = (remainder - days) / 7
  const weeks = remainder % 52
  const years = (remainder - weeks) / 52

  return {
    years,
    weeks,
    days,
    hours,
    minutes,
    seconds,
    milliseconds,
  }
}

const durationCss = css`
  display: flex;
  justify-content: center;
  width: 100%;
`

const numberCss = css`
  font-size: 100px;
  line-height: 100px;
  margin: 0;
  color: #00799c;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    font-size: 60px;
    line-height: 60px;
  }
`

const unitAndLabelCss = css`
  width: 125px;

  > p {
    text-align: center;
  }

  &:first-of-type {
    min-width: 125px;
    width: auto;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 85px;

    &:first-of-type {
      min-width: 85px;
    }
  }
`

const timeLabelCss = css`
  color: #00799c;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 0.1em;
`

const delimiterCss = css`
  margin: 0 10px;
`

const unitCss = css`
  display: flex;
`

/** Counts time duration at the specified interval and rate. Matches real time by default. */
export default function Duration({
  className,
  initialDuration = 0,
  rate = 1,
  interval = 1000,
  animate = false,
  animateDuration = 1500,
}: DurationProps): JSX.Element {
  const [duration, setDuration] = useState(animate ? 0 : initialDuration)
  const [enterAnimationCompleted, setEnterAnimationCompleted] = useState(!animate)

  useEffect(() => {
    let animateInterval: number
    if (animate && !enterAnimationCompleted) {
      const startMs = Date.now()
      animateInterval = window.setInterval(() => {
        const elapsedMs = Date.now() - startMs
        if (elapsedMs >= animateDuration) setEnterAnimationCompleted(true)
        setDuration(
          initialDuration * easeOutSine(Math.min(elapsedMs, animateDuration) / animateDuration),
        )
      }, 1000 / 30)
    }

    return () => {
      window.clearInterval(animateInterval)
    }
  }, [animate, animateDuration, initialDuration, enterAnimationCompleted])

  const time = useMemo(() => convertMsToTime(duration), [duration])
  const increment = useCallback(() => {
    if (enterAnimationCompleted)
      setDuration((prevDuration) => Math.max(Math.floor(prevDuration + rate * interval), 0))
  }, [rate, interval, enterAnimationCompleted])
  useInterval(increment, interval)
  const [width, widthRef] = useElementWidth()
  const displayUnits: (keyof Duration)[] =
    width < 750
      ? ['years', 'minutes', 'seconds']
      : ['years', 'weeks', 'days', 'hours', 'minutes', 'seconds']

  return (
    <div ref={widthRef} css={durationCss} className={className}>
      {displayUnits.map((unit, i) => (
        <div key={unit} css={unitCss}>
          <div css={unitAndLabelCss}>
            <p css={[numberCss, hBoldCss]}>{`${time[unit]}`.padStart(2, '0')}</p>
            <p css={[timeLabelCss, labelCss]}>{unit}</p>
          </div>
          {i < displayUnits.length - 1 && <span css={[numberCss, hBoldCss, delimiterCss]}>:</span>}
        </div>
      ))}
    </div>
  )
}
