import LinkGraphic from '../../assets/solutions/link.svg'
import {URLS} from '../../constants'
import {bulletedListCss, bulletedListItemCss} from '../../styles/common'

import SolutionCard from './SolutionCard'

export default function SolutionsLink(): JSX.Element {
  return (
    <SolutionCard
      label="Map Company Entities to External Databases"
      graphic={{src: LinkGraphic, alt: 'Link Icon'}}
      title="Link"
      description="Kensho Link maps your messy company data to S&P Global’s Company IDs, bringing structure to your datasets and allowing you to connect to the unparalleled quality and depth of S&P Global’s data."
      learnMoreUrl="/link"
      mainContent={
        <ul css={bulletedListCss}>
          <li css={bulletedListItemCss}>
            Company entities without unique identifiers? No problem!
          </li>
          <li css={bulletedListItemCss}>No need to spend time training an ML model.</li>
          <li css={bulletedListItemCss}>
            Input the data you have for a given entity (name, address, country, alias, etc.) and
            receive back probable links.
          </li>
          <li css={bulletedListItemCss}>
            Quickly spot the most relevant matches for each entity using Link’s match scores.
          </li>
          <li css={bulletedListItemCss}>
            Get matches with as little as a company name, but improve the accuracy by providing more
            fields.
          </li>
          <li css={bulletedListItemCss}>
            Gain access to all of S&P Global’s extensive data on your matched entity with S&P
            Global’s Company IDs.
          </li>
        </ul>
      }
      linkList={[
        {href: 'https://link.kensho.com', text: 'Developer Docs'},
        {href: 'mailto:sales@kensho.com?subject=Link', text: 'Contact Link'},
      ]}
      callToActionLink={{
        href: `${URLS.FREE_TRIAL}/link`,
        text: 'Free Trial',
      }}
    />
  )
}
