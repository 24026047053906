import {css} from '@emotion/react'

import DefineIcon from '../../assets/icons/classify-define.svg'
import TrainIcon from '../../assets/icons/classify-train.svg'
import AnnotateIcon from '../../assets/icons/classify-annotate.svg'
import GraphicContentGroup from '../../components/GraphicContentGroup'
import TopicHeadline from '../../components/TopicHeadline'
import {WideContent, AnimatedContent} from '../../components/content'
import {blackTextCss, backgroundWhiteCss, baseSectionCss} from '../../styles/common'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'

const widthCss = css`
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    min-width: 444px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    min-width: unset;
  }
`

export default function ProcessSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundWhiteCss]}>
      <WideContent>
        <TopicHeadline css={blackTextCss} text="How Classify Works" />
        <AnimatedContent animation="fade-in">
          <GraphicContentGroup
            graphicContent={[
              {
                graphic: {data: DefineIcon, isText: false},
                content: {
                  title: '1. Define',
                  description: (
                    <p css={widthCss}>
                      To make a custom concept, give Classify a name, definition, and ≥30 data
                      samples
                    </p>
                  ),
                },
              },
              {
                graphic: {data: TrainIcon, isText: false},
                content: {
                  title: '2. Train',
                  description: (
                    <p css={widthCss}>
                      Submit the definition to train your model in as little as 3 minutes. Add as
                      many concepts as you like or revise previously trained concepts.
                    </p>
                  ),
                },
              },
              {
                graphic: {data: AnnotateIcon, isText: false},
                content: {
                  title: '3. Annotate',
                  description: (
                    <p css={widthCss}>
                      Send text for Classify to indicate whether and where your concepts are found.
                    </p>
                  ),
                },
              },
            ]}
            maxItemsPerRow={3}
          />
        </AnimatedContent>
      </WideContent>
    </section>
  )
}
