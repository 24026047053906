import {css} from '@emotion/react'

import {AnimatedContent, TwoColumnContent, WideContent} from '../../components/content'
import TopicHeadline from '../../components/TopicHeadline'
import ButtonLink from '../../components/Button/ButtonLink'
import {TwoColumnHeadingContent} from '../../components/headings'
import bulletIcon from '../../assets/icons/list-bullet.svg'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {
  h4Body1Css,
  backgroundLightGrayCss,
  blackTextCss,
  baseSectionCss,
  twoColumnSectionHeadingMarginCss,
} from '../../styles/common'
import {URLS} from '../../constants'

import ExtractAnimatedDocument from './ExtractAnimatedDocument'

const animatedColumnsCss = css`
  margin-bottom: 60px;
  align-items: center;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    align-items: center;
  }
`

const leftHeightCss = css`
  height: 800px;
  overflow: hidden;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    height: 965px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 420px;
  }
`

const rightCss = css`
  padding-left: 80px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-top: 40px;
    max-width: 630px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-top: -20px;
  }
`

const listCss = css`
  list-style-image: url(${bulletIcon});
  padding-top: 64px;
  padding-left: 22px;
  margin: 0;
`

const itemPaddingCss = css`
  padding-left: 4px;
  padding-bottom: 24px;
`

const buttonCss = css`
  margin: 0px auto;
  max-width: 908px;
  height: 56px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    max-width: 750px;
  }
`

const listItemCss = [h4Body1Css, blackTextCss, itemPaddingCss]

export default function SampleSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundLightGrayCss]}>
      <WideContent>
        <TopicHeadline text="See for yourself" css={blackTextCss} />
      </WideContent>
      <TwoColumnHeadingContent
        headerText="Add structure to unstructured documents."
        additionalText="Kensho Extract is a fundamental machine learning (ML) capability that allows users to
        access both text and tables in a simple-to-use format for further analysis and action. "
        textColor="black"
        css={twoColumnSectionHeadingMarginCss}
      />
      <TwoColumnContent
        css={animatedColumnsCss}
        leftContentCss={leftHeightCss}
        left={
          <AnimatedContent animation="fade-in">
            <ExtractAnimatedDocument />
          </AnimatedContent>
        }
        rightContentCss={rightCss}
        right={
          <AnimatedContent animation="fade-in">
            <p css={[h4Body1Css, blackTextCss]}>
              Extract can be used independently or in conjunction with other services offered by
              Kensho. Combining our document layout analysis and table structure recognition models,
              Extract allows users to:
            </p>
            <ul css={listCss}>
              <li css={listItemCss}>
                Quickly transform unstructured documents into a machine-readable format that
                organizes the headers, titles, paragraphs, tables and footers detected within the
                document in natural reading order.{' '}
              </li>
              <li css={listItemCss}>
                Interpret messy page layouts, structuring text into cohesive paragraphs that can
                then be effectively analyzed and searched.
              </li>
            </ul>
          </AnimatedContent>
        }
      />
      <WideContent>
        <ButtonLink to={`${URLS.FREE_TRIAL}/extract`} variant="primary" css={buttonCss}>
          Get Started
        </ButtonLink>
      </WideContent>
    </section>
  )
}
