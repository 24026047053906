import {css} from '@emotion/react'

import linkAboutImg from '../../assets/photos/link-about-image.jpg'
import {AnimatedContent, TwoColumnContent} from '../../components/content'
import TopicHeadline from '../../components/TopicHeadline'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {
  h3TitleCss,
  h4Body1Css,
  hBoldCss,
  whiteTextCss,
  backgroundGradientCss,
  backgroundMidGrayCss,
  sectionHeadingMarginCss,
} from '../../styles/common'

const leftHalfCss = css`
  padding: 100px 0;

  @media (max-width: ${BREAKPOINT_LARGE}) {
    padding: 80px 0;
  }

  @media (max-width: ${BREAKPOINT_SMALL}) {
    padding: 55px 0;
  }
`

const fullSizeCss = css`
  height: 100%;
  width: 100%;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    height: 850px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 500px;
  }
`

const bottomMarginCss = css`
  margin-bottom: 25px;
`

const imageContainerCss = css`
  padding: 0;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding: 0;
    max-width: unset;
  }
`

const imageCss = css`
  object-fit: cover;
`

export default function AboutSection(): JSX.Element {
  return (
    <section>
      <TwoColumnContent
        leftWrapperCss={backgroundGradientCss}
        left={
          <div css={[leftHalfCss, whiteTextCss]}>
            <TopicHeadline text="About Link" />
            <AnimatedContent>
              <h3 css={[sectionHeadingMarginCss, h3TitleCss, hBoldCss]}>
                Connected Data is More Valuable Data
              </h3>
            </AnimatedContent>
            <AnimatedContent css={bottomMarginCss}>
              <p css={h4Body1Css}>
                Kensho Link is a machine learning service that maps entities in your database with
                unique ID numbers drawn from S&amp;P Global’s world-class company database with
                precision and speed. Link uses an AI algorithm trained to return high quality links,
                even when the data inputs are incomplete or contain errors.
              </p>
            </AnimatedContent>
            <AnimatedContent css={bottomMarginCss}>
              <p css={h4Body1Css}>
                Once mapped to these unique IDs, a database becomes much more powerful. It is linked
                and cleaned, making data enrichment and deduplication seamless.
              </p>
            </AnimatedContent>
            <AnimatedContent>
              <p css={h4Body1Css}>
                Whether you want to clean and organize your data or enrich it with S&amp;P Global’s
                data, Kensho Link makes your data more valuable.
              </p>
            </AnimatedContent>
          </div>
        }
        rightWrapperCss={backgroundMidGrayCss}
        rightContentCss={imageContainerCss}
        right={
          <AnimatedContent animation="fade-in" css={fullSizeCss} innerCss={fullSizeCss}>
            <img
              src={linkAboutImg}
              alt="A crystalline structure representing how link connects nodes between various data sources."
              height="100%"
              width="100%"
              css={imageCss}
              loading="lazy"
            />
          </AnimatedContent>
        }
      />
    </section>
  )
}
