import {css} from '@emotion/react'

import {BREAKPOINT_XLARGE, BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import COLORS from '../../styles/colors'

const containerCss = css`
  position: relative;
  height: 100%;
  margin: 0 18px;
  padding-top: 5%;

  @media (max-width: ${BREAKPOINT_XLARGE}px) {
    margin: 0 12px;
  }

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin: 0 18px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin: 0 3px;
    padding-top: 2%;
  }
`

const annotationCss = css`
  text-transform: uppercase;
  color: #50afc6;
  font-size: 14px;
  font-weight: 800;
  position: absolute;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    font-size: 9px;
  }
`

// Animation occurs over 11s
// All animations are defined once per screen size, e.g. desktop (>${BREAKPOINT_LARGE}px), tablet (500-${BREAKPOINT_LARGE}px), and mobile (<${BREAKPOINT_SMALL}px)
// This ensures that animation components don't get out of sync with scannerLoop animation when screen size changes

const titleAnnotationCss = css`
  bottom: 93%;
  right: 52%;

  @keyframes titleAnnotationEntranceLoopWide {
    from,
    2% {
      right: 32%;
      opacity: 0;
    }

    9%,
    to {
      right: 52%;
      opacity: 1;
    }
  }

  @keyframes titleAnnotationEntranceLoopMed {
    from,
    2% {
      right: 32%;
      opacity: 0;
    }

    9%,
    to {
      right: 52%;
      opacity: 1;
    }
  }

  @keyframes titleAnnotationEntranceLoopMobile {
    from,
    2% {
      right: 32%;
      opacity: 0;
    }

    9%,
    to {
      right: 52%;
      opacity: 1;
    }
  }
`

const titleAnnotationAnimationCss = css`
  animation: titleAnnotationEntranceLoopWide 11s infinite;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    animation: titleAnnotationEntranceLoopMobile 11s infinite;
  }
`

const subtitleAnnotationCss = css`
  bottom: 90.5%;
  left: 0%;

  @keyframes subtitleAnnotationEntranceLoopWide {
    from,
    3.5% {
      left: 20%;
      opacity: 0;
    }

    10.5%,
    to {
      left: 0%;
      opacity: 1;
    }
  }

  @keyframes subtitleAnnotationEntranceLoopMed {
    from,
    3.5% {
      left: 20%;
      opacity: 0;
    }

    10.5%,
    to {
      left: 0%;
      opacity: 1;
    }
  }

  @keyframes subtitleAnnotationEntranceLoopMobile {
    from,
    3.5% {
      left: 20%;
      opacity: 0;
    }

    10.5%,
    to {
      left: 0%;
      opacity: 1;
    }
  }
`

const subtitleAnnotationAnimationCss = css`
  animation: subtitleAnnotationEntranceLoopWide 11s infinite;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    animation: subtitleAnnotationEntranceLoopMobile 11s infinite;
  }
`

const tableTitleAnnotationCss = css`
  bottom: 85.5%;
  right: 58%;

  @keyframes tableTitleAnnotationEntranceLoopWide {
    from,
    5% {
      right: 78%;
      opacity: 0;
    }

    12%,
    to {
      right: 58%;
      opacity: 1;
    }
  }

  @keyframes tableTitleAnnotationEntranceLoopMed {
    from,
    5% {
      right: 78%;
      opacity: 0;
    }

    12%,
    to {
      right: 58%;
      opacity: 1;
    }
  }

  @keyframes tableTitleAnnotationEntranceLoopMobile {
    from,
    5% {
      right: 78%;
      opacity: 0;
    }

    12%,
    to {
      right: 58%;
      opacity: 1;
    }
  }
`

const tableTitleAnnotationAnimationCss = css`
  animation: tableTitleAnnotationEntranceLoopWide 11s infinite;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    animation: tableTitleAnnotationEntranceLoopMobile 11s infinite;
  }
`

const grossRevenueAnnotationCss = css`
  top: 23.5%;
  right: 44%;
  z-index: 1;
  opacity: 0;

  background: rgba(255, 255, 255, 0.6);
  line-height: 1em;
  text-align: right;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    top: 28.5%;
    right: 47%;
    font-size: 8px;
  }

  @keyframes grossRevenueAnnotationEntranceLoopWide {
    from,
    10%,
    93.5%, /* <10.5s */
    to {
      right: 64%;
      opacity: 0;
    }

    17%,
    88% {
      /* >9.5s */
      right: 44%;
      opacity: 1;
    }
  }

  @keyframes delayedGrossRevenueAnnotationEntranceLoopWide {
    from,
    57%,
    95.6%, /* 10.5s */
    to {
      opacity: 0;
    }

    67%,
    90.9% /* 10s */ {
      opacity: 1;
    }
  }

  @keyframes grossRevenueAnnotationEntranceLoopMed {
    from,
    10%,
    93.5%, /* <10.5s */
    to {
      right: 64%;
      opacity: 0;
    }

    17%,
    88% {
      /* >9.5s */
      right: 44%;
      opacity: 1;
    }
  }

  @keyframes delayedGrossRevenueAnnotationEntranceLoopMed {
    from,
    57%,
    95.6%, /* 10.5s */
    to {
      opacity: 0;
    }

    67%,
    90.9% /* 10s */ {
      opacity: 1;
    }
  }

  @keyframes grossRevenueAnnotationEntranceLoopMobile {
    from,
    10%,
    93.5%, /* <10.5s */
    to {
      right: 67%;
      opacity: 0;
    }

    17%,
    88% {
      /* >9.5s */
      right: 47%;
      opacity: 1;
    }
  }

  @keyframes delayedGrossRevenueAnnotationEntranceLoopMobile {
    from,
    60%,
    95.6%, /* 10.5s */
    to {
      opacity: 0;
    }

    70%,
    90.9% /* 10s */ {
      opacity: 1;
    }
  }
`

const grossRevenueAnnotationAnimationCss = css`
  animation: grossRevenueAnnotationEntranceLoopWide 11s infinite;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    animation: grossRevenueAnnotationEntranceLoopMobile 11s infinite;
  }
`

const delayedGrossRevenueAnnotationAnimationCss = css`
  animation: delayedGrossRevenueAnnotationEntranceLoopWide 11s infinite;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    animation: delayedGrossRevenueAnnotationEntranceLoopMobile 11s infinite;
  }
`

const netIncomeAnnotationCss = css`
  top: 52.5%;
  right: 12%;
  z-index: 1;
  opacity: 0;

  background: rgba(255, 255, 255, 0.6);

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    top: 78%;
  }

  @keyframes netIncomeAnnotationEntranceLoopWide {
    from,
    22%,
    93.5%, /* <10.5s */
    to {
      right: 32%;
      opacity: 0;
    }

    29%,
    88% {
      /* >9.5s */
      right: 12%;
      opacity: 1;
    }
  }

  @keyframes delayedNetIncomeAnnotationEntranceLoopWide {
    from,
    68%,
    95.6%, /* 10.5s */
    to {
      opacity: 0;
    }

    76%,
    90.9% /* 10s */ {
      opacity: 1;
    }
  }

  @keyframes netIncomeAnnotationEntranceLoopMed {
    from,
    28%,
    93.5%, /* <10.5s */
    to {
      right: 32%;
      opacity: 0;
    }

    34%,
    88% {
      /* >9.5s */
      right: 12%;
      opacity: 1;
    }
  }

  @keyframes delayedNetIncomeAnnotationEntranceLoopMed {
    from,
    74%,
    95.6%, /* 10.5s */
    to {
      opacity: 0;
    }

    82%,
    90.9% /* 10s */ {
      opacity: 1;
    }
  }

  @keyframes netIncomeAnnotationEntranceLoopMobile {
    from,
    32%,
    93.5%, /* <10.5s */
    to {
      right: 32%;
      opacity: 0;
    }

    38%,
    88% {
      /* >9.5s */
      right: 12%;
      opacity: 1;
    }
  }

  @keyframes delayedNetIncomeAnnotationEntranceLoopMobile {
    from,
    80%,
    95.6%, /* 10.5s */
    to {
      opacity: 0;
    }

    86%,
    90.9% /* 10s */ {
      opacity: 1;
    }
  }
`

const netIncomeAnnotationAnimationCss = css`
  animation: netIncomeAnnotationEntranceLoopWide 11s infinite;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    animation: netIncomeAnnotationEntranceLoopMobile 11s infinite;
  }
`

const delayedNetIncomeAnnotationAnimationCss = css`
  animation: delayedNetIncomeAnnotationEntranceLoopWide 11s infinite;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    animation: delayedNetIncomeAnnotationEntranceLoopMobile 11s infinite;
  }
`

const annotationOutlineCss = css`
  position: absolute;
  border: 2px solid #50afc6;
`

const titleOutlineCss = css`
  top: 7%;
  left: 36%;
  right: 36%;
  bottom: 90.5%;
`

const subtitleOutlineCss = css`
  top: 9%;
  left: -1%;
  bottom: 88%;
  right: 81%;
`

const tableTitleOutlineCss = css`
  top: 14%;
  left: 37%;
  right: 37%;
  bottom: 83%;
`

const tableCss = css`
  width: 100%;
  position: absolute;
  top: 17%;
  table-layout: fixed;

  font-family: proxima-nova;
  font-size: 10px;
  line-height: 14px;

  background: ${COLORS.white};
  border-collapse: collapse;
  border: 2px solid #50afc6;

  th,
  td {
    border: 2px solid #50afc6;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    font-size: 7px;
    line-height: 7px;
  }
`

const dateCellCss = css`
  width: 20%;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 25%;
  }
`

const dateTextCss = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    max-width: 26px;
  }
`

const ellipsisCss = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    max-width: 135px;
  }
`

interface ExtractedDocumentContentProps {
  delay: boolean
  fuse: boolean
}

export default function ExtractedDocumentContent({
  delay,
  fuse,
}: ExtractedDocumentContentProps): JSX.Element {
  const isAnimating = fuse && !delay
  const grossRevenueAnimationCss = delay
    ? delayedGrossRevenueAnnotationAnimationCss
    : grossRevenueAnnotationAnimationCss
  const netIncomeAnimationCss = delay
    ? delayedNetIncomeAnnotationAnimationCss
    : netIncomeAnnotationAnimationCss
  return (
    <div css={containerCss}>
      <div css={[annotationCss, titleAnnotationCss, isAnimating && titleAnnotationAnimationCss]}>
        Document section
      </div>
      <div css={[annotationOutlineCss, titleOutlineCss]} />
      <div
        css={[annotationCss, subtitleAnnotationCss, isAnimating && subtitleAnnotationAnimationCss]}
      >
        Subtitle
      </div>
      <div css={[annotationOutlineCss, subtitleOutlineCss]} />
      <div
        css={[
          annotationCss,
          tableTitleAnnotationCss,
          isAnimating && tableTitleAnnotationAnimationCss,
        ]}
      >
        Table title
      </div>
      <div css={[annotationOutlineCss, tableTitleOutlineCss]} />
      <div css={[annotationCss, grossRevenueAnnotationCss, fuse && grossRevenueAnimationCss]}>
        Gross revenue Q2 2021:
        <br />
        $2,106
      </div>
      <div css={[annotationCss, netIncomeAnnotationCss, fuse && netIncomeAnimationCss]}>
        Net income H1 2021: $1,553
      </div>
      <table css={tableCss}>
        <tbody>
          <tr>
            <th colSpan={1} rowSpan={2} css={ellipsisCss}>
              <span>
                <strong>
                  Consolidated Statements of Income (Unaudited) - USD ($)
                  <br />
                  shares in Millions, $ in Millions
                </strong>
              </span>
            </th>
            <th colSpan={2} css={dateCellCss}>
              3 Months Ended
            </th>
            <th colSpan={2} css={dateCellCss}>
              6 Months Ended
            </th>
          </tr>
          <tr>
            <th>
              <div css={dateTextCss}>Jun. 30, 2021</div>
            </th>
            <th>
              <div css={dateTextCss}>Jun. 30, 2020</div>
            </th>
            <th>
              <div css={dateTextCss}>Jun. 30, 2021</div>
            </th>
            <th>
              <div css={dateTextCss}>Jun. 30, 2020</div>
            </th>
          </tr>
          <tr>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <td />
          </tr>
          <tr>
            <td css={ellipsisCss} valign="top">
              <strong>Income Statement [Abstract]</strong>
            </td>
            <td>&#160;</td>
            <td>&#160;</td>
            <td>&#160;</td>
            <td>&#160;</td>
          </tr>
          <tr>
            <td css={ellipsisCss} valign="top">
              Revenue
            </td>
            <td>$ 2,106</td>
            <td>$ 1,943</td>
            <td>$ 4,122</td>
            <td>$ 3,729</td>
          </tr>
          <tr>
            <td css={ellipsisCss} valign="top">
              <strong>Expenses:</strong>
            </td>
            <td>&#160;</td>
            <td>&#160;</td>
            <td>&#160;</td>
            <td>&#160;</td>
          </tr>
          <tr>
            <td css={ellipsisCss} valign="top">
              Operating-related expenses
            </td>
            <td>533</td>
            <td>493</td>
            <td>1,060</td>
            <td>1,011</td>
          </tr>
          <tr>
            <td css={ellipsisCss} valign="top">
              Selling and general expenses
            </td>
            <td>374</td>
            <td>295</td>
            <td>735</td>
            <td>609</td>
          </tr>
          <tr>
            <td css={ellipsisCss} valign="top">
              Depreciation
            </td>
            <td>23</td>
            <td>19</td>
            <td>42</td>
            <td>39</td>
          </tr>
          <tr>
            <td css={ellipsisCss} valign="top">
              Amortization of intangibles
            </td>
            <td>22</td>
            <td>32</td>
            <td>53</td>
            <td>61</td>
          </tr>
          <tr>
            <td css={ellipsisCss} valign="top">
              Total expenses
            </td>
            <td>952</td>
            <td>839</td>
            <td>1,890</td>
            <td>1,720</td>
          </tr>
          <tr>
            <td css={ellipsisCss} valign="top">
              Gain on dispositions
            </td>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <td />
            <td>(1)</td>
            <td>(2)</td>
            <td>(8)</td>
          </tr>
          <tr>
            <td css={ellipsisCss} valign="top">
              Operating profit
            </td>
            <td>1,154</td>
            <td>1,105</td>
            <td>2,234</td>
            <td>2,017</td>
          </tr>
          <tr>
            <td css={ellipsisCss} valign="top">
              Other income, net
            </td>
            <td>(22)</td>
            <td>(10)</td>
            <td>(29)</td>
            <td>(9)</td>
          </tr>
          <tr>
            <td css={ellipsisCss} valign="top">
              Interest expense, net
            </td>
            <td>32</td>
            <td>40</td>
            <td>63</td>
            <td>74</td>
          </tr>
          <tr>
            <td css={ellipsisCss} valign="top">
              Income before taxes on income
            </td>
            <td>1,144</td>
            <td>1,075</td>
            <td>2,200</td>
            <td>1,952</td>
          </tr>
          <tr>
            <td css={ellipsisCss} valign="top">
              Provision for taxes on income
            </td>
            <td>287</td>
            <td>233</td>
            <td>534</td>
            <td>421</td>
          </tr>
          <tr>
            <td css={ellipsisCss} valign="top">
              Net income
            </td>
            <td>857</td>
            <td>842</td>
            <td>1,666</td>
            <td>1,531</td>
          </tr>
          <tr>
            <td css={ellipsisCss} valign="top">
              Less: net income attributable to noncontrolling interests
            </td>
            <td>$ (59)</td>
            <td>(50)</td>
            <td>(113)</td>
            <td>(100)</td>
          </tr>
          <tr>
            <td css={ellipsisCss} valign="top">
              Net income attributable to S&amp;P Global Inc.
            </td>
            <td>&#160;</td>
            <td>$ 792</td>
            <td>$ 1,553</td>
            <td>$ 1,431</td>
          </tr>
          <tr>
            <td css={ellipsisCss} valign="top">
              <strong>Net income:</strong>
            </td>
            <td>&#160;</td>
            <td>&#160;</td>
            <td>&#160;</td>
            <td>&#160;</td>
          </tr>
          <tr>
            <td css={ellipsisCss} valign="top">
              Basic (USD per share)
            </td>
            <td>$ 3.31</td>
            <td>$ 3.29</td>
            <td>$ 6.45</td>
            <td>$ 5.92</td>
          </tr>
          <tr>
            <td css={ellipsisCss} valign="top">
              Diluted (USD per share)
            </td>
            <td>$ 3.30</td>
            <td>$ 3.28</td>
            <td>$ 6.42</td>
            <td>$ 5.90</td>
          </tr>
          <tr>
            <td css={ellipsisCss} valign="top">
              <strong>Weighted-average number of common shares outstanding:</strong>
            </td>
            <td>&#160;</td>
            <td>&#160;</td>
            <td>&#160;</td>
            <td>&#160;</td>
          </tr>
          <tr>
            <td css={ellipsisCss} valign="top">
              Basic (shares)
            </td>
            <td>240.8</td>
            <td>240.9</td>
            <td>240.7</td>
            <td>241.5</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
