import photo01 from '../../assets/photos/careerGridMinified/01-bowling.jpg'
import photo02 from '../../assets/photos/careerGridMinified/02-meaghan.jpg'
import photo03 from '../../assets/photos/careerGridMinified/03-getting-coffee.jpg'
import photo04 from '../../assets/photos/careerGridMinified/04-group-darts.jpg'
import photo05 from '../../assets/photos/careerGridMinified/05-playing-chess.jpg'
import photo06 from '../../assets/photos/careerGridMinified/06-dog-kensho-sign.jpg'
import photo07 from '../../assets/photos/careerGridMinified/07-hannah-costume.jpg'
import photo08 from '../../assets/photos/careerGridMinified/08-SRE-talks.jpg'
import photo09 from '../../assets/photos/careerGridMinified/09-ai-lab-pc.jpg'
import photo10 from '../../assets/photos/careerGridMinified/10-all-hands.jpg'
import photo11 from '../../assets/photos/careerGridMinified/11-coffee-talk.jpg'
import photo12 from '../../assets/photos/careerGridMinified/12-ashmita.jpg'
import photo13 from '../../assets/photos/careerGridMinified/13-luke-commuting.jpg'
import photo14 from '../../assets/photos/careerGridMinified/14-ewout.jpg'
import photo15 from '../../assets/photos/careerGridMinified/15-volunteering.jpg'
import photo16 from '../../assets/photos/careerGridMinified/16-kensho-pride.jpg'
import photo17 from '../../assets/photos/careerGridMinified/17-ML-thinking.jpg'
import photo18 from '../../assets/photos/careerGridMinified/18-recruiting.jpg'
import photo19 from '../../assets/photos/careerGridMinified/19-dc-crew.jpg'
import photo20 from '../../assets/photos/careerGridMinified/20-ke-gesturing.jpg'
import photo21 from '../../assets/photos/careerGridMinified/21-canoes.jpg'

// Note: order matters
export default [
  photo01,
  photo02,
  photo03,
  photo04,
  photo05,
  photo06,
  photo07,
  photo08,
  photo09,
  photo10,
  photo11,
  photo12,
  photo13,
  photo14,
  photo15,
  photo16,
  photo17,
  photo18,
  photo19,
  photo20,
  photo21,
]
