import {css} from '@emotion/react'

import AccordionItem from './AccordionItem'

const flexCss = css`
  display: flex;
  flex-direction: column;
`

export interface AccordionContent {
  content: string
  expandedContent: React.ReactNode
}

interface AccordionGroupProps {
  accordionContent: AccordionContent[]
  defaultExpanded?: boolean
  className?: string
}

export default function Accordion({
  accordionContent,
  className,
  defaultExpanded,
}: AccordionGroupProps): JSX.Element {
  return (
    <div css={flexCss} className={className}>
      {accordionContent.map(({expandedContent, content}) => (
        <AccordionItem
          content={content}
          expandedContent={expandedContent}
          defaultExpanded={defaultExpanded}
          key={content}
        />
      ))}
    </div>
  )
}
