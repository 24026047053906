import {Level, Message, Transport, Context} from './types'

export default class Logger {
  constructor(private readonly transports: Transport[]) {}

  private log(level: Level, message: Message, context?: Context): void {
    this.transports.forEach((transport) => transport(level, message, context))
  }

  /**
   * Indicates that something went wrong in an unexpected way.
   * The application may be in a failure state, or yield undefined behavior.
   */
  error<K extends string>(message: Message, context?: Context<K>): void {
    const error = typeof message === 'string' ? new Error(message) : message
    this.log('error', error, context)
  }

  /** Indicates that something went wrong, but the application was able to recover. */
  warning<K extends string>(message: Message, context?: Context<K>): void {
    const error = typeof message === 'string' ? new Error(message) : message
    this.log('warning', error, context)
  }

  /** Indicates that something notable occurred. */
  info<K extends string>(message: Message, context?: Context<K>): void {
    this.log('info', message, context)
  }
}
