import {ClassNames, css} from '@emotion/react'

import Carousel from '../../components/Carousel/BaseCarousel'
import type {CustomerTestimonialSlideProps} from '../../components/Carousel/slides/CustomerTestimonialSlide'
import CustomerTestimonial from '../../components/Carousel/slides/CustomerTestimonialSlide'
import {WideContent} from '../../components/content'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'

export const TEGUS_SLIDE: CustomerTestimonialSlideProps = {
  quote:
    "Transcription services all promise 99%+ accuracy, but Scribe is the best machine transcription we've tested. On top of that, for our business that last 1% is crucial, and Kensho's human-in-the-loop process delivers accuracy that a machine alone currently can't match, especially at our scale.",
  citation: 'Jason Howard, General Counsel and Chief Compliance Officer, Tegus',
}

const scribeSlides: CustomerTestimonialSlideProps[] = [
  {
    quote:
      'Scribe is a phenomenal tool - being able to produce reliable transcripts on insanely tight deadlines in order to meet regulatory obligations was a life saver. Scribe enabled a much fuller communication strategy on matters with regulator implications because we knew we could produce the transcripts we needed for filings.',
    citation:
      'Alma Rosa Montanez, Chief Corporate Counsel & Assistant Corporate Secretary, S&P Global',
  },
  {
    quote:
      'Manually done, transcribing audio is a labor-intensive process to produce a high-quality transcript. With Scribe on board, we were able to re-deploy existing talent for more value-add work, and make the business scalable and more sustainable.',
    citation: 'Abhishek Tomar, Chief Data Officer of MI, S&P Global',
  },
]

const backgroundCss = css`
  background: #004d61;
`

const contentCss = css`
  padding-right: 0;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    max-width: unset;
  }
`

export default function TestimonialSection(): JSX.Element {
  return (
    <WideContent css={contentCss}>
      <ClassNames>
        {({css}) => (
          <Carousel
            slides={scribeSlides}
            slideRenderer={(slide) => <CustomerTestimonial {...slide} css={backgroundCss} />}
            slideKeyGen={(slide) => slide.quote}
            slideTrayClassName={css`
              width: 90%;
            `}
            buttonTrayClassName={css`
              @media (max-width: ${BREAKPOINT_SMALL}px) {
                display: none;
              }
            `}
            buttonColor="white"
          />
        )}
      </ClassNames>
    </WideContent>
  )
}
