import DatasetsGraphic from '../../assets/solutions/datasets.svg'
import {bulletedListCss, bulletedListItemCss} from '../../styles/common'

import SolutionCard from './SolutionCard'

export default function SolutionsDatasets(): JSX.Element {
  return (
    <SolutionCard
      label="Train your ML models"
      graphic={{src: DatasetsGraphic, alt: 'Datasets Icon'}}
      title="Datasets"
      description="Kensho Datasets are professionally produced collections of training data curated to provide the highest quality resource for machine learning development."
      learnMoreUrl="https://datasets.kensho.com"
      mainContent={
        <>
          <p>
            Kensho Datasets are ideal for training your domain-specific machine learning models:
          </p>
          <ul css={bulletedListCss}>
            <li css={bulletedListItemCss}>Manually curated and reviewed by layers of experts.</li>
            <li css={bulletedListItemCss}>
              Available for free for academia and non-commercial use.
            </li>
            <li css={bulletedListItemCss}>Contact us for commercial use cases.</li>
          </ul>
        </>
      }
      linkList={[{href: 'mailto:sales@kensho.com?subject=Data', text: 'Contact Datasets'}]}
    />
  )
}
