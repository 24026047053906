import {css} from '@emotion/react'

import bulletIcon from '../assets/icons/list-bullet.svg'

import {BREAKPOINT_LARGE, BREAKPOINT_MEDIUM, BREAKPOINT_SMALL} from './breakpoints'
import COLORS from './colors'

/* Typography */

export const h1Css = css`
  font-size: 170px;
  line-height: 145px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    font-size: 130px;
    line-height: 110px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    font-size: 73px;
    line-height: 65px;
  }
`

export const h2TitleCss = css`
  font-size: 130px;
`

export const h2SubheadlineCss = css`
  font-size: 73px;
`

export const h3TitleCss = css`
  font-size: 48px;
  line-height: 55px;
`

export const h3SubheadlineCss = css`
  font-size: 32px;
  line-height: 42px;
`

export const h4Body1Css = css`
  font-size: 24px;
  line-height: 30px;
`

export const h4Body2Css = css`
  font-size: 18px;
  line-height: 27px;
`

export const labelCss = css`
  font-size: 16px;
  line-height: 22px;
`

export const legalCss = css`
  font-size: 12px;
`

export const hNormalCss = css`
  font-weight: 400;
`

export const hSemiBoldCss = css`
  font-weight: 600;
`

export const hBoldCss = css`
  font-weight: 700;
`

export const whiteTextCss = css`
  color: ${COLORS.white};
`

export const blackTextCss = css`
  color: ${COLORS.black};
`

export const darkBlueTextCss = css`
  color: ${COLORS.darkBlue[500]};
`

export const bulletedListCss = css`
  list-style: url(${bulletIcon});
  padding-top: 25px;
  padding-left: 22px;
  margin: 0;
`

export const bulletedListItemCss = css`
  padding-left: 4px;
  padding-bottom: 20px;
`

export const backgroundGradientCss = css`
  background: linear-gradient(to right top, ${COLORS.brand.light[300]}, ${COLORS.brand.light[700]});
`

export const backgroundDarkBlueGradientCss = css`
  background: linear-gradient(to right, ${COLORS.darkBlue[300]}, ${COLORS.darkBlue[500]});
`

export const backgroundLightBlueCss = css`
  background-color: ${COLORS.brand.light[700]};
`

export const backgroundDarkBlueCss = css`
  background-color: ${COLORS.brand.light[100]};
`

export const backgroundWhiteCss = css`
  background-color: ${COLORS.white};
`

export const backgroundBlackCss = css`
  background-color: ${COLORS.black};
`

export const backgroundMidGrayCss = css`
  background-color: ${COLORS.gray[500]};
`

export const backgroundLightGrayCss = css`
  background-color: ${COLORS.gray[700]};
`

export const bodyTextMarginCss = css`
  margin: 18px 0;

  :first-of-type {
    margin-top: 0;
  }
`

export const centeredTextCss = css`
  text-align: center;
`

/* Layout */

export const baseSectionCss = css`
  padding: 88px 0;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    padding: 64px 0;
  }
`

export const baseSectionPaddingTopCss = css`
  padding-top: 65px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding-top: 65px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding-top: 50px;
  }
`

export const sectionHeadingMarginCss = css`
  margin-bottom: 40px;
`

export const twoColumnSectionHeadingMarginCss = css`
  margin-bottom: 88px;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    margin-bottom: 64px;
  }
`

export const noMarginCss = css`
  margin: 0;
`

export const avoidWrapCss = css`
  display: inline-block;
`

/* Components */

export const largeButtonCss = css`
  height: 70px;
  font-size: 24px;
`

export const centerButtonCss = css`
  display: inline-flex;
  margin: 50px auto;
`

export const outlinedButtonOnDarkCss = css`
  border-color: ${COLORS.white};
  color: ${COLORS.white};
`

export const listCss = css`
  list-style: url(${bulletIcon});
  padding: 25px 0 0 25px;
  margin: 0;
`
