import {SerializedStyles, css} from '@emotion/react'

import {
  h3TitleCss,
  h3SubheadlineCss,
  hBoldCss,
  blackTextCss,
  whiteTextCss,
  sectionHeadingMarginCss,
} from '../styles/common'
import {BREAKPOINT_LARGE} from '../styles/breakpoints'

import {TwoColumnContent, AnimatedContent} from './content'

const singleHeaderCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const textAlignCss = css`
  text-align: center;
`

type HeadingContentProps = {
  headerText: string
  textStyle?: SerializedStyles | SerializedStyles[]
  className?: string
}

export function HeadingContent({
  headerText,
  textStyle,
  className,
}: HeadingContentProps): JSX.Element {
  return (
    <AnimatedContent css={singleHeaderCss} className={className}>
      <h3 css={[textAlignCss, h3TitleCss, hBoldCss, blackTextCss, textStyle]}>{headerText}</h3>
    </AnimatedContent>
  )
}

const columnHeaderCss = css`
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    ${sectionHeadingMarginCss}
  }
`

export type TwoColumnHeadingContentProps = {
  headerText: string
  additionalText: string
  textColor: 'white' | 'black'
  className?: string
}

export function TwoColumnHeadingContent({
  headerText,
  additionalText,
  textColor,
  className,
}: TwoColumnHeadingContentProps): JSX.Element {
  return (
    <TwoColumnContent
      className={className}
      leftContentCss={columnHeaderCss}
      left={
        <AnimatedContent>
          <h3 css={[h3TitleCss, hBoldCss, textColor === 'black' ? blackTextCss : whiteTextCss]}>
            {headerText}
          </h3>
        </AnimatedContent>
      }
      right={
        <AnimatedContent>
          <p css={[h3SubheadlineCss, textColor === 'black' ? blackTextCss : whiteTextCss]}>
            {additionalText}
          </p>
        </AnimatedContent>
      }
    />
  )
}
