import {css} from '@emotion/react'

import {WideContent} from '../../components/content'
import EmailLink from '../../components/EmailLink'
import HeroSection from '../../components/Hero/HeroSection'
import Link from '../../components/Link'
import Page from '../../components/Page'
import PageMetadata from '../../components/PageMetadata'
import {COMPANY_NAME, CURRENT_YEAR, EMAILS} from '../../constants'
import {
  backgroundWhiteCss,
  baseSectionCss,
  h3SubheadlineCss,
  h4Body1Css,
  h4Body2Css,
} from '../../styles/common'

const privacyCss = css`
  h2 {
    ${h3SubheadlineCss};
    margin: 15px 0;
    text-align: left;
  }

  h3 {
    ${h4Body1Css};
    margin: 10px 0;
  }

  ul,
  table {
    font-size: 18px;
    font-weight: 300;
    margin-left: auto;
    margin-right: auto;
  }

  table {
    margin-top: 32px;
    margin-bottom: 32px;
    border-collapse: collapse;
  }

  ul {
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: justify;
  }

  table,
  table td,
  table th {
    vertical-align: top;
    border: 1px solid #444;
  }

  table td,
  table th {
    padding: 8px;
  }
`

const italicCss = css`
  font-style: italic;
`

const emailLinkCss = css`
  color: #50afc6;
`

export default function PrivacyPage(): JSX.Element {
  return (
    <Page title={<HeroSection short>Privacy Policy</HeroSection>}>
      <PageMetadata
        title="Privacy Policy | Kensho"
        description="Kensho knows you care how information about you is used and shared. This notice describes our privacy policy"
      />

      <section css={[baseSectionCss, backgroundWhiteCss, privacyCss, h4Body2Css]}>
        <WideContent>
          <p>
            The Kensho services are provided by {COMPANY_NAME} (“Kensho,” “we,” “us” or “our”).
            Kensho is a subsidiary of S&P Global Inc. (“S&P Global”) and creates breakthrough
            financial intelligence products for our colleagues at S&P Global as well as some of the
            world’s leading financial institutions. Our data scientists and machine learning
            engineers enjoy a dynamic and collaborative work environment, while leveraging the
            unparalleled breadth and depth of data available as part of S&P Global. Learn more about
            our team at <Link to="/team">https://kensho.com/team</Link>.
          </p>
          <p>
            Kensho knows that you care how information about you is used and shared, and we
            appreciate your trust that we will do so carefully and sensibly. This notice describes
            our privacy policy (“Privacy Policy”) for any service or website (including but not
            limited to services provided via <Link to="/">https://kensho.com</Link>) controlled by
            Kensho (each a “Service”). If you have an enterprise-level agreement for Kensho services
            (“ELA” or ELAs”), this Privacy Policy only applies to the extent it does not conflict
            with such ELAs. Where a conflict is present, the terms of the ELA will control. It is
            important that you read this Privacy Policy together with any other privacy policy or
            fair processing policy we may provide on specific occasions when we receive or process
            any personal data about you, so that you are aware of how and why we are using your
            data.
          </p>
          <p>
            This Privacy Policy covers only information that is collected through a Service and does
            not cover websites or services that we do not control.
          </p>
          <p>
            The personal information that we may collect about you broadly falls into the following
            categories:
          </p>
          <h2>Information That You Provide Voluntarily</h2>
          <p>
            We ask you to provide certain information voluntarily, such as your contact details,
            user credentials, and employment information.
          </p>
          <h3>Learn More Below</h3>
          <p>
            <i>
              For instance, this can be by way of filling in an on-line form when you register to
              use our products and services, when you register to attend an event, when you provide
              us with your business card information or when you request to receive information from
              us. The types of information we ask you to provide, and the reasons why we ask you to
              provide it, include:
            </i>
          </p>
          <table css={italicCss}>
            <thead>
              <tr>
                <th>Types of Personal Information</th>
                <th>Why we collect it</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Billing information</td>
                <td>To maintain your account with us.</td>
              </tr>
              <tr>
                <td>
                  Contact details including name, telephone numbers, email address and postal
                  address.
                </td>
                <td>
                  To respond to correspondence and enquiries you send us. In some cases, to gather
                  information needed to provide products and services requested by you or your
                  employer. To obtain your feedback regarding our products and services.
                </td>
              </tr>
              <tr>
                <td>
                  User credentials including name, email address and personal information you
                  provide to us in a profile.
                </td>
                <td>
                  To set up and maintain your user account.
                  <br />
                  To monitor and enforce compliance with our contractual terms.
                </td>
              </tr>
              <tr>
                <td>
                  Employer and employment information such as job title, function, seniority,
                  department, and the address/country/city of your office.
                </td>
                <td>
                  To manage your individual user account which is covered by a corporate account of
                  one of Kensho’s corporate customers - for example, the corporate account of your
                  employer.
                </td>
              </tr>
              <tr>
                <td>
                  Your preferences and interests, for instance which products or services you would
                  like to be subscribed to or what markets you are interested in.
                </td>
                <td>
                  To enable us to send you tailored information on our products and services across
                  our divisions that may be of interest to you.
                  <br />
                  Examples of marketing communications we may send are:
                  <ul>
                    <li>
                      Newsletters. We may send you periodic newsletters and communications that we
                      believe may be of interest to you based on your Service use.
                    </li>
                    <li>
                      Special Offers. We may occasionally send you information on products,
                      Services, special deals, and promotions.
                    </li>
                    <li>
                      Company News. We may send you press releases and other announcements regarding
                      Kensho and our services.
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <h2>Information That We Collect Automatically</h2>
          <p>
            We may also collect certain information automatically from your device, including
            information regarding your use patterns of our products and services. In some
            jurisdictions, this information may be considered personal information under applicable
            data protection laws.
          </p>
          <h3>Learn More Below</h3>
          <p>
            Specifically, the information we collect automatically may include information like your
            IP address, device type, unique device identification numbers, browser-type, broad
            geographic location (e.g. country or city-level location), “click stream” data and other
            technical information. We also collect information about how your device has interacted
            with our websites and products, including the pages and products accessed and links
            clicked.
          </p>
          <p>
            Collecting this information enables us to better understand the visitors who come to our
            website and the users of our products and services, where they come from, and what
            content on our website and products and services is of interest to them.
          </p>
          <h2>Browsing, User-Provided Content, and Chat</h2>
          <p>
            <strong>Browsing</strong> You may browse certain areas of a Service without telling us
            who you are or submitting any personal information about yourself. However, we collect
            and store information, including personal information, that is voluntarily supplied to
            us by you when registering for some Services. When you register as a member, we collect
            information such as your name, email address, company affiliation, username and
            password. Depending on the Service, more information may be needed, which will be
            requested at the time of registration. You can choose not to provide us with certain
            information, but by doing so, you may not be able to take advantage of many Service
            features and functionality.
          </p>
          <p>
            <strong>User-Provided Content:</strong> If you choose to provide us with or share
            content created by you (or created by a third party, and for which you have permission
            to post) (“User-Provided Content”) , we may collect the information you post. We may use
            User-Provided Content that you post as described in the Terms and Conditions. We may
            also retain User-Provided Content as necessary to resolve disputes, provide customer
            support and troubleshoot problems as permitted by law. If you send us personal
            correspondence, such as emails or letters, or if other users or third parties send us
            correspondence about your activities or postings on a Service, we may collect such
            information into a file specific to you.
          </p>
          <p>
            <strong>Sharing and Chat.</strong> Some Service features may allow you to share
            information available on the Service with others who may or may not be Service members.
            These features may require you to provide email addresses of your intended recipients.
            We will use these email addresses to email the requested information to the recipients
            you have chosen, and we may identify you in such emails as a Service user who requested
            that the information be shared with the recipient. Some of our Services include a chat
            or communication protocol, to allow you to communicate with us or other users in real
            time. We may use the information you provide, including personal information, to provide
            you with a response to any inquiries, questions, or similar, as well as provide you with
            links and information to Services you request or inquire about.
          </p>
          <h3>Use of Personal Information</h3>
          <p>We use the information we collect:</p>
          <ul>
            <li>for our analytics purposes;</li>
            <li>
              to improve the quality and relevance to users, of our websites and products and
              services;
            </li>
            <li>to develop and update our websites and products and services;</li>
            <li>
              for customer services purposes, for instance, reviewing our customers’ training needs
              in respect of the use of our products;
            </li>
            <li>
              to satisfy Kensho’s corporate customers’ requests regarding the use of our products
              and services by the individual users licensed by their corporate account (please note
              that such information in many instances and jurisdictions will be offered to clients
              in an aggregated form); note that customers may be required by law to request such
              information from Kensho;
            </li>
            <li>
              to send you tailored information on our products and services that we believe may be
              of interest or value to you and also marketing and promotional e-mails with your
              consent if and when required by applicable law;
            </li>
            <li>
              on rare occasions to identify unauthorized use or unauthorized distribution of our
              products and services related or unrelated to a security issue;
            </li>
            <li>
              where relevant, to review and/or update our pricing model agreed with our corporate
              customers;
            </li>
            <li>
              and for billing purposes, so that we or others (such as content providers) are able to
              bill for the services provided.
            </li>
          </ul>
          <h3>Sharing of Personal Information</h3>
          <p>
            In addition to the specific situations discussed elsewhere in this Privacy Policy, we
            may disclose personal information in the following situations:
          </p>
          <ul>
            <li>
              Affiliates and Acquisitions. We may share information with our corporate affiliates
              (e.g., parent company, sister companies, subsidiaries, joint ventures, or other
              companies under common control).
            </li>
            <li>
              Service Providers. We may share your information with service providers. Among other
              things service providers may help us to administer our website, conduct surveys,
              provide technical support, process payments, and assist in the fulfillment of orders.
            </li>
            <li>
              Other Disclosures with Your Consent. We may disclose your information to other third
              parties when we have your consent or direction to do so.
            </li>
            <li>
              Other Disclosures without Your Consent. We reserve the right to disclose your personal
              information as required by law and when we believe that disclosure is necessary to
              protect our rights; to comply with a judicial proceeding, court order, or legal
              process served on us or a Service; or in connection with an actual or proposed
              corporate transaction or insolvency proceeding involving all or part of our business
              that uses the personal information.
            </li>
          </ul>
          <h2>Your Data Protection Rights</h2>
          <p>
            Your data protection rights. Your rights may include the right to access, correct,
            update or request deletion of your personal information. You may also have the right to
            withdraw the consent you have provided to us. To exercise any of your data protection
            rights, please call us at 1-855-529-1761 or send your request to{' '}
            <EmailLink email={EMAILS.PRIVACY} />. You may also use this{' '}
            <Link to="https://privacyportal.onetrust.com/webform/5cb57702-8ef7-437e-a62b-408fe78cd310/93391c3d-d6c8-45b1-a169-39a0b7f9fb74">
              this personal data request form
            </Link>{' '}
            to contact us or exercise any of your rights.
          </p>
          <h3>Learn More Below</h3>
          <p>
            <i>You have the following data protection rights:</i>
          </p>
          <ul>
            <li>
              Access To Your Personal Information. You may request access to your personal
              information or confirmation that we have information about you. In certain limited
              circumstances, you may also request to receive access to your data in a portable,
              machine-readable format.
            </li>
            <li>
              Changes To Your Personal Information. We rely on you to update and correct your
              personal information. You may ask us to correct information that is inaccurate or
              incomplete. Note that we may keep historical information in our backup files as
              permitted by law.
            </li>
            <li>
              Deletion Of Your Personal Information. You may request that we delete your personal
              information. If required by law, we will grant a request to delete information, but
              you should note that in many situations we must keep your personal information to
              comply with our legal obligations, resolve disputes, enforce our agreements, or for
              another business purposes.
            </li>
            <li>
              Objection to Certain Processing. You may object to processing of your personal
              information or ask us to restrict processing of your personal information by
              contacting us as described above.
            </li>
            <li>
              Opt-out of Marketing Communications. You may opt-out of marketing communications we
              send you at any time. You can exercise this right by clicking on the “unsubscribe” or
              “opt-out” link in the marketing e-mails or communications we send you (where
              provided), or by sending reply email asking to opt-out.
            </li>
            <li>
              Withdraw Consent. If we have collected and process your personal information with your
              consent, then you can withdraw your consent at any time. Withdrawing your consent will
              not affect the lawfulness of any processing we conducted prior to your withdrawal, nor
              will it affect processing of your personal information conducted in reliance on lawful
              processing grounds other than consent.
            </li>
          </ul>
          <p>
            We may, and in some cases are required to, verify your identity before we can act on
            your request to exercise your privacy rights. In order to verify your identity, we may
            require you to provide photographic identity information to us.
          </p>
          <p>
            In some circumstances, you may designate an authorized agent to submit requests to
            exercise certain privacy rights on your behalf. If you are an authorized agent
            submitting a request on behalf of an individual, you must provide all requested
            information establishing your authorization as an agent to act on another person’s
            behalf.
          </p>
          <p>
            Please note that not all of the rights described above are absolute and they do not
            apply in all circumstances. In some cases, we may limit or deny your request because the
            law permits or requires us to do so, or if we are unable to adequately verify your
            identity. We will not discriminate against individuals who exercise their privacy rights
            under applicable law.
          </p>
          <h2>Links to other websites</h2>
          <p>
            A Service may contain links to other websites that are not owned or controlled by
            Kensho. Please be aware that we are not responsible for the privacy policies of such
            other websites. We encourage you to be aware when you leave a Service and to read the
            privacy policies of each and every website that collects personal information. This
            Privacy Policy applies only to information collected by our Services.
          </p>
          <h2>Information Security and Information Retention</h2>
          <p>
            The security of your personal information is important to us. We follow generally
            accepted industry standards to protect the personal information submitted to us, both
            during transmission and once we receive it. No method of transmission over the Internet,
            or method of electronic storage, is 100% secure, however. Therefore, while we strive to
            use commercially acceptable means to protect your personal information, we cannot
            guarantee its absolute security. If you have any questions about security on our
            Services, you can email us at <EmailLink email={EMAILS.INFO} />. In the event that we
            are required by law to inform you of a breach to your personal information we may notify
            you electronically, in writing, or by telephone, if permitted to do so by law.
          </p>
          <p>
            We retain your personal information for only as long as necessary to fulfil the purposes
            outlined in this Privacy Policy, including for the purposes of satisfying any legal,
            accounting, or reporting requirements, unless a longer retention period is required or
            permitted by law. To determine the appropriate retention period for personal
            information, we consider the amount, nature, and sensitivity of the information, the
            potential risk of harm from unauthorized use or disclosure of the information, the
            purposes for which we obtained the information and whether we can achieve those purposes
            through other means, as well as applicable legal requirements.
          </p>
          <h2>Persons under 18 years of age</h2>
          <p>
            Our Services are not directed to people under 18 years of age. If you become aware that
            your child has provided us with personal information without your consent, please
            contact us at <EmailLink email={EMAILS.SUPPORT} />. We do not knowingly collect personal
            information from people under 18. If we become aware that a person under 18 has provided
            us with personal information, we take steps to remove that information and terminate the
            applicable account.
          </p>
          <h2>Changes to our Privacy Policy</h2>
          <p>
            If we decide to change our Privacy Policy, we will post changes to this Privacy Policy
            and in other places we deem appropriate so that you are aware of what information we
            collect, how we use it, and under what circumstances we disclose it.
          </p>
          <p>
            We reserve the right to modify this Privacy Policy at any time, and such change will
            apply with respect to information you provide or your activity on a Service after the
            change in policy, so please review it frequently. If we make changes that materially
            affect our uses or disclosures of personal information that we have previously
            collected, we may contact you to obtain your consent to have the changes to our Privacy
            Policy apply retroactively.
          </p>
          <h2>Contact us</h2>
          <p>
            If you have any questions, concerns or suggestions regarding our Privacy Policy, or if
            you need to access this Privacy Policy in an alternative format due to having a
            disability, please contact us at <EmailLink email={EMAILS.PRIVACY} />.
          </p>
          <p>
            <strong>Customer Service.</strong> We may communicate with you in response to your
            inquiries, to provide the services you request, and to manage your account. We may
            communicate with you by email or telephone, in accordance with your wishes. You may
            contact customer service at <EmailLink email={EMAILS.SUPPORT} />.
          </p>
          <p>
            Last updated July 1, 2023.
            <br />
            Copyright &copy; {CURRENT_YEAR} {COMPANY_NAME}. All Rights Reserved.
          </p>
          <h2>Additional Information for California Residents</h2>
          <p>
            California law requires us to disclose the following additional information related to
            our privacy practices. If you are a California resident, the following privacy
            disclosures apply to you in addition to the rest of the Privacy Policy.
          </p>
          <ul>
            <li>
              Information Collection and Disclosures. The table below describes the categories of
              personal information we collect and disclose for a business purpose. We do not “sell”
              and/or “share” personal information for purposes of cross-context behavioral
              advertising (as those terms are defined by California law). Please note, in addition
              to the recipients identified below, we may disclose any of the categories of personal
              information we collect with government entities, as may be needed to comply with law
              or prevent illegal activity. For details regarding how we use personal information,
              please see the Information That You Provide Voluntarily and the Use of Personal
              Information sections of the Privacy Policy. For information regarding how long we
              retain personal information, please refer to the{' '}
              <strong>Information Security and Information Retention</strong> section of the Privacy
              Policy.
            </li>
          </ul>
          <table css={italicCss}>
            <thead>
              <tr>
                <th>Category of Personal Information</th>
                <th>
                  Category of Recipients Personal Information is Disclosed for a Business Purpose
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Identifiers – this may include real name, alias, postal address, unique personal
                  identifier, online identifier, email address, account name, or other similar
                  identifiers.
                </td>
                <td>
                  <ul>
                    <li>Affiliates or subsidiaries</li>
                    <li>Business partners</li>
                    <li>Data analytics providers</li>
                    <li>Internet service providers</li>
                    <li>Joint marketing partners </li>
                    <li>Operating systems and platforms</li>
                    <li>Other Service Providers</li>
                    <li>Payment processors and financial institutions </li>
                    <li>
                      Professional services organizations, this may include auditors and law firms
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  Financial Information – this may include bank account number, credit card number,
                  debit card number, and other financial information.
                </td>
                <td>
                  <ul>
                    <li>Affiliates or subsidiaries</li>
                    <li>Payment processors and financial institutions </li>
                    <li>Other Service Providers</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  Commercial information – this may include information about products or services
                  purchased, obtained, or considered, or other purchasing or consuming histories or
                  tendencies.
                </td>
                <td>
                  <ul>
                    <li>Business partners</li>
                    <li>Data analytics providers</li>
                    <li>Joint marketing partners </li>
                    <li>Payment processors and financial institutions </li>
                    <li>Other Service Providers</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  Internet or other electronic network activity information – this may include
                  browsing history, search history, and information regarding an individual’s
                  interaction with an internet website, application, or advertisement.
                </td>
                <td>
                  <ul>
                    <li>Affiliates or subsidiaries</li>
                    <li>Business partners</li>
                    <li>Internet service providers</li>
                    <li>Operating systems and platforms</li>
                    <li>Other Service Providers</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Professional or employment-related information</td>
                <td>
                  <ul>
                    <li>Affiliates or subsidiaries</li>
                    <li>Business partners</li>
                    <li>Other Service Providers</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Inferences drawn from any of the information listed above</td>
                <td>
                  <ul>
                    <li>Affiliates or subsidiaries</li>
                    <li>Business partners</li>
                    <li>Data analytics providers</li>
                    <li>Joint marketing partners </li>
                    <li>Other Service Providers</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  Additional categories of personal information described in the California Customer
                  Records statute (Cal. Civ. Code § 1798.80(e)) – this may include signature,
                  physical characteristics, or description, insurance policy number.
                </td>
                <td>
                  <ul>
                    <li>Affiliates or subsidiaries</li>
                    <li>Payment processors and financial institutions </li>
                    <li>Other Service Providers</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <ul>
            <li>
              California Sensitive Information Disclosure. We collect the following categories of
              sensitive personal information (as defined under California law): A consumer’s account
              log-in, financial account, debit card, or credit card number in combination with any
              required security or access code, password, or credentials allowing access to an
              account; and the contents of a consumer’s mail, email, and text messages unless the
              business is the intended recipient of the communication. This information is collected
              in order to set up your user accounts, monitor and enforce compliance with our
              contractual terms, comply with laws, manage our business, or provide you with
              services. Note that we do not use such information for any purposes that are not
              identified within the California Privacy Rights Act Section 1798.121. We do not “sell”
              or “share” sensitive personal information for purposes of cross-context behavioral
              advertising.
            </li>
            <li>
              &ldquo;Shine the Light&rdquo; Law. Residents of the State of California have the right
              to request information regarding third parties to whom the company has disclosed
              certain categories of personal information during the preceding year for the third
              parties’ direct marketing purposes. To exercise this right, please contact us at{' '}
              <EmailLink email={EMAILS.PRIVACY} css={emailLinkCss} />.
            </li>
          </ul>
        </WideContent>
      </section>
    </Page>
  )
}
