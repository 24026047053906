import {css} from '@emotion/react'

import Link from '../Link'

interface SectionProps {
  children: React.ReactNode
  full?: boolean
  wide?: boolean
  hero?: boolean
  totallyBlue?: boolean
  backgroundColor?: string
  outerClassName?: string
  className?: string
  image?: string
  imageClassName?: string
  id?: string
  title?: React.ReactNode
  topMarginElement?: React.ReactNode
  bottomMarginElement?: React.ReactNode
}

const sectionCss = css`
  position: relative;
  overflow: hidden;
  background-color: #fff;
`

const heroCss = css`
  padding-top: 84px;
  background-color: #444;
  color: white;
  text-align: center;
`

const wideCss = css`
  max-width: 1080px;
`

const fullCss = css`
  max-width: none;
`

const totallyBlueCss = css`
  background-color: #50afc6;
  color: white;

  h2 {
    color: white;
  }
`

const imageCss = css`
  transition: all 700ms;
  filter: contrast(120%) brightness(30%) grayscale(30%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center 55%;
  background-size: cover;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE11 and IE10 only, shim for unsupported filter property */
    opacity: 0.15;
  }
`

const verticalMarginEleCss = css`
  width: 100%;
`

const innerCss = css`
  position: relative;
  margin: 60px auto;
  max-width: 960px;
  padding: 20px;

  > p,
  > h2,
  > h3 {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  > h2::after {
    display: block;
    margin: 0 auto;
    width: 80px;
    margin-top: 10px;
    content: '';
    border-top: 2px solid #50afc6;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
  }

  @media (max-width: 640px) {
    margin: 30px auto;
  }
`

const headingLinkCss = css`
  color: inherit;

  &:hover {
    opacity: 1;
  }
`

export default function Section({
  children,
  wide = false,
  full = false,
  hero = false,
  totallyBlue = false,
  backgroundColor,
  outerClassName,
  className,
  image,
  imageClassName,
  id,
  title,
  topMarginElement,
  bottomMarginElement,
}: SectionProps): JSX.Element {
  return (
    <section
      id={id}
      className={outerClassName}
      css={[
        sectionCss,
        totallyBlue && totallyBlueCss,
        hero && heroCss,
        backgroundColor &&
          css`
            background-color: ${backgroundColor};
          `,
      ]}
    >
      {(image || imageClassName) && (
        <div
          className={imageClassName}
          css={imageCss}
          style={{backgroundImage: image ? `url(${image})` : undefined}}
        />
      )}
      {topMarginElement && <div css={verticalMarginEleCss}>{topMarginElement}</div>}
      <div className={className} css={[innerCss, wide && wideCss, full && fullCss]}>
        {title && (
          <h2>
            {id ? (
              <Link css={headingLinkCss} to={`#${id}`}>
                {title}
              </Link>
            ) : (
              title
            )}
          </h2>
        )}
        {children}
      </div>
      {bottomMarginElement && <div css={verticalMarginEleCss}>{bottomMarginElement}</div>}
    </section>
  )
}
