export const COMPANY_NAME = 'Kensho Technologies, LLC'
export const CURRENT_YEAR = new Date().getFullYear()

export const BASE_URL = 'https://kensho.com'

export const EMAILS = {
  INFO: 'info@kensho.com',
  SALES: 'sales@kensho.com',
  SECURITY: 'security-reports@kensho.com',
  SUPPORT: 'support@kensho.com',
  PAYMENT: 'payments@kensho.com',
  PRIVACY: 'privacy@kensho.com',
  LEGAL: 'legal@kensho.com',
}

export const URLS = {
  ACCOUNT: 'https://services.kensho.com',
  FREE_TRIAL: 'https://services.kensho.com/free-trial',
}

export const SOCIAL_URLS = {
  LINKEDIN: 'https://linkedin.com/company/kensho-technologies',
  TWITTER: 'https://twitter.com/kensho',
}

// wanted by S&P to keep track of referrals to marketplace pages from kensho.com pages
export const SPG_MARKETPLACE_UTM_QUERY_PARAMS =
  'utm_source=Kensho&utm_medium=referral&utm_content=&utm_campaign=Kenshowebsite'

export const SECTION_IDS = {
  CAREERS: {
    AVAILABLE_POSITIONS: 'available-positions',
    CULTURE: 'culture',
    DEI: 'dei',
  },
  ABOUT: {
    VALUES: 'values',
  },
}

export const CONSENT_COOKIE_NAME = 'consentCookie'
export const CONSENT_COOKIE_DEFAULTS = {
  functional: true,
  targeting: true,
  performance: true,
  isSet: false,
}
