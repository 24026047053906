import TwoColumnGradient from '../../components/TwoColumnGradient'

export default function EDXSection(): JSX.Element {
  return (
    <section>
      <TwoColumnGradient
        eyebrow="For Current Kensho Link EDX API Users"
        title="Upgrade Your Service!"
        subtitle="Customers who use S&P Global’s EDX API Drive can now upgrade directly 
        to our site. This will result in faster processing times, richer outputs, and 
        seamless BECR integration with additional subscription."
        buttonText="Get Started"
        href="https://static.kensho.com/user-content/Link_EDX_Instructions.pdf"
      />
    </section>
  )
}
