import {css} from '@emotion/react'

import ScribeProductLogo from '../../assets/logos/products/scribe.svg'
import ScribeHeroLarge from '../../assets/photos/scribe-hero-large.jpg'
import ScribeHeroMedium from '../../assets/photos/scribe-hero-medium.jpg'
import ScribeHeroSmall from '../../assets/photos/scribe-hero-small.jpg'
import socialImg from '../../assets/social/scribe.jpg'
import ProductHeroSection from '../../components/Hero/ProductHeroSection'
import Page from '../../components/Page'
import PageMetadata from '../../components/PageMetadata'

import ApplicationSection from './ApplicationSection'
import AudioSection from './AudioSection'
import BusinessSection from './BusinessSection'
import DeveloperSection from './DeveloperSection'
import IntroSection from './IntroSection'
import ProductSection from './ProductSection'
import StorySection from './StorySection'
import TestimonialSection from './TestimonialSection'
import WERSection from './WERSection'

const heroImageCss = css`
  background-image: url(${ScribeHeroLarge});

  @media (max-width: 2200px) {
    background-image: url(${ScribeHeroMedium});
  }

  @media (max-width: 600px) {
    background-image: url(${ScribeHeroSmall});
  }

  filter: none;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    opacity: 1;
  }
`

export default function Scribe(): JSX.Element {
  return (
    <Page
      title={
        <ProductHeroSection
          imageCss={heroImageCss}
          productLogo={ScribeProductLogo}
          productTitle="Scribe"
          productSubtitle="Our speech-to-text technology is easy and accurate."
        />
      }
    >
      <PageMetadata
        title="Scribe | Kensho"
        description="Kensho Scribe is an ML-based service that transcribes business and financial audio with unparalleled accuracy, speed and security"
        image={{source: socialImg, height: 1250, width: 2500}}
      />
      <IntroSection />
      <ProductSection />
      <AudioSection />
      <ApplicationSection />
      <BusinessSection />
      <TestimonialSection />
      <DeveloperSection />
      <StorySection />
      <WERSection />
    </Page>
  )
}
