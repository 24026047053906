export default function useMergedRef<T>(...refs: React.Ref<T>[]): React.RefCallback<T> {
  return (element: T) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(element)
      } else if (ref && typeof ref === 'object') {
        /* eslint-disable-next-line no-param-reassign */
        ;(ref as React.MutableRefObject<T>).current = element
      }
    })
  }
}
