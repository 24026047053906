import {css} from '@emotion/react'

import NERDGraphic from '../../assets/solutions/nerd.svg'
import Link from '../../components/Link'
import {URLS} from '../../constants'
import {bulletedListCss, bulletedListItemCss} from '../../styles/common'

import SolutionCard from './SolutionCard'

const blueLinkCss = css`
  color: #007694;
`

export default function SolutionsNERD(): JSX.Element {
  return (
    <SolutionCard
      label="Identify Entities in Text"
      graphic={{src: NERDGraphic, alt: 'NERD Icon'}}
      title="NERD"
      description="Kensho NERD unlocks new connections and deeper insights by systematically uncovering the companies, people, places, events and more in your text. "
      learnMoreUrl="/nerd"
      mainContent={
        <>
          <p>
            Identify the who, what, and where in text and connect them to Capital IQ or Wikimedia
            (nearly 100M entities identifiable!)
          </p>
          <ul css={bulletedListCss}>
            <li css={bulletedListItemCss}>
              Streamline manual workflows by annotating thousands of words in seconds.
            </li>
            <li css={bulletedListItemCss}>
              Reveal insights by analyzing NERD output directly or in conjunction with Capital IQ or
              Wiki data.
            </li>
            <li css={bulletedListItemCss}>
              Supercharge research workflows with interconnected content.
            </li>
            <li css={bulletedListItemCss}>
              Improve search results, content recommendations and document routing.
            </li>
            <li css={bulletedListItemCss}>
              Read about NERD in our blog post,{' '}
              <Link
                css={blueLinkCss}
                to="https://blog.kensho.com/kensho-nerd-unlocking-the-potential-of-unstructured-data-f75e69efcb1e"
              >
                Unlocking the Potential of Unstructured Data.
              </Link>
            </li>
          </ul>
        </>
      }
      linkList={[
        {
          href: '/nerd#kensho-nerd-demo-video',
          text: 'Watch Demo',
        },
        {href: 'https://nerd.kensho.com/', text: 'Developer Docs'},
        {href: 'mailto:sales@kensho.com?subject=Nerd', text: 'Contact NERD'},
      ]}
      callToActionLink={{
        href: `${URLS.FREE_TRIAL}/nerd`,
        text: 'Free Trial',
      }}
    />
  )
}
