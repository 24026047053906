import {css} from '@emotion/react'

import connectWithUsImg from '../../assets/photos/office-candid.jpg'
import TopicHeadline from '../../components/TopicHeadline'
import ButtonLink from '../../components/Button/ButtonLink'
import {AnimatedContent, TwoColumnContent} from '../../components/content'
import {
  backgroundBlackCss,
  backgroundMidGrayCss,
  backgroundLightGrayCss,
  baseSectionCss,
  blackTextCss,
  h2SubheadlineCss,
  h3SubheadlineCss,
  h3TitleCss,
  h4Body1Css,
  hBoldCss,
  whiteTextCss,
} from '../../styles/common'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'

const panelCss = css`
  padding-top: 65px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding-top: 55px;
  }
`

const bodyContainerCss = css`
  padding-bottom: 90px;
  padding-left: 40px;
  padding-right: 65px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding-bottom: 55px;
    padding-left: 23px;
    padding-right: 23px;
  }
`

const marginCss = css`
  margin-bottom: 30px;
`

const imageCss = css`
  width: 100%;
  height: 100%;
`

const leftContentCss = css`
  padding-left: 0;
  padding-right: 0;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding-right: 0;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const rightContentCss = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const rightHeaderCss = css`
  line-height: 80px;
`

const rightTextCss = css`
  margin: 100px 0;
`

const buttonCss = css`
  height: 70px;
`

export default function ConnectWithUsSection(): JSX.Element {
  return (
    <section>
      <TwoColumnContent
        leftContentCss={[backgroundBlackCss, leftContentCss]}
        leftWrapperCss={[backgroundMidGrayCss, whiteTextCss]}
        left={
          <div css={panelCss}>
            <div css={bodyContainerCss}>
              <TopicHeadline text="Connect with us" />
              <AnimatedContent>
                <h3 css={[h3TitleCss, hBoldCss, marginCss]}>We’d love to hear from you!</h3>
              </AnimatedContent>
              <AnimatedContent>
                <p css={h3SubheadlineCss}>
                  We can work with you on crafting the right mix of solutions that are stable,
                  secure and scalable.
                </p>
              </AnimatedContent>
            </div>
            <AnimatedContent animation="fade-in">
              <img
                src={connectWithUsImg}
                css={imageCss}
                alt="Candid office photograph"
                loading="lazy"
              />
            </AnimatedContent>
          </div>
        }
        rightContentCss={rightContentCss}
        rightWrapperCss={[baseSectionCss, backgroundLightGrayCss, blackTextCss]}
        right={
          <>
            <AnimatedContent animation="fade-in">
              <h3 css={[h2SubheadlineCss, rightHeaderCss]}>Let us guide you along the way.</h3>
            </AnimatedContent>
            <AnimatedContent animation="fade-in">
              <p css={[h3SubheadlineCss, rightTextCss]}>
                Whether you’re ready to take the next step or if you simply have questions, our team
                of experts is here to provide any assistance you might need.
              </p>
            </AnimatedContent>
            <AnimatedContent animation="fade-in">
              <ButtonLink to="/contact" variant="secondary" css={[buttonCss, h4Body1Css]}>
                Contact us
              </ButtonLink>
            </AnimatedContent>
          </>
        }
      />
    </section>
  )
}
