import {css} from '@emotion/react'

import {BREAKPOINT_LARGE} from '../../styles/breakpoints'
import {AnimatedContent, WideContent} from '../../components/content'
import {
  h4Body1Css,
  h4Body2Css,
  hBoldCss,
  blackTextCss,
  backgroundLightGrayCss,
  centeredTextCss,
  baseSectionCss,
  sectionHeadingMarginCss,
} from '../../styles/common'
import COLORS from '../../styles/colors'
import {HeadingContent} from '../../components/headings'

const contentCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const impactContainerCss = css`
  display: flex;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    flex-direction: column;
  }
`

const impactCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 440px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-bottom: 47px;
  }
`

const impactTitleSpacingCss = css`
  margin-bottom: 27px;
`

const blueTextCss = css`
  color: ${COLORS.darkBlue[500]};
`

const impactTitleCss = [impactTitleSpacingCss, h4Body1Css, hBoldCss, blueTextCss, centeredTextCss]
const impactTextCss = [h4Body2Css, blackTextCss, centeredTextCss]

export default function ImpactSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundLightGrayCss]}>
      <WideContent css={contentCss}>
        <HeadingContent
          headerText="NERD's Impact"
          css={[sectionHeadingMarginCss, centeredTextCss]}
        />
        <AnimatedContent animation="fade-in" innerCss={impactContainerCss}>
          <div css={impactCss}>
            <h3 css={impactTitleCss}>Streamline Process</h3>
            <p css={impactTextCss}>
              NERD streamlines operations by automating slow, manual workflows{' '}
            </p>
          </div>
          <div css={impactCss}>
            <h3 css={impactTitleCss}>Increase Discoverability</h3>
            <p css={impactTextCss}>
              Reveal actionable insights by leveraging your unstructured data in new ways
            </p>
          </div>
          <div css={impactCss}>
            <h3 css={impactTitleCss}>Customer Centered</h3>
            <p css={impactTextCss}>
              Increase customer engagement by making your solutions more valuable to users
            </p>
          </div>
        </AnimatedContent>
      </WideContent>
    </section>
  )
}
