import {css, SerializedStyles} from '@emotion/react'

import accentedSpeechAudio from '../../../assets/scribe-audio/accented-speech.mp3'
import industrySpecificJargonAudio from '../../../assets/scribe-audio/industry-specific-jargon.mp3'
import numbersCurrenciesAudio from '../../../assets/scribe-audio/numbers-currencies.mp3'
import productNamesAudio from '../../../assets/scribe-audio/product-names.mp3'
import {darkBlueTextCss} from '../../../styles/common'

const highlightCss = css`
  font-weight: 800;
`

export interface Token {
  startTime: number
  endTime: number
  content: string
  styles?: SerializedStyles[]
}

export interface TranscriptSample {
  name: string
  audio: string
  duration: number
  scribe: Token[]
  competition: Token[]
}

const transcriptionSamples: TranscriptSample[] = [
  {
    name: 'Numbers and Currencies',
    audio: numbersCurrenciesAudio,
    duration: 37000,
    scribe: [
      /* Because of the unusual level of stock option activity in the third quarter of 2017,
       * the stock-based compensation tax benefit that we received in 2018 was $0.14 lower than
       * in 2017. And as the number of employee stock options continues to decline, we expect the
       * stock-based compensation tax benefit to decline as well. For 2019, we estimate a positive
       * EPS impact of $0.05 to $0.10.
       */
      {startTime: 2770, endTime: 2970, content: 'Because'},
      {startTime: 3190, endTime: 3230, content: 'of'},
      {startTime: 3370, endTime: 3430, content: 'the'},
      {startTime: 3650, endTime: 4010, content: 'unusual'},
      {startTime: 4350, endTime: 4510, content: 'level'},
      {startTime: 4810, endTime: 4850, content: 'of'},
      {startTime: 4990, endTime: 5170, content: 'stock'},
      {startTime: 5350, endTime: 5550, content: 'option'},
      {startTime: 5810, endTime: 6150, content: 'activity'},
      {startTime: 6830, endTime: 6870, content: 'in'},
      {startTime: 6930, endTime: 6990, content: 'the'},
      {startTime: 7110, endTime: 7250, content: 'third'},
      {startTime: 7470, endTime: 7650, content: 'quarter'},
      {startTime: 7810, endTime: 7850, content: 'of'},
      {startTime: 7990, endTime: 9670, content: '2017,'},
      {startTime: 9710, endTime: 9770, content: 'the'},
      {
        startTime: 9870,
        endTime: 10390,
        content: 'stock-based',
        styles: [highlightCss, darkBlueTextCss],
      },
      {
        startTime: 10590,
        endTime: 11030,
        content: 'compensation',
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 11350, endTime: 11470, content: 'tax'},
      {startTime: 11730, endTime: 12010, content: 'benefit'},
      {startTime: 12650, endTime: 12730, content: 'that'},
      {startTime: 12850, endTime: 12890, content: 'we'},
      {startTime: 13030, endTime: 13290, content: 'received'},
      {startTime: 13570, endTime: 13610, content: 'in'},
      {startTime: 13710, endTime: 14390, content: '2018', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 15250, endTime: 15370, content: 'was', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 15650, endTime: 15850, content: '$0.14', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 16730, endTime: 16930, content: 'lower'},
      {startTime: 17130, endTime: 17210, content: 'than'},
      {startTime: 17330, endTime: 17370, content: 'in'},
      {startTime: 17470, endTime: 18270, content: '2017.'},
      {startTime: 18310, endTime: 18330, content: 'The'},
      {startTime: 19290, endTime: 19350, content: 'As'},
      {startTime: 19430, endTime: 19490, content: 'the'},
      {startTime: 19590, endTime: 19750, content: 'number'},
      {startTime: 19870, endTime: 19910, content: 'of'},
      {startTime: 20090, endTime: 20390, content: 'employee'},
      {startTime: 20550, endTime: 20730, content: 'stock'},
      {startTime: 20930, endTime: 21190, content: 'options'},
      {startTime: 21850, endTime: 22210, content: 'continues'},
      {startTime: 22370, endTime: 22410, content: 'to'},
      {startTime: 22530, endTime: 23290, content: 'decline,'},
      {startTime: 23330, endTime: 23370, content: 'we'},
      {startTime: 23490, endTime: 23710, content: 'expect'},
      {startTime: 23850, endTime: 23910, content: 'the'},
      {startTime: 24010, endTime: 24530, content: 'stock-based'},
      {startTime: 24730, endTime: 25150, content: 'compensation'},
      {startTime: 25410, endTime: 25530, content: 'tax', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 25790, endTime: 26070, content: 'benefit'},
      {startTime: 26650, endTime: 26710, content: 'to'},
      {startTime: 26810, endTime: 27110, content: 'decline'},
      {startTime: 27370, endTime: 27430, content: 'as'},
      {startTime: 27530, endTime: 28650, content: 'well.'},
      {startTime: 28690, endTime: 28770, content: 'For'},
      {startTime: 28890, endTime: 29990, content: '2019,'},
      {startTime: 30030, endTime: 30070, content: 'we'},
      {startTime: 30270, endTime: 30570, content: 'estimate'},
      {startTime: 31230, endTime: 31250, content: 'a'},
      {startTime: 31410, endTime: 31710, content: 'positive'},
      {startTime: 32170, endTime: 32430, content: 'EPS', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 32730, endTime: 32930, content: 'impact'},
      {startTime: 33530, endTime: 33570, content: 'of'},
      {startTime: 33770, endTime: 33950, content: '$0.05', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 34290, endTime: 34330, content: 'to'},
      {
        startTime: 34430,
        endTime: 34650,
        content: '$0.10.',
        styles: [highlightCss, darkBlueTextCss],
      },
    ],
    competition: [
      /* Google - Default
       *
       * The cost of the unusual level stock option activity in the third quarter of 2017. Based
       * conversation text benefit that we received in 2014 cents lower 10 in 2017 at the number
       * of employee stock option continues to decline expect the stock base compensation Texas
       * benefits to decline as well for 2019. We estimate a positive CPS impact of five to
       * ten cents.
       */
      {startTime: 2770, endTime: 2970, content: 'The cost'},
      {startTime: 3190, endTime: 3230, content: 'of'},
      {startTime: 3370, endTime: 3430, content: 'the'},
      {startTime: 3650, endTime: 4010, content: 'unusual'},
      {startTime: 4350, endTime: 4510, content: 'level'},
      {startTime: 4990, endTime: 5170, content: 'stock'},
      {startTime: 5350, endTime: 5550, content: 'option'},
      {startTime: 5810, endTime: 6150, content: 'activity'},
      {startTime: 6830, endTime: 6870, content: 'in'},
      {startTime: 6930, endTime: 6990, content: 'the'},
      {startTime: 7110, endTime: 7250, content: 'third'},
      {startTime: 7470, endTime: 7650, content: 'quarter'},
      {startTime: 7810, endTime: 7850, content: 'of'},
      {startTime: 7990, endTime: 9670, content: '2017,'},
      {startTime: 9870, endTime: 10390, content: 'Based', styles: [highlightCss, darkBlueTextCss]},
      {
        startTime: 10590,
        endTime: 11030,
        content: 'conversation',
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 11350, endTime: 11470, content: 'text'},
      {startTime: 11730, endTime: 12010, content: 'benefit'},
      {startTime: 12650, endTime: 12730, content: 'that'},
      {startTime: 12850, endTime: 12890, content: 'we'},
      {startTime: 13030, endTime: 13290, content: 'received'},
      {startTime: 13570, endTime: 13610, content: 'in'},
      {startTime: 13710, endTime: 14390, content: '20', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 15650, endTime: 15850, content: '14', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 16730, endTime: 16930, content: 'lower'},
      {startTime: 17130, endTime: 17210, content: '10', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 17330, endTime: 17370, content: 'in'},
      {startTime: 17470, endTime: 18270, content: '2017'},
      {startTime: 19290, endTime: 19350, content: 'at'},
      {startTime: 19430, endTime: 19490, content: 'the'},
      {startTime: 19590, endTime: 19750, content: 'number'},
      {startTime: 19870, endTime: 19910, content: 'of'},
      {startTime: 20090, endTime: 20390, content: 'employee'},
      {startTime: 20550, endTime: 20730, content: 'stock'},
      {startTime: 20930, endTime: 21190, content: 'options'},
      {startTime: 21850, endTime: 22210, content: 'continues'},
      {startTime: 22370, endTime: 22410, content: 'to'},
      {startTime: 22530, endTime: 23290, content: 'decline'},
      {startTime: 23490, endTime: 23710, content: 'expect'},
      {startTime: 23850, endTime: 23910, content: 'the'},
      {startTime: 24010, endTime: 24530, content: 'stock base'},
      {startTime: 24730, endTime: 25150, content: 'compensation'},
      {startTime: 25410, endTime: 25530, content: 'Texas', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 25790, endTime: 26070, content: 'benefits'},
      {startTime: 26650, endTime: 26710, content: 'to'},
      {startTime: 26810, endTime: 27110, content: 'decline'},
      {startTime: 27370, endTime: 27430, content: 'as'},
      {startTime: 27530, endTime: 28650, content: 'well'},
      {startTime: 28690, endTime: 28770, content: 'for'},
      {startTime: 28890, endTime: 29990, content: '2019.'},
      {startTime: 30030, endTime: 30070, content: 'We'},
      {startTime: 30270, endTime: 30570, content: 'estimate'},
      {startTime: 31230, endTime: 31250, content: 'a'},
      {startTime: 31410, endTime: 31710, content: 'positive'},
      {startTime: 32170, endTime: 32430, content: 'CPS', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 32730, endTime: 32930, content: 'impact'},
      {startTime: 33530, endTime: 33570, content: 'of'},
      {startTime: 33770, endTime: 33950, content: 'five', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 34290, endTime: 34330, content: 'to'},
      {
        startTime: 34430,
        endTime: 34650,
        content: 'ten cents.',
        styles: [highlightCss, darkBlueTextCss],
      },
    ],
  },

  {
    name: 'Product Names',
    audio: productNamesAudio,
    duration: 51084,
    scribe: [
      /* And as we continue to generate data that validates the utility of both IMBRUVICA and
       * VENCLEXTA across a wide range of patient populations and cancer types, we expect this
       * franchise to drive significant growth for many years to come. Additionally, our innovative
       * pipeline includes more than 20 active development programs for blood cancers and solid
       * tumors, which we expect will strengthen our growing position in oncology. The acquisition
       * of Allergan brings to AbbVie the world's leading medical aesthetics business. With
       * revenue of nearly $4.5 billion, this primarily cash paid business is growing high single
       * digits and is anchored by very well-known brands, including BOTOX as
       */
      {startTime: 10, endTime: 70, content: 'And', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 190, endTime: 230, content: 'as', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 350, endTime: 390, content: 'we'},
      {startTime: 610, endTime: 950, content: 'continue'},
      {startTime: 1050, endTime: 1090, content: 'to'},
      {startTime: 1270, endTime: 1570, content: 'generate'},
      {startTime: 1790, endTime: 1970, content: 'data'},
      {startTime: 2570, endTime: 2650, content: 'that'},
      {startTime: 2790, endTime: 3190, content: 'validates'},
      {startTime: 3330, endTime: 3390, content: 'the'},
      {startTime: 3530, endTime: 3850, content: 'utility'},
      {startTime: 4030, endTime: 4070, content: 'of'},
      {startTime: 4170, endTime: 4270, content: 'both'},
      {
        startTime: 4390,
        endTime: 4810,
        content: 'IMBRUVICA',
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 5330, endTime: 5390, content: 'and'},
      {
        startTime: 5510,
        endTime: 6030,
        content: 'VENCLEXTA',
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 6630, endTime: 6850, content: 'across', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 7030, endTime: 7050, content: 'a', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 7210, endTime: 7390, content: 'wide', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 7610, endTime: 7810, content: 'range', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 7970, endTime: 8010, content: 'of', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 8130, endTime: 8350, content: 'patient', styles: [highlightCss, darkBlueTextCss]},
      {
        startTime: 8550,
        endTime: 9090,
        content: 'populations',
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 9670, endTime: 9730, content: 'and', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 9890, endTime: 10130, content: 'cancer', styles: [highlightCss, darkBlueTextCss]},
      {
        startTime: 10270,
        endTime: 11090,
        content: 'types,',
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 11130, endTime: 11190, content: 'we', styles: [highlightCss, darkBlueTextCss]},
      {
        startTime: 11350,
        endTime: 11610,
        content: 'expect',
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 11770, endTime: 11870, content: 'this', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 12090, endTime: 12450, content: 'franchise'},
      {startTime: 12790, endTime: 12830, content: 'to'},
      {startTime: 12970, endTime: 13130, content: 'drive'},
      {startTime: 13910, endTime: 14330, content: 'significant'},
      {startTime: 14510, endTime: 14670, content: 'growth'},
      {startTime: 15210, endTime: 15310, content: 'for'},
      {startTime: 15390, endTime: 15530, content: 'many'},
      {startTime: 15610, endTime: 15770, content: 'years'},
      {startTime: 15930, endTime: 15970, content: 'to'},
      {startTime: 16070, endTime: 17110, content: 'come.'},
      {
        startTime: 17150,
        endTime: 18190,
        content: 'Additionally,',
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 18230, endTime: 18310, content: 'our', styles: [highlightCss, darkBlueTextCss]},
      {
        startTime: 18430,
        endTime: 18770,
        content: 'innovative',
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 18910, endTime: 19210, content: 'pipeline'},
      {startTime: 19410, endTime: 19710, content: 'includes'},
      {startTime: 19970, endTime: 20070, content: 'more'},
      {startTime: 20130, endTime: 20210, content: 'than'},
      {startTime: 20470, endTime: 20550, content: '20'},
      {startTime: 21010, endTime: 21230, content: 'active'},
      {startTime: 21970, endTime: 22350, content: 'development'},
      {startTime: 22570, endTime: 22910, content: 'programs'},
      {startTime: 23130, endTime: 23210, content: 'for'},
      {startTime: 23330, endTime: 23490, content: 'blood'},
      {startTime: 23630, endTime: 23950, content: 'cancers'},
      {startTime: 24630, endTime: 24690, content: 'and'},
      {startTime: 24850, endTime: 25070, content: 'solid'},
      {startTime: 25210, endTime: 25930, content: 'tumors,'},
      {startTime: 25950, endTime: 26070, content: 'which'},
      {startTime: 26170, endTime: 26210, content: 'we'},
      {startTime: 26410, endTime: 26650, content: 'expect'},
      {startTime: 27270, endTime: 27410, content: 'will'},
      {startTime: 27550, endTime: 27890, content: 'strengthen'},
      {startTime: 28130, endTime: 28190, content: 'our'},
      {startTime: 28310, endTime: 28550, content: 'growing'},
      {startTime: 28690, endTime: 28970, content: 'position'},
      {startTime: 29750, endTime: 29790, content: 'in', styles: [highlightCss, darkBlueTextCss]},
      {
        startTime: 29970,
        endTime: 31310,
        content: 'oncology.',
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 31940, endTime: 32000, content: 'The'},
      {startTime: 32160, endTime: 32540, content: 'acquisition'},
      {startTime: 32700, endTime: 32740, content: 'of'},
      {
        startTime: 32860,
        endTime: 33220,
        content: 'Allergan',
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 33720, endTime: 33920, content: 'brings'},
      {startTime: 34040, endTime: 34080, content: 'to'},
      {
        startTime: 34180,
        endTime: 34460,
        content: 'AbbVie',
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 34580, endTime: 34640, content: 'the'},
      {startTime: 34740, endTime: 35000, content: "world's"},
      {startTime: 35180, endTime: 35420, content: 'leading'},
      {startTime: 36000, endTime: 36260, content: 'medical'},
      {startTime: 36420, endTime: 36800, content: 'aesthetics'},
      {startTime: 37020, endTime: 38100, content: 'business.'},
      {startTime: 38140, endTime: 38240, content: 'With', styles: [highlightCss, darkBlueTextCss]},
      {
        startTime: 38400,
        endTime: 38640,
        content: 'revenue',
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 38720, endTime: 38760, content: 'of', styles: [highlightCss, darkBlueTextCss]},
      {
        startTime: 38900,
        endTime: 39100,
        content: 'nearly',
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 39240, endTime: 39860, content: '$4.5', styles: [highlightCss, darkBlueTextCss]},
      {
        startTime: 40180,
        endTime: 41080,
        content: 'billion,',
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 41100, endTime: 41220, content: 'this', styles: [highlightCss, darkBlueTextCss]},
      {
        startTime: 41380,
        endTime: 41800,
        content: 'primarily',
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 42460, endTime: 42640, content: 'cash', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 42900, endTime: 43020, content: 'paid', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 43220, endTime: 43500, content: 'business'},
      {startTime: 44220, endTime: 44300, content: 'is'},
      {startTime: 44440, endTime: 44680, content: 'growing'},
      {startTime: 44920, endTime: 45040, content: 'high'},
      {startTime: 45180, endTime: 45380, content: 'single'},
      {startTime: 45480, endTime: 45740, content: 'digits'},
      {startTime: 46600, endTime: 46660, content: 'and'},
      {startTime: 46760, endTime: 46840, content: 'is'},
      {startTime: 46960, endTime: 47220, content: 'anchored'},
      {startTime: 47380, endTime: 47420, content: 'by'},
      {startTime: 47660, endTime: 47820, content: 'very'},
      {startTime: 48020, endTime: 48360, content: 'well-known'},
      {startTime: 48520, endTime: 48920, content: 'brands,'},
      {startTime: 48960, endTime: 49320, content: 'including'},
      {startTime: 49920, endTime: 50260, content: 'BOTOX', styles: [highlightCss, darkBlueTextCss]},
    ],
    competition: [
      /* Google - Default
       *
       * We continue to generate data to validate the utility of both imbruvica and venclexta
       * franchise to drive significant growth for many years to come. I just can't lie or
       * Innovative pipeline includes more than 20 active development program for blood cancers in
       * solid tumors, which we will strengthen our glowing position. The acquisition of bring
       * Tabby the world's leading Medical Aesthetics business is growing high single-digit and is
       * anchored by very well-known Brands included.
       */

      {startTime: 350, endTime: 390, content: 'We'},
      {startTime: 610, endTime: 950, content: 'continue'},
      {startTime: 1050, endTime: 1090, content: 'to'},
      {startTime: 1270, endTime: 1570, content: 'generate'},
      {startTime: 1790, endTime: 1970, content: 'data'},
      {startTime: 2570, endTime: 2650, content: 'to'},
      {startTime: 2790, endTime: 3190, content: 'validate'},
      {startTime: 3330, endTime: 3390, content: 'the'},
      {startTime: 3530, endTime: 3850, content: 'utility'},
      {startTime: 4030, endTime: 4070, content: 'of'},
      {startTime: 4170, endTime: 4270, content: 'both'},
      {
        startTime: 4390,
        endTime: 4810,
        content: 'imbruvica',
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 5330, endTime: 5390, content: 'and'},
      {
        startTime: 5510,
        endTime: 6030,
        content: 'venclexta',
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 12090, endTime: 12450, content: 'franchise'},
      {startTime: 12790, endTime: 12830, content: 'to'},
      {startTime: 12970, endTime: 13130, content: 'drive'},
      {startTime: 13910, endTime: 14330, content: 'significant'},
      {startTime: 14510, endTime: 14670, content: 'growth'},
      {startTime: 15210, endTime: 15310, content: 'for'},
      {startTime: 15390, endTime: 15530, content: 'many'},
      {startTime: 15610, endTime: 15770, content: 'years'},
      {startTime: 15930, endTime: 15970, content: 'to'},
      {startTime: 16070, endTime: 17110, content: 'come.'},
      {
        startTime: 17150,
        endTime: 18190,
        content: "I just can't lie",
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 18230, endTime: 18310, content: 'or', styles: [highlightCss, darkBlueTextCss]},
      {
        startTime: 18430,
        endTime: 18770,
        content: 'Innovative',
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 18910, endTime: 19210, content: 'pipeline'},
      {startTime: 19410, endTime: 19710, content: 'includes'},
      {startTime: 19970, endTime: 20070, content: 'more'},
      {startTime: 20130, endTime: 20210, content: 'than'},
      {startTime: 20470, endTime: 20550, content: '20'},
      {startTime: 21010, endTime: 21230, content: 'active'},
      {startTime: 21970, endTime: 22350, content: 'development'},
      {startTime: 22570, endTime: 22910, content: 'program'},
      {startTime: 23130, endTime: 23210, content: 'for'},
      {startTime: 23330, endTime: 23490, content: 'blood'},
      {startTime: 23630, endTime: 23950, content: 'cancers'},
      {startTime: 24630, endTime: 24690, content: 'in'},
      {startTime: 24850, endTime: 25070, content: 'solid'},
      {startTime: 25210, endTime: 25930, content: 'tumors,'},
      {startTime: 25950, endTime: 26070, content: 'which'},
      {startTime: 26170, endTime: 26210, content: 'we'},
      {startTime: 27270, endTime: 27410, content: 'will'},
      {startTime: 27550, endTime: 27890, content: 'strengthen'},
      {startTime: 28130, endTime: 28190, content: 'our'},
      {startTime: 28310, endTime: 28550, content: 'glowing'},
      {startTime: 28690, endTime: 28970, content: 'position.'},
      {startTime: 31940, endTime: 32000, content: 'The'},
      {startTime: 32160, endTime: 32540, content: 'acquisition'},
      {startTime: 32700, endTime: 32740, content: 'of'},
      {startTime: 33720, endTime: 33920, content: 'bring'},
      {startTime: 34180, endTime: 34460, content: 'Tabby', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 34580, endTime: 34640, content: 'the'},
      {startTime: 34740, endTime: 35000, content: "world's"},
      {startTime: 35180, endTime: 35420, content: 'leading'},
      {startTime: 36000, endTime: 36260, content: 'Medical'},
      {startTime: 36420, endTime: 36800, content: 'Aesthetics'},
      {startTime: 37020, endTime: 38100, content: 'business'},
      {startTime: 43220, endTime: 43500, content: 'business'},
      {startTime: 44220, endTime: 44300, content: 'is'},
      {startTime: 44440, endTime: 44680, content: 'growing'},
      {startTime: 44920, endTime: 45040, content: 'high'},
      {startTime: 45180, endTime: 45380, content: 'single-digit'},
      {startTime: 46600, endTime: 46660, content: 'and'},
      {startTime: 46760, endTime: 46840, content: 'is'},
      {startTime: 46960, endTime: 47220, content: 'anchored'},
      {startTime: 47380, endTime: 47420, content: 'by'},
      {startTime: 47660, endTime: 47820, content: 'very'},
      {startTime: 48020, endTime: 48360, content: 'well-known'},
      {startTime: 48520, endTime: 48920, content: 'Brands,'},
      {startTime: 48960, endTime: 49320, content: 'included.'},
    ],
  },

  {
    name: 'Heavily Accented Speech',
    audio: accentedSpeechAudio,
    duration: 7488,
    scribe: [
      /* Already. So our factories in Wujiang, Dongguan and even in Thailand after we */
      {startTime: 170, endTime: 670, content: 'Already.'},
      {startTime: 710, endTime: 750, content: 'So'},
      {startTime: 1030, endTime: 1130, content: 'our'},
      {startTime: 1270, endTime: 1610, content: 'factories'},
      {startTime: 2510, endTime: 2550, content: 'in'},
      {
        startTime: 2790,
        endTime: 3410,
        content: 'Wujiang,',
        styles: [highlightCss, darkBlueTextCss],
      },
      {
        startTime: 3450,
        endTime: 3850,
        content: 'Dongguan',
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 4090, endTime: 4150, content: 'and'},
      {startTime: 4290, endTime: 4430, content: 'even'},
      {startTime: 4630, endTime: 4690, content: 'in'},
      {startTime: 4830, endTime: 5370, content: 'Thailand'},
      {startTime: 5430, endTime: 5590, content: 'after'},
      {startTime: 5730, endTime: 5790, content: 'we'},
    ],
    competition: [
      /* Google - Phone Call
       *
       * Karate, so our fax is in rude on don't go on a even Thailand after wake up.
       */
      {startTime: 170, endTime: 670, content: 'Karate,'},
      {startTime: 710, endTime: 750, content: 'so'},
      {startTime: 1030, endTime: 1130, content: 'our'},
      {startTime: 1270, endTime: 1610, content: 'fax is'},
      {startTime: 2510, endTime: 2550, content: 'in'},
      {startTime: 2790, endTime: 3410, content: 'rude on', styles: [highlightCss, darkBlueTextCss]},
      {
        startTime: 3450,
        endTime: 3850,
        content: 'don’t go on',
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 4090, endTime: 4150, content: 'a'},
      {startTime: 4290, endTime: 4430, content: 'even'},
      {startTime: 4630, endTime: 4690, content: 'Thailand'},
      {startTime: 4830, endTime: 5370, content: 'after'},
      {startTime: 5430, endTime: 5590, content: 'wake'},
      {startTime: 5730, endTime: 5790, content: 'up'},
    ],
  },

  {
    name: 'Industry-Specific Jargon',
    audio: industrySpecificJargonAudio,
    duration: 33044,
    scribe: [
      /* As indicated on last quarter's call, we're not reporting adjusted profit per share again
       * this quarter as restructuring costs are expected to be lower in 2019. It's our intention
       * to report adjusted profit per share at the end of the fourth quarter of 2019 to exclude
       * any mark-to-market gain or loss for the remeasurement of pension and any other
       * post-employment benefit plans as well as any other discrete items. As a reminder, our
       * U.S. GAAP-based guidance for profit per share continues to include the benefit of a $0.31
       * discrete tax item we recognized in the first quarter.
       */
      {startTime: 310, endTime: 350, content: 'As'},
      {startTime: 490, endTime: 790, content: 'indicated'},
      {startTime: 930, endTime: 970, content: 'on'},
      {startTime: 1110, endTime: 1230, content: 'last'},
      {startTime: 1330, endTime: 1590, content: "quarter's"},
      {startTime: 1690, endTime: 1930, content: 'call,'},
      {startTime: 1950, endTime: 2110, content: "we're"},
      {startTime: 2170, endTime: 2250, content: 'not'},
      {startTime: 2370, endTime: 2670, content: 'reporting'},
      {startTime: 2790, endTime: 3070, content: 'adjusted'},
      {startTime: 3170, endTime: 3370, content: 'profit'},
      {startTime: 3470, endTime: 3550, content: 'per'},
      {startTime: 3610, endTime: 3730, content: 'share'},
      {startTime: 3930, endTime: 4090, content: 'again'},
      {startTime: 4230, endTime: 4310, content: 'this'},
      {startTime: 4410, endTime: 4590, content: 'quarter'},
      {startTime: 5090, endTime: 5130, content: 'as'},
      {startTime: 5250, endTime: 5670, content: 'restructuring'},
      {startTime: 5790, endTime: 5970, content: 'costs'},
      {startTime: 6170, endTime: 6230, content: 'are'},
      {startTime: 6350, endTime: 6630, content: 'expected'},
      {startTime: 6710, endTime: 6750, content: 'to'},
      {startTime: 6790, endTime: 6830, content: 'be'},
      {startTime: 6930, endTime: 7090, content: 'lower'},
      {startTime: 7210, endTime: 7250, content: 'in'},
      {startTime: 7370, endTime: 8690, content: '2019.'},
      {startTime: 8750, endTime: 8870, content: "It's"},
      {startTime: 8950, endTime: 9010, content: 'our'},
      {startTime: 9090, endTime: 9370, content: 'intention'},
      {startTime: 9570, endTime: 9610, content: 'to'},
      {startTime: 9690, endTime: 9910, content: 'report'},
      {startTime: 10090, endTime: 10390, content: 'adjusted'},
      {startTime: 10530, endTime: 10730, content: 'profit'},
      {startTime: 10830, endTime: 10910, content: 'per'},
      {startTime: 10990, endTime: 11110, content: 'share'},
      {startTime: 11550, endTime: 11590, content: 'at'},
      {startTime: 11670, endTime: 11730, content: 'the'},
      {startTime: 11790, endTime: 11870, content: 'end'},
      {startTime: 11930, endTime: 11970, content: 'of'},
      {startTime: 12030, endTime: 12090, content: 'the'},
      {startTime: 12130, endTime: 12270, content: 'first', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 12590, endTime: 12610, content: '--', styles: [highlightCss, darkBlueTextCss]},
      {
        startTime: 12650,
        endTime: 12810,
        content: 'fourth',
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 12930, endTime: 13090, content: 'quarter'},
      {startTime: 13190, endTime: 13230, content: 'of'},
      {startTime: 13370, endTime: 13870, content: '2019'},
      {startTime: 14650, endTime: 14690, content: 'to'},
      {startTime: 14830, endTime: 15090, content: 'exclude'},
      {startTime: 15350, endTime: 15470, content: 'any'},
      {startTime: 15550, endTime: 16070, content: 'mark-to-market'},
      {startTime: 16230, endTime: 16370, content: 'gain'},
      {startTime: 16490, endTime: 16530, content: 'or'},
      {startTime: 16630, endTime: 16770, content: 'loss'},
      {startTime: 17400, endTime: 17480, content: 'for'},
      {startTime: 17401, endTime: 17460, content: 'the'},
      {startTime: 17490, endTime: 17930, content: 'remeasurement'},
      {startTime: 18050, endTime: 18090, content: 'of'},
      {startTime: 18210, endTime: 18430, content: 'pension'},
      {startTime: 18910, endTime: 18970, content: 'and'},
      {startTime: 19090, endTime: 19210, content: 'any'},
      {startTime: 19310, endTime: 19470, content: 'other'},
      {startTime: 19590, endTime: 20110, content: 'post-employment'},
      {startTime: 20210, endTime: 20470, content: 'benefit'},
      {startTime: 20610, endTime: 20810, content: 'plans'},
      {startTime: 21130, endTime: 21190, content: 'as'},
      {startTime: 21250, endTime: 21350, content: 'well'},
      {startTime: 21410, endTime: 21450, content: 'as'},
      {startTime: 21590, endTime: 21670, content: 'any'},
      {startTime: 21750, endTime: 21890, content: 'other'},
      {startTime: 21970, endTime: 22270, content: 'discrete'},
      {startTime: 22410, endTime: 23850, content: 'items.'},
      {startTime: 23890, endTime: 23930, content: 'As'},
      {startTime: 24030, endTime: 24050, content: 'a'},
      {startTime: 24130, endTime: 24650, content: 'reminder,'},
      {startTime: 24690, endTime: 24750, content: 'our'},
      {startTime: 24830, endTime: 24990, content: 'U.S.', styles: [highlightCss, darkBlueTextCss]},
      {
        startTime: 25110,
        endTime: 25550,
        content: 'GAAP-based',
        styles: [highlightCss, darkBlueTextCss],
      },
      {startTime: 25650, endTime: 25890, content: 'guidance'},
      {startTime: 26030, endTime: 26110, content: 'for'},
      {startTime: 26210, endTime: 26410, content: 'profit'},
      {startTime: 26510, endTime: 26590, content: 'per'},
      {startTime: 26650, endTime: 26770, content: 'share'},
      {startTime: 26970, endTime: 27270, content: 'continues'},
      {startTime: 27370, endTime: 27410, content: 'to'},
      {startTime: 27510, endTime: 27710, content: 'include'},
      {startTime: 27830, endTime: 27890, content: 'the'},
      {startTime: 27950, endTime: 28210, content: 'benefit'},
      {startTime: 28430, endTime: 28470, content: 'of'},
      {startTime: 28570, endTime: 28590, content: 'the'},
      {startTime: 28650, endTime: 29010, content: '$0.31', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 29430, endTime: 29730, content: 'discrete'},
      {startTime: 29830, endTime: 29930, content: 'tax'},
      {startTime: 30150, endTime: 30290, content: 'item'},
      {startTime: 30690, endTime: 30730, content: 'we'},
      {startTime: 30870, endTime: 31330, content: 'recognized'},
      {startTime: 31630, endTime: 31670, content: 'in'},
      {startTime: 31710, endTime: 31790, content: 'the'},
      {startTime: 31830, endTime: 31970, content: 'first'},
      {startTime: 32090, endTime: 32650, content: 'quarter.'},
    ],
    competition: [
      /* Google - Phone Call (Enhanced)
       *
       * As indicated on last quarter's call. We're not reporting adjusted profit per share. Again.
       * This quarter as restructuring costs are expected to be lower in 2019. It's Our intention
       * to report adjusted profit per share if the May 1st fourth quarter of 2019 to exclude any
       * mark-to-market gain or loss for the remeasurement of pension and any other post-employment
       * benefit plans as well as any other discrete items off reminder. Are you based guidance for
       * profit per share continues to include the benefit of the 31% discreet tax item. We
       * recognized in the first quarter.
       */
      {startTime: 310, endTime: 350, content: 'As'},
      {startTime: 490, endTime: 790, content: 'indicated'},
      {startTime: 930, endTime: 970, content: 'on'},
      {startTime: 1110, endTime: 1230, content: 'last'},
      {startTime: 1330, endTime: 1590, content: "quarter's"},
      {startTime: 1690, endTime: 1930, content: 'call.'},
      {startTime: 1950, endTime: 2110, content: "We're"},
      {startTime: 2170, endTime: 2250, content: 'not'},
      {startTime: 2370, endTime: 2670, content: 'reporting'},
      {startTime: 2790, endTime: 3070, content: 'adjusted'},
      {startTime: 3170, endTime: 3370, content: 'profit'},
      {startTime: 3470, endTime: 3550, content: 'per'},
      {startTime: 3610, endTime: 3730, content: 'Again.'},
      {startTime: 4230, endTime: 4310, content: 'This'},
      {startTime: 4410, endTime: 4590, content: 'quarter'},
      {startTime: 5090, endTime: 5130, content: 'as'},
      {startTime: 5250, endTime: 5670, content: 'restructuring'},
      {startTime: 5790, endTime: 5970, content: 'costs'},
      {startTime: 6170, endTime: 6230, content: 'are'},
      {startTime: 6350, endTime: 6630, content: 'expected'},
      {startTime: 6710, endTime: 6750, content: 'to'},
      {startTime: 6790, endTime: 6830, content: 'be'},
      {startTime: 6930, endTime: 7090, content: 'lower'},
      {startTime: 7210, endTime: 7250, content: 'in'},
      {startTime: 7370, endTime: 8690, content: '2019.'},
      {startTime: 8750, endTime: 8870, content: "It's"},
      {startTime: 8950, endTime: 9010, content: 'Our'},
      {startTime: 9090, endTime: 9370, content: 'intention'},
      {startTime: 9570, endTime: 9610, content: 'to'},
      {startTime: 9690, endTime: 9910, content: 'report'},
      {startTime: 10090, endTime: 10390, content: 'adjusted'},
      {startTime: 10530, endTime: 10730, content: 'profit'},
      {startTime: 10830, endTime: 10910, content: 'per'},
      {startTime: 10990, endTime: 11110, content: 'share'},
      {startTime: 11550, endTime: 11590, content: 'if'},
      {startTime: 11670, endTime: 11730, content: 'the', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 11790, endTime: 11870, content: 'May', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 11930, endTime: 11970, content: '1st', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 12650, endTime: 12810, content: 'fourth'},
      {startTime: 12930, endTime: 13090, content: 'quarter'},
      {startTime: 13190, endTime: 13230, content: 'of'},
      {startTime: 13370, endTime: 13870, content: '2019'},
      {startTime: 14650, endTime: 14690, content: 'to'},
      {startTime: 14830, endTime: 15090, content: 'exclude'},
      {startTime: 15350, endTime: 15470, content: 'any'},
      {startTime: 15550, endTime: 16070, content: 'mark-to-market'},
      {startTime: 16230, endTime: 16370, content: 'gain'},
      {startTime: 16490, endTime: 16530, content: 'or'},
      {startTime: 16630, endTime: 16770, content: 'loss'},
      {startTime: 17400, endTime: 17480, content: 'for'},
      {startTime: 17401, endTime: 17460, content: 'the'},
      {startTime: 17490, endTime: 17930, content: 'remeasurement'},
      {startTime: 18050, endTime: 18090, content: 'of'},
      {startTime: 18210, endTime: 18430, content: 'pension'},
      {startTime: 18910, endTime: 18970, content: 'and'},
      {startTime: 19090, endTime: 19210, content: 'any'},
      {startTime: 19310, endTime: 19470, content: 'other'},
      {startTime: 19590, endTime: 20110, content: 'post-employment'},
      {startTime: 20210, endTime: 20470, content: 'benefit'},
      {startTime: 20610, endTime: 20810, content: 'plans'},
      {startTime: 21130, endTime: 21190, content: 'as'},
      {startTime: 21250, endTime: 21350, content: 'well'},
      {startTime: 21410, endTime: 21450, content: 'as'},
      {startTime: 21590, endTime: 21670, content: 'any'},
      {startTime: 21750, endTime: 21890, content: 'other'},
      {startTime: 21970, endTime: 22270, content: 'discrete'},
      {startTime: 22410, endTime: 23850, content: 'items'},
      {startTime: 23890, endTime: 23930, content: 'off'},
      {startTime: 24130, endTime: 24650, content: 'reminder,'},
      {startTime: 24690, endTime: 24750, content: 'Are'},
      {startTime: 24830, endTime: 24990, content: 'you', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 25110, endTime: 25550, content: 'based', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 25650, endTime: 25890, content: 'guidance'},
      {startTime: 26030, endTime: 26110, content: 'for'},
      {startTime: 26210, endTime: 26410, content: 'profit'},
      {startTime: 26510, endTime: 26590, content: 'per'},
      {startTime: 26650, endTime: 26770, content: 'share'},
      {startTime: 26970, endTime: 27270, content: 'continues'},
      {startTime: 27370, endTime: 27410, content: 'to'},
      {startTime: 27510, endTime: 27710, content: 'include'},
      {startTime: 27830, endTime: 27890, content: 'the'},
      {startTime: 27950, endTime: 28210, content: 'benefit'},
      {startTime: 28430, endTime: 28470, content: 'of'},
      {startTime: 28570, endTime: 28590, content: 'the'},
      {startTime: 28650, endTime: 29010, content: '31%', styles: [highlightCss, darkBlueTextCss]},
      {startTime: 29430, endTime: 29730, content: 'discreet'},
      {startTime: 29830, endTime: 29930, content: 'tax'},
      {startTime: 30150, endTime: 30290, content: 'item.'},
      {startTime: 30690, endTime: 30730, content: 'We'},
      {startTime: 30870, endTime: 31330, content: 'recognized'},
      {startTime: 31630, endTime: 31670, content: 'in'},
      {startTime: 31710, endTime: 31790, content: 'the'},
      {startTime: 31830, endTime: 31970, content: 'first'},
      {startTime: 32090, endTime: 32650, content: 'quarter.'},
    ],
  },
]

export default transcriptionSamples
