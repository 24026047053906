import {StrictMode} from 'react'
import {Global} from '@emotion/react'

import AppHead from './AppHead'
import AppLoggerProvider from './AppLoggerProvider'
import ErrorBoundary from './components/ErrorBoundary'
import ScrollManager from './components/ScrollManager'
import globalCss from './styles/global'
import {IsMountedProvider} from './hooks/useIsMounted'
import AppRouter from './AppRouter'
import fonts from './styles/fonts'

export default function App(): JSX.Element {
  return (
    <StrictMode>
      <AppLoggerProvider>
        <IsMountedProvider>
          <ErrorBoundary>
            {fonts}
            <AppHead />
            <Global styles={globalCss} />
            <ScrollManager />
            <AppRouter />
          </ErrorBoundary>
        </IsMountedProvider>
      </AppLoggerProvider>
    </StrictMode>
  )
}
