/**
 * Converts seconds to mm:ss
 *
 * @param seconds seconds to convert
 * @returns mm:ss
 */
export default function durationToMinutesAndSeconds(seconds: number): string {
  const minutes = Math.floor(seconds / 60)
  const remainder = Math.round(seconds % 60)
  return `${minutes}:${remainder < 10 ? '0' : ''}${remainder}`
}
