import {css, SerializedStyles} from '@emotion/react'

/** Generate a flex rule with appropriate flex-basis for the number of items and max row size */
export default function getRowItemFlex(items: number, maxItemsPerRow?: number): SerializedStyles {
  let flexBasis
  if (items < 3) {
    flexBasis = 0.5
  } else if (items < 7) {
    flexBasis = 0.33
  } else if (items < 11) {
    flexBasis = 0.25
  } else {
    flexBasis = 0.2
  }

  if (maxItemsPerRow) {
    flexBasis = Math.max(1 / maxItemsPerRow, flexBasis)
  }

  return css`
    flex: 0 1 ${Math.floor(flexBasis * 100)}%;
  `
}
