import {useRef, useEffect, useCallback} from 'react'
import {ResizeObserver} from '@juggle/resize-observer'

import {Size} from './types'

/**
 * Listens for changes in the bounding box of an element using a `ResizeObserver`.
 *
 * This is a low-level primitive for advanced use cases. For the common case of tracking the height
 * and/or width of an element, see `useElementSize`, `useElementHeight`, and `useElementWidth`.
 *
 * @param callback A callback to invoke on element resize.
 * @returns A callback ref to attach to the element.
 *
 * @see https://tacklebox.beta-p.kensho.com/useResizeObserver
 */
export default function useResizeObserver(
  callback: (size: Size) => void
): (node: HTMLElement | null) => void {
  const callbackRef = useRef(callback)
  const elementRef = useRef<HTMLElement | null>(null)
  const observerRef = useRef<ResizeObserver | null>(null)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  function getObserver(): ResizeObserver {
    if (observerRef.current === null) {
      observerRef.current = new ResizeObserver((entries) => {
        /* istanbul ignore next: just a safeguard, this should never happen */
        if (entries.length === 0) return
        const {inlineSize: width, blockSize: height} = entries[0].contentBoxSize[0]
        callbackRef.current({height, width})
      })
    }
    return observerRef.current
  }

  return useCallback((node: HTMLElement | null): void => {
    const observer = getObserver()
    if (elementRef.current) observer.unobserve(elementRef.current)
    if (node) observer.observe(node)
    elementRef.current = node
  }, [])
}
