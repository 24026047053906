import {css} from '@emotion/react'
import {IconCheck, IconXSmall} from '@kensho/icons'

import ButtonLink from '../../components/Button/ButtonLink'
import {AnimatedContent, WideContent} from '../../components/content'
import ProductComparisonTable from '../../components/ProductComparisonTable'
import {URLS} from '../../constants'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {
  backgroundLightBlueCss,
  baseSectionCss,
  centeredTextCss,
  whiteTextCss,
} from '../../styles/common'

const contentCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1130px;
`

const headerCss = css`
  font-size: 40px;
  margin-bottom: 60px;
`

const animationContainerWidthCss = css`
  width: 100%;
`

const endButtonCss = css`
  height: 44px;
  width: 185px;
  margin-top: 30px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    width: 174px;
    margin-top: 50px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 100%;
    margin-top: 23px;
  }
`

export default function CompareSection(): JSX.Element {
  return (
    <section css={[baseSectionCss, backgroundLightBlueCss]}>
      <WideContent css={contentCss}>
        <AnimatedContent>
          <h3 css={[headerCss, centeredTextCss, whiteTextCss]}>
            Classify outperforms alternatives across critical dimensions, delivering higher quality
            tags with less time and data, and is ready to meet enterprise workloads.
          </h3>
        </AnimatedContent>
        <AnimatedContent animation="fade-in" css={animationContainerWidthCss}>
          <ProductComparisonTable
            headerCells={[
              {key: 'label', display: null},
              {key: 'classify', display: 'Classify'},
              {key: 'alternatives', display: 'Alternatives'},
            ]}
            rows={[
              {
                key: 'prediction-quality',
                cells: [
                  {key: 'label', display: 'Prediction Quality (F1)'},
                  {key: 'classify', display: <IconCheck size={35} />},
                  {key: 'alternatives', display: <IconXSmall size={36} />},
                ],
              },
              {
                key: 'training-time',
                cells: [
                  {key: 'label', display: 'Training Time'},
                  {key: 'classify', display: <IconCheck size={35} />},
                  {key: 'alternatives', display: <IconXSmall size={36} />},
                ],
              },
              {
                key: 'annotation-time',
                cells: [
                  {key: 'label', display: 'Annotation Time'},
                  {key: 'classify', display: <IconCheck size={35} />},
                  {key: 'alternatives', display: <IconXSmall size={36} />},
                ],
              },
              {
                key: 'payload-capacity',
                cells: [
                  {key: 'label', display: 'Payload Capacity'},
                  {key: 'classify', display: <IconCheck size={35} />},
                  {key: 'alternatives', display: <IconXSmall size={36} />},
                ],
              },
              {
                key: 'training-data',
                cells: [
                  {key: 'label', display: 'Training Data Needed'},
                  {key: 'classify', display: <IconCheck size={35} />},
                  {key: 'alternatives', display: <IconCheck size={35} />},
                ],
              },
              {
                key: 'buttons',
                cells: [
                  {key: 'label', display: null},
                  {
                    key: 'classify',
                    display: (
                      <ButtonLink
                        to="mailto:sales@kensho.com?subject=Classify"
                        variant="primary"
                        css={[endButtonCss, centeredTextCss]}
                      >
                        Talk to Us
                      </ButtonLink>
                    ),
                  },
                  {
                    key: 'alternatives',
                    display: (
                      <ButtonLink
                        to={`${URLS.FREE_TRIAL}/classify`}
                        variant="primary"
                        css={[endButtonCss, centeredTextCss]}
                      >
                        Free Trial
                      </ButtonLink>
                    ),
                  },
                ],
              },
            ]}
          />
        </AnimatedContent>
      </WideContent>
    </section>
  )
}
