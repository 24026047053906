import {useEffect, useState} from 'react'

interface TextTyperProps {
  className?: string
  children: string
  typingSpeed?: number
  paused?: boolean
}

export const DEFAULT_TYPING_SPEED = 20

export default function TextTyper({
  className,
  children,
  typingSpeed = DEFAULT_TYPING_SPEED,
  paused,
}: TextTyperProps): JSX.Element {
  const [typedChars, setTypedChars] = useState('')

  useEffect(() => {
    let timeoutId: number
    if (!paused && typedChars !== children) {
      timeoutId = window.setTimeout(() => {
        setTypedChars(children.slice(0, typedChars.length + 1))
      }, typingSpeed)
    }

    return () => {
      window.clearTimeout(timeoutId)
    }
  }, [children, typedChars, typingSpeed, paused])

  return <span className={className}>{typedChars}</span>
}
